import React from "react";
import './TextField.css';
import { useTranslation } from 'react-i18next';


const TextField = ({ label, type, placeholder, value, onChange, disabled, className, instruction }) => {
    const { t } = useTranslation();

    
    return (
        <div className={`textfield`}>
            {label && <label className="text-field-label">{t(label)}</label>}
            {instruction && <p className="instruction-text">{t(instruction)}</p>}
            <input className="text-field-input"
                type={type}
                placeholder={t(placeholder)}
                value={value}
                onChange={onChange}
                disabled={disabled}
            />
        </div>
    );
};

export default TextField;
