import React, { useState, useEffect } from 'react';
import './RecruiterSettings.css';
import NavbarRecruiting from '../../../components/navbar_recruiting/NavbarRecruiting';
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery, useMutation, gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import TextField from '../../../components/textfields/TextField';
import LongTextField from '../../../components/textfields/LongTextField';
import axios from 'axios'; // You need to install axios if you haven't: `npm install axios`
import { loadStripe } from '@stripe/stripe-js';
import { useSubscription } from '../../../contexts/SubscriptionContext';
import SaveChanges from '../../../components/save-changes/SaveChanges';

const stripePromise = loadStripe('your_public_key');


// GraphQL Queries and Mutations
const GET_RECRUITER_BY_USER = gql`
query GetRecruiterByUserId($userId: String!) {
    getRecruiterByUserId(userID: $userId) {
      id
      userID
      company
      description
      email
    }
  }
`;

const UPDATE_RECRUITER_PROFILE = gql`
mutation UpdateRecruiter($id: ID!, $company: String!, $description: String!, $email: String!) {
    updateRecruiter(id: $id, company: $company, description: $description, email: $email) {
        id
        company
        description
        email
    }
}
`;

    const RecruiterSettings = () => {
      const {
          user,
          isAuthenticated,
          isLoading,
          getAccessTokenSilently, // Retrieve the getAccessTokenSilently function from the hook
      } = useAuth0();
      const { subscription, data, stripeCustomerId } = useSubscription();
      const [activeSection, setActiveSection] = useState('basicInfo');
      const { t } = useTranslation();

      const fetchPlans = async () => {
        const response = await axios.get('https://simpl-backend-f1e8862591da.herokuapp.com/stripe/plans');
        //console.log ('Plans:', response.data);
    };

    const handleSectionClick = (section) => {
        setActiveSection(section);
    };



    return (
        <div className='recruiter-settings-page'>
          <NavbarRecruiting />
            <div className='settings-navigation'>

              <div className={`nav-item ${activeSection === 'basicInfo' ? 'active' : ''}`} onClick={() => handleSectionClick('basicInfo')}>
                {t('Basic Information')}
              </div>
             
              <div className={`nav-item ${activeSection === 'managePlan' ? 'active' : ''}`} onClick={() => handleSectionClick('managePlan')}>
                {t('Manage Plan')}
              </div>
            </div>
            <div className='settings-content'>
              {activeSection === 'basicInfo' && <BasicInformation />}
              {activeSection === 'managePlan' && <ManagePlan/>}
            </div>
        </div>
    );
}

const BasicInformation = () => {
  const { user } = useAuth0();
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [description, setDescription] = useState("");
  const [initialValues, setInitialValues] = useState({});
  const [isModified, setIsModified] = useState(false);

  const { loading, error, data } = useQuery(GET_RECRUITER_BY_USER, {
      variables: { userId: user?.sub },
      onCompleted: data => {
          if (data?.getRecruiterByUserId) {
              const { email, company, description } = data.getRecruiterByUserId;
              setEmail(email);
              setCompany(company);
              setDescription(description);
              setInitialValues({ email, company, description });
          }
      }
  });

  const [updateRecruiterProfile, { data: updateData }] = useMutation(UPDATE_RECRUITER_PROFILE);

  useEffect(() => {
    if (initialValues.email !== email || initialValues.company !== company || initialValues.description !== description) {
      setIsModified(true);
    } else {
      setIsModified(false);
    }
  }, [email, company, description, initialValues]);

  const handleSave = () => {
      updateRecruiterProfile({
          variables: {
              id: data?.getRecruiterByUserId?.id,
              email: email,
              company: company,
              description: description
          }
      }).then(response => {
          // You can add more sophisticated feedback to the user here
          alert('Profile updated successfully!');
          setInitialValues({ email, company, description }); // Update initial values after saving
      }).catch(error => {
          console.error('Error updating profile:', error);
          alert('Failed to update profile.');
      });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data!</p>;

  return (
      <div>
          <h2>Basic Information</h2>
          <TextField 
              label="Email" 
              type="email" 
              placeholder="Email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
          />
          <TextField 
              label="Company Name" 
              type="text" 
              placeholder="Company Name" 
              value={company} 
              onChange={(e) => setCompany(e.target.value)} 
          />
          <LongTextField 
              label="Description" 
              type="text" 
              placeholder="Description" 
              value={description} 
              onChange={(e) => setDescription(e.target.value)} 
          />
          <SaveChanges onClick={handleSave} isModified={isModified} />
      </div>
  );
};


const PlanPromotionCard = ({ planName, price, features, bestValue, onUpgrade }) => {
  return (
    <div className="plan-promotion-card">
      <div className='plan-card-left'>
        <div className="plan-name">{planName}</div>
        <div className="price-container">
          <span className="plan-price">{price}</span>
          <span className="price-period">/month</span>
        </div>
        <div className="features-container">
          {features.map((feature, index) => (
            <div className="feature-item" key={index}>
              {feature.text}
              {feature.earlyAdopter && <span className="early-adopter-tag">Early Adopter Only</span>}
            </div>
          ))}
        </div>
        <button onClick={onUpgrade}>Start Now</button>
      </div>
      <div className='plan-card-right'>
        {bestValue && <div className="best-value-tag">Best Value</div>}
      </div>
    </div>
  );
};

const ManagePlan = () => {

  const fetchAuthToken = async () => {
    try {
      //console.log ('Fetching Auth0 token...');
      //console.log  ('Auth0 client id:', process.env.REACT_APP_AUTH0_MANAGEMENT_CLIENT_ID);
      //console.log  ('Auth0 client secret:', process.env.REACT_APP_AUTH0_CLIENT_SECRET);
      //console.log  ('Auth0 audience:', process.env.REACT_APP_AUTH0_AUDIENCE);
      
      const response = await axios.post(
        'https://dev-l6ki5j3n4dfx6eg6.eu.auth0.com/oauth/token',
        new URLSearchParams({
          grant_type: 'client_credentials',
          client_id: process.env.REACT_APP_AUTH0_MANAGEMENT_CLIENT_ID,
          client_secret: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE
        }),
        {
          headers: {
            'content-type': 'application/x-www-form-urlencoded'
          }
        }
      );
      
      //console.log ('Auth0 token response:', response.data);
      return response.data.access_token;
    } catch (error) {
      console.error('Error fetching Auth0 token:', error.response ? error.response.data : error.message);
      throw error;
    }
  };
  const handleManageBilling = () => {
    window.location.href = 'https://billing.stripe.com/p/login/7sIdS09qggvA1DaaEE';
  };
  const { subscription, stripeCustomerId } = useSubscription();

  const onPlanUpgrade = async (customerID) => {
    try {
      //console.log ('Starting plan upgrade process...');
      const accessToken = await fetchAuthToken();
      //console.log ('Access token fetched successfully:', accessToken);
  
      if (!stripeCustomerId) {
        throw new Error('Invalid customerID');
      }
      //console.log ('Subscription:', subscription)
      //console.log ('Customer ID:', stripeCustomerId);
  
      const response = await fetch('https://simpl-backend-f1e8862591da.herokuapp.com/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ customerID })
      });
  
      const data = await response.json();
  
      if (response.ok) {
        //console.log ('Checkout session created successfully:', data);
        if (data.url) {
          try {
            new URL(data.url);
            window.location.href = data.url;
          } catch (e) {
            console.error('Invalid URL in response:', data.url);
            alert('Received invalid URL from server.');
          }
        }
      } else {
        console.error('Error creating checkout session:', data);
        alert(`Error: ${data.message || 'Failed to create checkout session'}`);
      }
    } catch (error) {
      console.error('Error in onPlanUpgrade:', error);
      alert(`Error: ${error.message || 'An unexpected error occurred'}`);
    }
  };
  
  
  

  if (subscription === "Free") {
    return (
      <PlanPromotionCard
        planName="Early Adopter"
        price="€59"
        features={[
          { text: "Up to 30 Job Offers per year", earlyAdopter: false },
          { text: "Post on any job board", earlyAdopter: false },
          { text: "Candidate management features", earlyAdopter: false },
          { text: "Dedicated Founder Support", earlyAdopter: true },
          { text: "New Feature Requests", earlyAdopter: true }
        ]}
        bestValue={true}
        onUpgrade={() => onPlanUpgrade(stripeCustomerId)}
        />
    );
  }

  return (
    <div>
      <h2>Manage Plan</h2>
      <p>Manage your subscription details.</p>
      <button className='manage-billing-button' onClick={handleManageBilling}>Manage Billing</button>
    </div>
  );
};

// Notification Settings Section
const NotificationSettings = () => {
  return (
      <div>
          <h2>Notification Settings</h2>
          <TextField label="Email Notifications" type="checkbox" />
      </div>
  );
}



export default RecruiterSettings;
