import React from "react";
import './LogoutButton.css'
import { useAuth0 } from '@auth0/auth0-react';
import logoutimage from '../../images/logout.svg';

function LogoutButton({ isExpanded }) {
  const { logout } = useAuth0();

  const handleLogout = () => {
    // Clear local and session storage
    localStorage.clear();
    sessionStorage.clear();

    // Log out using Auth0
    logout({ returnTo: window.location.origin });
  };

  return (
    <button className="logout-button" onClick={handleLogout}>
      <img src={logoutimage} alt="Logout" className="logout-svg"/>
      {isExpanded && <span>Log Out</span>}
    </button>
  );
};

export default LogoutButton;