import React from 'react';
import './PersonalInfo.css';
import Tag from '../tag/Tag';
import { useTranslation } from 'react-i18next';
import LinkedInButton from '../linkedinbutton/LinkedInButton'; // Adjust the import path accordingly
import ProfilePicture from '../profile-picture/ProfilePicture'; // Adjust the import path accordingly

const PROFICIENCY_LEVELS = [
  { value: '1', label: 'Basic' },
  { value: '2', label: 'Conversational' },
  { value: '3', label: 'Proficient' },
  { value: '4', label: 'Fluent' },
  { value: '5', label: 'Native' }
];

const getProficiencyLabel = (value) => {
  if (!value) return "";  
  const proficiency = PROFICIENCY_LEVELS.find(p => p.value === value.toString());
  return proficiency ? proficiency.label : "";
};

const PersonalInfo = ({ applicant }) => {
  const { t } = useTranslation();
  const languages = applicant && applicant.languages ? applicant.languages : [];

  const calculateAge = (birthdateTimestamp) => {
    const birthdate = new Date(parseInt(birthdateTimestamp));
    const today = new Date();
    
    let age = today.getFullYear() - birthdate.getFullYear();
    const monthDifference = today.getMonth() - birthdate.getMonth();
    
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthdate.getDate())) {
        age--;
    }
    
    return age;
  };

  return (
    <div className="personal-info">
      <div className='profile-pic-name'>
        <div className='profile-picture'>
          <ProfilePicture name={applicant.fullname} imageUrl={applicant.profileImageUrl} highlight={true} />
        </div>
        <div className="name-linkedin">
          <p className="name">{applicant ? applicant.fullname : "Full Name"}</p>
        </div>
      </div>
      <div className="buttons">
        {applicant && applicant.linkedinUrl && (
          <a href={applicant.linkedinUrl} target="_blank" rel="noopener noreferrer">
            <LinkedInButton />
          </a>
        )}
        {applicant && applicant.resumeUrl && (
          <a href={applicant.resumeUrl} target="_blank" rel="noopener noreferrer">
            <button className="cv-button">
            </button>
          </a>
        )}
      </div>
      <p className="other-info">Phone: {applicant ? applicant.phone : "Phone Number"}</p>
      <p className="other-info">Email: {applicant ? applicant.email : "Email"}</p>
      <p className="other-info">Address: {applicant ? applicant.address : "Address"}</p>
      {applicant && applicant.birthdate && (
        <p className="other-info">
          {t('Age')}: {calculateAge(applicant.birthdate)} ({new Date(parseInt(applicant.birthdate)).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })})
        </p>
      )}
      <p className="other-info-gender">{t('Gender')}: {applicant ? applicant.gender : t("Gender")}</p>
      {languages.map((lang, index) => {
        const proficiencyLabel = getProficiencyLabel(lang.level);
        return (
          <Tag key={index} className="language-tag">{`${t(lang.name)} (${t(proficiencyLabel)})`}</Tag>
        );
      })}
    </div>
  );
};

export default PersonalInfo;
