import React, { useState, useEffect } from 'react';
import './ApplicantTop.css';
import { Link } from "react-router-dom";
import { useQuery, useMutation, gql } from '@apollo/client';
import State from '../applicant-general/Application_State.svg';
import checkmark from '../../../images/checkmark.svg';
import LongTextField from '../../textfields/LongTextField';
import SaveChanges from '../../save-changes/SaveChanges';
import { useTranslation } from 'react-i18next';
import ProfilePicture from '../../profile-picture/ProfilePicture';
import Tag from '../../tag/Tag';

// Updated GraphQL Queries and Mutations
const GET_APPLICANT_NOTES = gql`
  query GetApplicantNotes($applicantID: ID!) {
    getApplicantNotes(applicantID: $applicantID) {
      id
      content
      createdAt
      recruiterID
    }
  }
`;

const ADD_NOTE_TO_APPLICANT = gql`
  mutation AddNoteToApplicant($applicantID: ID!, $note: NoteInput!) {
    addNoteToApplicant(applicantID: $applicantID, note: $note) {
      id
      notes {
        id
        content
        createdAt
        recruiterID
      }
    }
  }
`;

const ScoreCircle = ({ score }) => {
    const getColor = (score) => {
        if (score >= 100) return '#D2FF2F'; // Green
        const middleColor = { r: 255, g: 255, b: 47 }; // Yellow #FFFF2F
        const endColor = { r: 255, g: 0, b: 0 }; // Red #FF0000

        if (score >= 50) {
            const ratio = (score - 50) / 50;
            const red = Math.floor(middleColor.r + (210 - middleColor.r) * ratio);
            const green = Math.floor(middleColor.g + (255 - middleColor.g) * ratio);
            return `rgb(${red}, ${green}, ${middleColor.b})`;
        } else {
            const ratio = score / 50;
            const red = Math.floor(middleColor.r + (endColor.r - middleColor.r) * ratio);
            const green = Math.floor(middleColor.g + (endColor.g - middleColor.g) * ratio);
            return `rgb(${red}, ${green}, 0)`;
        }
    };

    return (
        <div className="score-circle" style={{ backgroundColor: getColor(score) }}>
            {Math.round(score)}
        </div>
    );
};




const ApplicantTop = ({ applicant, application, totalRequirements, totalQuestions, onOpenOverlay, latestExperience, coverLetter, overallScore }) => {
    const [newNote, setNewNote] = useState('');
    const [noteId, setNoteId] = useState(null);
    const [isModified, setIsModified] = useState(false);
    const [toCreate, setToCreate] = useState(false);
    const { t } = useTranslation();

    const recruiterId = sessionStorage.getItem('recruiterId');

    const { loading, error, data, refetch } = useQuery(GET_APPLICANT_NOTES, {
        variables: { applicantID: applicant.id },
        skip: !applicant.id,
      });

      const [addNoteToApplicant] = useMutation(ADD_NOTE_TO_APPLICANT);

    


    const handleTextChange = (e) => {
        setNewNote(e.target.value);
        setIsModified(true);
      };

      const handleSaveClick = async () => {
        if (newNote.trim() !== '') {
          await addNoteToApplicant({
            variables: {
              applicantID: applicant.id,
              note: {
                content: newNote,
                recruiterID: recruiterId,
              },
            },
          });
          setNewNote('');
          setIsModified(false);
          refetch();
        }
      };

    function truncateText(text, maxLength) {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    }

    const renderCircles = (filledCount, totalCount) => {
        const circles = [];
        for (let i = 0; i < totalCount; i++) {
            circles.push(
                <span key={i} className={`circle-top ${i < filledCount ? 'filled' : ''}`}></span>
            );
        }
        return circles;
    };

    // Filter out empty answers
    const nonEmptyAnswers = application.answers.filter(answer => answer.text.trim() !== '');

    // Check if the cover letter length is greater than 100 characters
    const isCoverLetterPresent = coverLetter && coverLetter.length > 100;

    const renderLatestNote = () => {
        if (loading) return <p>Loading notes...</p>;
        if (error) return <p>Error loading notes</p>;
        
        const notes = data?.getApplicantNotes || [];
        if (notes.length === 0) {
          return <p>No notes added for the applicant yet.</p>;
        }
        
        const latestNote = notes.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];
        return (
          <div className="latest-note">
            <p><strong>Latest Note:</strong></p>
            <p>{latestNote.content}</p>
          </div>
        );
      };

    return (
        <div className="application-top">
            <div onClick={(e) => onOpenOverlay(applicant.id)} className="application-top-top">
                <ScoreCircle score={overallScore} /> {/* Add ScoreCircle component here */}
                <div className='pic-info-top'>
                    <div className='profile-picture'>
                        <ProfilePicture name={applicant.profile.fullname} imageUrl={applicant.profile.profileImageUrl} />
                    </div>
                    <div className='application-info-general'>
                        <div className="application-name">
                            {truncateText(applicant.profile.fullname, 15)}
                        </div>
                        <div className="application-date">
                            {t('Applied ')} {new Date(parseInt(application.applicationDate)).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                        </div>
                        <div className="application-role">{t(application.role)}</div>
                        <div className='application-state'>
                            <div className="application-status">
                                {t(application.status)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='application-req-answ-cover'>
                    {totalRequirements > 0 &&
                        <div className={`application-requirements ${application.requirementsMet.length === totalRequirements ? 'full-opacity' : ''}`}>
                            {t("Requirements")}
                            <br />
                            <div className="circle-top-container">
                                {renderCircles(application.requirementsMet.length, totalRequirements)}
                            </div>
                        </div>
                    }
                    {totalQuestions > 0 &&
                        <div className={`application-answers ${nonEmptyAnswers.length === totalQuestions ? 'full-opacity' : ''}`}>
                            {t("Answers")}
                            <br />
                            <div className="circle-top-container">
                                {renderCircles(nonEmptyAnswers.length, totalQuestions)}
                            </div>
                        </div>
                    }
                    <div className={`application-cover-letter ${isCoverLetterPresent ? 'full-opacity' : ''}`}>
                        {t("Cover Letter")}
                        <br />
                        <div className="circle-top-container">
                            <span className={`circle-top ${isCoverLetterPresent ? 'filled' : ''}`}></span>
                        </div>
                    </div>
                </div>
                <div className='application-top-latest-experience'>
                    <h3>{t('Latest Experience')}</h3>
                    <div className="application-top-experience-item">
                        <p className='application-top-experience-item-text'>
                            {latestExperience?.role}
                        </p>
                    </div>

                </div>
            </div>
            <div className="application-top-bottom">
            {renderLatestNote()}
        
        <LongTextField
          label="Add New Note"
          placeholder="Type your note here..."
          value={newNote}
          onChange={handleTextChange}
          mode="dark" // or "light"
        />

        <SaveChanges onClick={handleSaveClick} isModified={isModified} />
            </div>
        </div>
    );
}

export default ApplicantTop;
