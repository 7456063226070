import React, { useState, useEffect } from 'react';
import './PostEmployer.css';
import { useNavigate } from 'react-router-dom';
import TextField from "../../../components/textfields/TextField";
import TextLogo from '../../../images/logo-text.svg';
import { useMutation, useQuery, gql } from '@apollo/client';
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';
import LongTextField from '../../../components/textfields/LongTextField';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import axios from 'axios';

const CREATE_RECRUITER = gql`
  mutation CreateRecruiter($userID: String!, $company: String!, $email: String!, $description: String!, $logoUrl: String, $stripeCustomerId: String, $subscription: String!) {
    createRecruiter(userID: $userID, company: $company, email: $email, description: $description, logoUrl: $logoUrl, stripeCustomerId: $stripeCustomerId, subscription: $subscription) {
      id
      company
      email
      description
      logoUrl
      stripeCustomerId
      subscription
    }
  } 
`;

const GET_RECRUITER = gql`
  query GetRecruiterByUserId($userId: String!) {
    getRecruiterByUserId(userID: $userId) {
      id
      userID
      company
      description
      email
      logoUrl
    }
  }
`;

const CHECK_COMPANY_NAME = gql`
  query CheckCompanyName($company: String!) {
    checkCompanyName(company: $company) {
      exists
    }
  }
`;

const EmployerCreationModal = ({ onContinue, onBack }) => {
  const { t } = useTranslation();
  const [company, setCompany] = useState("");
  const [acceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false);
  const [acceptTermsService, setAcceptTermsService] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [description, setDescription] = useState("");
  const navigate = useNavigate();
  const [navigateToJobOffers, setNavigateToJobOffers] = useState(false);
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [linkedinUrl, setLinkedinUrl] = useState(null);
  const formFields = 3;
  const [createRecruiter, { loading, error }] = useMutation(CREATE_RECRUITER);
  const [companyExists, setCompanyExists] = useState(false);
  const { loading: loadingRecruiter, error: recruiterError, data: recruiterData } = useQuery(
    GET_RECRUITER,
    {
      variables: { userId: user?.sub },
      skip: !isAuthenticated,
    }
  );
  
  const { data: companyData, refetch: refetchCompany } = useQuery(
    CHECK_COMPANY_NAME,
    {
      variables: { company },
      skip: !company,
    }
  );

  useEffect(() => {
    if (companyData && companyData.checkCompanyName) {
      setCompanyExists(companyData.checkCompanyName.exists);
    }
  }, [companyData]);

  useEffect(() => {
    const recruiterExists = Boolean(recruiterData?.getRecruiterByUserId);
    if (recruiterExists) {
      setNavigateToJobOffers(true);
    }
  }, [recruiterData]);

  useEffect(() => {
    if (navigateToJobOffers) {
      navigate('/job_offers');
    }
  }, [navigateToJobOffers, navigate]);

  const canProceedToNext = () => {
    if (currentStep === 0 && (!company || companyExists)) return false;
    if (currentStep === 2 && !description) return false;
    return true;
  };

  const nextStep = () => {
    if (canProceedToNext() && currentStep < formFields) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const fetchAuthToken = async () => {
    try {
      const response = await axios.post(
        'https://dev-l6ki5j3n4dfx6eg6.eu.auth0.com/oauth/token',
        new URLSearchParams({
          grant_type: 'client_credentials',
          client_id: process.env.REACT_APP_AUTH0_MANAGEMENT_CLIENT_ID,
          client_secret: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE
        }),
        {
          headers: {
            'content-type': 'application/x-www-form-urlencoded'
          }
        }
      );
      return response.data.access_token;
    } catch (error) {
      console.error('Error fetching Auth0 token:', error.response ? error.response.data : error.message);
      throw error;
    }
  };
  
  const handleSubmit = async () => {
    const allFieldsFilled = company && description && acceptPrivacyPolicy && acceptTermsService;
    if (!allFieldsFilled) {
      alert('Please fill in all the fields.');
      return;
    }
    let imageUrl = "";
    let stripeCustomerId = "";    
    const subscription = "Free";
    if (!Boolean(recruiterData?.getRecruiterByUserId) && isAuthenticated) {
      try {
        const accessToken = await fetchAuthToken();
        const userId = user.sub;
        const metadataResponse = await axios.post(
          'https://simpl-backend-f1e8862591da.herokuapp.com/fetch-user-metadata',
          { userId },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (metadataResponse.status !== 200) {
          console.error('Failed to fetch user metadata:', metadataResponse);
          throw new Error('Failed to fetch user metadata');
        }
        const metadata = metadataResponse.data;
        stripeCustomerId = metadata.app_metadata.stripe_customer_id;
        const recruiterVariables = {
          userID: user.sub,
          company: company,
          email: user.email,
          description: description,
          logoUrl: imageUrl,
          stripeCustomerId: stripeCustomerId,
          subscription: subscription
        };
        const { data } = await createRecruiter({ variables: recruiterVariables });
        setNavigateToJobOffers(true);
      } catch (error) {
        console.error("Error during operations: ", error);
        alert('Error processing your request. Please try again.');
      }
    }
  };
  

  return (
    <div className='post-employer-page'>
      <div className="welcome-employer-modal">
        <div className="applicant-creation-contents">
          <div className='employer-creation-info'>
            <h1 className='post-employer-title'>{t("Let's get you started")}</h1>
            <p className='employer-creation-info-text'>We're seeking a talented and motivated Full Stack Developer to join our team. The ideal candidate will have a passion for technology, a keen eye for detail, and the </p>
          </div>
          <div className="employer-creation-form">
            <TransitionGroup component={null}>
              <CSSTransition
                key={currentStep}
                timeout={300}
                classNames="slide"
              >
                <div className="form-step-container">
                  {currentStep === 0 && (
                    <TextField
                      label="Company Name"
                      type="text"
                      placeholder="Company Name"
                      value={company}
                      onChange={(e) => {
                        setCompany(e.target.value);
                        refetchCompany();
                      }}
                      error={companyExists ? 'Company name already in use' : ''}
                    />
                  )}
                  {currentStep === 1 && (
                    <TextField
                      label="LinkedIn Profile URL"
                      type="text"
                      placeholder="Enter LinkedIn URL"
                      value={linkedinUrl}
                      onChange={(e) => setLinkedinUrl(e.target.value)}
                    />
                  )}
                  {currentStep === 2 && (
                    <LongTextField
                      label="Company Description"
                      type="text"
                      placeholder="A short company description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  )}
                  {currentStep === 3 && (
                    <div className="terms-container">
                      <div className="privacy-policy-acceptance">
                        <input
                          type="checkbox"
                          id="acceptPrivacyPolicy"
                          checked={acceptPrivacyPolicy}
                          onChange={(e) => setAcceptPrivacyPolicy(e.target.checked)}
                        />
                        <label htmlFor="acceptPrivacyPolicy" className='privacy-and-terms'>
                          {t('I have read and accept the')} <a href="/privacy-and-terms" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>.
                        </label>
                      </div>
                      <div className="privacy-policy-acceptance">
                        <input
                          type="checkbox"
                          id="acceptTermsService"
                          checked={acceptTermsService}
                          onChange={(e) => setAcceptTermsService(e.target.checked)}
                        />
                        <label htmlFor="acceptTermsService" className='privacy-and-terms'>
                          {t('I have read and accept the')} <a href="/privacy-and-terms" target="_blank" rel="noopener noreferrer">Terms of Service</a>.
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </CSSTransition>
            </TransitionGroup>

            <div className='employer-creation-buttons'>
              {currentStep > 0 && <button className='back-button-employer' onClick={prevStep}>Back</button>}
              {currentStep < formFields && <button className='next-button-employer' onClick={nextStep} disabled={!canProceedToNext()}>Next</button>}
              {currentStep === formFields && <button className='next-button-employer' onClick={handleSubmit}>Start</button>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployerCreationModal;
