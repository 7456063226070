import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useQuery, gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';
import ApplicantTop from '../../../../components/applicant/applicant-top5/ApplicantTop';
import NavbarRecruiting from '../../../../components/navbar_recruiting/NavbarRecruiting';
import ApplicantGeneral from '../../../../components/applicant/applicant-general/ApplicantGeneral';
import SelectField from '../../../../components/textfields/SelectField';
import back_icon from '../../../../images/back_icon.svg'
import copy from '../../../../images/copy_clipboard.svg';
import TextField from '../../../../components/textfields/TextField';
import ApplicantView from '../applicant_view/ApplicantView';
import { useSubscription } from '../../../../contexts/SubscriptionContext';
import './ApplicantsGeneral.css';
import inbox_icon from '../../../../images/inbox_icon.svg';
import profile_pic_fake from '../../../../images/profile_pic_fake.png';
import email_icon from '../../../../images/email_icon.svg';
import phone_icon from '../../../../images/number_icon.svg';
import address_icon from '../../../../images/address_icon.svg';
import gender_icon from '../../../../images/gender_icon.svg';
import languages_icon from '../../../../images/languages_icon.svg';
import linkedin_logo from '../../../../images/linkedin_logo.svg';
import resume_logo from '../../../../images/resume_logo.svg';
import reject_icon from '../../../../images/reject_icon.svg';
import promote_icon from '../../../../images/promote_icon.svg';
import next_icon from '../../../../images/next_icon.svg';
import filter_icon from '../../../../images/filter_icon.svg';
import screening_icon from '../../../../images/screening_icon.svg';
import interview_icon from '../../../../images/interview_icon.svg';
import offer_icon from '../../../../images/offer_icon.svg';
import { toast } from 'react-toastify';
import copy_icon from '../../../../images/copy_icon.svg';
import note_icon from '../../../../images/note_icon.svg';
import status_change_icon from '../../../../images/status_change_icon.svg';
import { format } from 'date-fns'; // Make sure to install date-fns: npm install date-fns



const GET_APPLICATIONS_FOR_JOB_OFFER = gql`
  query GetApplicationsForJobOffer($jobOfferId: ID!) {
    getApplicationsForJobOffer(jobOfferID: $jobOfferId) {
      id
      profile {
        fullname
        email
        birthdate
        gender
        phone
        linkedinUrl
        address
        profileImageUrl
        resumeUrl
        languages {
          name
          level
        }
      }
      academics {
        school
        title
        startDate
        endDate
        category
        tags
      }
      jobexperiences {
        employer
        role
        description
        startDate
        endDate
        experience
        category
        tags
      }
      applications {
        id
        jobOfferID
        status
        isQualified
        requirementsMet
        applicationDate
        resumeScore
        answersScore
        coverLetterScore
        answers {
          questionId
          text
        }
        coverLetter
      }
    }
  }
`;

const UPDATE_APPLICATION_STATUS = gql`
  mutation UpdateApplicationStatus($applicantId: ID!, $applicationId: ID!, $newStatus: String!) {
    updateApplicationStatus(applicantId: $applicantId, applicationId: $applicationId, newStatus: $newStatus) {
      id
      status
      events {
        id
        type
        date
        text
      }
    }
  }
`;


const UPDATE_JOB_OFFER = gql`
  mutation UpdateJobOffer($jobOfferId: ID!, $newState: String!, $coverLetterImportance: Int!, $questionAnswersImportance: Int!) {
    updateJobOffer(id: $jobOfferId, state: $newState, coverLetterImportance: $coverLetterImportance, questionAnswersImportance: $questionAnswersImportance) {
      id
      state
      coverLetterImportance
      questionAnswersImportance
    }
  }
`;

const GET_JOB_OFFER = gql`
  query GetJobOffer($JobOfferId: ID!) {
    getJobOffer(id: $JobOfferId) {
      id
      userID
      recruiterID
      title
      description
      place
      workMode
      responsibilities
      offers
      salaryRange
      requirements {
        id
        requirementtype
        category
        years
        tags
        mandatory
      }
      applicationsCount
      state
      createdAt
      workHours
      questions {
        id
        text
      }
      coverLetterImportance
      questionAnswersImportance
    }
  }
`;

const GET_APPLICANT_EVENTS = gql`
  query GetApplicantEvents($applicantId: ID!) {
    getApplicantEvents(applicantId: $applicantId) {
      id
      type
      date
      text
    }
  }
`;


const ADD_NOTE_TO_APPLICANT = gql`
  mutation AddNoteToApplicant($applicantID: ID!, $note: NoteInput!) {
    addNoteToApplicant(applicantID: $applicantID, note: $note) {
      id
      notes {
        content
        recruiterID
        createdAt
      }
    }
  }
`;

const RequirementView = ({ requirements, applicantRequirementsMet }) => {
  // Filter requirements that the applicant meets
  const metRequirements = requirements.filter(req => applicantRequirementsMet.includes(req.id));
  console.log('All requirements:', requirements);
console.log('Met requirements IDs:', applicantRequirementsMet);
console.log('Filtered met requirements:', metRequirements);

const getLanguageLevel = (level) => {
  const levels = ['Beginner', 'Elementary', 'Intermediate', 'Advanced', 'Fluent'];
  return levels[Math.min(parseInt(level) - 1, 4)] || 'Unknown';
};

  const renderRequirementContent = (requirement) => {
    if (!requirement.requirementtype || !requirement.category) {
      return (
        <div className='applicants-general-view-middle-requirement'>
          <span className='applicants-general-view-middle-requirement-text'>
            Requirement details unavailable
          </span>
        </div>
      );
    }
    switch (requirement.requirementtype) {
      case 'Experience':
        return (
          <div className='applicants-general-view-middle-requirement'>
            <span className='applicants-general-view-middle-requirement-text'>
            {requirement.years === 0 ? 'Experience with' : `${requirement.years} years of experience in`} <strong>{requirement.category}</strong> with
            </span>
            <div className='applicants-general-middle-credential-tags'>
              {requirement.tags.map((tag, index) => (
                <span key={index} className='applicants-general-middle-credential-tag'>{tag}</span>
              ))}
            </div>
          </div>
        );
      case 'Academic':
        return (
          <div className='applicants-general-view-middle-requirement'>
            <span className='applicants-general-view-middle-requirement-text'>
              A <strong>{requirement.category}</strong> with
            </span>
            <div className='applicants-general-middle-credential-tags'>
              {requirement.tags.map((tag, index) => (
                <span key={index} className='applicants-general-middle-credential-tag'>{tag}</span>
              ))}
            </div>
          </div>
        );
      case 'Language':
        return (
          <div className='applicants-general-view-middle-requirement'>
            <span className='applicants-general-view-middle-requirement-text'>
              Knowledge of <strong>{requirement.category}</strong> at {getLanguageLevel(requirement.tags[0])} level
            </span>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className='applicants-general-view-middle-requirements-section'>
      <span className='applicants-general-middle-credential-section-title'>Requirements Met</span>
      <div className='applicants-general-view-middle-requirements'>
      {metRequirements.length > 0 ? (
        metRequirements.map((requirement, index) => (
          <React.Fragment key={index}>
            {renderRequirementContent(requirement)}
          </React.Fragment>
        ))
      ) : (
        <div className='applicants-general-view-middle-requirement'>
          <span className='applicants-general-view-middle-requirement-text'>
            No requirements met
          </span>
        </div>
      )}
      </div>
    </div>
  );
};


const QuestionsAnswersView = ({ questions, answers, coverLetter }) => {
  return (
    <div className='applicants-general-view-middle-questions-section'>
      <span className='applicants-general-middle-credential-section-title'>Questions & Answers</span>
      <div className='applicants-general-view-middle-questions-answers'>
        {questions.map((question, index) => {
          const answer = answers.find(a => a.questionId === question.id);
          return (
            <div key={index} className='applicants-general-view-middle-question-answer'>
              <div className='applicants-general-view-middle-question'>
                <span className='applicants-general-view-middle-question-text'>{question.text}</span>
              </div>
              <div className='applicants-general-view-middle-answer'>
                <span className='applicants-general-view-middle-answer-text'>{answer ? answer.text : 'No answer provided'}</span>
              </div>
            </div>
          );
        })}
      </div>
      
      {/* Add the cover letter section */}
      <span className='applicants-general-middle-credential-section-title'>Cover Letter</span>
      <div className='applicants-general-view-middle-questions-answers'>
        <div className='applicants-general-view-middle-question-answer'>
          <div className='applicants-general-view-middle-answer'>
            <span className='applicants-general-view-middle-answer-text'>
              {coverLetter ? coverLetter : 'No cover letter provided'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const ConfirmationModal = ({ isOpen, onClose, onConfirm, message }) => {
  if (!isOpen) return null;

  return (
    <div className="confirmation-modal-overlay">
      <div className="confirmation-modal">
        <p className="confirmation-modal-message">{message}</p>
        <div className="confirmation-modal-buttons">
          <button className="confirmation-modal-button cancel" onClick={onClose}>Cancel</button>
          <button className="confirmation-modal-button confirm" onClick={onConfirm}>Confirm</button>
        </div>
      </div>
    </div>
  );
};

const ApplicantsStats = ({ totalApplicants, averageScore }) => {
  const getColor = (score) => {
    if (score >= 100) return '#D2FF2F'; // Green
    const middleColor = { r: 251, g: 235, b: 94 }; // Yellow #FFFF2F
    const endColor = { r: 255, g: 0, b: 0 }; // Red #FF0000

    if (score >= 50) {
      const ratio = (score - 50) / 50;
      const red = Math.floor(middleColor.r + (210 - middleColor.r) * ratio);
      const green = Math.floor(middleColor.g + (255 - middleColor.g) * ratio);
      return `rgb(${red}, ${green}, middleColor.b)`;
    } else {
      const ratio = score / 50;
      const red = Math.floor(middleColor.r + (endColor.r - middleColor.r) * ratio);
      const green = Math.floor(middleColor.g + (endColor.g - middleColor.g) * ratio);
      return `rgb(${red}, ${green}, 0)`;
    }
  };

  return (
    <div className="applicants-stats-section">
      <div className="applicant-card total-applicants-card">
        <p className="number">{totalApplicants}</p>
        <p className="text">Total<br />Applicants</p>
      </div>
      <div className="applicant-card average-score-card" style={{ backgroundColor: '#FCFCFC' }}>
        <p className="number">{Math.round(averageScore)}</p>
        <p className="text">Average<br />Score</p>
      </div>
    </div>
  );
};
const scrollContainerBy = (containerId, distance) => {
  const container = document.getElementById(containerId);
  if (container) {
    container.scrollBy({
      left: distance,
      behavior: 'smooth'
    });
  }
};

const continuousScroll = (containerId, distance) => {
  const container = document.getElementById(containerId);
  let scrollInterval;

  const startScrolling = () => {
    scrollInterval = setInterval(() => {
      container.scrollBy({ left: distance, behavior: 'smooth' });
    }, 100);
  };

  const stopScrolling = () => {
    clearInterval(scrollInterval);
  };

  return { startScrolling, stopScrolling };
};

const Applicants = () => {
  const [linkCopied, setLinkCopied] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeApplicant, setActiveApplicant] = useState(null);
  const [jobOfferState, setJobOfferState] = useState(null);
  const [applicationStartDate, setApplicationStartDate] = useState('');
  const [applicationEndDate, setApplicationEndDate] = useState('');
  const [minRequirementsMet, setMinRequirementsMet] = useState(0);
  const [maxRequirementsMet, setMaxRequirementsMet] = useState(100);
  const states = ["Active", "Paused", "Closed"]; // You can update this list as needed
  const options = ["Yes", "No"]; // You can update this list as needed
  const [minQuestionsAnswered, setMinQuestionsAnswered] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState('Applied');
  const [maxQuestionsAnswered, setMaxQuestionsAnswered] = useState(100);
  const [coverLetterProvided, setCoverLetterProvided] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [filteredApplicants, setFilteredApplicants] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedJobExperiences, setExpandedJobExperiences] = useState(false);
  const [expandedAcademics, setExpandedAcademics] = useState(false);

  const [selectedView, setSelectedView] = useState('general');
  const [filterSettings, setFilterSettings] = useState({
    searchTerm: '',
    filterStates: ['Applied'], // Set 'Applied' as the initial filter state
    minRequirementsMet: 0,
    maxRequirementsMet: 100,
    minQuestionsAnswered: 0,
    maxQuestionsAnswered: 100,
    coverLetterProvided: false,
  });
  const [selectedApplicantId, setSelectedApplicantId] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { subscription } = useSubscription();
  const [coverLetterImportance, setCoverLetterImportance] = useState(1);
  const [questionAnswersImportance, setQuestionAnswersImportance] = useState(5);
  const [resumeImportance, setResumeImportance] = useState(10); const [isFilteredView, setIsFilteredView] = useState(false);

  const [updateApplicationStatus] = useMutation(UPDATE_APPLICATION_STATUS, {
    onError: (error) => {
      console.error('Application Status Update Error Details:', error);
      console.error('GraphQL Errors:', error.graphQLErrors);
      console.error('Network Error:', error.networkError);
    },
    onCompleted: (data) => {
      console.log('Application status updated successfully', data);
    }
  });

  const handleViewAllClick = (status, total) => {
    setFilterSettings(prevSettings => ({
      ...prevSettings,
      filterStates: [status],
    }));
    setCurrentPage(1);
    setIsFilteredView(true); // Switch to "All Applicants" view
    setSelectedView('general'); // Ensure the general view is selected
  };

  const handleBackToMainClick = () => {
    setIsFilteredView(false);
  };

  const handleOpenOverlay = (applicantId) => {
    const applicantObj = applicants.find(applicant => applicant && applicant.id === applicantId);
    if (applicantObj) {
      setSelectedApplicant(applicantObj);
    }
  };



  const getStatusCount = (status) => {
    if (!data || !data.getApplicationsForJobOffer) return 0;
    return data.getApplicationsForJobOffer.filter(applicant =>
      applicant.applications.some(app => app.status === status)
    ).length;
  };

  const formatEventDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd/MM/yy \'at\' HH:mm');
  };

  const topApplicantsScrollRight = continuousScroll('top-applicants-content', 200);
  const topApplicantsScrollLeft = continuousScroll('top-applicants-content', -200);
  const midApplicantsScrollRight = continuousScroll('mid-applicants-content', 200);
  const midApplicantsScrollLeft = continuousScroll('mid-applicants-content', -200);

  const currentJobOfferId = sessionStorage.getItem('currentJobOfferId');

  const [updateJobOffer] = useMutation(UPDATE_JOB_OFFER, {
    onError: (error) => {
      console.error('Job Offer Update Error Details:', error);
      console.error('GraphQL Errors:', error.graphQLErrors);
      console.error('Network Error:', error.networkError);
    },
    onCompleted: (data) => {
      setJobOfferState(data.updateJobOffer.state);
      setCoverLetterImportance(data.updateJobOffer.coverLetterImportance);
      setQuestionAnswersImportance(data.updateJobOffer.questionAnswersImportance);
    }
  });

  const { loading: loadingJobOffer, error: jobofferError, data: jobofferData } = useQuery(
    GET_JOB_OFFER,
    {
      variables: { JobOfferId: currentJobOfferId },
      skip: !currentJobOfferId,
      onError: (error) => {
        console.error('Job Offer Fetch Error Details:', error);
        console.error('GraphQL Errors:', error.graphQLErrors);
        console.error('Network Error:', error.networkError);
      }
    }
  );


  const { loading, error, data } = useQuery(GET_APPLICATIONS_FOR_JOB_OFFER, {
    variables: { jobOfferId: currentJobOfferId },
    skip: !currentJobOfferId,
    onError: (error) => {
      console.error('Applications Fetch Error Details:', error);
      console.error('GraphQL Errors:', error.graphQLErrors);
      console.error('Network Error:', error.networkError);
    }
  });

  


  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [eventFilters, setEventFilters] = useState({
    NOTE_ADDED: true,
    STATUS_CHANGED: true,
    APPLICATION_CREATED: true
  });
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [newNote, setNewNote] = useState('');

  const { data: eventData, loading: eventLoading, error: eventError, refetch: refetchEvents } = useQuery(GET_APPLICANT_EVENTS, {
    variables: { applicantId: activeApplicant },
    skip: !activeApplicant,
    onError: (error) => {
      console.error('Error fetching events:', error);
      toast.error('Failed to fetch applicant events');
    }
  });

  useEffect(() => {
    if (eventData && eventData.getApplicantEvents) {
      setEvents(eventData.getApplicantEvents);
    }
  }, [eventData]);

  const [addNoteToApplicant] = useMutation(ADD_NOTE_TO_APPLICANT, {
    onError: (error) => {
      console.error('Note Addition Error:', error);
      toast.error('Failed to add note');
    },
    onCompleted: (data) => {
      console.log('Note added successfully:', data);
    }
  });

  

  useEffect(() => {
    const filtered = events.filter(event => eventFilters[event.type]);
    setFilteredEvents(filtered);
  }, [events, eventFilters]);

  const handleFilterToggle = (eventType) => {
    setEventFilters(prev => ({ ...prev, [eventType]: !prev[eventType] }));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isFilterOpen && !event.target.closest('.applicants-general-view-right-filter-dropdown')) {
        setIsFilterOpen(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isFilterOpen]);
  const handleAddNote = async () => {
    if (newNote.trim() === '') return;
  
    try {
      await addNoteToApplicant({
        variables: {
          applicantID: activeApplicant,
          note: {
            content: newNote,
            recruiterID: 'currentRecruiterId' // Replace with actual recruiter ID
          }
        }
      });
      setNewNote('');
      // Refetch events or update local state
      refetchEvents();
      toast.success('Note added successfully');
    } catch (error) {
      console.error('Error adding note:', error);
      toast.error('Failed to add note');
    }
  };

  const renderEventIcon = (eventType) => {
    switch (eventType) {
      case 'NOTE_ADDED':
        return <img src={note_icon} alt="note" className="applicants-general-view-right-event-element-icon" />;
      case 'STATUS_CHANGED':
        return <img src={status_change_icon} alt="status change" className="applicants-general-view-right-event-element-icon" />;
      // ... (add cases for other event types)
      default:
        return <img src={inbox_icon} alt="event" className="applicants-general-view-right-event-element-icon" />;
    }
  };

  const renderEvent = (event) => (
    <div key={event.id} className="applicants-general-view-right-event">
      <div className="applicants-general-view-right-event-element">
        {renderEventIcon(event.type)}
        <span className="applicants-general-view-right-event-element-text">
          {event.text || event.type} {/* Display the text if available, otherwise fall back to the type */}
        </span>
      </div>
      <span className="applicants-general-view-right-event-date">
        {formatDate(event.date)}
      </span>
    </div>
  );
  const jobOffer = jobofferData ? jobofferData.getJobOffer : null;
  const applicants = data ? data.getApplicationsForJobOffer.map(applicant => ({
    ...applicant,
    application: applicant.applications.length > 0 ? applicant.applications[0] : null,
  })) : [];
  const jobApplicationLink = `https://app.thesimplplatform.io/apply/${currentJobOfferId}`;
  const applicantsPerPage = 10;
  const indexOfLastApplicant = currentPage * applicantsPerPage;
  const indexOfFirstApplicant = indexOfLastApplicant - applicantsPerPage;
  const currentApplicants = filteredApplicants.slice(indexOfFirstApplicant, indexOfLastApplicant);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  useEffect(() => {
    if (jobofferData && jobofferData.getJobOffer) {
      setJobOfferState(jobofferData.getJobOffer.state);
      setCoverLetterImportance(jobofferData.getJobOffer.coverLetterImportance || 1);
      setQuestionAnswersImportance(jobofferData.getJobOffer.questionAnswersImportance || 1);
    }
  }, [jobofferData]);

  useEffect(() => {
    if (data && data.getApplicationsForJobOffer) {
      setFilteredApplicants(applicants);
    }
  }, [data]);

  const handleStateChange = (selectedState) => {
    setFilterSettings(prevSettings => {
      const isStateSelected = prevSettings.filterStates.includes(selectedState);
      const newFilterStates = isStateSelected
        ? prevSettings.filterStates.filter(state => state !== selectedState) // Remove state
        : [...prevSettings.filterStates, selectedState]; // Add state

      return {
        ...prevSettings,
        filterStates: newFilterStates,
      };
    });
  };

  const calculateOverallScore = (resumeScore, coverLetterScore, answersScore) => {
    const totalImportance = resumeImportance + coverLetterImportance + questionAnswersImportance;
    return (
      (resumeScore * resumeImportance +
        coverLetterScore * coverLetterImportance +
        answersScore * questionAnswersImportance) /
      totalImportance
    );
  };


  const applyFilters = () => {
    console.log('Applying filters...');
    console.log('Current filter settings:', filterSettings);

    const filtered = applicants.filter(applicantData => {
      const application = applicantData.application;

      if (!application || !application.jobOfferID) {
        console.log(`Skipping applicant ${applicantData.profile.fullname} due to missing application or jobOfferID.`);
        return false;
      }

      const answers = application.answers || [];
      const nonEmptyAnswersCount = answers.filter(answer => answer.text.trim() !== '').length;
      const meetsRequirements = application.requirementsMet.length >= filterSettings.minRequirementsMet;
      const statusCheck = filterSettings.filterStates.length === 0 || filterSettings.filterStates.includes(application.status);
      const nameCheck = applicantData.profile.fullname.toLowerCase().includes(filterSettings.searchTerm.toLowerCase()) ||
        applicantData.profile.address.toLowerCase().includes(filterSettings.searchTerm.toLowerCase());
      const answersCheck = nonEmptyAnswersCount >= filterSettings.minQuestionsAnswered;
      const coverLetterCheck = !filterSettings.coverLetterProvided || (application.coverLetter && application.coverLetter.length > 0);
      const startDateCheck = !applicationStartDate || new Date(application.applicationDate) >= new Date(applicationStartDate);
      const endDateCheck = !applicationEndDate || new Date(application.applicationDate) <= new Date(applicationEndDate);

      return nameCheck && statusCheck && answersCheck && coverLetterCheck && startDateCheck && endDateCheck && meetsRequirements;
    });

    setFilteredApplicants(filtered);
    setCurrentPage(1);
    setIsFilteredView(true); // Switch to "All Applicants" view
    console.log('Filtered applicants:', filtered);
  };

  const resetFilters = () => {
    setFilterSettings({
      searchTerm: '',
      filterStates: [],
      minRequirementsMet: 0,
      maxRequirementsMet: 100,
      minQuestionsAnswered: 0,
      maxQuestionsAnswered: 100,
      coverLetterProvided: false,
    });
    setCurrentPage(1);
    setFilteredApplicants(applicants);
    setIsFilteredView(true); // Switch to "All Applicants" view
    console.log('Filters reset.');
  };

  useEffect(() => {
    if (jobOffer) {
      setJobOfferState(jobOffer.state);
    }
  }, [jobOffer]);

  const totalQuestions = jobOffer && jobOffer.questions ? jobOffer.questions.length : 0;
  const jobOfferQuestions = jobOffer && jobOffer.questions ? jobOffer.questions.map(q => q.text) : [];
  const totalRequirements = jobOffer && jobOffer.requirements ? jobOffer.requirements.length : 0;

  const handleJobOfferStateChange = (event) => {
    const newState = event.target.value;
    updateJobOffer({
      variables: {
        jobOfferId: currentJobOfferId,
        newState,
        coverLetterImportance,
        questionAnswersImportance
      }
    });
  };

  const renderCircles = (filled, total) => {
    return (
      <div className="circle-container">
        {[...Array(total)].map((_, index) => (
          <div
            key={index}
            className={`circle ${index < filled ? 'filled' : ''}`}
          />
        ))}
      </div>
    );
  };

  const renderViewOption = (optionName, path) => {
    const isSelected = optionName === selectedView;

    return (
      <Link
        to={path}
        className={isSelected ? 'view-option-selected' : 'view-option'}
        onClick={() => setSelectedView(optionName)}
      >
        {optionName.charAt(0).toUpperCase() + optionName.slice(1)}
      </Link>
    );
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const draggedApplicantId = result.draggableId;
      const newStatus = destination.droppableId;

      // Find the applicant that is being dragged
      const draggedApplicant = filteredApplicants.find(applicant =>
        applicant.applications.some(application => application.id === draggedApplicantId)
      );

      if (!draggedApplicant) {
        console.error("Dragged applicant not found");
        return;
      }

      // Update the local state immediately
      const updatedApplicants = filteredApplicants.map(applicant => {
        if (applicant.id === draggedApplicant.id) {
          return {
            ...applicant,
            applications: applicant.applications.map(application =>
              application.id === draggedApplicantId ? { ...application, status: newStatus } : application
            )
          };
        }
        return applicant;
      });

      setFilteredApplicants(updatedApplicants);

      // Get the applicantId and applicationId
      const applicantId = draggedApplicant.id;
      const applicationId = draggedApplicantId;  // In this context, this is the applicationId

      // Update the applicant's status in the backend/database
      updateApplicationStatus({
        variables: {
          applicantId: applicantId,
          applicationId: applicationId,
          newStatus: newStatus,
        },
        update: (cache, { data: { updateApplicationStatus } }) => {
          const existingApplications = cache.readQuery({
            query: GET_APPLICATIONS_FOR_JOB_OFFER,
            variables: { jobOfferId: currentJobOfferId }
          });

          const updatedApplications = existingApplications.getApplicationsForJobOffer.map(applicant => {
            if (applicant.id === applicantId) {
              return {
                ...applicant,
                applications: applicant.applications.map(application =>
                  application.id === applicationId ? { ...application, status: newStatus } : application
                )
              };
            }
            return applicant;
          });

          cache.writeQuery({
            query: GET_APPLICATIONS_FOR_JOB_OFFER,
            variables: { jobOfferId: currentJobOfferId },
            data: { getApplicationsForJobOffer: updatedApplications }
          });
        }
      });
    }
  };



  const sortedApplicants = [...filteredApplicants].sort((a, b) => {
    const scoreA = calculateOverallScore(a.application.resumeScore, a.application.coverLetterScore, a.application.answersScore);
    const scoreB = calculateOverallScore(b.application.resumeScore, b.application.coverLetterScore, b.application.answersScore);
    return scoreB - scoreA;
  });

  const best5Applicants = sortedApplicants.slice(0, 5);
  const medium5Applicants = sortedApplicants.slice(5, 10);
  const otherApplicants = sortedApplicants.slice(10);

  // New calculation for total applicants and average score
  const totalApplicants = filteredApplicants.length;
  const totalScore = filteredApplicants.reduce((acc, applicant) => {
    const overallScore = calculateOverallScore(
      applicant.application.resumeScore,
      applicant.application.coverLetterScore,
      applicant.application.answersScore
    );
    return acc + overallScore;
  }, 0);
  const averageScore = totalApplicants > 0 ? totalScore / totalApplicants : 0;

  const getRequirementsMet = (applicant) => {
    return applicant.application.requirementsMet.length;
  };

  const getAnsweredQuestions = (applicant) => {
    return applicant.application.answers.filter(answer => answer.text.trim() !== '').length;
  };

  const handleReject = (applicant) => {
    setActiveApplicant(applicant);
    setIsConfirmModalOpen(true);
  };

  const confirmReject = () => {
    if (activeApplicant) {
      updateApplicationStatus({
        variables: {
          applicantId: activeApplicant.id,
          applicationId: activeApplicant.application.id,
          newStatus: 'Rejected'
        }
      });
      setIsConfirmModalOpen(false);
      toast.success('Applicant rejected successfully');
    } else {
      console.error('No active applicant selected');
      toast.error('Error: No applicant selected');
    }
  };

  const handleMoveToNextStatus = (applicant) => {
    const statuses = ['Applied', 'Screening', 'Interview', 'Offer'];
    const currentIndex = statuses.indexOf(applicant.application.status);
    if (currentIndex < statuses.length - 1) {
      const nextStatus = statuses[currentIndex + 1];
      updateApplicationStatus({
        variables: {
          applicantId: applicant.id,
          applicationId: applicant.application.id,
          newStatus: nextStatus
        },
        onCompleted: (data) => {
          toast.success(`Applicant moved to ${nextStatus} successfully`);
          // Update the local state with the new event
          setEvents(prevEvents => [...prevEvents, data.updateApplicationStatus.events[data.updateApplicationStatus.events.length - 1]]);
        },
        onError: (error) => {
          console.error('Error updating status:', error);
          toast.error('Failed to update applicant status');
        }
      });
    }
  };

  const handleViewNextApplicant = () => {
    const currentIndex = filteredApplicants.findIndex(a => a.id === activeApplicant);
    if (currentIndex < filteredApplicants.length - 1) {
      setActiveApplicant(filteredApplicants[currentIndex + 1].id);
    }
  };

  const getNextStatus = (currentStatus) => {
    const statuses = ['Applied', 'Screening', 'Interview', 'Offer'];
    const currentIndex = statuses.indexOf(currentStatus);
    return currentIndex < statuses.length - 1 ? statuses[currentIndex + 1] : null;
  };

 

  

  const formatDate = (milliseconds) => {
    const date = new Date(parseInt(milliseconds));
    return date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' });
  };

  const getInitials = (fullName) => {
    const names = fullName.split(' ');
    return names.length > 1
      ? `${names[0][0]}${names[names.length - 1][0]}`.toUpperCase()
      : fullName.substring(0, 2).toUpperCase();
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'Applied':
        return inbox_icon;
      case 'Screening':
        return screening_icon;
      case 'Interview':
        return interview_icon;
      case 'Offer':
        return offer_icon;
      default:
        return inbox_icon;
    }
  };

  const handleApplicantClick = (applicantId) => {
    setActiveApplicant(applicantId);
  };

 /*  useEffect(() => {
    if (filteredApplicants.length > 0 && !activeApplicant) {
      setActiveApplicant(filteredApplicants[0].id);
    } else if (filteredApplicants.length === 0) {
      setActiveApplicant(null);
    }
  }, [filteredApplicants, selectedStatus]); */

  useEffect(() => {
    // Filter applicants based on the selected status
    if (data && data.getApplicationsForJobOffer) {
      const applicants = data.getApplicationsForJobOffer.map(applicant => ({
        ...applicant,
        application: applicant.applications.length > 0 ? applicant.applications[0] : null,
      }));

      const filteredByStatus = applicants.filter(applicant =>
        applicant.application && applicant.application.status === selectedStatus
      );

      const filteredBySearch = filteredByStatus.filter(applicant =>
        applicant.profile.fullname.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setFilteredApplicants(filteredBySearch);

  /*     // Set the first applicant as active when changing status
      if (filteredBySearch.length > 0) {
        setActiveApplicant(filteredBySearch[0].id);
      } else {
        setActiveApplicant(null);
      } */
    }
  }, [data, selectedStatus, searchTerm]);

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    setActiveApplicant(null); // Reset active applicant when changing status
  };

  const getLanguageLevel = (level) => {
    const levels = ['Beginner', 'Elementary', 'Intermediate', 'Advanced', 'Fluent'];
    return levels[Math.min(parseInt(level) - 1, 4)] || 'Unknown';
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const truncateName = (name, maxLength = 16) => {
    return name.length > maxLength ? name.substring(0, maxLength - 3) + '...' : name;
  };


  const truncateText = (text, maxLength = 40) => {
    return text.length > maxLength ? text.substring(0, maxLength - 3) + '...' : text;
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(jobApplicationLink);
    toast.success('Job application link copied!');
  };

  const formatName = (fullName) => {
    const names = fullName.split(' ');
    if (names.length === 1) return { firstName: names[0], lastName: '' };
    if (names.length === 2) return { firstName: names[0], lastName: names[1] };

    // Handle cases with more than two names or complex surnames
    const lastName = names.slice(-2).join(' '); // Assume last two words are the surname
    const firstName = names.slice(0, -2).join(' ');

    return { firstName, lastName };
  };



  if (loading || error) {
    return <div>Loading applicants...</div>; // Or handle the error appropriately
  }

  const renderApplicantView = (applicant) => {
    if (!applicant) return null;

    const { firstName, lastName } = formatName(applicant.profile.fullname);
    const overallScore = calculateOverallScore(
      applicant.application.resumeScore,
      applicant.application.coverLetterScore,
      applicant.application.answersScore
    );
    const requirementsMet = getRequirementsMet(applicant);
    const answeredQuestions = getAnsweredQuestions(applicant);
    const nextStatus = getNextStatus(applicant.application.status);

    const formatDateRange = (startDate, endDate) => {
      const start = new Date(parseInt(startDate));
      const end = new Date(parseInt(endDate));
      const now = new Date();
      const isPresent = end.getFullYear() === 2100;
      
      const calculateDuration = (start, end) => {
        const months = (end.getFullYear() - start.getFullYear()) * 12 + end.getMonth() - start.getMonth();
        const years = Math.floor(months / 12);
        const remainingMonths = months % 12;
        return `${years > 0 ? `${years} year${years > 1 ? 's' : ''}` : ''}${years > 0 && remainingMonths > 0 ? ' ' : ''}${remainingMonths > 0 ? `${remainingMonths} month${remainingMonths > 1 ? 's' : ''}` : ''}`;
      };
    
      const duration = calculateDuration(start, isPresent ? now : end);
      const endDisplay = isPresent ? 'Present' : `${end.getMonth() + 1}/${end.getFullYear()}`;
    
      return `${start.getMonth() + 1}/${start.getFullYear()} - ${endDisplay} (${duration})`;
    };
    const hasAppliedEvent = filteredEvents.some(event => event.type === 'APPLICATION_CREATED');

    

    const renderCredential = (credential, isJob = true) => (
      <div className='applicants-general-middle-credential'>
        <div className='applicants-general-middle-credential-info'>
          <span className='applicants-general-middle-credential-title'>{truncateText(isJob ? credential.role : credential.title)}</span>
          <span className='applicants-general-middle-credential-company'>{truncateText(isJob ? credential.employer : credential.school)}</span>
          <span className='applicants-general-middle-credential-date'>
            {formatDateRange(credential.startDate, credential.endDate)}
          </span>
        </div>
        <div className='applicants-general-middle-credential-tags'>
          {credential.tags.map((tag, tagIndex) => (
            <span key={tagIndex} className='applicants-general-middle-credential-tag'>{tag}</span>
          ))}
        </div>
      </div>
    );
    return (
      <div className='applicants-general-view'>

        <div className='applicants-general-view-left'>
          <div className='applicants-general-view-left-info'>
            <div className='applicants-general-view-left-info-pic-name'>
              {applicant.profile.profileImageUrl && (
                <div className='applicants-general-view-left-info-pic-name'>
                  <img
                    className='applicants-general-view-left-pic'
                    src={applicant.profile.profileImageUrl}
                    alt='Profile'
                  />
                </div>
              )}
              <div className='applicants-general-view-left-name'>
                <span className='applicants-general-view-left-firstname'>{firstName}</span>
                <span className='applicants-general-view-left-lastname'>{lastName}</span>
              </div>
            </div>
            <div className='applicants-general-view-left-info-element'>
              <img src={email_icon} alt='email' className='applicants-general-view-left-info-element-icon' />
              <span className='applicants-general-view-left-info-element-text'>
                {applicant.profile.email || 'Email not provided'}
              </span>
            </div>
            <div className='applicants-general-view-left-info-element'>
              <img src={phone_icon} alt='phone' className='applicants-general-view-left-info-element-icon' />
              <span className='applicants-general-view-left-info-element-text'>
                {applicant.profile.phone || 'Phone number not provided'}
              </span>
            </div>
            <div className='applicants-general-view-left-info-element'>
              <img src={address_icon} alt='address' className='applicants-general-view-left-info-element-icon' />
              <span className='applicants-general-view-left-info-element-text'>
                {applicant.profile.address || 'Address not provided'}
              </span>
            </div>
            <div className='applicants-general-view-left-info-element'>
              <img src={gender_icon} alt='gender' className='applicants-general-view-left-info-element-icon' />
              <span className='applicants-general-view-left-info-element-text'>
                {applicant.profile.gender ? applicant.profile.gender.charAt(0).toUpperCase() + applicant.profile.gender.slice(1) : 'Gender not specified'}
              </span>
            </div>
            <div className='applicants-general-view-left-info-element'>
              <img src={languages_icon} alt='languages' className='applicants-general-view-left-info-element-icon' />
              <span className='applicants-general-view-left-info-element-text'>
                {applicant.profile.languages && applicant.profile.languages.length > 0
                  ? applicant.profile.languages.map(lang => `${lang.name} (${getLanguageLevel(lang.level)})`).join(', ')
                  : 'Languages not specified'}
              </span>
            </div>
            <div className='applicants-general-view-left-info-buttons'>
              <button
                className={`applicants-general-view-left-info-button ${!applicant.profile.linkedinUrl ? 'disabled' : ''}`}
                onClick={() => applicant.profile.linkedinUrl && window.open(applicant.profile.linkedinUrl, '_blank')}
                disabled={!applicant.profile.linkedinUrl}
              >
                <img src={linkedin_logo} alt='linkedin_logo' className='applicants-general-view-left-info-button-icon' />
                <span className='applicants-general-view-left-info-button-text'>View Profile</span>
              </button>
              <button
                className={`applicants-general-view-left-info-button ${!applicant.profile.resumeUrl ? 'disabled' : ''}`}
                onClick={() => applicant.profile.resumeUrl && window.open(applicant.profile.resumeUrl, '_blank')}
                disabled={!applicant.profile.resumeUrl}
              >
                <img src={resume_logo} alt='resume_logo' className='applicants-general-view-left-info-button-icon' />
                <span className='applicants-general-view-left-info-button-text'>View CV</span>
              </button>
            </div>
          </div>
          <div className='applicants-general-view-left-divider' />
          <div className='applicants-general-view-left-score-req'>
            <div className='applicants-general-view-left-score'>
              <span className='applicants-general-view-left-score-title'>Score</span>
                <div className='applicants-general-view-left-score-main'>
                  <span className='applicants-general-view-left-score-main-number'>{Math.round(overallScore)}</span>
                </div>

            </div>
              <div className='applicants-general-view-left-score-details'>
                <div className='score-detail'>
                  <span className='applicants-general-view-left-score-title'>Requirements met</span>
                  {renderCircles(requirementsMet, totalRequirements)}
                </div>
                <div className='score-detail'>
                  <span className='applicants-general-view-left-score-title'>Questions answered</span>
                  {renderCircles(answeredQuestions, totalQuestions)}
                </div>
              </div>
          </div>
          <div className='applicants-general-view-left-divider' />
          <div className='applicants-general-view-left-buttons'>
            <div className='applicants-general-view-left-buttons-top'>
            <button
  className='applicants-general-view-left-reject-button'
  onClick={() => handleReject(applicant)}
>
  <img src={reject_icon} alt='reject' className='applicants-general-view-left-reject-button-icon' />
  <span className='applicants-general-view-left-buttons-text'>Reject</span>
</button>
              {nextStatus && (
                <button
                  className='applicants-general-view-left-promote-button'
                  onClick={() => handleMoveToNextStatus(applicant)}
                >
                  <img src={promote_icon} alt='promote' className='applicants-general-view-left-promote-button-icon' />
                  <span className='applicants-general-view-left-buttons-text'>Move to {nextStatus}</span>
                </button>
              )}
            </div>
            <button
              className='applicants-general-view-left-next-button'
              onClick={handleViewNextApplicant}
            >
              <img src={next_icon} alt='next' className='applicants-general-view-left-next-button-icon' />
              <span className='applicants-general-view-left-buttons-text'>View next applicant</span>
            </button>
          </div>
        </div>
        <div className='applicants-general-view-divider' />
        <div className='applicants-general-view-middle'>
          <div className='applicants-general-middle-credential-sections'>
            <div className='applicants-general-middle-credential-section'>
              <span className='applicants-general-middle-credential-section-title'>Job Experiences</span>
              {applicant.jobexperiences.slice(0, 2).map((job, index) => renderCredential(job))}
              <div className={`additional-credentials ${expandedJobExperiences ? 'expanded' : ''}`}>
                {applicant.jobexperiences.slice(2).map((job, index) => renderCredential(job))}
              </div>
              {applicant.jobexperiences.length > 2 && (
                <button 
                  className='applicants-general-middle-crendetial-view-all'
                  onClick={() => setExpandedJobExperiences(!expandedJobExperiences)}
                >
                  {expandedJobExperiences ? 'Close' : `View all ${applicant.jobexperiences.length}`}
                </button>
              )}
            </div>
            <div className='applicants-general-middle-credential-section'>
              <span className='applicants-general-middle-credential-section-title'>Academics</span>
              {applicant.academics.slice(0, 1).map((academic, index) => renderCredential(academic, false))}
              <div className={`additional-credentials ${expandedAcademics ? 'expanded' : ''}`}>
                {applicant.academics.slice(1).map((academic, index) => renderCredential(academic, false))}
              </div>
              {applicant.academics.length > 1 && (
                <button 
                  className='applicants-general-middle-crendetial-view-all'
                  onClick={() => setExpandedAcademics(!expandedAcademics)}
                >
                  {expandedAcademics ? 'Close' : `View all ${applicant.academics.length}`}
                </button>
              )}
            </div>
          </div>
          <div className='applicants-general-view-middle-internal-divider' />
          <RequirementView 
  requirements={jobOffer.requirements}
  applicantRequirementsMet={applicant.application.requirementsMet}
/>
          <div className='applicants-general-view-middle-internal-divider' />
          <QuestionsAnswersView 
  questions={jobOffer.questions}
  answers={applicant.application.answers}
  coverLetter={applicant.application.coverLetter}
/>
        </div>
        <div className='applicants-general-view-divider' />
        <div className='applicants-general-view-right'>
          <div className='applicants-general-view-right-title-filter'>
            <span className='applicants-general-view-right-title'>Events</span>
            <button 
              className='applicants-general-view-right-filter-button'
              onClick={() => setIsFilterOpen(!isFilterOpen)}
            >
              <img src={filter_icon} alt='filter' className='applicants-general-view-right-filter-button-icon' />
              <span className='applicants-general-view-right-filter-button-text'>Filter</span>
            </button>
          </div>
          {isFilterOpen && (
            <div className="applicants-general-view-right-filter-dropdown">
                <button className="filter-close-button" onClick={() => setIsFilterOpen(false)}>
    &times;
  </button>
              {Object.keys(eventFilters).map(eventType => (
                <label key={eventType} className="applicants-general-view-right-filter-option">
                  <input
                    type="checkbox"
                    checked={eventFilters[eventType]}
                    onChange={() => handleFilterToggle(eventType)}
                  />
                  {eventType.replace('_', ' ')}
                </label>
              ))}
            </div>
          )}
          <div className='applicants-general-view-right-divider' />
          <div className='applicants-general-view-right-events-section'>
          {eventLoading ? (
              <div>Loading events...</div>
            ) : eventError ? (
              <div>Error loading events. Please try again.</div>
            ) : (
              <>
                {!hasAppliedEvent && (
                  <div className="applicants-general-view-right-event">
                    <div className="applicants-general-view-right-event-element">
                      <img src={inbox_icon} alt="applied" className="applicants-general-view-right-event-element-icon" />
                      <span className="applicants-general-view-right-event-element-text">Applied</span>
                    </div>
                    <span className="applicants-general-view-right-event-date">
                      {formatDate(applicant.application.applicationDate)}
                    </span>
                  </div>
                )}
                {filteredEvents.map(renderEvent)}
              </>
            )}
      </div>
          <div className='applicants-general-view-right-note-input'>
            <textarea
              className='applicants-general-view-right-note-textarea'
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
              placeholder="Add a note..."
            />
            <button 
              className='applicants-general-view-right-note-button'
              onClick={handleAddNote}
            >
              Add Note
            </button>
          </div>
        </div>
      </div>
    );
  };

    
  // Render loading or error states
  if (loadingJobOffer) {
    return <div>Loading job offer details...</div>;
  }
  if (jobofferError) {
    return <div>Error loading job offer details. Please try again.</div>;
  }



  return (
    <div className='applicants-general'>
      <ConfirmationModal
  isOpen={isConfirmModalOpen}
  onClose={() => setIsConfirmModalOpen(false)}
  onConfirm={confirmReject}
  message="Are you sure you want to reject this applicant?"
/>
      <NavbarRecruiting />
      <div className='applicants-general-content'>
        <div className='job-title-back'>
          <Link to='/job_offers' className='job-back'>
            <img src={back_icon} alt='back' />
          </Link>
          <h1 className='job-title'>{jobOffer ? jobOffer.title : 'Loading...'}</h1>
          </div>
      <div className='applicants-general-statuses-apply-link'>
      {jobOffer && (
  <>
    <div className='applicants-general-statuses'>
      {['Applied', 'Screening', 'Interview', 'Offer'].map((status, index, array) => (
        <React.Fragment key={status}>
          <div
            className={`applicants-general-status ${selectedStatus === status ? 'active' : ''}`}
            onClick={() => handleStatusChange(status)}
          >
            <div className='applicants-general-status-title-icon'>
              <img src={getStatusIcon(status)} alt={status.toLowerCase()} className='applicants-general-status-icon' />
              <span className='applicants-general-status-title'>{status}</span>
            </div>
            <div className='applicants-general-status-count'>
              <span className='applicants-general-status-count-number'>{getStatusCount(status)}</span>
            </div>
          </div>
          {index < array.length - 1 && <div className='applicants-general-status-divider' />}
        </React.Fragment>
      ))}
    </div>
    <button className='applicants-general-copy-apply-link-button' onClick={handleCopyLink}>
      <img src={copy_icon} alt='copy' className='applicants-general-copy-apply-link-button-icon' />
      <span className='applicants-general-copy-apply-link-button-text'>Copy job application link</span>
    </button>
  </>
)}

      </div>
        <div className='applicants-general-list-view'>

          <div className='applicants-general-list-search'>
            <div className='applicants-general-search'>
              <span className='applicants-general-search-title'>Search</span>
              <input
                placeholder="Search applicant info"
                className='applicants-general-search-input'
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <div className='applicants-general-list-divider-view' />
            <div className='applicants-general-list'>
              {filteredApplicants.length > 0 ? (
                filteredApplicants.map((applicant, index) => (
                  <React.Fragment key={applicant.id}>
                    <div
                      className={`applicants-general-list-applicant ${activeApplicant === applicant.id ? 'active' : ''}`}
                      onClick={() => handleApplicantClick(applicant.id)}
                    >
                      <div className='applicants-general-list-applicant-pic-info'>
                        {applicant.profile.profileImageUrl ? (
                          <img
                            className='applicants-general-list-applicant-pic'
                            src={applicant.profile.profileImageUrl}
                            alt='profile'
                          />
                        ) : (
                          <div className='applicants-general-list-applicant-pic' style={{
                            backgroundColor: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'black',
                            fontWeight: 'bold'
                          }}>
                            {getInitials(applicant.profile.fullname)}
                          </div>
                        )}
                        <div className='applicants-general-list-applicant-info'>
                          <span className='applicants-general-list-applicant-name'>{truncateName(applicant.profile.fullname)}</span>
                          <span className='applicants-general-list-applicant-applydate'>
                            Applied {formatDate(applicant.application.applicationDate)}
                          </span>
                        </div>
                      </div>
                      <div className='applicants-general-list-applicant-score'>
                        <span className='applicants-general-list-applicant-score-number'>
                          {Math.round(calculateOverallScore(
                            applicant.application.resumeScore,
                            applicant.application.coverLetterScore,
                            applicant.application.answersScore
                          ))}
                        </span>
                      </div>
                    </div>
                    {index < filteredApplicants.length - 1 && <div className='applicants-general-list-divider' />}
                  </React.Fragment>
                ))
              ) : (
                <div className='applicants-general-list-empty'>
                  No applicants in {selectedStatus} yet
                </div>
              )}
            </div>
          </div>
          {activeApplicant && renderApplicantView(filteredApplicants.find(a => a.id === activeApplicant))}
        </div>
      </div>
    </div>
  );
};

export default Applicants;