import React from 'react';
import './Add-button.css';
import { Link } from 'react-router-dom';

const Add_Button = ({ onClick }) => {
  return (
    <Link onClick={onClick}>
      <button className="add-button">
        +
      </button>
    </Link>
  );
}

export default Add_Button;
