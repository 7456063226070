import { useState, useEffect } from 'react';
import { Card, Input } from 'antd';
import CheckableTag from 'antd/es/tag/CheckableTag';
import { useTranslation } from 'react-i18next';


const TagsComponent = ({ tagsData, selectedTags, handleTagSelect, maxTags }) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');

  const filteredTags = tagsData.filter(tag => 
    tag.toLowerCase().includes(searchValue.toLowerCase())
  );

  // Assuming `CheckableTag` calls this function with the correct `checked` status
const onTagClick = (tag, checked) => {
  handleTagSelect(tag, checked);
};

  return (
    <Card title={t("Tags")} className="tags-container">
      <Input
        placeholder={t("Search tags")}
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        style={{ marginBottom: '10px' }}
      />
      {filteredTags.map(tag => (
  <CheckableTag
    key={tag}
    checked={selectedTags.includes(tag)}
    onChange={(checked) => {
      handleTagSelect(tag, checked);
    }}
  >
    {t(tag)}
  </CheckableTag>
))}
      <p className='max-tags-text'>{t('You can select up to')} {maxTags} {t('tags')}.</p>
    </Card>
  );
};

export default TagsComponent;
