// components/JobBoard.js
import React, { useEffect, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import JobOffer from '../../../components/joboffer/JobOfferComponent';
import './JobBoard.css';


const GET_JOB_OFFERS_BY_COMPANY = gql`
  query GetJobOffersByCompany($company: String!) {
    getJobOffersByCompany(company: $company) {
      id
      userID
      recruiterID
      title
      description
      place
      workMode
      responsibilities
      offers
      salaryRange
      applicationsCount
      state
      createdAt
      workHours
    }
  }
`;

const GET_RECRUITER_BY_COMPANY = gql`
query GetRecruiterByCompany($company: String!) {
    getRecruiterByCompany(company: $company) {
      id
      userID
      company
      description
      subscription
    }
  }
`;

const JobBoard = () => {
  const { companyName } = useParams();
  const [jobOffers, setJobOffers] = useState([]);
  const [companyDetails, setCompanyDetails] = useState(null);

  const { loading: loadingJobOffers, error: joboffersError, data: joboffersData } = useQuery(
    GET_JOB_OFFERS_BY_COMPANY,
    {
      variables: { company: companyName },
      skip: !companyName,
      pollInterval: 10000,  // Poll every 10000 ms (10 seconds)
      onError: (error) => {
        console.error('Job Offers Error:', error);
      }
    }
  );

  const { loading: loadingRecruiter, error: recruiterError, data: recruiterData } = useQuery(
    GET_RECRUITER_BY_COMPANY,
    {
      variables: { company: companyName },
      skip: !companyName,
      pollInterval: 10000,  // Poll every 10000 ms (10 seconds)
      onError: (error) => {
        console.error('Job Offers Error:', error);
      }
    }
  );


  useEffect(() => {
    if (joboffersData && joboffersData.getJobOffersByCompany) {
      setJobOffers(joboffersData.getJobOffersByCompany);
    }
  }, [joboffersData]);

  return (
    <div className="jobboard-container">
      {companyDetails && (
        <div className="company-info">
          <img src={recruiterData.logoUrl} alt={`${companyName} logo`} className="company-logo" />
          <p className="company-description">{recruiterData.description}</p>
          <div className="company-links">
            {recruiterData.linkedinUrl && <a href={recruiterData.linkedinUrl} target="_blank" rel="noopener noreferrer">LinkedIn</a>}
            {recruiterData.bookingUrl && <a href={recruiterData.bookingUrl} target="_blank" rel="noopener noreferrer">Book a Meeting</a>}
          </div>
        </div>
      )}
      <h1 className="jobboard-title">Job Offers at {companyName}</h1>
      {jobOffers.length === 0 ? (
        <p className="no-joboffers">No job offers available</p>
      ) : (
        <ul className="joboffers-list">
          {jobOffers.map((job) => (
            <li key={job.id} className="joboffer-item">
              <JobOffer joboffer={job} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default JobBoard;
