// src/components/BookInterviewButton.js

import React, { useState } from 'react';
import './BookInterview.css';

const BookInterview = ({ recruiter, applicant, jobTitle }) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  
    const handleButtonClick = () => {
      console.log('Book Interview button clicked');
      console.log('Recruiter:', recruiter);
        console.log('Applicant:', applicant);
        console.log('Job Title:', jobTitle);
      setShowConfirmation(true);
    };
  
    const handleConfirm = () => {
      console.log('Confirmation received for sending email');
      console.log('Email details:', {
        recruiterEmail: recruiter.email,
        applicantEmail: applicant.profile.email,
        bookingUrl: recruiter.bookingUrl,
        applicantName: applicant.profile.fullname,
        jobTitle: jobTitle,
        companyName: recruiter.company,
      });
  
      fetch('https://simpl-backend-f1e8862591da.herokuapp.com/send-booking-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          recruiterEmail: recruiter.email,
          applicantEmail: applicant.profile.email,
          bookingUrl: recruiter.bookingUrl,
          applicantName: applicant.profile.fullname,
          jobTitle: jobTitle,
          companyName: recruiter.company,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            console.log('Email sent successfully');
            setShowSuccessMessage(true);
            setTimeout(() => {
              setShowSuccessMessage(false);
            }, 5000); // Hide the success message after 5 seconds
          } else {
            console.error('Error sending email:', data.error);
            alert('Error sending email');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
  
      setShowConfirmation(false);
    };
  
    const handleCancel = () => {
      console.log('Email sending cancelled');
      setShowConfirmation(false);
    };
  
    return (
      <div className="book-interview-container">
        {!showConfirmation && !showSuccessMessage && (
          <button className="book-interview-button" onClick={handleButtonClick}>
            Book Interview
          </button>
        )}
        {showConfirmation && (
          <div className="confirmation-card">
            <p>Are you sure you want to send an interview booking email to {applicant.profile.fullname}?</p>
            <button className="confirm-button" onClick={handleConfirm}>Confirm</button>
            <button className="cancel-button" onClick={handleCancel}>Cancel</button>
          </div>
        )}
        {showSuccessMessage && (
          <div className="success-message">
            <p>Email sent successfully! You will see the interview booking in your calendar once the applicant accepts.</p>
          </div>
        )}
      </div>
    );
  };

export default BookInterview;
