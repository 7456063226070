import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'; // Import the plugin

i18n
  .use(LanguageDetector) // Use the language detector
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          "Page": "Page",
          "Filter": "Filter",
          "Search": "Search",
          "Search Applicants": "Search Applicants",
          "Active": "Active",
          "Paused": "Paused",
          "Closed": "Closed",
          "Create Job Offer": "Create Job Offer",
          "Dashboard": "Dashboard",
          "Job Offers": "Job Offers",
          "Settings": "Settings",
          "Error creating job offer": "Error creating job offer",
          "Job Title": "Job Title",
          "Job Description": "Job Description",
          "Office Address": "Office Address",
          "Mode": "Mode",
          "Work Mode": "Work Mode",
          "In Office": "In Office",
          "Hybrid": "Hybrid",
          "Remote": "Remote",
          "Type": "Type",
          "Job Type": "Job Type",
          "Full-time": "Full-time",
          "Part-time": "Part-time",
          "Contract": "Contract",
          "Internship": "Internship",
          "Responsibilities": "Responsibilities",
          "Do Not Specify Salary Range": "Don't Specify Salary Range",
          "Requirements": "Requirements",
          "Salary Range": "Salary Range",
          "Responsibility": "Responsibility",
          "Add": "Add",
          "Delete": "Delete",
          "Current range": "Current range",
          "Not Specified": "Not Specified",
          "Add Requirement": "Add Requirement",
          "Experience": "Experience",
          "Academic": "Academic",
          "Distance": "Distance",
          "Language": "Language",
          "Specify job title and minimum years of experience.": "Specify job title and minimum years of experience.",
          "Specify required academic qualification.": "Specify required academic qualification.",
          "Specify maximum distance from work place.": "Specify maximum distance from work place.",
          "Specify a language required for the job": "Specify a language required for the job",
          "Years of Experience": "Years of Experience",
          "Select a Category": "Select a Category",
          "Category": "Category",
          "Tags": "Tags",
          "Search tags": "Search tags",
          "No years required": "No years required",
          "Degree": "Degree",
          "Select a degree": "Select a Degree",
          "Fields of Study": "Fields of Study",
          "Search fields of study": "Search fields of study",
          "High School Diploma": "High School Diploma",
          "Algebra": "Algebra",
          "Calculus": "Calculus",
          "Trigonomety": "Trigonometry",
          "Statistics": "Statistics",
          "Physics": "Physics",
          "Chemistry": "Chemistry",
          "Biology": "Biology",
          "English Literature": "English Literature",
          "Creative Writing": "Creative Writing",
          "History": "History",
          "Geography": "Geography",
          "Economics": "Economics",
          "Computer Programming": "Computer Programming",
          "Web Development": "Web Development",
          "Graphic Design": "Graphic Design",
          "Music": "Music",
          "Visual Arts": "Visual Arts",
          "Theater": "Theater",
          "Bachelor's Degree": "Bachelor's Degree",
          "Accounting": "Accounting",
          "Anthropology": "Anthropology",
          "Architecture": "Architecture",
          "Biomedical Engineering": "Biomedical Engineering",
          "Chemical Engineering": "Chemical Engineering",
          "Civil Engineering": "Civil Engineering",
          "Computer Science": "Computer Science",
          "Criminology": "Criminology",
          "Economics": "Economics",
          "Electrical Engineering": "Electrical Engineering",
          "Environmental Science": "Environmental Science",
          "Film Studies": "Film Studies",
          "Finance": "Finance",
          "Game Design": "Game Design",
          "Healthcare Administration": "Healthcare Administration",
          "Industrial Engineering": "Industrial Engineering",
          "Journalism": "Journalism",
          "Kinesiology": "Kinesiology",
          "Linguistics": "Linguistics",
          "Marketing": "Marketing",
          "Master's Degree": "Master's Degree",
          "Aerospace Engineering": "Aerospace Engineering",
          "Astronomy": "Astronomy",
          "Bioinformatics": "Bioinformatics",
          "Biostatistics": "Biostatistics",
          "Clinical Psychology": "Clinical Psychology",
          "Cognitive Science": "Cognitive Science",
          "Data Science": "Data Science",
          "Educational Leadership": "Educational Leadership",
          "Energy Engineering": "Energy Engineering",
          "Entrepreneurship": "Entrepreneurship",
          "Food Science": "Food Science",
          "Genetics": "Genetics",
          "Geophysics": "Geophysics",
          "Human Resources": "Human Resources",
          "Information Security": "Information Security",
          "Materials Science": "Materials Science",
          "Mathematical Modeling": "Mathematical Modeling",
          "Molecular Biology": "Molecular Biology",
          "Nanotechnology": "Nanotechnology",
          "Neuroscience": "Neuroscience",
          "Doctorate Degree": "Doctorate Degree",
          "Artificial Intelligence": "Artificial Intelligence",
          "Astrophysics": "Astrophysics",
          "Behavioral Economics": "Behavioral Economics",
            "Biochemistry": "Biochemistry",
            "Biophysics": "Biophysics",
            "Cell Biology": "Cell Biology",
            "Climate Science": "Climate Science",
            "Complex Systems": "Complex Systems",
            "Computational Biology": "Computational Biology",
            "Cryptography": "Cryptography",
            "Demography": "Demography",
            "Developmental Psychology": "Developmental Psychology",
            "Ecology": "Ecology",
            "Epidemiology": "Epidemiology",
            "Ethics": "Ethics",
            "Evolutionary Biology": "Evolutionary Biology",
            "Fluid Mechanics": "Fluid Mechanics",
            "Human-Computer Interaction": "Human-Computer Interaction",
            "Immunology": "Immunology",
          "Philosophy of Science": "Philosophy of Science",
          "Post-Doctorate": "Post-Doctorate",
          "Additive Manufacturing": "Additive Manufacturing",
            "Affective Computing": "Affective Computing",
            "Applied Mathematics": "Applied Mathematics",
            "Biomaterials": "Biomaterials",
            "Biomedical Imaging": "Biomedical Imaging",
            "Biophotonics": "Biophotonics",
            "Cancer Research": "Cancer Research",
            "Cardiovascular Research": "Cardiovascular Research",
            "Cognitive Neuroscience": "Cognitive Neuroscience",
            "Computational Chemistry": "Computational Chemistry",
            "Computational Fluid Dynamics": "Computational Fluid Dynamics",
            "Control Theory": "Control Theory",
            "Drug Discovery": "Drug Discovery",
            "Energy Storage": "Energy Storage",
            "Environmental Policy": "Environmental Policy",
            "Graph Theory": "Graph Theory",
            "Machine Learning": "Machine Learning",
            "Microbiology": "Microbiology",
            "Optics": "Optics",
        "Robotics":"Robotics",
        "years": "years",
        "Max Distance": "Max Distance",
        "Proficiency": "Proficiency",
        "Basic": "Basic",
        "Conversational": "Conversational",
        "Proficient": "Proficient",
        "Fluent": "Fluent",
        'English': 'English', 'Spanish': 'Spanish', 'Mandarin': 'Mandarin', 'Hindi': 'Hindi', 'Arabic': 'Arabic', 'Portuguese': 'Portuguese', 'Bengali': 'Bengali', 'Russian': 'Russian', 
        'Japanese': 'Japanese', 'Punjabi': 'Punjabi', 'German': 'German', 'Javanese': 'Javanese', 'Wu': 'Wu', 'Malay': 'Malay', 'Telugu': 'Telugu', 'Vietnamese': 'Vietnamese', 
        'Korean': 'Korean', 'French': 'French', 'Marathi': 'Marathi', 'Tamil': 'Tamil', 'Urdu': 'Urdu', 'Turkish': 'Turkish', 'Italian': 'Italian', 'Thai': 'Thai', 'Gujarati': 'Gujarati', 
        'Jin': 'Jin', 'Southern Min': 'Southern Min', 'Persian': 'Persian', 'Polish': 'Polish', 'Pashto': 'Pashto', 'Kannada': 'Kannada', 'Xiang': 'Xiang', 'Malayalam': 'Malayalam', 
        'Sundanese': 'Sundanese', 'Hausa': 'Hausa', 'Odia': 'Odia', 'Burmese': 'Burmese', 'Hakka': 'Hakka', 'Ukrainian': 'Ukrainian', 'Bhojpuri': 'Bhojpuri', 'Tagalog': 'Tagalog', 
        'Yoruba': 'Yoruba', 'Maithili': 'Maithili', 'Uzbek': 'Uzbek', 'Sindhi': 'Sindhi', 'Amharic': 'Amharic', 'Fula': 'Fula', 'Romanian': 'Romanian', 'Oromo': 'Oromo', 'Igbo': 'Igbo', 
        'Azerbaijani': 'Azerbaijani', 'Awadhi': 'Awadhi', 'Gan': 'Gan', 'Cebuano': 'Cebuano', 'Dutch': 'Dutch', 'Kurdish': 'Kurdish', 'Serbo-Croatian': 'Serbo-Croatian', 'Malagasy': 'Malagasy', 
        'Saraiki': 'Saraiki', 'Nepali': 'Nepali', 'Sinhalese': 'Sinhalese', 'Chittagonian': 'Chittagonian', 'Zhuang': 'Zhuang', 'Khmer': 'Khmer', 'Turkmen': 'Turkmen', 'Somali': 'Somali', 
        'Marwari': 'Marwari', 'Magahi': 'Magahi', 'Haryanvi': 'Haryanvi', 'Hungarian': 'Hungarian', 'Chhattisgarhi': 'Chhattisgarhi', 'Greek': 'Greek', 'Chewa': 'Chewa', 'Deccan': 'Deccan', 
        'Akan': 'Akan', 'Kazakh': 'Kazakh', 'Northern Min': 'Northern Min', 'Sylheti': 'Sylheti', 'Zulu': 'Zulu', 'Czech': 'Czech', 'Kinyarwanda': 'Kinyarwanda',
        "Select a Category": "Select a Category",
        "Max distance in km": "Max distance in km",
        "Select Language": "Select Language",
        "Select Proficiency": "Select Proficiency",
        "Add Questions": "Add Questions",
        "Add Question": "Add Question",
        "Question": "Question",
        "Priority": "Priority",
        "Profile updated successfully!": "Profile updated successfully!",
        "Profile Settings": "Profile Settings",
        "Company Name": "Company Name",
        "Description": "Description",
        "Save Changes": "Save Changes",
        "Answer": "Answer",
        "There are either no questions or they have not been answered by this applicant": "There are either no questions or they have not been answered by this applicant",
        "No answer": "No answer",
        "Questions & Answers": "Questions & Answers",
        "There was an error updating the application status": "There was an error updating the application status",
        "Job Experiences": "Job Experiences",
        "Education": "Education",
        "Courses": "Courses",
        "Languages": "Languages",
        "Applicant Info": "Applicant Info",
        "Applied on": "Applied on",
        "Status": "Status",
        "Rejected": "Rejected",
        "Applied": "Applied",
        "Screening": "Screening",
        "Interview": "Interview",
        "Offer": "Offer",
        "Cover Letter": "Cover Letter",
        "Age": "Age",
        "Gender":"Gender",
        "Unnkown": "Unknown",
        "Showing": "Showing",
        "of": "of",
        "applicants": "applicants",
        "Clear All Filters": "Clear All Filters",
        "Apply Filters": "Apply Filters",
        "Job Offer Status": "Job Offer Status",
        "To": "To",
        "From": "From",
        "Submission Details": "Submission Details",
        "Cover Letter Provided": "Cover Letter Provided",
        "Filter & Sort": "Filter & Sort",
        "Job Application Link": "Job Application Link",
        "Search Applicants": "Search Applicants",
        "Application Status": "Application Status",
        "Application Details": "Application Details",
        "Requirements Met": "Requirements Met",
        "Answers": "Answers",
        "Present": "Present",
        "Absent": "Absent",
        "Search Tags": "Search Tags",
        "You can select up to": "You can select up to",
        "tags": "tags",
        "Are you sure you want to change the state of the job offer?": "Are you sure you want to change the state of the job offer?",
        "Confirm": "Confirm",
        "Cancel": "Cancel",
        "The job was created successfully. Share this link for applications:": "The job was created successfully. Share this link for applications:",
        "Draft": "Draft",
        "Notes": "Notes",
        "Applicant Notes": "Applicant Notes",
        "In Review": "In Review",
        "Send Application": "Send Application",
        "Not available": "Not available",
        "Thank you for your feedback!": "Thank you for your feedback!",
        "There was an error submitting your feedback. Please try again.": "There was an error submitting your feedback. Please try again.",
        "We Value Your Feedback!": "We Value Your Feedback!",
        "Your feedback is essential to us. Please let us know about any issues, confusing interfaces, or potential new features you would like to see. We aim to make this platform as user-friendly as possible": "Your feedback is essential to us. Please let us know about any issues, confusing interfaces, or potential new features you would like to see. We aim to make this platform as user-friendly as possible",
        "Provide your feedback...": "Provide your feedback...",
        "Submit": "Submit",
        "Created": "Created",
        "days ago": "days ago",
        "Applications": "Applications",
        "Profile": "Profile",
        "Let's start creating credentials": "Let's start creating credentials",
        "Fill these credential sections before finishing your application, I know it’s very annoying but we created this startup, for this very purpose, ending this resume creation every time you apply for job offers. Also, shouldn’t your resume be as easy to manage as your instagram profile? Bear with me and this will become standard.": "Fill these credential sections before finishing your application, I know it’s very annoying but we created this startup, for this very purpose, ending this resume creation every time you apply for job offers. Also, shouldn’t your resume be as easy to manage as your instagram profile? Bear with me and this will become standard.",
        "Back": "Back",
        "Create Academic Credential": "Create Academic Credential",
        "Male":"Male",
        "Female":"Female",
        "Other":"Other",
        "I have read and accept the": "I have read and accept the",
        "Are you sure you want to continue? Please ensure you have completed all profile creation tasks.": "Are you sure you want to continue? Please ensure you have completed all profile creation tasks.",
        "Profile complete? Apply instantly from now on": "Profile complete? Apply instantly from now on",
        "From now on you will be able to apply instantly with this profile, we are working to make this profile accepted in as many job offers as possible, no more having to refill your entire resume every time! (yea, except this time). Access your account to monitor the status of your applications and stay tuned for other functions coming!":"From now on you will be able to apply instantly with this profile, we are working to make this profile accepted in as many job offers as possible, no more having to refill your entire resume every time! (yea, except this time). Access your account to monitor the status of your applications and stay tuned for other functions coming!",
        "Create Course Credential": "Create Course Credential",
        "Create Job Experience Credential": "Create Job Experience Credential",
        "Please fill all fields before submitting": "Please fill all fields before submitting",
        "Remember PDF Resumes?": "Remember PDF Resumes?",
        "Continue": "Continue",
        "Organise Interview": "Organise Interview",
        "For any info": "For any info",
        "Company Overview": "Company Overview",
        "Job Description": "Job Description",
        "Questions": "Questions",
        "Answers": "Answers",
        "Your cover letter can detail your motivation and why you're a good fit for this role.": "Your cover letter can detail your motivation and why you're a good fit for this role.",
        "This is where you will be able to keep track and remain updated on the state of your job applications":"This is where you will be able to keep track and remain updated on the state of your job applications",
        "Applied on ": "Applied on ",
        "From ": "From ",
        " to ": " to ",
        "This is your personal information. We already filled this out from your registration.": "This is your personal information. We already filled this out from your registration.",
        "Here you can manage your educational credentials like High School Diplomas, Degrees, etc. Start adding them right away!": "Here you can manage your educational credentials like High School Diplomas, Degrees, etc. Start adding them right away!",
        'Manage your job experiences here. We use categories to ease filtering, there are many to choose from, let us know if we missed any!': 'Manage your job experiences here. We use categories to ease filtering, there are many to choose from, let us know if we missed any!',
        'List all the courses you have done here.': 'List all the courses you have done here.',
        'Now, finish completing your professional profile here and then navigate to "Applications" to start applying for jobs instantly': 'Now, finish completing your professional profile here and then navigate to "Applications" to start applying for jobs instantly',
        "Failed to update profile. Please try again.": "Failed to update profile. Please try again.",
        "An error occurred. Please try again later.": "An error occurred. Please try again later.",
        "Profile updated successfully!": "Profile updated successfully!",
        "Software & Web Development": "Software & Web Development",
"Full-Stack Development": "Full-Stack Development",
"JavaScript": "JavaScript",
"Node.js": "Node.js",
"Express.js": "Express.js",
"React.js": "React.js",
"Vue.js": "Vue.js",
"Ruby on Rails": "Ruby on Rails",
"Python": "Python",
"Django": "Django",
"Java": "Java",
"Spring Boot": "Spring Boot",
"SQL": "SQL",
"NoSQL": "NoSQL",
"MongoDB": "MongoDB",
"PostgreSQL": "PostgreSQL",
"Git": "Git",
"API Development": "API Development",
"Heroku": "Heroku",
"Docker": "Docker",
"AWS": "AWS",
"CI/CD": "CI/CD",
"Software & Web Development": "Software & Web Development",
"Front-end Development": "Front-end Development",
"HTML5": "HTML5",
"CSS": "CSS",
"SASS": "SASS",
"LESS": "LESS",
"JavaScript": "JavaScript",
"ES6": "ES6",
"TypeScript": "TypeScript",
"React.js": "React.js",
"Vue.js": "Vue.js",
"Angular.js": "Angular.js",
"Redux": "Redux",
"Vuex": "Vuex",
"Bootstrap": "Bootstrap",
"Material-UI": "Material-UI",
"JQuery": "JQuery",
"Bulma": "Bulma",
"Webpack": "Webpack",
"Babel": "Babel",
"NPM": "NPM",
"Yarn": "Yarn",
"GraphQL": "GraphQL",
"Ajax": "Ajax",
"Web Accessibility": "Web Accessibility",
"UI/UX Design": "UI/UX Design",
"Performance Optimization": "Performance Optimization",
"Responsive Web Design": "Responsive Web Design",
"Cross-Browser Compatibility": "Cross-Browser Compatibility",
"Software & Web Development": "Software & Web Development",
"Back-end Development": "Back-end Development",
"Node.js": "Node.js",
"Ruby on Rails": "Ruby on Rails",
"Python": "Python",
"Flask": "Flask",
"Django": "Django",
"Java": "Java",
"Spring Boot": "Spring Boot",
"PHP": "PHP",
"Laravel": "Laravel",
"API Development": "API Development",
"REST": "REST",
"GraphQL": "GraphQL",
"SQL": "SQL",
"NoSQL": "NoSQL",
"MongoDB": "MongoDB",
"PostgreSQL": "PostgreSQL",
"MySQL": "MySQL",
"Docker": "Docker",
"Microservices": "Microservices",
"AWS Lambda": "AWS Lambda",
"Serverless Architecture": "Serverless Architecture",
"Apache Kafka": "Apache Kafka",
"Git": "Git",
"Software & Web Development": "Software & Web Development",
"Mobile App Development": "Mobile App Development",
"Swift": "Swift",
"Objective-C": "Objective-C",
"iOS": "iOS",
"Java": "Java",
"Kotlin": "Kotlin",
"Android": "Android",
"Flutter": "Flutter",
"Dart": "Dart",
"React Native": "React Native",
"JavaScript": "JavaScript",
"TypeScript": "TypeScript",
"Mobile UI/UX Design": "Mobile UI/UX Design",
"Firebase": "Firebase",
"Cross-Platform Development": "Cross-Platform Development",
"App Store Deployment": "App Store Deployment",
"Performance Tuning": "Performance Tuning",
"SQLite": "SQLite",
"Push Notifications": "Push Notifications",
"Mobile Hardware Integration": "Mobile Hardware Integration",
"Software & Web Development": "Software & Web Development",
"Embedded Systems Development": "Embedded Systems Development",
"C": "C",
"C++": "C++",
"Assembly": "Assembly",
"Microcontroller Programming": "Microcontroller Programming",
"RTOS": "RTOS",
"Arduino": "Arduino",
"Raspberry Pi": "Raspberry Pi",
"Firmware Development": "Firmware Development",
"IoT": "IoT",
"UART": "UART",
"SPI": "SPI",
"I2C": "I2C",
"CAN": "CAN",
"Ethernet": "Ethernet",
"Wireless Communication": "Wireless Communication",
"Device Drivers": "Device Drivers",
"RTOS": "RTOS",
"Embedded Linux": "Embedded Linux",
"Debugging": "Debugging",
"Hardware-Software Integration": "Hardware-Software Integration",
"Software & Web Development": "Software & Web Development",
"Game Development": "Game Development",
"Unity": "Unity",
"Unreal Engine": "Unreal Engine",
"C#": "C#",
"C++": "C++",
"DirectX": "DirectX",
"OpenGL": "OpenGL",
"Physics Simulation": "Physics Simulation",
"2D/3D Animation": "2D/3D Animation",
"AI Programming": "AI Programming",
"VR/AR Integration": "VR/AR Integration",
"Game Testing": "Game Testing",
"Scripting": "Scripting",
"Game Design": "Game Design",
"Multiplayer Networking": "Multiplayer Networking",
"Monetization": "Monetization",
"Mobile Game Development": "Mobile Game Development",
"Console Game Development": "Console Game Development",
"Cross-Platform Game Development": "Cross-Platform Game Development",
"Software & Web Development": "Software & Web Development",
"Desktop Application Development": "Desktop Application Development",
"Java": "Java",
"C++": "C++",
"Python": "Python",
"Electron": "Electron",
"C#": "C#",
".NET": ".NET",
"WPF": "WPF",
"Qt": "Qt",
"GTK": "GTK",
"Windows": "Windows",
"macOS": "macOS",
"Linux": "Linux",
"User Interface Design": "User Interface Design",
"Multi-Threading": "Multi-Threading",
"Database Integration": "Database Integration",
"API Integration": "API Integration",
"Performance Optimization": "Performance Optimization",
"Software & Web Development": "Software & Web Development",
"DevOps & Infrastructure": "DevOps & Infrastructure",
"Docker": "Docker",
"Kubernetes": "Kubernetes",
"Jenkins": "Jenkins",
"GitLab CI/CD": "GitLab CI/CD",
"Travis CI": "Travis CI",
"AWS": "AWS",
"Azure": "Azure",
"Google Cloud": "Google Cloud",
"Terraform": "Terraform",
"Ansible": "Ansible",
"Monitoring": "Monitoring",
"Logging": "Logging",
"Site Reliability": "Site Reliability",
"Network Management": "Network Management",
"Server Configuration": "Server Configuration",
"Automation": "Automation",
"Release Management": "Release Management",
"Version Control": "Version Control",
"Infrastructure as Code": "Infrastructure as Code",
"Continuous Integration": "Continuous Integration",
"Continuous Deployment": "Continuous Deployment",
"Software & Web Development": "Software & Web Development",
"Database Administration & Development": "Database Administration & Development",
"SQL": "SQL",
"NoSQL": "NoSQL",
"MongoDB": "MongoDB",
"PostgreSQL": "PostgreSQL",
"MySQL": "MySQL",
"Oracle DB": "Oracle DB",
"Microsoft SQL Server": "Microsoft SQL Server",
"SQLite": "SQLite",
"Database Design": "Database Design",
"Normalization": "Normalization",
"Performance Tuning": "Performance Tuning",
"Backup and Recovery": "Backup and Recovery",
"Replication": "Replication",
"Clustering": "Clustering",
"Indexing": "Indexing",
"Stored Procedures": "Stored Procedures",
"ETL": "ETL",
"Big Data Solutions": "Big Data Solutions",
"Data Warehousing": "Data Warehousing",
"Software & Web Development": "Software & Web Development",
"Systems Architecture & Design": "Systems Architecture & Design",
"System Design": "System Design",
"Microservices": "Microservices",
"Monolithic Architecture": "Monolithic Architecture",
"Service-Oriented Architecture (SOA)": "Service-Oriented Architecture (SOA)",
"API Design": "API Design",
"Database Design": "Database Design",
"Distributed Systems": "Distributed Systems",
"Scalability": "Scalability",
"Performance Optimization": "Performance Optimization",
"System Integration": "System Integration",
"Data Flow Diagrams": "Data Flow Diagrams",
"Network Design": "Network Design",
"Security Architecture": "Security Architecture",
"High Availability": "High Availability",
"Disaster Recovery": "Disaster Recovery",
"Software & Web Development": "Software & Web Development",
"Quality Assurance & Testing": "Quality Assurance & Testing",
"Manual Testing": "Manual Testing",
"Automated Testing": "Automated Testing",
"JUnit": "JUnit",
"TestNG": "TestNG",
"Selenium": "Selenium",
"Appium": "Appium",
"Load Testing": "Load Testing",
"Stress Testing": "Stress Testing",
"Performance Testing": "Performance Testing",
"Integration Testing": "Integration Testing",
"Regression Testing": "Regression Testing",
"Black Box Testing": "Black Box Testing",
"White Box Testing": "White Box Testing",
"UAT (User Acceptance Testing)": "UAT (User Acceptance Testing)",
"Bug Tracking": "Bug Tracking",
"Test Case Development": "Test Case Development",
"Agile Testing": "Agile Testing",
"TDD (Test Driven Development)": "TDD (Test Driven Development)",
"BDD (Behavior Driven Development)": "BDD (Behavior Driven Development)",
"Continuous Testing": "Continuous Testing",
"Software & Web Development": "Software & Web Development",
"Cybersecurity & Ethical Hacking": "Cybersecurity & Ethical Hacking",
"Penetration Testing": "Penetration Testing",
"Network Security": "Network Security",
"Application Security": "Application Security",
"Endpoint Security": "Endpoint Security",
"Cryptography": "Cryptography",
"Firewall Management": "Firewall Management",
"IDS/IPS": "IDS/IPS",
"Vulnerability Assessment": "Vulnerability Assessment",
"Malware Analysis": "Malware Analysis",
"Risk Assessment": "Risk Assessment",
"Incident Response": "Incident Response",
"Security Compliance": "Security Compliance",
"Ethical Hacking": "Ethical Hacking",
"Forensic Analysis": "Forensic Analysis",
"OWASP": "OWASP",
"Social Engineering": "Social Engineering",
"Phishing": "Phishing",
"Software & Web Development": "Software & Web Development",
"Data Science & Machine Learning Development": "Data Science & Machine Learning Development",
"Python": "Python",
"R": "R",
"TensorFlow": "TensorFlow",
"Keras": "Keras",
"PyTorch": "PyTorch",
"Scikit-learn": "Scikit-learn",
"Data Cleaning": "Data Cleaning",
"Data Visualization": "Data Visualization",
"Statistical Analysis": "Statistical Analysis",
"Regression": "Regression",
"Classification": "Classification",
"Deep Learning": "Deep Learning",
"Neural Networks": "Neural Networks",
"Natural Language Processing": "Natural Language Processing",
"Computer Vision": "Computer Vision",
"Big Data": "Big Data",
"Spark": "Spark",
"Hadoop": "Hadoop",
"Time Series Analysis": "Time Series Analysis",
"Software & Web Development": "Software & Web Development",
"Cloud Computing & Development": "Cloud Computing & Development",
"AWS": "AWS",
"Azure": "Azure",
"Google Cloud Platform": "Google Cloud Platform",
"Serverless Computing": "Serverless Computing",
"Lambda": "Lambda",
"CloudFormation": "CloudFormation",
"Docker": "Docker",
"Kubernetes": "Kubernetes",
"Elastic Beanstalk": "Elastic Beanstalk",
"S3": "S3",
"EC2": "EC2",
"CloudFront": "CloudFront",
"Cloud Networking": "Cloud Networking",
"IAM": "IAM",
"Database Services": "Database Services",
"Storage Services": "Storage Services",
"Cloud Security": "Cloud Security",
"Hybrid Cloud": "Hybrid Cloud",
"Multi-cloud Management": "Multi-cloud Management",
"Software & Web Development": "Software & Web Development",
"Blockchain & Distributed Systems Development": "Blockchain & Distributed Systems Development",
"Ethereum": "Ethereum",
"Smart Contracts": "Smart Contracts",
"Solidity": "Solidity",
"Hyperledger": "Hyperledger",
"DApp Development": "DApp Development",
"Cryptography": "Cryptography",
"Blockchain Architecture": "Blockchain Architecture",
"Distributed Ledger Technology": "Distributed Ledger Technology",
"Token Development": "Token Development",
"ICO": "ICO",
"Consensus Algorithms": "Consensus Algorithms",
"Peer-to-Peer Networks": "Peer-to-Peer Networks",
"Blockchain Security": "Blockchain Security",
"Decentralized Systems": "Decentralized Systems",
"Public and Private Blockchains": "Public and Private Blockchains",
"Software & Web Development": "Software & Web Development",
"AR/VR Development": "AR/VR Development",
"Unity": "Unity",
"Unreal Engine": "Unreal Engine",
"C#": "C#",
"C++": "C++",
"ARKit": "ARKit",
"ARCore": "ARCore",
"Vuforia": "Vuforia",
"Oculus SDK": "Oculus SDK",
"SteamVR": "SteamVR",
"Virtual Reality": "Virtual Reality",
"Augmented Reality": "Augmented Reality",
"Mixed Reality": "Mixed Reality",
"3D Modeling": "3D Modeling",
"Spatial Audio": "Spatial Audio",
"Gesture Recognition": "Gesture Recognition",
"VR Game Development": "VR Game Development",
"VR Interface Design": "VR Interface Design",
"Mobile AR/VR": "Mobile AR/VR",
"Performance Optimization": "Performance Optimization",
"Software & Web Development": "Software & Web Development",
"Algorithm Design & Optimization": "Algorithm Design & Optimization",
"Big O Notation": "Big O Notation",
"Search Algorithms": "Search Algorithms",
"Sort Algorithms": "Sort Algorithms",
"Graph Algorithms": "Graph Algorithms",
"Dynamic Programming": "Dynamic Programming",
"Greedy Algorithms": "Greedy Algorithms",
"Recursion": "Recursion",
"Tree Structures": "Tree Structures",
"Hashing": "Hashing",
"Cryptography Algorithms": "Cryptography Algorithms",
"Machine Learning Algorithms": "Machine Learning Algorithms",
"Parallel Processing": "Parallel Processing",
"Distributed Algorithms": "Distributed Algorithms",
"Optimization Techniques": "Optimization Techniques",
"Software & Web Development": "Software & Web Development",
"API Development & Integration": "API Development & Integration",
"REST": "REST",
"SOAP": "SOAP",
"GraphQL": "GraphQL",
"JSON": "JSON",
"XML": "XML",
"OpenAPI": "OpenAPI",
"Postman": "Postman",
"Swagger": "Swagger",
"OAuth": "OAuth",
"JWT": "JWT",
"API Security": "API Security",
"Rate Limiting": "Rate Limiting",
"API Gateway": "API Gateway",
"Webhooks": "Webhooks",
"API Documentation": "API Documentation",
"Third-party API Integration": "Third-party API Integration",
"Software & Web Development": "Software & Web Development",
"E-commerce Development": "E-commerce Development",
"WooCommerce": "WooCommerce",
"Magento": "Magento",
"Shopify": "Shopify",
"BigCommerce": "BigCommerce",
"Drupal Commerce": "Drupal Commerce",
"PrestaShop": "PrestaShop",
"OsCommerce": "OsCommerce",
"SSL Integration": "SSL Integration",
"Payment Gateway Integration": "Payment Gateway Integration",
"Shopping Cart": "Shopping Cart",
"Product Management": "Product Management",
"Checkout Process": "Checkout Process",
"E-commerce SEO": "E-commerce SEO",
"Mobile E-commerce": "Mobile E-commerce",
"Inventory Management": "Inventory Management",
"Software & Web Development": "Software & Web Development",
"Content Management Systems (CMS) Development": "Content Management Systems (CMS) Development",
"WordPress": "WordPress",
"Joomla": "Joomla",
"Drupal": "Drupal",
"Typo3": "Typo3",
"Squarespace": "Squarespace",
"Wix": "Wix",
"Blogger": "Blogger",
"Custom CMS Development": "Custom CMS Development",
"Themes": "Themes",
"Plugins": "Plugins",
"Database Management": "Database Management",
"Content Organization": "Content Organization",
"SEO Integration": "SEO Integration",
"Multilingual Support": "Multilingual Support",
"Software & Web Development": "Software & Web Development",
"Firmware Development": "Firmware Development",
"C": "C",
"C++": "C++",
"Assembly": "Assembly",
"RTOS": "RTOS",
"Embedded C": "Embedded C",
"Microcontrollers": "Microcontrollers",
"Device Drivers": "Device Drivers",
"Hardware Abstraction Layer": "Hardware Abstraction Layer",
"I2C": "I2C",
"SPI": "SPI",
"UART": "UART",
"Memory Management": "Memory Management",
"Power Management": "Power Management",
"Bootloaders": "Bootloaders",
"Debugging Tools": "Debugging Tools",
"Real-time Systems": "Real-time Systems",
"Design & Multimedia": "Design & Multimedia",
"Graphic Design": "Graphic Design",
"Adobe Creative Suite": "Adobe Creative Suite",
"Photoshop": "Photoshop",
"Illustrator": "Illustrator",
"InDesign": "InDesign",
"CorelDRAW": "CorelDRAW",
"Vector Illustration": "Vector Illustration",
"Photo Editing": "Photo Editing",
"Typography": "Typography",
"Branding": "Branding",
"Logo Design": "Logo Design",
"Print Design": "Print Design",
"Layout Design": "Layout Design",
"Color Theory": "Color Theory",
"Infographics": "Infographics",
"Digital Illustration": "Digital Illustration",
"Raster Graphics": "Raster Graphics",
"Design & Multimedia": "Design & Multimedia",
"UI/UX Design": "UI/UX Design",
"Sketch": "Sketch",
"Figma": "Figma",
"Adobe XD": "Adobe XD",
"Wireframing": "Wireframing",
"Prototyping": "Prototyping",
"User Testing": "User Testing",
"Interaction Design": "Interaction Design",
"User Persona Creation": "User Persona Creation",
"User Journey Mapping": "User Journey Mapping",
"Usability Testing": "Usability Testing",
"Information Architecture": "Information Architecture",
"Responsive Design": "Responsive Design",
"Design & Multimedia": "Design & Multimedia",
"Animation & Video Production": "Animation & Video Production",
"After Effects": "After Effects",
"Premiere Pro": "Premiere Pro",
"Cinema 4D": "Cinema 4D",
"Maya": "Maya",
"3D Animation": "3D Animation",
"2D Animation": "2D Animation",
"Storyboarding": "Storyboarding",
"Video Editing": "Video Editing",
"Motion Graphics": "Motion Graphics",
"Character Animation": "Character Animation",
"Special Effects": "Special Effects",
"Sound Editing": "Sound Editing",
"Color Correction": "Color Correction",
"Design & Multimedia": "Design & Multimedia",
"Web Design": "Web Design",
"HTML5": "HTML5",
"CSS3": "CSS3",
"Responsive Design": "Responsive Design",
"Bootstrap": "Bootstrap",
"WordPress": "WordPress",
"Joomla": "Joomla",
"Drupal": "Drupal",
"Theme Customization": "Theme Customization",
"Landing Page Design": "Landing Page Design",
"Parallax Design": "Parallax Design",
"Mobile-first Design": "Mobile-first Design",
"Web Accessibility": "Web Accessibility",
"Design & Multimedia": "Design & Multimedia",
"Illustration": "Illustration",
"Digital Art": "Digital Art",
"Character Design": "Character Design",
"Concept Art": "Concept Art",
"Technical Illustration": "Technical Illustration",
"Children's Book Illustration": "Children's Book Illustration",
"Comic Art": "Comic Art",
"Storyboarding": "Storyboarding",
"Pen & Ink Drawing": "Pen & Ink Drawing",
"Watercolor": "Watercolor",
"Digital Painting": "Digital Painting",
"Vector Art": "Vector Art",
"Design & Multimedia": "Design & Multimedia",
"Product Design": "Product Design",
"Sketching": "Sketching",
"Prototyping": "Prototyping",
"CAD Design": "CAD Design",
"Material Selection": "Material Selection",
"Ergonomics": "Ergonomics",
"3D Modeling": "3D Modeling",
"User-centered Design": "User-centered Design",
"Functional Prototypes": "Functional Prototypes",
"Product Lifecycle Management": "Product Lifecycle Management",
"Industrial Design": "Industrial Design",
"Design Thinking": "Design Thinking",
"Design & Multimedia": "Design & Multimedia",
"Print Design & Layout": "Print Design & Layout",
"Brochure Design": "Brochure Design",
"Flyer Design": "Flyer Design",
"Poster Design": "Poster Design",
"Business Cards": "Business Cards",
"Magazine Layout": "Magazine Layout",
"Catalog Design": "Catalog Design",
"Typography": "Typography",
"Page Layout": "Page Layout",
"Pre-press": "Pre-press",
"Packaging Design": "Packaging Design",
"Print Production": "Print Production",
"Design & Multimedia": "Design & Multimedia",
"3D Modeling & CAD": "3D Modeling & CAD",
"Autodesk AutoCAD": "Autodesk AutoCAD",
"Blender": "Blender",
"ZBrush": "ZBrush",
"SolidWorks": "SolidWorks",
"3ds Max": "3ds Max",
"Rhinoceros": "Rhinoceros",
"Revit": "Revit",
"Architecture Visualization": "Architecture Visualization",
"3D Printing": "3D Printing",
"Sculpting": "Sculpting",
"Surface Modeling": "Surface Modeling",
"Technical Drawing": "Technical Drawing",
"Design & Multimedia": "Design & Multimedia",
"Game Design & Development": "Game Design & Development",
"Unity": "Unity",
"Unreal Engine": "Unreal Engine",
"Game Mechanics": "Game Mechanics",
"Level Design": "Level Design",
"Character Design": "Character Design",
"Scripting": "Scripting",
"Mobile Game Design": "Mobile Game Design",
"VR & AR Design": "VR & AR Design",
"Game Testing": "Game Testing",
"Sound Design": "Sound Design",
"Texture and Lighting": "Texture and Lighting",
"Player Experience (PX)": "Player Experience (PX)",
"Design & Multimedia": "Design & Multimedia",
"Audio Production & Editing": "Audio Production & Editing",
"Pro Tools": "Pro Tools",
"Ableton Live": "Ableton Live",
"FL Studio": "FL Studio",
"Sound Mixing": "Sound Mixing",
"Mastering": "Mastering",
"Sound Effects": "Sound Effects",
"Audio Restoration": "Audio Restoration",
"MIDI Production": "MIDI Production",
"Voice Over": "Voice Over",
"Field Recording": "Field Recording",
"Sound Design": "Sound Design",
"Music Composition": "Music Composition",
"Design & Multimedia": "Design & Multimedia",
"Photography": "Photography",
"Digital Photography": "Digital Photography",
"Photo Editing": "Photo Editing",
"Lightroom": "Lightroom",
"Portrait Photography": "Portrait Photography",
"Landscape Photography": "Landscape Photography",
"Product Photography": "Product Photography",
"Event Photography": "Event Photography",
"Studio Lighting": "Studio Lighting",
"Photojournalism": "Photojournalism",
"Macro Photography": "Macro Photography",
"Retouching": "Retouching",
"Color Grading": "Color Grading",
"Design & Multimedia": "Design & Multimedia",
"Multimedia Artistry": "Multimedia Artistry",
"Interactive Design": "Interactive Design",
"Virtual Reality": "Virtual Reality",
"Augmented Reality": "Augmented Reality",
"MixedReality": "MixedReality",
"Video Production": "Video Production",
"Sound Editing": "Sound Editing",
"Multimedia Presentation": "Multimedia Presentation",
"Interactive PDFs": "Interactive PDFs",
"Digital Installations": "Digital Installations",
"eLearning Modules": "eLearning Modules",
"Design & Multimedia": "Design & Multimedia",
"Motion Graphics": "Motion Graphics",
"After Effects": "After Effects",
"Cinema 4D": "Cinema 4D",
"Storyboarding": "Storyboarding",
"Character Animation": "Character Animation",
"Typography Animation": "Typography Animation",
"Visual Effects": "Visual Effects",
"Particle Effects": "Particle Effects",
"2D & 3D Motion": "2D & 3D Motion",
"Logo Animation": "Logo Animation",
"Infographics Animation": "Infographics Animation",
"Design & Multimedia": "Design & Multimedia",
"Brand & Identity Design": "Brand & Identity Design",
"Logo Design": "Logo Design",
"Brand Guidelines": "Brand Guidelines",
"Corporate Identity": "Corporate Identity",
"Brand Strategy": "Brand Strategy",
"Visual Identity Systems": "Visual Identity Systems",
"Business Cards": "Business Cards",
"Letterhead Design": "Letterhead Design",
"Brand Voice": "Brand Voice",
"Merchandise Design": "Merchandise Design",
"Brand Audit": "Brand Audit",
"Design & Multimedia": "Design & Multimedia",
"Digital Art & Drawing": "Digital Art & Drawing",
"Digital Painting": "Digital Painting",
"Character Art": "Character Art",
"Concept Art": "Concept Art",
"Manga & Comics": "Manga & Comics",
"Digital Sketching": "Digital Sketching",
"Fantasy Art": "Fantasy Art",
"Sci-fi Art": "Sci-fi Art",
"Storyboarding": "Storyboarding",
"Tablet & Stylus Techniques": "Tablet & Stylus Techniques",
"Digital Sculpting": "Digital Sculpting",
"Design & Multimedia": "Design & Multimedia",
"Typography & Lettering": "Typography & Lettering",
"Font Design": "Font Design",
"Calligraphy": "Calligraphy",
"Hand Lettering": "Hand Lettering",
"Typeface Selection": "Typeface Selection",
"Print Typography": "Print Typography",
"Digital Typography": "Digital Typography",
"Font Pairing": "Font Pairing",
"Custom Lettering": "Custom Lettering",
"Typography Layout": "Typography Layout",
"Type Anatomy": "Type Anatomy",
"Kerning": "Kerning",
"Leading": "Leading",
"Design & Multimedia": "Design & Multimedia",
"Environmental & Spatial Design": "Environmental & Spatial Design",
"Interior Design": "Interior Design",
"Architectural Design": "Architectural Design",
"Landscape Design": "Landscape Design",
"Retail Space Design": "Retail Space Design",
"Exhibition Design": "Exhibition Design",
"Wayfinding": "Wayfinding",
"Signage": "Signage",
"Spatial Branding": "Spatial Branding",
"Public Space Planning": "Public Space Planning",
"Furniture Design": "Furniture Design",
"Design & Multimedia": "Design & Multimedia",
"Fashion & Apparel Design": "Fashion & Apparel Design",
"Garment Design": "Garment Design",
"Fashion Illustration": "Fashion Illustration",
"Pattern Making": "Pattern Making",
"Textile Design": "Textile Design",
"Fashion Branding": "Fashion Branding",
"Accessory Design": "Accessory Design",
"Footwear Design": "Footwear Design",
"Trend Forecasting": "Trend Forecasting",
"Fashion Styling": "Fashion Styling",
"Fashion Photography": "Fashion Photography",
"Design & Multimedia": "Design & Multimedia",
"Package Design": "Package Design",
"Product Packaging": "Product Packaging",
"Label Design": "Label Design",
"Bottle & Container Design": "Bottle & Container Design",
"Retail Packaging": "Retail Packaging",
"Eco-Friendly Packaging": "Eco-Friendly Packaging",
"Unboxing Experience": "Unboxing Experience",
"Branding": "Branding",
"Typography": "Typography",
"Barcodes & Labeling": "Barcodes & Labeling",
"Prototype Development": "Prototype Development",
"Sales & Marketing": "Sales & Marketing",
"Digital & Online Marketing": "Digital & Online Marketing",
"SEO": "SEO",
"SEM": "SEM",
"PPC": "PPC",
"Google Ads": "Google Ads",
"Facebook Ads": "Facebook Ads",
"Google Analytics": "Google Analytics",
"Affiliate Marketing": "Affiliate Marketing",
"Email Campaigns": "Email Campaigns",
"Landing Page Optimization": "Landing Page Optimization",
"Conversion Rate Optimization": "Conversion Rate Optimization",
"Sales & Marketing": "Sales & Marketing",
"Public Relations & Branding": "Public Relations & Branding",
"Media Relations": "Media Relations",
"Crisis Management": "Crisis Management",
"Event Coordination": "Event Coordination",
"Brand Strategy": "Brand Strategy",
"Corporate Communication": "Corporate Communication",
"Stakeholder Engagement": "Stakeholder Engagement",
"Content Creation": "Content Creation",
"Sales & Marketing": "Sales & Marketing",
"Sales": "Sales",
"Lead Generation": "Lead Generation",
"Cold Calling": "Cold Calling",
"Sales Presentations": "Sales Presentations",
"Account Management": "Account Management",
"Negotiation": "Negotiation",
"CRM Tools": "CRM Tools",
"Sales Strategy": "Sales Strategy",
"Cross-selling": "Cross-selling",
"Up-selling": "Up-selling",
"Sales & Marketing": "Sales & Marketing",
"Content & Social Media Marketing": "Content & Social Media Marketing",
"Content Strategy": "Content Strategy",
"Blogging": "Blogging",
"Video Creation": "Video Creation",
"Instagram Marketing": "Instagram Marketing",
"Twitter Marketing": "Twitter Marketing",
"LinkedIn Marketing": "LinkedIn Marketing",
"Influencer Collaboration": "Influencer Collaboration",
"Content Calendar": "Content Calendar",
"Engagement Analysis": "Engagement Analysis",
"Sales & Marketing": "Sales & Marketing",
"Market Research & Strategy": "Market Research & Strategy",
"Consumer Surveys": "Consumer Surveys",
"Focus Groups": "Focus Groups",
"Market Segmentation": "Market Segmentation",
"Competitive Analysis": "Competitive Analysis",
"SWOT Analysis": "SWOT Analysis",
"Trend Forecasting": "Trend Forecasting",
"Data Analytics": "Data Analytics",
"Product Positioning": "Product Positioning",
"Sales & Marketing": "Sales & Marketing",
"Product & Event Marketing": "Product & Event Marketing",
"Product Launches": "Product Launches",
"Promotional Campaigns": "Promotional Campaigns",
"Event Planning": "Event Planning",
"Sponsorship Management": "Sponsorship Management",
"Trade Shows": "Trade Shows",
"Product Demos": "Product Demos",
"Cross-Promotions": "Cross-Promotions",
"Venue Selection": "Venue Selection",
"Event Logistics": "Event Logistics",
"Sales & Marketing": "Sales & Marketing",
"Channel & Partnership Management": "Channel & Partnership Management",
"Channel Strategy": "Channel Strategy",
"Partner Recruitment": "Partner Recruitment",
"Co-Marketing Initiatives": "Co-Marketing Initiatives",
"Channel Training": "Channel Training",
"Partnership Agreements": "Partnership Agreements",
"Revenue Sharing": "Revenue Sharing",
"Affiliate Programs": "Affiliate Programs",
"Reseller Programs": "Reseller Programs",
"Administration & Support": "Administration & Support",
"Office & Executive Administration": "Office & Executive Administration",
"Scheduling": "Scheduling",
"Meeting Coordination": "Meeting Coordination",
"Office Management": "Office Management",
"Stakeholder Communication": "Stakeholder Communication",
"Calendar Management": "Calendar Management",
"Reporting": "Reporting",
"Office Software Proficiency": "Office Software Proficiency",
"Travel Coordination": "Travel Coordination",
"Budgeting": "Budgeting",
"Event Organization": "Event Organization",
"Minute Taking": "Minute Taking",
"Task Prioritization": "Task Prioritization",
"Administration & Support": "Administration & Support",
"Customer & Technical Support": "Customer & Technical Support",
"Helpdesk Operation": "Helpdesk Operation",
"Customer Service": "Customer Service",
"Technical Troubleshooting": "Technical Troubleshooting",
"CRM Tools": "CRM Tools",
"Communication Skills": "Communication Skills",
"Problem-Solving": "Problem-Solving",
"SLA Compliance": "SLA Compliance",
"Customer Feedback Analysis": "Customer Feedback Analysis",
"Product Knowledge": "Product Knowledge",
"Remote Support Tools": "Remote Support Tools",
"Service Ticket Management": "Service Ticket Management",
"Conflict Resolution": "Conflict Resolution",
"Administration & Support": "Administration & Support",
"Human Resources & Recruitment": "Human Resources & Recruitment",
"Talent Acquisition": "Talent Acquisition",
"Onboarding": "Onboarding",
"Employee Relations": "Employee Relations",
"Benefits Administration": "Benefits Administration",
"HRIS Systems": "HRIS Systems",
"Training & Development": "Training & Development",
"Performance Management": "Performance Management",
"Compensation Analysis": "Compensation Analysis",
"Legal Compliance": "Legal Compliance",
"Employee Engagement": "Employee Engagement",
"Exit Interviews": "Exit Interviews",
"Payroll Management": "Payroll Management",
"Administration & Support": "Administration & Support",
"Facilities & Event Management": "Facilities & Event Management",
"Venue Sourcing": "Venue Sourcing",
"Vendor Management": "Vendor Management",
"Event Planning": "Event Planning",
"Budgeting & Costing": "Budgeting & Costing",
"Logistics": "Logistics",
"Facility Maintenance": "Facility Maintenance",
"Space Planning": "Space Planning",
"Security Coordination": "Security Coordination",
"Health & Safety": "Health & Safety",
"Inventory Control": "Inventory Control",
"Resource Allocation": "Resource Allocation",
"Event Promotion": "Event Promotion",
"Administration & Support": "Administration & Support",
"Data & Document Management": "Data & Document Management",
"Document Archiving": "Document Archiving",
"Data Entry": "Data Entry",
"Database Management": "Database Management",
"Confidentiality": "Confidentiality",
"Version Control": "Version Control",
"Data Analysis": "Data Analysis",
"Record Keeping": "Record Keeping",
"Filing Systems": "Filing Systems",
"Electronic Storage Solutions": "Electronic Storage Solutions",
"Document Review & Editing": "Document Review & Editing",
"Collaborative Tools": "Collaborative Tools",
"Metadata Management": "Metadata Management",
"Finance & Management": "Finance & Management",
"Financial Analysis & Corporate Finance": "Financial Analysis & Corporate Finance",
"Financial Modeling": "Financial Modeling",
"Forecasting": "Forecasting",
"Budgeting": "Budgeting",
"Variance Analysis": "Variance Analysis",
"Financial Reporting": "Financial Reporting",
"Strategic Planning": "Strategic Planning",
"Capital Budgeting": "Capital Budgeting",
"DCF": "DCF",
"NPV Analysis": "NPV Analysis",
"IRR": "IRR",
"Cost Analysis": "Cost Analysis",
"Profitability Analysis": "Profitability Analysis",
"Financial Ratios": "Financial Ratios",
"Balance Sheet Analysis": "Balance Sheet Analysis",
"Income Statement Analysis": "Income Statement Analysis",
"Cash Flow Analysis": "Cash Flow Analysis",
"Scenario Analysis": "Scenario Analysis",
"Sensitivity Analysis": "Sensitivity Analysis",
"Financial Statements Consolidation": "Financial Statements Consolidation",
"Merger Modeling": "Merger Modeling",
"Finance & Management": "Finance & Management",
"Project Management": "Project Management",
"Scrum": "Scrum",
"Agile": "Agile",
"Waterfall": "Waterfall",
"Kanban": "Kanban",
"Lean": "Lean",
"MS Project": "MS Project",
"PMP": "PMP",
"Prince2": "Prince2",
"Risk Assessment": "Risk Assessment",
"Stakeholder Management": "Stakeholder Management",
"Gantt Chart": "Gantt Chart",
"Project Scheduling": "Project Scheduling",
"Budget Management": "Budget Management",
"Resource Allocation": "Resource Allocation",
"Scope Management": "Scope Management",
"Quality Management": "Quality Management",
"Documentation": "Documentation",
"Reporting": "Reporting",
"Finance & Management": "Finance & Management",
"Consulting": "Consulting",
"Business Strategy": "Business Strategy",
"Management Consulting": "Management Consulting",
"IT Consulting": "IT Consulting",
"Market Analysis": "Market Analysis",
"SWOT Analysis": "SWOT Analysis",
"Competitive Analysis": "Competitive Analysis",
"Change Management": "Change Management",
"Operational Efficiency": "Operational Efficiency",
"Stakeholder Interviews": "Stakeholder Interviews",
"Client Relations": "Client Relations",
"Business Process Reengineering": "Business Process Reengineering",
"Benchmarking": "Benchmarking",
"Finance & Management": "Finance & Management",
"Accounting & Bookkeeping": "Accounting & Bookkeeping",
"General Ledger": "General Ledger",
"Accounts Payable": "Accounts Payable",
"Accounts Receivable": "Accounts Receivable",
"Payroll Processing": "Payroll Processing",
"Journal Entries": "Journal Entries",
"Bank Reconciliation": "Bank Reconciliation",
"Financial Statement Preparation": "Financial Statement Preparation",
"GAAP": "GAAP",
"Tax Preparation": "Tax Preparation",
"Invoicing": "Invoicing",
"Expense Tracking": "Expense Tracking",
"QuickBooks": "QuickBooks",
"Xero": "Xero",
"Financial Record Keeping": "Financial Record Keeping",
"Monthly Closings": "Monthly Closings",
"Finance & Management": "Finance & Management",
"Investment & Portfolio Management": "Investment & Portfolio Management",
"Asset Allocation": "Asset Allocation",
"Risk Management": "Risk Management",
"Bonds": "Bonds",
"Equities": "Equities",
"Hedge Funds": "Hedge Funds",
"Mutual Funds": "Mutual Funds",
"Alternative Investments": "Alternative Investments",
"Technical Analysis": "Technical Analysis",
"Fundamental Analysis": "Fundamental Analysis",
"Return on Investment (ROI)": "Return on Investment (ROI)",
"CAPM": "CAPM",
"Derivatives": "Derivatives",
"Securities Analysis": "Securities Analysis",
"Market Research": "Market Research",
"Wealth Management": "Wealth Management",
"Finance & Management": "Finance & Management",
"Auditing & Compliance & Regulatory Affairs": "Auditing & Compliance & Regulatory Affairs",
"Internal Audit": "Internal Audit",
"External Audit": "External Audit",
"SOX Compliance": "SOX Compliance",
"Risk Assessment": "Risk Assessment",
"Audit Reports": "Audit Reports",
"Financial Review": "Financial Review",
"Operational Audit": "Operational Audit",
"Compliance Testing": "Compliance Testing",
"Regulatory Reporting": "Regulatory Reporting",
"Quality Assurance": "Quality Assurance",
"Control Framework": "Control Framework",
"Financial Regulations": "Financial Regulations",
"Anti-Money Laundering": "Anti-Money Laundering",
"Know Your Customer (KYC)": "Know Your Customer (KYC)",
"Finance & Management": "Finance & Management",
"Taxation & Tax Consulting": "Taxation & Tax Consulting",
"Income Tax": "Income Tax",
"VAT": "VAT",
"Sales Tax": "Sales Tax",
"Corporate Tax": "Corporate Tax",
"International Tax": "International Tax",
"Tax Planning": "Tax Planning",
"Tax Compliance": "Tax Compliance",
"Tax Deductions": "Tax Deductions",
"Tax Advisory": "Tax Advisory",
"Tax Law": "Tax Law",
"Tax Audit": "Tax Audit",
"Tax Accounting": "Tax Accounting",
"Transfer Pricing": "Transfer Pricing",
"Tax Software": "Tax Software",
"Excise Tax": "Excise Tax",
"Finance & Management": "Finance & Management",
"Risk Management & Treasury Management": "Risk Management & Treasury Management",
"Liquidity Management": "Liquidity Management",
"Financial Risk": "Financial Risk",
"Operational Risk": "Operational Risk",
"Credit Risk": "Credit Risk",
"Market Risk": "Market Risk",
"Enterprise Risk Management (ERM)": "Enterprise Risk Management (ERM)",
"Risk Modelling": "Risk Modelling",
"Derivatives Hedging": "Derivatives Hedging",
"Currency Hedging": "Currency Hedging",
"Interest Rate Forecasting": "Interest Rate Forecasting",
"Cash Management": "Cash Management",
"Investment Management": "Investment Management",
"Debt Management": "Debt Management",
"Capital Structure": "Capital Structure",
"Finance & Management": "Finance & Management",
"Mergers & Acquisitions": "Mergers & Acquisitions",
"Due Diligence": "Due Diligence",
"Valuation": "Valuation",
"Synergy Analysis": "Synergy Analysis",
"Deal Structuring": "Deal Structuring",
"Negotiation": "Negotiation",
"Integration Planning": "Integration Planning",
"Post-merger Integration": "Post-merger Integration",
"Divestitures": "Divestitures",
"Spin-offs": "Spin-offs",
"LBO Modelling": "LBO Modelling",
"Acquisition Financing": "Acquisition Financing",
"Finance & Management": "Finance & Management",
"Banking & Lending": "Banking & Lending",
"Retail Banking": "Retail Banking",
"Commercial Banking": "Commercial Banking",
"Investment Banking": "Investment Banking",
"Credit Analysis": "Credit Analysis",
"Loan Underwriting": "Loan Underwriting",
"Portfolio Management": "Portfolio Management",
"Mortgages": "Mortgages",
"Credit Cards": "Credit Cards",
"Personal Loans": "Personal Loans",
"Asset Management": "Asset Management",
"Relationship Management": "Relationship Management",
"Finance & Management": "Finance & Management",
"Financial Planning & Advising": "Financial Planning & Advising",
"Wealth Management": "Wealth Management",
"Retirement Planning": "Retirement Planning",
"Estate Planning": "Estate Planning",
"Tax Planning": "Tax Planning",
"Risk Assessment": "Risk Assessment",
"Asset Allocation": "Asset Allocation",
"Life Insurance": "Life Insurance",
"Trusts": "Trusts",
"Investment Strategies": "Investment Strategies",
"Client Relations": "Client Relations",
"Financial Goals Setting": "Financial Goals Setting",
"Finance & Management": "Finance & Management",
"Insurance": "Insurance",
"Underwriting": "Underwriting",
"Claims Management": "Claims Management",
"Policy Administration": "Policy Administration",
"Risk Analysis": "Risk Analysis",
"Product Development": "Product Development",
"Insurance Sales": "Insurance Sales",
"Reinsurance": "Reinsurance",
"Actuarial Analysis": "Actuarial Analysis",
"Insurance Regulations": "Insurance Regulations",
"Property and Casualty": "Property and Casualty",
"Life Insurance": "Life Insurance",
"Finance & Management": "Finance & Management",
"Venture Capital & Private Equity": "Venture Capital & Private Equity",
"Deal Sourcing": "Deal Sourcing",
"Portfolio Management": "Portfolio Management",
"Equity Research": "Equity Research",
"Valuation": "Valuation",
"Due Diligence": "Due Diligence",
"Term Sheet Negotiation": "Term Sheet Negotiation",
"Capital Raising": "Capital Raising",
"Exit Strategies": "Exit Strategies",
"Investment Strategies": "Investment Strategies",
"Fund Management": "Fund Management",
"Finance & Management": "Finance & Management",
"Business Strategy & Planning & Operations Management": "Business Strategy & Planning & Operations Management",
"Strategic Planning": "Strategic Planning",
"Operational Efficiency": "Operational Efficiency",
"Process Improvement": "Process Improvement",
"Business Modelling": "Business Modelling",
"Market Analysis": "Market Analysis",
"Product Lifecycle Management": "Product Lifecycle Management",
"Operational Strategy": "Operational Strategy",
"Lean Management": "Lean Management",
"Six Sigma": "Six Sigma",
"Supply Chain Strategy": "Supply Chain Strategy",
"Performance Metrics": "Performance Metrics",
"Finance & Management": "Finance & Management",
"Supply Chain & Logistics Management": "Supply Chain & Logistics Management",
"Inventory Management": "Inventory Management",
"Procurement": "Procurement",
"Demand Forecasting": "Demand Forecasting",
"Logistics Planning": "Logistics Planning",
"Warehousing": "Warehousing",
"Distribution": "Distribution",
"Supplier Relations": "Supplier Relations",
"Supply Chain Optimization": "Supply Chain Optimization",
"Transportation Management": "Transportation Management",
"Order Fulfillment": "Order Fulfillment",
"Just-In-Time (JIT)": "Just-In-Time (JIT)",
"Vendor Management": "Vendor Management",
"Engineering & Manufacturing": "Engineering & Manufacturing",
"Mechanical Engineering": "Mechanical Engineering",
"CAD": "CAD",
"SolidWorks": "SolidWorks",
"AutoCAD": "AutoCAD",
"Finite Element Analysis": "Finite Element Analysis",
"Dynamics": "Dynamics",
"Thermodynamics": "Thermodynamics",
"Fluid Mechanics": "Fluid Mechanics",
"Kinematics": "Kinematics",
"Machine Design": "Machine Design",
"MATLAB": "MATLAB",
"ANSYS": "ANSYS",
"Pro/ENGINEER": "Pro/ENGINEER",
"HVAC": "HVAC",
"Mechanical Testing": "Mechanical Testing",
"Prototyping": "Prototyping",
"Engineering & Manufacturing": "Engineering & Manufacturing",
"Electrical Engineering": "Electrical Engineering",
"Circuit Design": "Circuit Design",
"PCB Design": "PCB Design",
"Power Systems": "Power Systems",
"Embedded Systems": "Embedded Systems",
"Signal Processing": "Signal Processing",
"Control Systems": "Control Systems",
"VHDL": "VHDL",
"Verilog": "Verilog",
"Microcontrollers": "Microcontrollers",
"PLC": "PLC",
"Electromagnetic Theory": "Electromagnetic Theory",
"MATLAB": "MATLAB",
"LabVIEW": "LabVIEW",
"Oscilloscope": "Oscilloscope",
"RF Engineering": "RF Engineering",
"Engineering & Manufacturing": "Engineering & Manufacturing",
"Civil Engineering": "Civil Engineering",
"Structural Analysis": "Structural Analysis",
"Hydrology": "Hydrology",
"Geotechnical": "Geotechnical",
"AutoCAD Civil 3D": "AutoCAD Civil 3D",
"Surveying": "Surveying",
"Transportation Engineering": "Transportation Engineering",
"Sustainability": "Sustainability",
"Infrastructure Design": "Infrastructure Design",
"Construction Management": "Construction Management",
"Land Development": "Land Development",
"Water Resources": "Water Resources",
"Environmental Impact": "Environmental Impact",
"Reinforced Concrete": "Reinforced Concrete",
"Steel Design": "Steel Design",
"Engineering & Manufacturing": "Engineering & Manufacturing",
"Chemical Engineering": "Chemical Engineering",
"Process Engineering": "Process Engineering",
"Material Balance": "Material Balance",
"Kinetics": "Kinetics",
"ChemCAD": "ChemCAD",
"Distillation": "Distillation",
"Heat Transfer": "Heat Transfer",
"Reactor Design": "Reactor Design",
"Separation Processes": "Separation Processes",
"Fluid Dynamics": "Fluid Dynamics",
"Petrochemicals": "Petrochemicals",
"Pharmaceuticals": "Pharmaceuticals",
"Safety & Hazard Analysis": "Safety & Hazard Analysis",
"Polymers": "Polymers",
"Biochemical": "Biochemical",
"Engineering & Manufacturing": "Engineering & Manufacturing",
"Biomedical Engineering": "Biomedical Engineering",
"Medical Devices": "Medical Devices",
"Bioinformatics": "Bioinformatics",
"Biomechanics": "Biomechanics",
"Biomedical Imaging": "Biomedical Imaging",
"Tissue Engineering": "Tissue Engineering",
"Biomedical Signal Processing": "Biomedical Signal Processing",
"Biomaterials": "Biomaterials",
"Rehabilitation Engineering": "Rehabilitation Engineering",
"Genomics": "Genomics",
"Prosthetics": "Prosthetics",
"Orthopedic Design": "Orthopedic Design",
"Biomedical Optics": "Biomedical Optics",
"Medical Robotics": "Medical Robotics",
"Clinical Engineering": "Clinical Engineering",
"Engineering & Manufacturing": "Engineering & Manufacturing",
"Aerospace Engineering": "Aerospace Engineering",
"Flight Mechanics": "Flight Mechanics",
"Aerodynamics": "Aerodynamics",
"Propulsion": "Propulsion",
"Aircraft Structures": "Aircraft Structures",
"Avionics": "Avionics",
"Navigation Systems": "Navigation Systems",
"Control Systems": "Control Systems",
"Spacecraft Design": "Spacecraft Design",
"Wind Tunnel Testing": "Wind Tunnel Testing",
"Aircraft Maintenance": "Aircraft Maintenance",
"Composite Materials": "Composite Materials",
"Orbital Mechanics": "Orbital Mechanics",
"CFD": "CFD",
"Rocket Science": "Rocket Science",
"Flight Simulation": "Flight Simulation",
"Engineering & Manufacturing": "Engineering & Manufacturing",
"Industrial Engineering": "Industrial Engineering",
"Lean Manufacturing": "Lean Manufacturing",
"Six Sigma": "Six Sigma",
"Operations Research": "Operations Research",
"Ergonomics": "Ergonomics",
"Supply Chain Management": "Supply Chain Management",
"Logistics": "Logistics",
"Work Study": "Work Study",
"Quality Control": "Quality Control",
"Systems Optimization": "Systems Optimization",
"Project Management": "Project Management",
"Facility Layout": "Facility Layout",
"Material Handling": "Material Handling",
"Statistical Analysis": "Statistical Analysis",
"Simulation Modeling": "Simulation Modeling",
"Production Planning": "Production Planning",
"Engineering & Manufacturing": "Engineering & Manufacturing",
"Manufacturing Engineering": "Manufacturing Engineering",
"CNC Machining": "CNC Machining",
"Injection Molding": "Injection Molding",
"Quality Assurance": "Quality Assurance",
"Process Improvement": "Process Improvement",
"Materials Selection": "Materials Selection",
"Robotics": "Robotics",
"Welding": "Welding",
"Assembly Line Design": "Assembly Line Design",
"Product Lifecycle Management": "Product Lifecycle Management",
"Tool Design": "Tool Design",
"Lean Principles": "Lean Principles",
"Additive Manufacturing": "Additive Manufacturing",
"Rapid Prototyping": "Rapid Prototyping",
"ISO Standards": "ISO Standards",
"SPC (Statistical Process Control)": "SPC (Statistical Process Control)",
"Engineering & Manufacturing": "Engineering & Manufacturing",
"Environmental Engineering": "Environmental Engineering",
"Water Treatment": "Water Treatment",
"Air Quality Management": "Air Quality Management",
"Waste Management": "Waste Management",
"Sustainability": "Sustainability",
"Environmental Impact Assessment": "Environmental Impact Assessment",
"Hazardous Waste Management": "Hazardous Waste Management",
"Site Remediation": "Site Remediation",
"Stormwater Management": "Stormwater Management",
"Pollution Control": "Pollution Control",
"Green Building Design": "Green Building Design",
"Ecology": "Ecology",
"Hydrogeology": "Hydrogeology",
"Carbon Footprint Analysis": "Carbon Footprint Analysis",
"Recycling Systems": "Recycling Systems",
"Environmental Regulations": "Environmental Regulations",
"Engineering & Manufacturing": "Engineering & Manufacturing",
"Structural Engineering": "Structural Engineering",
"Building Design": "Building Design",
"Foundation Design": "Foundation Design",
"Seismic Design": "Seismic Design",
"Load Analysis": "Load Analysis",
"Finite Element Analysis": "Finite Element Analysis",
"Bridge Design": "Bridge Design",
"Retaining Walls": "Retaining Walls",
"Structural Inspections": "Structural Inspections",
"Reinforced Concrete": "Reinforced Concrete",
"Steel Structures": "Steel Structures",
"Timber Structures": "Timber Structures",
"Masonry Design": "Masonry Design",
"Earthquake Engineering": "Earthquake Engineering",
"Wind Analysis": "Wind Analysis",
"Composite Structures": "Composite Structures",
"Remove All Data": "Remove All Data",
"Download My Data": "Download My Data",
"Full Name": "Full Name",
"Birth Date": "Birth Date",
"Phone Number": "Phone Number",
"Address": "Address",
"By creating an account and applying to roles, I consent to SimpL sharing my CV and personal information with the employer for the role I am applying to, as well as with other potential employers for any future job applications made through my account. I understand that SimpL and its automated decision-making systems will process my application to enhance the recruitment process. I acknowledge that I can erase my data at any time by contacting SimpL or through my account settings.": "By creating an account and applying to roles, I consent to SimpL sharing my CV and personal information with the employer for the role I am applying to, as well as with other potential employers for any future job applications made through my account. I understand that SimpL and its automated decision-making systems will process my application to enhance the recruitment process. I acknowledge that I can erase my data at any time by contacting SimpL or through my account settings.",
"Company Name": "Company Name",
       
        

        
        









        

        




        














        


        





        

        

        







      





          // Add more key-value pairs for English translations
        }
      },
      it: {
        translation: {
          "Page": "Pagina",
          "Filter": "Filtri",
          "Search": "Cerca",
          "Search Applicants": "Cerca Candidati",
          "Active": "Attiva",
          "Paused": "In pausa",
          "Closed": "Chiusa",
          "Create Job Offer": "Nuovo Annuncio",
          "Dashboard": "Dashboard",
          "Job Offers": "Annunci",
          "Settings": "Impostazioni",
          "Error creating job offer": "Errore durante la creazione dell'annuncio di lavoro",
          "Job Title": "Titolo",
          "Job Description": "Descrizione",
          "Office Address": "Indirizzo ufficio",
          "Mode": "Modalità",
          "Work Mode": "Modalità di lavoro",
          "In Office": "In ufficio",
          "Hybrid": "Ibrido",
          "Remote": "Da remoto",
          "Type": "Tipo",
          "Job Type": "Tipo di lavoro",
          "Full-time": "Tempo pieno",
          "Part-time": "Part-time",
          "Contract": "Contratto",
          "Internship": "Tirocinio",
          "Responsibilities": "Responsabilità",
          "Do Not Specify Salary Range": "Non specificare la fascia di stipendio",
          "Requirements": "Requisiti",
          "Salary Range": "Fascia di stipendio",
          "Responsibility": "Responsabilità",
          "Add": "Aggiungi",
          "Delete": "Elimina",
          "Current range": "Fascia attuale",
          "Not Specified": "Non specificato",
          "Add Requirement": "Aggiungi Requisito",
          "Experience": "Esperienza",
          "Academic": "Titolo di studio",
          "Distance": "Distanza",
          "Language": "Lingua",
          "Specify job title and minimum years of experience": "Specificare il titolo di lavoro e gli anni minimi di esperienza",
          "Specify required academic qualification": "Specificare la qualifica accademica richiesta",
          "Specify maximum distance from work place": "Specificare la distanza massima dal luogo di lavoro",
          "Specify a language required for the job": "Specificare una lingua richiesta per il lavoro",
          "Years of Experience": "Anni di esperienza",
          "Select a Category": "Seleziona una categoria",
          "Category": "Categoria",
          "Tags": "Tag",
          "Search tags": "Cerca tag",
          "No years required": "Numero anni non richiesto",
          "Degree": "Titolo di studio",
          "Select a degree": "Seleziona un titolo di studio",
          "Fields of Study": "Campi di studio",
          "Search fields of study": "Cerca campi di studio",
          "High School Diploma": "Diploma di scuola superiore",
          "Algebra": "Algebra",
          "Calculus": "Calcolo",
          "Trigonomety": "Trigonometria",
          "Statistics": "Statistica",
          "Physics": "Fisica",
          "Chemistry": "Chimica",
          "Biology": "Biologia",
          "English Literature": "Letteratura inglese",
          "Creative Writing": "Scrittura creativa",
          "History": "Storia",
          "Geography": "Geografia",
          "Economics": "Economia",
          "Computer Programming": "Programmazione",
          "Web Development": "Sviluppo web",
          "Graphic Design": "Grafica",
          "Music": "Musica",
          "Visual Arts": "Arti visive",
          "Theater": "Teatro",
          "Bachelor's Degree": "Laurea triennale",
          "Accounting": "Contabilità",
          "Anthropology": "Antropologia",
          "Architecture": "Architettura",
          "Biomedical Engineering": "Ingegneria biomedica",
          "Chemical Engineering": "Ingegneria chimica",
          "Civil Engineering": "Ingegneria civile",
          "Computer Science": "Informatica",
          "Criminology": "Criminologia",
          "Electrical Engineering": "Ingegneria elettrica",
          "Environmental Science": "Scienze ambientali",
          "Film Studies": "Cinema",
          "Finance": "Finanza",
          "Game Design": "Game design",
          "Healthcare Administration": "Amministrazione sanitaria",
          "Industrial Engineering": "Ingegneria industriale",
          "Journalism": "Giornalismo",
          "Kinesiology": "Chinesiologia",
          "Linguistics": "Linguistica",
          "Marketing": "Marketing",
          "Master's Degree": "Laurea magistrale",
          "Aerospace Engineering": "Ingegneria aerospaziale",
          "Astronomy": "Astronomia",
          "Bioinformatics": "Bioinformatica",
          "Biostatistics": "Bio statistica",
          "Clinical Psychology": "Psicologia clinica",
          "Cognitive Science": "Scienze cognitive",
          "Data Science": "Data Science",
          "Educational Leadership": "Leadership educativa",
          "Energy Engineering": "Ingegneria energetica",
          "Entrepreneurship": "Imprenditoria",
          "Food Science": "Scienze alimentari",
          "Genetics": "Genetica",
          "Geophysics": "Geofisica",
          "Human Resources": "Risorse umane",
          "Information Security": "Sicurezza informatica",
          "Materials Science": "Scienza dei materiali",
          "Mathematical Modeling": "Modellazione matematica",
          "Molecular Biology": "Biologia molecolare",
          "Nanotechnology": "Nanotecnologia",
          "Neuroscience": "Neuroscienze",
          "Doctorate Degree": "Dottorato",
          "Artificial Intelligence": "Intelligenza artificiale",
          "Astrophysics": "Astrofisica",
          "Behavioral Economics": "Economia comportamentale",
          "Biochemistry": "Biochimica",
          "Biophysics": "Biofisica",
          "Cell Biology": "Biologia cellulare",
          "Climate Science": "Scienze climatiche",
          "Complex Systems": "Sistemi complessi",
          "Computational Biology": "Biologia computazionale",
          "Cryptography": "Crittografia",
          "Demography": "Demografia",
          "Developmental Psychology": "Psicologia dello sviluppo",
          "Ecology": "Ecologia",
          "Epidemiology": "Epidemiologia",
          "Ethics": "Etica",
          "Evolutionary Biology": "Biologia evolutiva",
          "Fluid Mechanics": "Meccanica dei fluidi",
          "Human-Computer Interaction": "Interazione uomo-computer",
          "Immunology": "Immunologia",
          "Philosophy of Science": "Filosofia della scienza",
          "Post-Doctorate": "Post-dottorato",
          "Additive Manufacturing": "Produzione additiva",
          "Affective Computing": "Elaborazione affettiva",
          "Applied Mathematics": "Matematica applicata",
          "Biomaterials": "Biomateriali",
          "Biomedical Imaging": "Imaging biomedico",
          "Biophotonics": "Biofotonica",
          "Cancer Research": "Ricerca sul cancro",
          "Cardiovascular Research": "Ricerca cardiovascolare",
          "Cognitive Neuroscience": "Neuroscienze cognitive",
          "Computational Chemistry": "Chimica computazionale",
          "Computational Fluid Dynamics": "Dinamica dei fluidi computazionale",
          "Control Theory": "Control Theory",
          "Drug Discovery": "Scoperta Farmacologica",
          "Energy Storage": "Stoccaggio di energia",
          "Environmental Policy": "Politica ambientale",
          "Graph Theory": "Graph Theory",
          "Machine Learning": "Machine Learning",
          "Microbiology": "Microbiologia",
          "Optics": "Ottica",
          "Robotics": "Robotica",
          "years": "anni",
          "Max Distance": "Distanza Massima",
          "Proficiency": "Livello",
          "Basic": "Base",
          "Conversational": "Intermedio",
          "Proficient": "Avanzato",
          "Fluent": "Padronanza",
          'English': 'Inglese', 'Spanish': 'Spagnolo', 'Mandarin': 'Mandarino', 'Hindi': 'Hindi', 'Arabic': 'Arabo', 'Portuguese': 'Portoghese', 'Bengali': 'Bengalese', 'Russian': 'Russo',
          'Japanese': 'Giapponese', 'Punjabi': 'Punjabi', 'German': 'Tedesco', 'Javanese': 'Giavanese', 'Wu': 'Wu', 'Malay': 'Malese', 'Telugu': 'Telugu', 'Vietnamese': 'Vietnamita',
          'Korean': 'Coreano', 'French': 'Francese', 'Marathi': 'Marathi', 'Tamil': 'Tamil', 'Urdu': 'Urdu', 'Turkish': 'Turco', 'Italian': 'Italiano', 'Thai': 'Tailandese', 'Gujarati': 'Gujarati',
          'Jin': 'Jin', 'Southern Min': 'Min del sud', 'Persian': 'Persiano', 'Polish': 'Polacco', 'Pashto': 'Pashto', 'Kannada': 'Kannada', 'Xiang': 'Xiang', 'Malayalam': 'Malayalam',
          'Sundanese': 'Sundanese', 'Hausa': 'Hausa', 'Odia': 'Odia', 'Burmese': 'Burmese', 'Hakka': 'Hakka', 'Ukrainian': 'Ucraino', 'Bhojpuri': 'Bhojpuri', 'Tagalog': 'Tagalog',
          'Yoruba': 'Yoruba', 'Maithili': 'Maithili', 'Uzbek': 'Uzbek', 'Sindhi': 'Sindhi', 'Amharic': 'Amarico', 'Fula': 'Fula', 'Romanian': 'Rumeno', 'Oromo': 'Oromo', 'Igbo': 'Igbo',
          'Azerbaijani': 'Azero', 'Awadhi': 'Awadhi', 'Gan': 'Gan', 'Cebuano': 'Cebuano', 'Dutch': 'Olandese', 'Kurdish': 'Curdo', 'Serbo-Croatian': 'Serbo-croato', 'Malagasy': 'Malgascio',
          'Saraiki': 'Saraiki', 'Nepali': 'Nepalese', 'Sinhalese': 'Sinhalese', 'Chittagonian': 'Chittagonian', 'Zhuang': 'Zhuang', 'Khmer': 'Khmer', 'Turkmen': 'Turkmeno', 'Somali': 'Somalo',
          'Marwari': 'Marwari', 'Magahi': 'Magahi', 'Haryanvi': 'Haryanvi', 'Hungarian': 'Ungherese', 'Chhattisgarhi': 'Chhattisgarhi', 'Greek': 'Greco', 'Chewa': 'Chewa', 'Deccan': 'Deccan',
          'Akan': 'Akan', 'Kazakh': 'Kazako', 'Northern Min': 'Min del nord', 'Sylheti': 'Sylheti', 'Zulu': 'Zulu', 'Czech': 'Ceco', 'Kinyarwanda': 'Kinyarwanda',
          "Select a Category": "Seleziona una Categoria",
          "Max distance in km": "Distanza massima in km",
          "Select Language": "Seleziona Lingua",
          "Select Proficiency": "Seleziona Livello",
          "Add Questions": "Aggiungi Domande",
          "Add Question": "Aggiungi Domanda",
          "Question": "Domanda",
          "Priority": "Priorità",
          "Profile updated successfully!": "Profilo aggiornato correttamente!",
          "Profile Settings": "Impostazioni del Profilo",
          "Company Name": "Nome Azienda",
          "Description": "Descrizione",
          "Save Changes": "Salva Modifiche",
          "Answer": "Risposta",
          "There are either no questions or they have not been answered by this applicant": "Non ci sono domande o non sono state risposte da questo candidato",
          "No answer": "Nessuna risposta",
          "Questions & Answers": "Domande e Risposte",
          "There was an error updating the application status": "Si è verificato un errore nell'aggiornamento dello stato dell'applicazione",
          "Job Experiences": "Esperienze Lavorative",
          "Education": "Istruzione",
          "Courses": "Corsi",
          "Languages": "Lingue",
          "Applicant Info": "Informazioni Candidato",
          "Applied on": "Candidato il",
          "Status": "Stato",
          "Rejected": "Rifiutato",
          "Applied": "Candidato",
          "Screening": "Screening",
          "Interview": "Colloquio",
          "Offer": "Offerta",
          "Cover Letter": "Lettera di Presentazione",
          "Age": "Età",
          "Gender":"Genere",
          "Unnkown": "Sconosciuto",
          "Showing": "Mostrando",
          "of": "di",
          "applicants": "candidati",
          "Clear All Filters": "Rimuovi tutti i filtri",
          "Apply Filters": "Applica Filtri",
          "Job Offer Status": "Stato Annuncio",
          "To": "A",
          "From": "Da",
          "Submission Details": "Dettagli Invio",
          "Cover Letter Provided": "Lettera di Presentazione",
          "Filter & Sort": "Filtri e Ordine",
          "Job Application Link": "Link Annuncio",
          "Search Applicants": "Cerca Candidati",
          "Application Status": "Stato Candidatura",
          "Application Details": "Dettagli Candidatura",
          "Requirements Met": "Requisiti Soddisfatti",
          "Answers": "Risposte",
          "Present": "Presente",
          "Absent": "Assente",
          "Search Tags": "Cerca Tag",
          "You can select up to": "Puoi selezionare fino a",
          "tags": "tag",
          "Are you sure you want to change the state of the job offer?": "Sei sicuro di voler cambiare lo stato dell'annuncio di lavoro?",
          "Confirm": "Conferma",
          "Cancel": "Annulla",
          "The job was created successfully. Share this link for applications:": "L'annuncio è stato creato con successo. Condividi questo link per le candidature:",
          "Draft": "Bozza",
          "Notes": "Note",
          "Applicant Notes": "Note Candidato",
          "In Review": "In Revisione",
          "Send Application": "Invia Candidatura",
          "Not available": "Non disponibile",
          "Thank you for your feedback!": "Grazie per il tuo feedback!",
          "There was an error submitting your feedback. Please try again.": "Si è verificato un errore nell'invio del feedback. Riprova.",
          "We Value Your Feedback!": "Il tuo feedback è importante per noi!",
          "Your feedback is essential to us. Please let us know about any issues, confusing interfaces, or potential new features you would like to see. We aim to make this platform as user-friendly as possible": "Il tuo feedback è essenziale per noi. Per favore facci sapere di eventuali problemi, interfacce confuse o nuove funzionalità che vorresti vedere. Vogliamo rendere questa piattaforma il più user-friendly possibile",
          "Provide your feedback...": "Fornisci il tuo feedback...",
          "Submit": "Invia",
          "Created": "Creato",
          "days ago": "giorni fa",
          "Applications": "Candidature",
          "Profile": "Profilo",
          "Let's start creating credentials": "Iniziamo a creare le credenziali",
          "Fill these credential sections before finishing your application, I know it’s very annoying but we created this startup, for this very purpose, ending this resume creation every time you apply for job offers. Also, shouldn’t your resume be as easy to manage as your instagram profile? Bear with me and this will become standard.": "Completa queste sezioni delle credenziali prima di terminare la tua candidatura, so che è molto fastidioso, ma abbiamo creato questa startup proprio per questo scopo, per evitare di dover creare il curriculum ogni volta che ti candidi per offerte di lavoro. Inoltre, il tuo curriculum non dovrebbe essere facile da gestire come il tuo profilo Instagram? Sopportaci e diventerà lo standard.",
          "Back": "Indietro",
          "Create Academic Credential": "Crea Credenziale Accademica",
          "Male":"Maschio",
          "Female":"Femmina",
          "Other":"Altro",
          "I have read and accept the": "Ho letto e accetto il",
          "Are you sure you want to continue? Please ensure you have completed all profile creation tasks.": "Sei sicuro di voler continuare? Assicurati di aver completato tutte le attività di creazione del profilo.",
          "Profile complete? Apply instantly from now on": "Profilo completo? Candidati istantaneamente da ora in poi",
          "From now on you will be able to apply instantly with this profile, we are working to make this profile accepted in as many job offers as possible, no more having to refill your entire resume every time! (yea, except this time). Access your account to monitor the status of your applications and stay tuned for other functions coming!":"Da ora in poi potrai candidarti istantaneamente con questo profilo, stiamo lavorando per rendere questo profilo accettato in quante più offerte di lavoro possibile, niente più bisogno di riempire tutto il curriculum ogni volta! (sì, tranne questa volta). Accedi al tuo account per monitorare lo stato delle tue candidature e rimani sintonizzato per altre funzioni in arrivo!",
          "Create Course Credential": "Crea Credenziale del Corso",
          "Create Job Experience Credential": "Crea Credenziale dell'Esperienza Lavorativa",
          "Please fill all fields before submitting": "Compila tutti i campi prima di inviare",
          "Remember PDF Resumes?": "Ricordi i curricula in PDF?",
          "Continue": "Continua",
          "Organise Interview": "Organizza Colloquio",
          "For any info": "Per qualsiasi informazione",
          "Company Overview": "Panoramica dell'Azienda",
          "Job Description": "Descrizione dell'Annuncio",
          "Questions": "Domande",
          "Answers": "Risposte",
          "Your cover letter can detail your motivation and why you're a good fit for this role.": "La tua lettera di presentazione può dettagliare la tua motivazione e perché sei adatto per questo ruolo.",
          "This is where you will be able to keep track and remain updated on the state of your job applications":"Qui potrai tenere traccia e rimanere aggiornato sullo stato delle tue candidature",
          "Applied on ": "Candidato il ",
          "From ": "Da ",
          " to ": " a ",
          "This is your personal information. We already filled this out from your registration.": "Queste sono le tue informazioni personali. Abbiamo già compilato queste informazioni dalla tua registrazione.",
          "Here you can manage your educational credentials like High School Diplomas, Degrees, etc. Start adding them right away!": "Qui puoi gestire le tue credenziali educative come Diplomi di Scuola Superiore, Lauree, ecc. Inizia ad aggiungerle subito!",
          'Manage your job experiences here. We use categories to ease filtering, there are many to choose from, let us know if we missed any!': 'Gestisci le tue esperienze lavorative qui. Utilizziamo categorie per facilitare il filtraggio, ce ne sono molte tra cui scegliere, facci sapere se ne abbiamo saltata qualcuna!',
          'List all the courses you have done here.': 'Elencare tutti i corsi che hai fatto qui.',
          'Now, finish completing your professional profile here and then navigate to "Applications" to start applying for jobs instantly': 'Ora, completa il tuo profilo professionale qui e poi vai su "Candidature" per iniziare a candidarti per lavori istantaneamente',
          "Failed to update profile. Please try again.": "Impossibile aggiornare il profilo. Riprova.",
          "An error occurred. Please try again later.": "Si è verificato un errore. Riprova più tardi.",
          "Profile updated successfully!": "Profilo aggiornato con successo!",
          "Profile Settings": "Impostazioni del Profilo",
          "Company Name": "Nome Azienda",
          "Description": "Descrizione",
          "Save Changes": "Salva Modifiche",
          "Full-Stack Development": "Full-Stack Development",
"JavaScript": "JavaScript",
"Node.js": "Node.js",
"Express.js": "Express.js",
"React.js": "React.js",
"Vue.js": "Vue.js",
"Ruby on Rails": "Ruby on Rails",
"Python": "Python",
"Django": "Django",
"Java": "Java",
"Spring Boot": "Spring Boot",
"SQL": "SQL",
"NoSQL": "NoSQL",
"MongoDB": "MongoDB",
"PostgreSQL": "PostgreSQL",
"Git": "Git",
"API Development": "Sviluppo API",
"Heroku": "Heroku",
"Docker": "Docker",
"AWS": "AWS",
"CI/CD": "CI/CD",
"Software & Web Development": "Sviluppo Software e Web",
"Front-end Development": "Sviluppo Front-end",
"HTML5": "HTML5",
"CSS": "CSS",
"SASS": "SASS",
"LESS": "LESS",
"JavaScript": "JavaScript",
"ES6": "ES6",
"TypeScript": "TypeScript",
"React.js": "React.js",
"Vue.js": "Vue.js",
"Angular.js": "Angular.js",
"Redux": "Redux",
"Vuex": "Vuex",
"Bootstrap": "Bootstrap",
"Material-UI": "Material-UI",
"JQuery": "JQuery",
"Bulma": "Bulma",
"Webpack": "Webpack",
"Babel": "Babel",
"NPM": "NPM",
"Yarn": "Yarn",
"GraphQL": "GraphQL",
"Ajax": "Ajax",
"Web Accessibility": "Accessibilità Web",
"UI/UX Design": "Design UI/UX",
"Performance Optimization": "Ottimizzazione delle Prestazioni",
"Responsive Web Design": "Design Web Responsive",
"Cross-Browser Compatibility": "Compatibilità Cross-Browser",
"Software & Web Development": "Sviluppo Software e Web",
"Back-end Development": "Sviluppo Back-end",
"Node.js": "Node.js",
"Ruby on Rails": "Ruby on Rails",
"Python": "Python",
"Flask": "Flask",
"Django": "Django",
"Java": "Java",
"Spring Boot": "Spring Boot",
"PHP": "PHP",
"Laravel": "Laravel",
"API Development": "Sviluppo API",
"REST": "REST",
"GraphQL": "GraphQL",
"SQL": "SQL",
"NoSQL": "NoSQL",
"MongoDB": "MongoDB",
"PostgreSQL": "PostgreSQL",
"MySQL": "MySQL",
"Docker": "Docker",
"Microservices": "Microservizi",
"AWS Lambda": "AWS Lambda",
"Serverless Architecture": "Architettura Serverless",
"Apache Kafka": "Apache Kafka",
"Git": "Git",
"Software & Web Development": "Sviluppo Software e Web",
"Mobile App Development": "Sviluppo App Mobile",
"Swift": "Swift",
"Objective-C": "Objective-C",
"iOS": "iOS",
"Java": "Java",
"Kotlin": "Kotlin",
"Android": "Android",
"Flutter": "Flutter",
"Dart": "Dart",
"React Native": "React Native",
"JavaScript": "JavaScript",
"TypeScript": "TypeScript",
"Mobile UI/UX Design": "Design UI/UX Mobile",
"Firebase": "Firebase",
"Cross-Platform Development": "Sviluppo Cross-Platform",
"App Store Deployment": "Distribuzione App Store",
"Performance Tuning": "Regolazione delle Prestazioni",
"SQLite": "SQLite",
"Push Notifications": "Notifiche Push",
"Mobile Hardware Integration": "Integrazione Hardware Mobile",
"Software & Web Development": "Sviluppo Software e Web",
"Embedded Systems Development": "Sviluppo Sistemi Embedded",
"C": "C",
"C++": "C++",
"Assembly": "Assembly",
"Microcontroller Programming": "Programmazione Microcontroller",
"RTOS": "RTOS",
"Arduino": "Arduino",
"Raspberry Pi": "Raspberry Pi",
"Firmware Development": "Sviluppo Firmware",
"IoT": "IoT",
"UART": "UART",
"SPI": "SPI",
"I2C": "I2C",
"CAN": "CAN",
"Ethernet": "Ethernet",
"Wireless Communication": "Comunicazione Wireless",
"Device Drivers": "Driver dei Dispositivi",
"RTOS": "RTOS",
"Embedded Linux": "Linux Embedded",
"Debugging": "Debugging",
"Hardware-Software Integration": "Integrazione Hardware-Software",
"Software & Web Development": "Sviluppo Software e Web",
"Game Development": "Sviluppo Giochi",
"Unity": "Unity",
"Unreal Engine": "Unreal Engine",
"C#": "C#",
"C++": "C++",
"DirectX": "DirectX",
"OpenGL": "OpenGL",
"Physics Simulation": "Simulazione Fisica",
"2D/3D Animation": "Animazione 2D/3D",
"AI Programming": "Programmazione IA",
"VR/AR Integration": "Integrazione VR/AR",
"Game Testing": "Test di Giochi",
"Scripting": "Scripting",
"Game Design": "Design di Giochi",
"Multiplayer Networking": "Networking Multiplayer",
"Monetization": "Monetizzazione",
"Mobile Game Development": "Sviluppo Giochi Mobile",
"Console Game Development": "Sviluppo Giochi Console",
"Cross-Platform Game Development": "Sviluppo Giochi Cross-Platform",
"Software & Web Development": "Sviluppo Software e Web",
"Desktop Application Development": "Sviluppo Applicazioni Desktop",
"Java": "Java",
"C++": "C++",
"Python": "Python",
"Electron": "Electron",
"C#": "C#",
".NET": ".NET",
"WPF": "WPF",
"Qt": "Qt",
"GTK": "GTK",
"Windows": "Windows",
"macOS": "macOS",
"Linux": "Linux",
"User Interface Design": "Design dell'Interfaccia Utente",
"Multi-Threading": "Multi-Threading",
"Database Integration": "Integrazione Database",
"API Integration": "Integrazione API",
"Performance Optimization": "Ottimizzazione delle Prestazioni",
"Software & Web Development": "Sviluppo Software e Web",
"DevOps & Infrastructure": "DevOps e Infrastruttura",
"Docker": "Docker",
"Kubernetes": "Kubernetes",
"Jenkins": "Jenkins",
"GitLab CI/CD": "GitLab CI/CD",
"Travis CI": "Travis CI",
"AWS": "AWS",
"Azure": "Azure",
"Google Cloud": "Google Cloud",
"Terraform": "Terraform",
"Ansible": "Ansible",
"Monitoring": "Monitoraggio",
"Logging": "Logging",
"Site Reliability": "Affidabilità del Sito",
"Network Management": "Gestione della Rete",
"Server Configuration": "Configurazione del Server",
"Automation": "Automazione",
"Release Management": "Gestione del Rilascio",
"Version Control": "Controllo Versione",
"Infrastructure as Code": "Infrastruttura come Codice",
"Continuous Integration": "Integrazione Continua",
"Continuous Deployment": "Deployment Continuo",
"Software & Web Development": "Sviluppo Software e Web",
"Database Administration & Development": "Amministrazione e Sviluppo Database",
"SQL": "SQL",
"NoSQL": "NoSQL",
"MongoDB": "MongoDB",
"PostgreSQL": "PostgreSQL",
"MySQL": "MySQL",
"Oracle DB": "Oracle DB",
"Microsoft SQL Server": "Microsoft SQL Server",
"SQLite": "SQLite",
"Database Design": "Design Database",
"Normalization": "Normalizzazione",
"Performance Tuning": "Regolazione delle Prestazioni",
"Backup and Recovery": "Backup e Ripristino",
"Replication": "Replica",
"Clustering": "Clustering",
"Indexing": "Indicizzazione",
"Stored Procedures": "Procedure Memorizzate",
"ETL": "ETL",
"Big Data Solutions": "Soluzioni Big Data",
"Data Warehousing": "Data Warehousing",
"Software & Web Development": "Sviluppo Software e Web",
"Systems Architecture & Design": "Architettura e Design dei Sistemi",
"System Design": "Design dei Sistemi",
"Microservices": "Microservizi",
"Monolithic Architecture": "Architettura Monolitica",
"Service-Oriented Architecture (SOA)": "Architettura Orientata ai Servizi (SOA)",
"API Design": "Design API",
"Database Design": "Design Database",
"Distributed Systems": "Sistemi Distribuiti",
"Scalability": "Scalabilità",
"Performance Optimization": "Ottimizzazione delle Prestazioni",
"System Integration": "Integrazione dei Sistemi",
"Data Flow Diagrams": "Diagrammi di Flusso dei Dati",
"Network Design": "Design della Rete",
"Security Architecture": "Architettura della Sicurezza",
"High Availability": "Alta Disponibilità",
"Disaster Recovery": "Disaster Recovery",
"Software & Web Development": "Sviluppo Software e Web",
"Quality Assurance & Testing": "Assicurazione della Qualità e Testing",
"Manual Testing": "Test Manuale",
"Automated Testing": "Test Automatizzato",
"JUnit": "JUnit",
"TestNG": "TestNG",
"Selenium": "Selenium",
"Appium": "Appium",
"Load Testing": "Test di Carico",
"Stress Testing": "Test di Stress",
"Performance Testing": "Test delle Prestazioni",
"Integration Testing": "Test di Integrazione",
"Regression Testing": "Test di Regressione",
"Black Box Testing": "Test Black Box",
"White Box Testing": "Test White Box",
"UAT (User Acceptance Testing)": "Test di Accettazione Utente (UAT)",
"Bug Tracking": "Tracciamento dei Bug",
"Test Case Development": "Sviluppo dei Casi di Test",
"Agile Testing": "Testing Agile",
"TDD (Test Driven Development)": "Sviluppo Guidato dai Test (TDD)",
"BDD (Behavior Driven Development)": "Sviluppo Guidato dal Comportamento (BDD)",
"Continuous Testing": "Testing Continuo",
"Software & Web Development": "Sviluppo Software e Web",
"Cybersecurity & Ethical Hacking": "Cybersecurity e Hacking Etico",
"Penetration Testing": "Test di Penetrazione",
"Network Security": "Sicurezza della Rete",
"Application Security": "Sicurezza delle Applicazioni",
"Endpoint Security": "Sicurezza degli Endpoint",
"Cryptography": "Crittoverifica",
"Firewall Management": "Gestione del Firewall",
"IDS/IPS": "IDS/IPS",
"Vulnerability Assessment": "Valutazione delle Vulnerabilità",
"Malware Analysis": "Analisi dei Malware",
"Risk Assessment": "Valutazione del Rischio",
"Incident Response": "Risposta agli Incidenti",
"Security Compliance": "Conformità alla Sicurezza",
"Ethical Hacking": "Hacking Etico",
"Forensic Analysis": "Analisi Forense",
"OWASP": "OWASP",
"Social Engineering": "Ingegneria Sociale",
"Phishing": "Phishing",
"Software & Web Development": "Sviluppo Software e Web",
"Data Science & Machine Learning Development": "Sviluppo Data Science e Machine Learning",
"Python": "Python",
"R": "R",
"TensorFlow": "TensorFlow",
"Keras": "Keras",
"PyTorch": "PyTorch",
"Scikit-learn": "Scikit-learn",
"Data Cleaning": "Pulizia dei Dati",
"Data Visualization": "Visualizzazione dei Dati",
"Statistical Analysis": "Analisi Statistica",
"Regression": "Regressione",
"Classification": "Classificazione",
"Deep Learning": "Deep Learning",
"Neural Networks": "Reti Neurali",
"Natural Language Processing": "Elaborazione del Linguaggio Naturale",
"Computer Vision": "Visione Artificiale",
"Big Data": "Big Data",
"Spark": "Spark",
"Hadoop": "Hadoop",
"Time Series Analysis": "Analisi delle Serie Temporali",
"Software & Web Development": "Sviluppo Software e Web",
"Cloud Computing & Development": "Cloud Computing e Sviluppo",
"AWS": "AWS",
"Azure": "Azure",
"Google Cloud Platform": "Google Cloud Platform",
"Serverless Computing": "Computing Serverless",
"Lambda": "Lambda",
"CloudFormation": "CloudFormation",
"Docker": "Docker",
"Kubernetes": "Kubernetes",
"Elastic Beanstalk": "Elastic Beanstalk",
"S3": "S3",
"EC2": "EC2",
"CloudFront": "CloudFront",
"Cloud Networking": "Rete Cloud",
"IAM": "IAM",
"Database Services": "Servizi di Database",
"Storage Services": "Servizi di Archiviazione",
"Cloud Security": "Sicurezza Cloud",
"Hybrid Cloud": "Cloud Ibrido",
"Multi-cloud Management": "Gestione Multi-cloud",
"Software & Web Development": "Sviluppo Software e Web",
"Blockchain & Distributed Systems Development": "Sviluppo Blockchain e Sistemi Distribuiti",
"Ethereum": "Ethereum",
"Smart Contracts": "Smart Contracts",
"Solidity": "Solidity",
"Hyperledger": "Hyperledger",
"DApp Development": "Sviluppo DApp",
"Cryptography": "Crittoverifica",
"Blockchain Architecture": "Architettura Blockchain",
"Distributed Ledger Technology": "Tecnologia del Registro Distribuito",
"Token Development": "Sviluppo di Token",
"ICO": "ICO",
"Consensus Algorithms": "Algoritmi di Consenso",
"Peer-to-Peer Networks": "Reti Peer-to-Peer",
"Blockchain Security": "Sicurezza Blockchain",
"Decentralized Systems": "Sistemi Decentralizzati",
"Public and Private Blockchains": "Blockchain Pubbliche e Private",
"Software & Web Development": "Sviluppo Software e Web",
"AR/VR Development": "Sviluppo AR/VR",
"Unity": "Unity",
"Unreal Engine": "Unreal Engine",
"C#": "C#",
"C++": "C++",
"ARKit": "ARKit",
"ARCore": "ARCore",
"Vuforia": "Vuforia",
"Oculus SDK": "Oculus SDK",
"SteamVR": "SteamVR",
"Virtual Reality": "Realtà Virtuale",
"Augmented Reality": "Realtà Aumentata",
"Mixed Reality": "Realtà Mista",
"3D Modeling": "Modellazione 3D",
"Spatial Audio": "Audio Spaziale",
"Gesture Recognition": "Riconoscimento dei Gesti",
"VR Game Development": "Sviluppo Giochi VR",
"VR Interface Design": "Design Interfaccia VR",
"Mobile AR/VR": "AR/VR Mobile",
"Performance Optimization": "Ottimizzazione delle Prestazioni",
"Software & Web Development": "Sviluppo Software e Web",
"Algorithm Design & Optimization": "Progettazione e Ottimizzazione degli Algoritmi",
"Big O Notation": "Notazione Big O",
"Search Algorithms": "Algoritmi di Ricerca",
"Sort Algorithms": "Algoritmi di Ordinamento",
"Graph Algorithms": "Algoritmi sui Grafi",
"Dynamic Programming": "Programmazione Dinamica",
"Greedy Algorithms": "Algoritmi Greedy",
"Recursion": "Ricorsione",
"Tree Structures": "Strutture ad Albero",
"Hashing": "Hashing",
"Cryptography Algorithms": "Algoritmi di Crittografia",
"Machine Learning Algorithms": "Algoritmi di Machine Learning",
"Parallel Processing": "Elaborazione Parallela",
"Distributed Algorithms": "Algoritmi Distribuiti",
"Optimization Techniques": "Tecniche di Ottimizzazione",
"Software & Web Development": "Sviluppo Software e Web",
"API Development & Integration": "Sviluppo e Integrazione API",
"REST": "REST",
"SOAP": "SOAP",
"GraphQL": "GraphQL",
"JSON": "JSON",
"XML": "XML",
"OpenAPI": "OpenAPI",
"Postman": "Postman",
"Swagger": "Swagger",
"OAuth": "OAuth",
"JWT": "JWT",
"API Security": "Sicurezza API",
"Rate Limiting": "Limitazione Frequenza",
"API Gateway": "Gateway API",
"Webhooks": "Webhooks",
"API Documentation": "Documentazione API",
"Third-party API Integration": "Integrazione API di Terze Parti",
"Software & Web Development": "Sviluppo Software e Web",
"E-commerce Development": "Sviluppo E-commerce",
"WooCommerce": "WooCommerce",
"Magento": "Magento",
"Shopify": "Shopify",
"BigCommerce": "BigCommerce",
"Drupal Commerce": "Drupal Commerce",
"PrestaShop": "PrestaShop",
"OsCommerce": "OsCommerce",
"SSL Integration": "Integrazione SSL",
"Payment Gateway Integration": "Integrazione Gateway di Pagamento",
"Shopping Cart": "Carrello della Spesa",
"Product Management": "Gestione del Prodotto",
"Checkout Process": "Processo di Checkout",
"E-commerce SEO": "SEO per E-commerce",
"Mobile E-commerce": "E-commerce Mobile",
"Inventory Management": "Gestione dell'Inventario",
"Software & Web Development": "Sviluppo Software e Web",
"Content Management Systems (CMS) Development": "Sviluppo di Sistemi di Gestione dei Contenuti (CMS)",
"WordPress": "WordPress",
"Joomla": "Joomla",
"Drupal": "Drupal",
"Typo3": "Typo3",
"Squarespace": "Squarespace",
"Wix": "Wix",
"Blogger": "Blogger",
"Custom CMS Development": "Sviluppo CMS Personalizzato",
"Themes": "Temi",
"Plugins": "Plugin",
"Database Management": "Gestione del Database",
"Content Organization": "Organizzazione dei Contenuti",
"SEO Integration": "Integrazione SEO",
"Multilingual Support": "Supporto Multilingua",
"Software & Web Development": "Sviluppo Software e Web",
"Firmware Development": "Firmware Development",
"C": "C",
"C++": "C++",
"Assembly": "Assembly",
"RTOS": "RTOS",
"Embedded C": "Embedded C",
"Microcontrollers": "Microcontrollers",
"Device Drivers": "Driver di dispositivo",
"Hardware Abstraction Layer": "Layer di astrazione hardware",
"I2C": "I2C",
"SPI": "SPI",
"UART": "UART",
"Memory Management": "Gestione della memoria",
"Power Management": "Gestione dell'alimentazione",
"Bootloaders": "Bootloader",
"Debugging Tools": "Strumenti di debug",
"Real-time Systems": "Sistemi in tempo reale",
"Design & Multimedia": "Design & Multimedia",
"Graphic Design": "Design grafico",
"Adobe Creative Suite": "Adobe Creative Suite",
"Photoshop": "Photoshop",
"Illustrator": "Illustrator",
"InDesign": "InDesign",
"CorelDRAW": "CorelDRAW",
"Vector Illustration": "Illustrazione vettoriale",
"Photo Editing": "Editing fotografico",
"Typography": "Tipografia",
"Branding": "Branding",
"Logo Design": "Design del logo",
"Print Design": "Design da stampa",
"Layout Design": "Design del layout",
"Color Theory": "Teoria del colore",
"Infographics": "Infografiche",
"Digital Illustration": "Illustrazione digitale",
"Raster Graphics": "Grafica raster",
"Design & Multimedia": "Design & Multimedia",
"UI/UX Design": "Design UI/UX",
"Sketch": "Sketch",
"Figma": "Figma",
"Adobe XD": "Adobe XD",
"Wireframing": "Wireframing",
"Prototyping": "Prototipazione",
"User Testing": "Test utente",
"Interaction Design": "Design dell'interazione",
"User Persona Creation": "Creazione di persona utente",
"User Journey Mapping": "Mappatura del percorso utente",
"Usability Testing": "Test di usabilità",
"Information Architecture": "Architettura dell'informazione",
"Responsive Design": "Design responsivo",
"Design & Multimedia": "Design & Multimedia",
"Animation & Video Production": "Animazione & Produzione video",
"After Effects": "After Effects",
"Premiere Pro": "Premiere Pro",
"Cinema 4D": "Cinema 4D",
"Maya": "Maya",
"3D Animation": "Animazione 3D",
"2D Animation": "Animazione 2D",
"Storyboarding": "Storyboarding",
"Video Editing": "Montaggio video",
"Motion Graphics": "Grafica in movimento",
"Character Animation": "Animazione dei personaggi",
"Special Effects": "Effetti speciali",
"Sound Editing": "Editing del suono",
"Color Correction": "Correzione del colore",
"Design & Multimedia": "Design & Multimedia",
"Web Design": "Design web",
"HTML5": "HTML5",
"CSS3": "CSS3",
"Responsive Design": "Design responsivo",
"Bootstrap": "Bootstrap",
"WordPress": "WordPress",
"Joomla": "Joomla",
"Drupal": "Drupal",
"Theme Customization": "Personalizzazione del tema",
"Landing Page Design": "Design della pagina di destinazione",
"Parallax Design": "Design parallasse",
"Mobile-first Design": "Design mobile-first",
"Web Accessibility": "Accessibilità web",
"Design & Multimedia": "Design & Multimedia",
"Illustration": "Illustrazione",
"Digital Art": "Arte digitale",
"Character Design": "Design dei personaggi",
"Concept Art": "Concept art",
"Technical Illustration": "Illustrazione tecnica",
"Children's Book Illustration": "Illustrazione di libri per bambini",
"Comic Art": "Arte dei fumetti",
"Storyboarding": "Storyboarding",
"Pen & Ink Drawing": "Disegno a penna e inchiostro",
"Watercolor": "Acquerello",
"Digital Painting": "Pittura digitale",
"Vector Art": "Arte vettoriale",
"Design & Multimedia": "Design & Multimedia",
"Product Design": "Design del prodotto",
"Sketching": "Schizzi",
"Prototyping": "Prototipazione",
"CAD Design": "Progettazione CAD",
"Material Selection": "Selezione dei materiali",
"Ergonomics": "Ergonomia",
"3D Modeling": "Modellazione 3D",
"User-centered Design": "Design centrato sull'utente",
"Functional Prototypes": "Prototipi funzionali",
"Product Lifecycle Management": "Gestione del ciclo di vita del prodotto",
"Industrial Design": "Design industriale",
"Design Thinking": "Design Thinking",
"Design & Multimedia": "Design & Multimedia",
"Print Design & Layout": "Design e layout da stampa",
"Brochure Design": "Design di brochure",
"Flyer Design": "Design di volantini",
"Poster Design": "Design di poster",
"Business Cards": "Biglietti da visita",
"Magazine Layout": "Layout di riviste",
"Catalog Design": "Design di cataloghi",
"Typography": "Tipografia",
"Page Layout": "Layout della pagina",
"Pre-press": "Pre-stampa",
"Packaging Design": "Design di imballaggi",
"Print Production": "Produzione di stampa",
"Design & Multimedia": "Design & Multimedia",
"3D Modeling & CAD": "Modellazione 3D & CAD",
"Autodesk AutoCAD": "Autodesk AutoCAD",
"Blender": "Blender",
"ZBrush": "ZBrush",
"SolidWorks": "SolidWorks",
"3ds Max": "3ds Max",
"Rhinoceros": "Rhinoceros",
"Revit": "Revit",
"Architecture Visualization": "Visualizzazione architettonica",
"3D Printing": "Stampa 3D",
"Sculpting": "Scultura",
"Surface Modeling": "Modellazione di superfici",
"Technical Drawing": "Disegno tecnico",
"Design & Multimedia": "Design & Multimedia",
"Game Design & Development": "Design e sviluppo di giochi",
"Unity": "Unity",
"Unreal Engine": "Unreal Engine",
"Game Mechanics": "Meccaniche di gioco",
"Level Design": "Design di livelli",
"Character Design": "Design dei personaggi",
"Scripting": "Scripting",
"Mobile Game Design": "Design di giochi per cellulari",
"VR & AR Design": "Design VR & AR",
"Game Testing": "Test di giochi",
"Sound Design": "Design sonoro",
"Texture and Lighting": "Texture e illuminazione",
"Player Experience (PX)": "Esperienza del giocatore (PX)",
"Design & Multimedia": "Design & Multimedia",
"Audio Production & Editing": "Produzione ed editing audio",
"Pro Tools": "Pro Tools",
"Ableton Live": "Ableton Live",
"FL Studio": "FL Studio",
"Sound Mixing": "Missaggio sonoro",
"Mastering": "Masterizzazione",
"Sound Effects": "Effetti sonori",
"Audio Restoration": "Restauro audio",
"MIDI Production": "Produzione MIDI",
"Voice Over": "Doppiaggio",
"Field Recording": "Registrazione sul campo",
"Sound Design": "Design sonoro",
"Music Composition": "Composizione musicale",
"Design & Multimedia": "Design & Multimedia",
"Photography": "Fotografia",
"Digital Photography": "Fotografia digitale",
"Photo Editing": "Editing fotografico",
"Lightroom": "Lightroom",
"Portrait Photography": "Fotografia ritrattistica",
"Landscape Photography": "Fotografia paesaggistica",
"Product Photography": "Fotografia di prodotto",
"Event Photography": "Fotografia di eventi",
"Studio Lighting": "Illuminazione da studio",
"Photojournalism": "Fotogiornalismo",
"Macro Photography": "Fotografia macro",
"Retouching": "Ritocco",
"Color Grading": "Color grading",
"Design & Multimedia": "Design & Multimedia",
"Multimedia Artistry": "Arte multimediale",
"Interactive Design": "Design interattivo",
"Virtual Reality": "Realtà virtuale",
"Augmented Reality": "Realtà aumentata",
"MixedReality": "Realtà mista",
"Video Production": "Produzione video",
"Sound Editing": "Editing del suono",
"Multimedia Presentation": "Presentazione multimediale",
"Interactive PDFs": "PDF interattivi",
"Digital Installations": "Installazioni digitali",
"eLearning Modules": "Moduli eLearning",
"Design & Multimedia": "Design & Multimedia",
"Motion Graphics": "Grafica in movimento",
"After Effects": "After Effects",
"Cinema 4D": "Cinema 4D",
"Storyboarding": "Storyboarding",
"Character Animation": "Animazione dei personaggi",
"Typography Animation": "Animazione tipografica",
"Visual Effects": "Effetti visivi",
"Particle Effects": "Effetti particellari",
"2D & 3D Motion": "Movimento 2D & 3D",
"Logo Animation": "Animazione del logo",
"Infographics Animation": "Animazione di infografiche",
"Design & Multimedia": "Design & Multimedia",
"Brand & Identity Design": "Design del marchio e dell'identità",
"Logo Design": "Design del logo",
"Brand Guidelines": "Linee guida del marchio",
"Corporate Identity": "Identità aziendale",
"Brand Strategy": "Strategia di marca",
"Visual Identity Systems": "Sistemi di identità visiva",
"Business Cards": "Biglietti da visita",
"Letterhead Design": "Design dell'intestazione",
"Brand Voice": "Voce del marchio",
"Merchandise Design": "Design del merchandising",
"Brand Audit": "Audit del marchio",
"Design & Multimedia": "Design & Multimedia",
"Digital Art & Drawing": "Arte e disegno digitale",
"Digital Painting": "Pittura digitale",
"Character Art": "Arte dei personaggi",
"Concept Art": "Concept art",
"Manga & Comics": "Manga e fumetti",
"Digital Sketching": "Schizzi digitali",
"Fantasy Art": "Arte fantasy",
"Sci-fi Art": "Arte fantascienza",
"Storyboarding": "Storyboarding",
"Tablet & Stylus Techniques": "Tecniche di tablet e stilo",
"Digital Sculpting": "Scultura digitale",
"Design & Multimedia": "Design & Multimedia",
"Typography & Lettering": "Tipografia e lettering",
"Font Design": "Design del carattere",
"Calligraphy": "Calligrafia",
"Hand Lettering": "Lettering a mano",
"Typeface Selection": "Selezione del tipo di carattere",
"Print Typography": "Tipografia da stampa",
"Digital Typography": "Tipografia digitale",
"Font Pairing": "Abbinamento di caratteri",
"Custom Lettering": "Lettering personalizzato",
"Typography Layout": "Layout tipografico",
"Type Anatomy": "Anatomia del carattere",
"Kerning": "Crenatura",
"Leading": "Interlinea",
"Design & Multimedia": "Design & Multimedia",
"Environmental & Spatial Design": "Design ambientale e spaziale",
"Interior Design": "Design degli interni",
"Architectural Design": "Design architettonico",
"Landscape Design": "Design del paesaggio",
"Retail Space Design": "Design dello spazio commerciale",
"Exhibition Design": "Design di esposizioni",
"Wayfinding": "Segnaletica",
"Signage": "Insegne",
"Spatial Branding": "Branding spaziale",
"Public Space Planning": "Pianificazione degli spazi pubblici",
"Furniture Design": "Design del mobile",
"Design & Multimedia": "Design & Multimedia",
"Fashion & Apparel Design": "Design di moda e abbigliamento",
"Garment Design": "Design di capi d'abbigliamento",
"Fashion Illustration": "Illustrazione di moda",
"Pattern Making": "Creazione di modelli",
"Textile Design": "Design tessile",
"Fashion Branding": "Branding della moda",
"Accessory Design": "Design di accessori",
"Footwear Design": "Design di calzature",
"Trend Forecasting": "Previsione delle tendenze",
"Fashion Styling": "Styling di moda",
"Fashion Photography": "Fotografia di moda",
"Design & Multimedia": "Design & Multimedia",
"Package Design": "Design di imballaggi",
"Product Packaging": "Packaging del prodotto",
"Label Design": "Design dell'etichetta",
"Bottle & Container Design": "Design di bottiglie e contenitori",
"Retail Packaging": "Packaging al dettaglio",
"Eco-Friendly Packaging": "Packaging ecologico",
"Unboxing Experience": "Esperienza di unboxing",
"Branding": "Branding",
"Typography": "Tipografia",
"Barcodes & Labeling": "Codici a barre ed etichettatura",
"Prototype Development": "Sviluppo di prototipi",
"Sales & Marketing": "Vendite e Marketing",
"Digital & Online Marketing": "Marketing digitale e online",
"SEO": "SEO",
"SEM": "SEM",
"PPC": "PPC",
"Google Ads": "Google Ads",
"Facebook Ads": "Facebook Ads",
"Google Analytics": "Google Analytics",
"Affiliate Marketing": "Marketing affiliato",
"Email Campaigns": "Campagne email",
"Landing Page Optimization": "Ottimizzazione della pagina di destinazione",
"Conversion Rate Optimization": "Ottimizzazione del tasso di conversione",
"Sales & Marketing": "Vendite e Marketing",
"Public Relations & Branding": "Relazioni pubbliche e branding",
"Media Relations": "Relazioni con i media",
"Crisis Management": "Gestione delle crisi",
"Event Coordination": "Coordinamento di eventi",
"Brand Strategy": "Strategia di marca",
"Corporate Communication": "Comunicazione aziendale",
"Stakeholder Engagement": "Coinvolgimento degli stakeholder",
"Content Creation": "Creazione di contenuti",
"Sales & Marketing": "Vendite e Marketing",
"Sales": "Vendite",
"Lead Generation": "Generazione di lead",
"Cold Calling": "Telefonate a freddo",
"Sales Presentations": "Presentazioni di vendita",
"Account Management": "Gestione dell'account",
"Negotiation": "Negoziazione",
"CRM Tools": "Strumenti CRM",
"Sales Strategy": "Strategia di vendita",
"Cross-selling": "Vendita incrociata",
"Up-selling": "Vendita aggiuntiva",
"Sales & Marketing": "Vendite e Marketing",
"Content & Social Media Marketing": "Content e Social Media Marketing",
"Content Strategy": "Strategia di contenuto",
"Blogging": "Blogging",
"Video Creation": "Creazione di video",
"Instagram Marketing": "Marketing su Instagram",
"Twitter Marketing": "Marketing su Twitter",
"LinkedIn Marketing": "Marketing su LinkedIn",
"Influencer Collaboration": "Collaborazione con influencer",
"Content Calendar": "Calendario dei contenuti",
"Engagement Analysis": "Analisi dell'engagement",
"Sales & Marketing": "Vendite e Marketing",
"Market Research & Strategy": "Ricerca di mercato e strategia",
"Consumer Surveys": "Sondaggi ai consumatori",
"Focus Groups": "Gruppi di discussione",
"Market Segmentation": "Segmentazione del mercato",
"Competitive Analysis": "Analisi competitiva",
"SWOT Analysis": "Analisi SWOT",
"Trend Forecasting": "Previsione delle tendenze",
"Data Analytics": "Analisi dei dati",
"Product Positioning": "Posizionamento del prodotto",
"Sales & Marketing": "Vendite e Marketing",
"Product & Event Marketing": "Marketing di prodotto ed eventi",
"Product Launches": "Lanci di prodotto",
"Promotional Campaigns": "Campagne promozionali",
"Event Planning": "Pianificazione di eventi",
"Sponsorship Management": "Gestione degli sponsor",
"Trade Shows": "Fiere commerciali",
"Product Demos": "Dimostrazioni di prodotto",
"Cross-Promotions": "Promozioni incrociate",
"Venue Selection": "Selezione del luogo",
"Event Logistics": "Logistica degli eventi",
"Sales & Marketing": "Vendite e Marketing",
"Channel & Partnership Management": "Gestione di canali e partnership",
"Channel Strategy": "Strategia di canale",
"Partner Recruitment": "Reclutamento di partner",
"Co-Marketing Initiatives": "Iniziative di co-marketing",
"Channel Training": "Formazione di canale",
"Partnership Agreements": "Accordi di partnership",
"Revenue Sharing": "Condivisione dei ricavi",
"Affiliate Programs": "Programmi di affiliazione",
"Reseller Programs": "Programmi per rivenditori",
"Administration & Support": "Amministrazione e supporto",
"Office & Executive Administration": "Amministrazione ufficio ed esecutiva",
"Scheduling": "Pianificazione",
"Meeting Coordination": "Coordinamento riunioni",
"Office Management": "Gestione dell'ufficio",
"Stakeholder Communication": "Comunicazione con gli stakeholder",
"Calendar Management": "Gestione del calendario",
"Reporting": "Reporting",
"Office Software Proficiency": "Competenza nel software d'ufficio",
"Travel Coordination": "Coordinamento viaggi",
"Budgeting": "Budgeting",
"Event Organization": "Organizzazione di eventi",
"Minute Taking": "Verbale",
"Task Prioritization": "Prioritizzazione dei compiti",
"Administration & Support": "Amministrazione e supporto",
"Customer & Technical Support": "Supporto tecnico e al cliente",
"Helpdesk Operation": "Gestione del helpdesk",
"Customer Service": "Servizio clienti",
"Technical Troubleshooting": "Risoluzione dei problemi tecnici",
"CRM Tools": "Strumenti CRM",
"Communication Skills": "Competenze comunicative",
"Problem-Solving": "Risoluzione dei problemi",
"SLA Compliance": "Conformità SLA",
"Customer Feedback Analysis": "Analisi del feedback dei clienti",
"Product Knowledge": "Conoscenza del prodotto",
"Remote Support Tools": "Strumenti di supporto remoto",
"Service Ticket Management": "Gestione dei Ticket di Servizio",
"Conflict Resolution": "Risoluzione dei Conflitti",
"Administration & Support": "Amministrazione e Supporto",
"Human Resources & Recruitment": "Risorse Umane e Reclutamento",
"Talent Acquisition": "Acquisizione di Talenti",
"Onboarding": "Inserimento",
"Employee Relations": "Relazioni con i Dipendenti",
"Benefits Administration": "Amministrazione dei Benefit",
"HRIS Systems": "Sistemi HRIS",
"Training & Development": "Formazione e Sviluppo",
"Performance Management": "Gestione delle Prestazioni",
"Compensation Analysis": "Analisi della Retribuzione",
"Legal Compliance": "Conformità Legale",
"Employee Engagement": "Coinvolgimento dei Dipendenti",
"Exit Interviews": "Interviste di Uscita",
"Payroll Management": "Gestione della Paghe",
"Facilities & Event Management": "Gestione delle Strutture ed Eventi",
"Venue Sourcing": "Ricerca delle Location",
"Vendor Management": "Gestione dei Fornitori",
"Event Planning": "Pianificazione degli Eventi",
"Budgeting & Costing": "Budgeting e Costing",
"Logistics": "Logistica",
"Facility Maintenance": "Manutenzione delle Strutture",
"Space Planning": "Pianificazione degli Spazi",
"Security Coordination": "Coordinamento della Sicurezza",
"Health & Safety": "Salute e Sicurezza",
"Inventory Control": "Controllo dell'Inventario",
"Resource Allocation": "Assegnazione delle Risorse",
"Event Promotion": "Promozione degli Eventi",
"Data & Document Management": "Gestione dei Dati e dei Documenti",
"Document Archiving": "Archiviazione dei Documenti",
"Data Entry": "Inserimento dei Dati",
"Database Management": "Gestione del Database",
"Confidentiality": "Riservatezza",
"Version Control": "Controllo delle Versioni",
"Data Analysis": "Analisi dei Dati",
"Record Keeping": "Conservazione dei Registri",
"Filing Systems": "Sistemi di Archiviazione",
"Electronic Storage Solutions": "Soluzioni di Archiviazione Elettronica",
"Document Review & Editing": "Revisione e Modifica dei Documenti",
"Collaborative Tools": "Strumenti di Collaborazione",
"Metadata Management": "Gestione dei Metadati",
"Financial Analysis & Corporate Finance": "Analisi Finanziaria e Finanza Aziendale",
"Financial Modeling": "Modellazione Finanziaria",
"Forecasting": "Previsione",
"Budgeting": "Budgeting",
"Variance Analysis": "Analisi delle Varianze",
"Financial Reporting": "Reporting Finanziario",
"Strategic Planning": "Pianificazione Strategica",
"Capital Budgeting": "Budgeting del Capitale",
"DCF": "DCF",
"NPV Analysis": "Analisi del NPV",
"IRR": "IRR",
"Cost Analysis": "Analisi dei Costi",
"Profitability Analysis": "Analisi della Redditività",
"Financial Ratios": "Rapporti Finanziari",
"Balance Sheet Analysis": "Analisi del Bilancio",
"Income Statement Analysis": "Analisi del Conto Economico",
"Cash Flow Analysis": "Analisi del Flusso di Cassa",
"Scenario Analysis": "Analisi degli Scenario",
"Sensitivity Analysis": "Analisi di Sensibilità",
"Financial Statements Consolidation": "Consolidamento dei Bilanci",
"Merger Modeling": "Modellazione delle Fusioni",
"Project Management": "Gestione dei Progetti",
"Scrum": "Scrum",
"Agile": "Agile",
"Waterfall": "Waterfall",
"Kanban": "Kanban",
"Lean": "Lean",
"MS Project": "MS Project",
"PMP": "PMP",
"Prince2": "Prince2",
"Risk Assessment": "Valutazione del Rischio",
"Stakeholder Management": "Gestione degli Stakeholder",
"Gantt Chart": "Diagramma di Gantt",
"Project Scheduling": "Pianificazione del Progetto",
"Budget Management": "Gestione del Budget",
"Resource Allocation": "Assegnazione delle Risorse",
"Scope Management": "Gestione dello Scope",
"Quality Management": "Gestione della Qualità",
"Documentation": "Documentazione",
"Reporting": "Reporting",
"Consulting": "Consulenza",
"Business Strategy": "Strategia Aziendale",
"Management Consulting": "Consulenza Manageriale",
"IT Consulting": "Consulenza IT",
"Market Analysis": "Analisi di Mercato",
"SWOT Analysis": "Analisi SWOT",
"Competitive Analysis": "Analisi della Concorrenza",
"Change Management": "Gestione del Cambiamento",
"Operational Efficiency": "Efficienza Operativa",
"Stakeholder Interviews": "Interviste agli Stakeholder",
"Client Relations": "Relazioni con i Clienti",
"Business Process Reengineering": "Riingegnerizzazione dei Processi Aziendali",
"Benchmarking": "Benchmarking",
"Accounting & Bookkeeping": "Contabilità e Tenuta dei Libri",
"General Ledger": "Registro Generale",
"Accounts Payable": "Fornitori",
"Accounts Receivable": "Clienti",
"Payroll Processing": "Elaborazione delle Paghe",
"Journal Entries": "Registrazioni Contabili",
"Bank Reconciliation": "Conciliazione Bancaria",
"Financial Statement Preparation": "Preparazione dei Bilanci",
"GAAP": "GAAP",
"Tax Preparation": "Preparazione delle Tasse",
"Invoicing": "Fatturazione",
"Expense Tracking": "Monitoraggio delle Spese",
"QuickBooks": "QuickBooks",
"Xero": "Xero",
"Financial Record Keeping": "Conservazione dei Registri Finanziari",
"Monthly Closings": "Chiusure Mensili",
"Investment & Portfolio Management": "Gestione degli Investimenti e dei Portafogli",
"Asset Allocation": "Assegnazione degli Asset",
"Risk Management": "Gestione del Rischio",
"Bonds": "Obbligazioni",
"Equities": "Azioni",
"Hedge Funds": "Fondi Hedge",
"Mutual Funds": "Fondi Comuni di Investimento",
"Alternative Investments": "Investimenti Alternativi",
"Technical Analysis": "Analisi Tecnica",
"Fundamental Analysis": "Analisi Fondamentale",
"Return on Investment (ROI)": "Ritorno sull'Investimento (ROI)",
"CAPM": "CAPM",
"Derivatives": "Derivati",
"Securities Analysis": "Analisi dei Titoli",
"Market Research": "Ricerca di Mercato",
"Wealth Management": "Gestione del Patrimonio",
"Auditing & Compliance & Regulatory Affairs": "Revisione, Conformità e Affari Regolatori",
"Internal Audit": "Revisione Interna",
"External Audit": "Revisione Esterna",
"SOX Compliance": "Conformità SOX",
"Risk Assessment": "Valutazione del Rischio",
"Audit Reports": "Relazioni di Revisione",
"Financial Review": "Revisione Finanziaria",
"Operational Audit": "Revisione Operativa",
"Compliance Testing": "Test di Conformità",
"Regulatory Reporting": "Reporting Regolatorio",
"Quality Assurance": "Assicurazione della Qualità",
"Control Framework": "Framework di Controllo",
"Financial Regulations": "Regolamentazione Finanziaria",
"Anti-Money Laundering": "Antiriciclaggio",
"Know Your Customer (KYC)": "Conosci il Tuo Cliente (KYC)",
"Taxation & Tax Consulting": "Tassazione e Consulenza Fiscale",
"Income Tax": "Imposta sul Reddito",
"VAT": "IVA",
"Sales Tax": "Imposta sulle Vendite",
"Corporate Tax": "Imposta sulle Società",
"International Tax": "Imposta Internazionale",
"Tax Planning": "Pianificazione Fiscale",
"Tax Compliance": "Conformità Fiscale",
"Tax Deductions": "Deduzioni Fiscali",
"Tax Advisory": "Consulenza Fiscale",
"Tax Law": "Diritto Fiscale",
"Tax Audit": "Revisione Fiscale",
"Tax Accounting": "Contabilità Fiscale",
"Transfer Pricing": "Prezzi di Trasferimento",
"Tax Software": "Software Fiscale",
"Excise Tax": "Imposta di Consumo",
"Risk Management & Treasury Management": "Gestione del Rischio e Gestione del Tesoro",
"Liquidity Management": "Gestione della Liquidità",
"Financial Risk": "Rischio Finanziario",
"Operational Risk": "Rischio Operativo",
"Credit Risk": "Rischio di Credito",
"Market Risk": "Rischio di Mercato",
"Enterprise Risk Management (ERM)": "Gestione del Rischio Aziendale (ERM)",
"Risk Modelling": "Modellazione del Rischio",
"Derivatives Hedging": "Copertura dei Derivati",
"Currency Hedging": "Copertura del Cambio Valuta",
"Interest Rate Forecasting": "Previsione dei Tassi di Interesse",
"Cash Management": "Gestione del Cash",
"Investment Management": "Gestione degli Investimenti",
"Debt Management": "Gestione del Debito",
"Capital Structure": "Struttura del Capitale",
"Finance & Management": "Finanza e Management",
"Mergers & Acquisitions": "Fusioni e Acquisizioni",
"Due Diligence": "Due Diligenze",
"Valuation": "Valutazione",
"Synergy Analysis": "Analisi di Sinergia",
"Deal Structuring": "Strutturazione dell'Operazione",
"Negotiation": "Negoziazione",
"Integration Planning": "Pianificazione dell'Integrazione",
"Post-merger Integration": "Integrazione Post-Fusione",
"Divestitures": "Disinvestimenti",
"Spin-offs": "Scorpori",
"LBO Modelling": "Modellazione LBO",
"Acquisition Financing": "Finanziamento delle Acquisizioni",
"Banking & Lending": "Banca e Prestiti",
"Retail Banking": "Banca al Dettaglio",
"Commercial Banking": "Banca Commerciale",
"Investment Banking": "Banca d'Investimento",
"Credit Analysis": "Analisi del Credito",
"Loan Underwriting": "Sottoscrizione dei Prestiti",
"Portfolio Management": "Gestione del Portafoglio",
"Mortgages": "Mutui",
"Credit Cards": "Carte di Credito",
"Personal Loans": "Prestiti Personali",
"Asset Management": "Gestione degli Asset",
"Relationship Management": "Gestione delle Relazioni",
"Financial Planning & Advising": "Pianificazione Finanziaria e Consulenza",
"Wealth Management": "Gestione del Patrimonio",
"Retirement Planning": "Pianificazione della Pensione",
"Estate Planning": "Pianificazione Patrimoniale",
"Tax Planning": "Pianificazione Fiscale",
"Risk Assessment": "Valutazione del Rischio",
"Asset Allocation": "Asset Allocation",
"Life Insurance": "Assicurazione sulla Vita",
"Trusts": "Trust",
"Investment Strategies": "Strategie di Investimento",
"Client Relations": "Relazioni con i Clienti",
"Financial Goals Setting": "Definizione degli Obiettivi Finanziari",
"Insurance": "Assicurazione",
"Underwriting": "Sottoscrizione",
"Claims Management": "Gestione dei Sinistri",
"Policy Administration": "Amministrazione delle Polizze",
"Risk Analysis": "Analisi del Rischio",
"Product Development": "Sviluppo del Prodotto",
"Insurance Sales": "Vendite Assicurative",
"Reinsurance": "Riassicurazione",
"Actuarial Analysis": "Analisi Attuariale",
"Insurance Regulations": "Regolamentazione Assicurativa",
"Property and Casualty": "Proprietà e Infortuni",
"Life Insurance": "Assicurazione sulla Vita",
"Venture Capital & Private Equity": "Capitale di Rischio e Private Equity",
"Deal Sourcing": "Ricerca di Operazioni",
"Portfolio Management": "Gestione del Portafoglio",
"Equity Research": "Ricerca Azionaria",
"Valuation": "Valutazione",
"Due Diligence": "Due Diligenze",
"Term Sheet Negotiation": "Negoziazione del Term Sheet",
"Capital Raising": "Raccolta di Capitale",
"Exit Strategies": "Strategie di Uscita",
"Investment Strategies": "Strategie di Investimento",
"Fund Management": "Gestione dei Fondi",
"Business Strategy & Planning & Operations Management": "Strategia Aziendale e Pianificazione e Gestione delle Operazioni",
"Strategic Planning": "Pianificazione Strategica",
"Operational Efficiency": "Efficienza Operativa",
"Process Improvement": "Miglioramento dei Processi",
"Business Modelling": "Modellazione Aziendale",
"Market Analysis": "Analisi di Mercato",
"Product Lifecycle Management": "Gestione del Ciclo di Vita del Prodotto",
"Operational Strategy": "Strategia Operativa",
"Lean Management": "Gestione Snella",
"Six Sigma": "Six Sigma",
"Supply Chain Strategy": "Strategia della Catena di Fornitura",
"Performance Metrics": "Indicatori di Performance",
"Supply Chain & Logistics Management": "Gestione della Catena di Fornitura e della Logistica",
"Inventory Management": "Gestione dell'Inventario",
"Procurement": "Acquisti",
"Demand Forecasting": "Previsione della Domanda",
"Logistics Planning": "Pianificazione Logistica",
"Warehousing": "Magazzinaggio",
"Distribution": "Distribuzione",
"Supplier Relations": "Relazioni con i Fornitori",
"Supply Chain Optimization": "Ottimizzazione della Catena di Fornitura",
"Transportation Management": "Gestione dei Trasporti",
"Order Fulfillment": "Evasione degli Ordini",
"Just-In-Time (JIT)": "Just-In-Time (JIT)",
"Vendor Management": "Gestione dei Fornitori",
"Engineering & Manufacturing": "Ingegneria e Manifattura",
"Mechanical Engineering": "Ingegneria Meccanica",
"CAD": "CAD",
"SolidWorks": "SolidWorks",
"AutoCAD": "AutoCAD",
"Finite Element Analysis": "Analisi agli Elementi Finiti",
"Dynamics": "Dinamica",
"Thermodynamics": "Termodinamica",
"Fluid Mechanics": "Meccanica dei Fluidi",
"Kinematics": "Cinematica",
"Machine Design": "Progettazione di Macchine",
"MATLAB": "MATLAB",
"ANSYS": "ANSYS",
"Pro/ENGINEER": "Pro/ENGINEER",
"HVAC": "HVAC",
"Mechanical Testing": "Test Meccanici",
"Prototyping": "Prototipazione",
"Engineering & Manufacturing": "Ingegneria e Manifattura",
"Electrical Engineering": "Ingegneria Elettrica",
"Circuit Design": "Progettazione di Circuiti",
"PCB Design": "Progettazione di PCB",
"Power Systems": "Sistemi di Alimentazione",
"Embedded Systems": "Sistemi Embedded",
"Signal Processing": "Elaborazione del Segnale",
"Control Systems": "Sistemi di Controllo",
"VHDL": "VHDL",
"Verilog": "Verilog",
"Microcontrollers": "Microcontrollori",
"PLC": "PLC",
"Electromagnetic Theory": "Teoria Elettromagnetica",
"MATLAB": "MATLAB",
"LabVIEW": "LabVIEW",
"Oscilloscope": "Oscilloscopio",
"RF Engineering": "Ingegneria RF",
"Engineering & Manufacturing": "Ingegneria e Manifattura",
"Civil Engineering": "Ingegneria Civile",
"Structural Analysis": "Analisi Strutturale",
"Hydrology": "Idrologia",
"Geotechnical": "Geotecnica",
"AutoCAD Civil 3D": "AutoCAD Civil 3D",
"Surveying": "Rilevamento",
"Transportation Engineering": "Ingegneria dei Trasporti",
"Sustainability": "Sostenibilità",
"Infrastructure Design": "Progettazione delle Infrastrutture",
"Construction Management": "Gestione delle Costruzioni",
"Land Development": "Sviluppo del Territorio",
"Water Resources": "Risorse Idriche",
"Environmental Impact": "Impatto Ambientale",
"Reinforced Concrete": "Calcestruzzo Armato",
"Steel Design": "Progettazione in Acciaio",
"Engineering & Manufacturing": "Ingegneria e Manifattura",
"Chemical Engineering": "Ingegneria Chimica",
"Process Engineering": "Ingegneria dei Processi",
"Material Balance": "Bilancio dei Materiali",
"Kinetics": "Cinetica",
"ChemCAD": "ChemCAD",
"Distillation": "Distillazione",
"Heat Transfer": "Trasferimento di Calore",
"Reactor Design": "Progettazione di Reattori",
"Separation Processes": "Processi di Separazione",
"Fluid Dynamics": "Dinamica dei Fluidi",
"Petrochemicals": "Petrochimica",
"Pharmaceuticals": "Farmaceutica",
"Safety & Hazard Analysis": "Analisi di Sicurezza e Rischio",
"Polymers": "Polimeri",
"Biochemical": "Biochimica",
"Engineering & Manufacturing": "Ingegneria e Manifattura",
"Biomedical Engineering": "Ingegneria Biomedica",
"Medical Devices": "Dispositivi Medici",
"Bioinformatics": "Bioinformatica",
"Biomechanics": "Biomeccanica",
"Biomedical Imaging": "Imaging Biomedico",
"Tissue Engineering": "Ingegneria dei Tessuti",
"Biomedical Signal Processing": "Elaborazione del Segnale Biomedico",
"Biomaterials": "Biomateriali",
"Rehabilitation Engineering": "Ingegneria della Riabilitazione",
"Genomics": "Genomica",
"Prosthetics": "Protesi",
"Orthopedic Design": "Progettazione Ortopedica",
"Biomedical Optics": "Ottica Biomedica",
"Medical Robotics": "Robotica Medica",
"Clinical Engineering": "Ingegneria Clinica",
"Engineering & Manufacturing": "Ingegneria e Manifattura",
"Aerospace Engineering": "Ingegneria Aerospaziale",
"Flight Mechanics": "Meccanica del Volo",
"Aerodynamics": "Aerodinamica",
"Propulsion": "Propulsione",
"Aircraft Structures": "Strutture Aeronautiche",
"Avionics": "Avionica",
"Navigation Systems": "Sistemi di Navigazione",
"Control Systems": "Sistemi di Controllo",
"Spacecraft Design": "Progettazione di Veicoli Spaziali",
"Wind Tunnel Testing": "Test in Galleria del Vento",
"Aircraft Maintenance": "Manutenzione Aeronautica",
"Composite Materials": "Materiali Compositi",
"Orbital Mechanics": "Meccanica Orbitale",
"CFD": "CFD",
"Rocket Science": "Scienza dei Razzi",
"Flight Simulation": "Simulazione di Volo",
"Engineering & Manufacturing": "Ingegneria e Manifattura",
"Industrial Engineering": "Ingegneria Industriale",
"Lean Manufacturing": "Produzione Snella",
"Six Sigma": "Six Sigma",
"Operations Research": "Ricerca Operativa",
"Ergonomics": "Ergonomia",
"Supply Chain Management": "Gestione della Catena di Fornitura",
"Logistics": "Logistica",
"Work Study": "Studio del Lavoro",
"Quality Control": "Controllo di Qualità",
"Systems Optimization": "Ottimizzazione dei Sistemi",
"Project Management": "Gestione dei Progetti",
"Facility Layout": "Layout degli Impianti",
"Material Handling": "Movimentazione dei Materiali",
"Statistical Analysis": "Analisi Statistica",
"Simulation Modeling": "Modellazione di Simulazione",
"Production Planning": "Pianificazione della Produzione",
"Engineering & Manufacturing": "Ingegneria e Manifattura",
"Manufacturing Engineering": "Ingegneria di Produzione",
"CNC Machining": "Lavorazione CNC",
"Injection Molding": "Stampaggio ad Iniezione",
"Quality Assurance": "Assicurazione della Qualità",
"Process Improvement": "Miglioramento dei Processi",
"Materials Selection": "Selezione dei Materiali",
"Robotics": "Robotica",
"Welding": "Saldatura",
"Assembly Line Design": "Progettazione di Linee di Assemblaggio",
"Product Lifecycle Management": "Gestione del Ciclo di Vita del Prodotto",
"Tool Design": "Progettazione di Utensili",
"Lean Principles": "Principi Lean",
"Additive Manufacturing": "Manifattura Additiva",
"Rapid Prototyping": "Prototipazione Rapida",
"ISO Standards": "Standard ISO",
"SPC (Statistical Process Control)": "SPC (Controllo Statistico dei Processi)",
"Engineering & Manufacturing": "Ingegneria e Manifattura",
"Environmental Engineering": "Ingegneria Ambientale",
"Water Treatment": "Trattamento dell'Acqua",
"Air Quality Management": "Gestione della Qualità dell'Aria",
"Waste Management": "Gestione dei Rifiuti",
"Sustainability": "Sostenibilità",
"Environmental Impact Assessment": "Valutazione dell'Impatto Ambientale",
"Hazardous Waste Management": "Gestione dei Rifiuti Pericolosi",
"Site Remediation": "Rimedio del Sito",
"Stormwater Management": "Gestione delle Acque Piovane",
"Pollution Control": "Controllo dell'Inquinamento",
"Green Building Design": "Progettazione di Edifici Ecologici",
"Ecology": "Ecologia",
"Hydrogeology": "Idrogeologia",
"Carbon Footprint Analysis": "Analisi dell'Impronta di Carbonio",
"Recycling Systems": "Sistemi di Riciclaggio",
"Environmental Regulations": "Regolamentazione Ambientale",
"Engineering & Manufacturing": "Ingegneria e Manifattura",
"Structural Engineering": "Ingegneria Strutturale",
"Building Design": "Progettazione di Edifici",
"Foundation Design": "Progettazione delle Fondazioni",
"Seismic Design": "Progettazione Sismica",
"Load Analysis": "Analisi del Carico",
"Finite Element Analysis": "Analisi agli Elementi Finiti",
"Bridge Design": "Progettazione di Ponti",
"Retaining Walls": "Muri di Sostegno",
"Structural Inspections": "Ispezioni Strutturali",
"Reinforced Concrete": "Calcestruzzo Armato",
"Steel Structures": "Strutture in Acciaio",
"Timber Structures": "Strutture in Legno",
"Masonry Design": "Progettazione di Murature",
"Earthquake Engineering": "Ingegneria Sismica",
"Wind Analysis": "Analisi del Vento",
"Composite Structures": "Strutture Composte",
"Download My Data": "Scarica i miei dati",
"Remove All Data": "Rimuovi tutti i dati",
"Full Name": "Nome completo",
"Address": "Indirizzo",
"Phone Number": "Numero di telefono",
"Birth Date": "Data di nascita",
"By creating an account and applying to roles, I consent to SimpL sharing my CV and personal information with the employer for the role I am applying to, as well as with other potential employers for any future job applications made through my account. I understand that SimpL and its automated decision-making systems will process my application to enhance the recruitment process. I acknowledge that I can erase my data at any time by contacting SimpL or through my account settings.":"Creando un account e candidandomi a ruoli, acconsento a SimpL di condividere il mio CV e le mie informazioni personali con il datore di lavoro per il ruolo a cui sto applicando, nonché con altri potenziali datori di lavoro per eventuali future candidature fatte tramite il mio account. Comprendo che SimpL e i suoi sistemi di decisione automatizzati elaboreranno la mia candidatura per migliorare il processo di selezione. Riconosco che posso cancellare i miei dati in qualsiasi momento contattando SimpL o tramite le impostazioni del mio account.",
"Company Name": "Nome dell'azienda",

          

          


          
          
          









          








          // Add more key-value pairs for Italian translations
        }
      }
    },
    // Removed the lng option to let the language detector decide
    fallbackLng: "en", // Fallback language
    detection: {
      // Configure the language detection plugin options
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'], // Cache the detected language in localStorage and cookies
      checkWhitelist: true, // Check if the detected language is in the whitelist
    },
    whitelist: ['en', 'it'], // List of available languages
    interpolation: {
      escapeValue: false, // Not needed for React as it escapes by default
    }
  });

export default i18n;

