import React, { useState, useEffect } from 'react';
import './ApplicantPool.css';
import { useQuery, useMutation, gql } from '@apollo/client';
import LongTextField from '../../textfields/LongTextField';
import SaveChanges from '../../save-changes/SaveChanges';
import { useTranslation } from 'react-i18next';
import copy from '../../../images/copy_clipboard_white.svg'
import ProfilePicture from '../../profile-picture/ProfilePicture';

// GraphQL Queries and Mutations
const GET_NOTE = gql`
  query GetNoteByRecruiterAndApplicant($recruiterID: ID!, $applicantID: ID!) {
    getNoteByRecruiterAndApplicant(recruiterID: $recruiterID, applicantID: $applicantID) {
      id
      content
      createdAt
      updatedAt
    }
  }
`;

const CREATE_NOTE = gql`
  mutation CreateNote($applicantID: ID!, $content: String!, $recruiterID: ID!, $updatedAt: String) {
    createNote(applicantID: $applicantID, content: $content, recruiterID: $recruiterID, updatedAt: $updatedAt) {
      id 
      applicantID
      recruiterID
      content
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_NOTE = gql`
  mutation UpdateNote($id: ID!, $content: String, $recruiterID: ID, $updatedAt: String) {
    updateNote(id: $id, content: $content, recruiterID: $recruiterID, updatedAt: $updatedAt) {
      id
      content
      recruiterID
      updatedAt
    }
  }
`;

const PROFICIENCY_LEVELS = [
  { value: 'Basic', label: 'Basic' },
  { value: 'Conversational', label: 'Conversational' },
  { value: 'Proficient', label: 'Proficient' },
  { value: 'Fluent', label: 'Fluent' }
];

const getProficiencyLabel = (value) => {
  if (!value) return "";  // Check for the existence of the value
  const proficiency = PROFICIENCY_LEVELS.find(p => p.value === value.toString());
  return proficiency ? proficiency.label : "";
};

const ApplicantPool = ({ applicant, onOpenOverlay }) => {
  const [notes, setNotes] = useState('');
  const [emailCopied, setEmailCopied] = useState(false);
  const [phoneCopied, setPhoneCopied] = useState(false);
  const [noteId, setNoteId] = useState(null);
  const [isModified, setIsModified] = useState(false);
  const [toCreate, setToCreate] = useState(false);
  const { t } = useTranslation();

  const recruiterId = sessionStorage.getItem('recruiterId');

  const { loading, error, data } = useQuery(GET_NOTE, {
    variables: { recruiterID: recruiterId, applicantID: applicant.id },
    skip: !recruiterId || !applicant.id,
  });


  const [createNote] = useMutation(CREATE_NOTE);
  const [updateNote] = useMutation(UPDATE_NOTE);

  useEffect(() => {
    if (data && data.getNoteByRecruiterAndApplicant) {
      setNotes(data.getNoteByRecruiterAndApplicant.content);
      setNoteId(data.getNoteByRecruiterAndApplicant.id);
      setToCreate(false);
    } else {
      setToCreate(true);
    }
  }, [data]);

  const handleTextChange = (e) => {
    e.stopPropagation();
    setNotes(e.target.value);
    setIsModified(true);
  };

  const handleSaveClick = async (e) => {
    e.stopPropagation();
    const currentTime = new Date().toISOString();

    if (toCreate) {
      await createNote({
        variables: {
          applicantID: applicant.id,
          content: notes,
          recruiterID: recruiterId,
          updatedAt: currentTime,
        },
      });
      setToCreate(false);
    } else {
      await updateNote({
        variables: {
          id: noteId,
          content: notes,
          recruiterID: recruiterId,
          updatedAt: currentTime,
        },
      });
    }
    setIsModified(false);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  const calculateAge = (birthdateTimestamp) => {
    const birthdate = new Date(birthdateTimestamp);
    const today = new Date();
    let age = today.getFullYear() - birthdate.getFullYear();
    const monthDifference = today.getMonth() - birthdate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthdate.getDate())) {
      age--;
    }
    return age;
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => //console.log ('Text copied successfully!'),
      (err) => console.error('Failed to copy text: ', err)
    );
  };

  const languages = applicant.profile?.languages || [];

  return (
    <div className="application-pool" onClick={() => {
      //console.log ("Application clicked, opening overlay for applicant ID:", applicant.id);
      onOpenOverlay(applicant.id);
    }}>
      <div className="application-left-pool">
        <div className='pic-info-pool'>
          <div className='profile-picture'>
            <ProfilePicture name={applicant.profile?.fullname} />
          </div>
          <div className='application-info-pool'>
            <div className="application-name">
              {truncateText(applicant.profile?.fullname, 15)}
            </div>
            <div className="applicant-detail">{t('Age')}: {calculateAge(applicant.profile?.birthdate)}</div>
            <div className="applicant-detail">{t('Gender')}: {applicant.profile?.gender}</div>
          </div>
          <div className='application-email-number-pool'>
            <div className={`applicant-detail clickable ${emailCopied ? 'copied' : ''}`} onClick={() => {
              copyToClipboard(applicant.profile?.email);
              setEmailCopied(true);
              setTimeout(() => setEmailCopied(false), 2000);
            }}>
              {t('Email')}
              <img src={copy} alt="Copy" />
            </div>

            <div className={`applicant-detail clickable ${phoneCopied ? 'copied' : ''}`} onClick={() => {
              copyToClipboard(applicant.profile?.phone);
              setPhoneCopied(true);
              setTimeout(() => setPhoneCopied(false), 2000);
            }}>
              {t('Phone Number')}
              <img src={copy} alt="Copy" />
            </div>
          </div>
          <div className="applicant-detail">
            {truncateText(applicant.profile?.address, 25)}
          </div>
        </div>
      </div>
      <div className="application-right-pool">
        <LongTextField
          placeholder="Applicant Notes"
          value={notes}
          onChange={handleTextChange}
          mode="dark" // or "light"
        />
        <SaveChanges onClick={(e) => handleSaveClick(e)} isModified={isModified} />
      </div>
    </div>
  );
};

export default ApplicantPool;
