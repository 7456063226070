import React, { useState } from 'react';
import './AcademicForm.css';
import { Form, Card, Select, Tag, Input, Button } from 'antd';
import { AcademicCategories } from '../../../../categoriestags/AcademicCategories';
import { useTranslation } from 'react-i18next';
const { Option } = Select;
const { CheckableTag } = Tag;

const AcademicForm = ({ onAdd, setVisible }) => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [mandatory, setMandatory] = useState(true);
  const { t } = useTranslation();
  let counter = 0;

  const generateUUID = () => {
    const timePart = Date.now().toString(36);
    const randomPart = Math.random().toString(36).substring(2, 15);
    const countPart = (counter++).toString(36);
    return `${timePart}-${randomPart}-${countPart}`;
  };

  const handleSubmit = () => {
    const requirement = {
      id: generateUUID(),
      requirementtype: "Academic",
      category: selectedCategory,
      tags: selectedTags,
      mandatory: mandatory,
      years: 0
    };
    onAdd(requirement);
    setSelectedTags([]);
    setSelectedCategory('');
    setSearchValue('');
    setMandatory(true);
    setVisible(null); // Hide the form after submission by setting it to null
  };

  const handleDropdownVisibleChange = (open) => {
    document.body.style.overflow = open ? 'hidden' : 'visible';
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setSelectedTags([]);
    setSearchValue('');
  };

  const handleTagSelect = (tag, checked) => {
    let nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
    if (nextSelectedTags.length > 5) {
      nextSelectedTags = nextSelectedTags.slice(0, 5);
    }
    setSelectedTags(nextSelectedTags);
  };

  const categories = AcademicCategories.map(category => (
    <Option value={category.name} key={category.name}>{t(category.name)}</Option>
  ));

  let tags = [];
  if (selectedCategory) {
    const selectedCat = AcademicCategories.find(cat => cat.name === selectedCategory);
    if (selectedCat && selectedCat.tags) {
      tags = selectedCat.tags.filter(tag => tag.toLowerCase().includes(searchValue.toLowerCase()))
        .map(tag => (
          <CheckableTag
            key={tag}
            checked={selectedTags.includes(tag)}
            onChange={checked => handleTagSelect(tag, checked)}
          >
            {t(tag)}
          </CheckableTag>
        ));
    }
  }

  return (
    <div className='language-form'>
      <Form layout="vertical" onFinish={handleSubmit}>
        <h2 className='category-title'>{t('Degree')}</h2>
        <Form.Item>
          <Select placeholder={t('Select a degree')} onSelect={handleCategorySelect} onDropdownVisibleChange={handleDropdownVisibleChange}>
            {categories}
          </Select>
        </Form.Item>
        {selectedCategory && (
          <Card title={t('Fields of Study')}>
            <Input
              placeholder='Search fields of study'
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              style={{ marginBottom: '10px' }}
            />
            {tags}
          </Card>
        )}
        <Form.Item>
          <Button className='add-button-requirement' type="primary" htmlType="submit">{t('Add')}</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AcademicForm;
