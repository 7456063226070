import React, { useState, useEffect } from 'react';
import './CreateJobOfferPage.css';
import NavbarRecruiting from '../../../../components/navbar_recruiting/NavbarRecruiting';
import TextField from '../../../../components/textfields/TextField';
import LongTextField from '../../../../components/textfields/LongTextField';
import SelectField from '../../../../components/textfields/SelectField';
import ItemList from '../../../../components/textfields/ItemList';
import RangeSlider from '../../../../components/textfields/RangeSlider';
import DropdownButton from '../../../../components/textfields/requirements/DropdownButton';
import QuestionList from '../../../../components/textfields/QuestionList';
import RequirementItem from '../../../../components/textfields/requirements/RequirementItem';
import AddressField from '../../../../components/textfields/AddressField';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useSubscription } from '../../../../contexts/SubscriptionContext';

const CREATE_JOB_OFFER = gql`
mutation CreateJobOffer(
  $userId: String!,
  $recruiterId: ID!,
  $title: String!,
  $description: String,
  $place: String,
  $workMode: String,
  $responsibilities: [String],
  $offers: [String],
  $salaryRange: [Int],
  $requirements: [RequirementInput],
  $applicationsCount: Int,
  $state: String,
  $createdAt: String,
  $workHours: String,
  $questions: [QuestionInput]
) {
  createJobOffer(
    userID: $userId,
    recruiterID: $recruiterId,
    title: $title,
    description: $description,
    place: $place,
    workMode: $workMode,
    responsibilities: $responsibilities,
    offers: $offers,
    salaryRange: $salaryRange,
    requirements: $requirements,
    applicationsCount: $applicationsCount,
    state: $state,
    createdAt: $createdAt,
    workHours: $workHours,
    questions: $questions
  ) {
    id
    userID
    recruiterID
    title
    description
    place
    workMode
    responsibilities
    offers
    salaryRange
    requirements {
      id
      requirementtype
      category
      tags
      years
      mandatory
    }
    applicationsCount
    state
    createdAt
    workHours
    questions {
      id
      text
      questiontype
      questionrequirement
      rejectIfNotMet
    }
  }
}
`;

const getStepDetails = (subscription) => [
  {
    title: "The Perfect Job Title",
    paragraph: "Craft a job title that is concise and descriptive, clearly reflecting the role's level and expected responsibilities."
  },
  {
    title: "Role Description Details",
    paragraph: "Provide a clear and detailed role description that outlines the profile you are looking for."
  },
  {
    title: "Specify the Location",
    paragraph: "State the exact working location, whether it's fully onsite, remote, or hybrid to attract suitable candidates."
  },
  {
    title: "Work Mode Selection",
    paragraph: "Choose the work mode that fits the role best. Each option caters to different preferences and logistics."
  },
  {
    title: "Add Responsibilities",
    paragraph: "List all the responsibilities the candidate will have in the job to ensure they understand what will be expected of them."
  },
  {
    title: "What We Offer",
    paragraph: "List all the benefits and perks offered by the company to attract the best candidates."
  },
  {
    title: "Add Requirements",
    paragraph: "List all necessary qualifications and skills required for the job to ensure only qualified candidates apply."
  },
  {
    title: "Set Interview Questions",
    paragraph: "Prepare specific questions that help evaluate the candidates' fit for the role and organization."
  },
  {
    title: "Salary Range Setup",
    paragraph: "Define a competitive salary range to ensure transparency and attract the right candidates."
  },
  ...(subscription === "Free" ? [{
    title: "Job Offer Created",
    paragraph: "Your job offer has been created but will be posted on job boards automatically after you subscribe to the platform."
  }] : [{
    title: "Job Offer Created",
    paragraph: "Your job offer has been created and will be posted immediately on as many job boards as possible. To promote the job offer and receive up to 5x more applications, contact us at nico@thesimplplatform.io. Meanwhile, you can use the provided link to share the job offer."
  }])
];

const CreateJobOfferPage = ({ onClose }) => {
  const { t } = useTranslation();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();
  const { subscription, stripeCustomerId } = useSubscription();
  const [jobTitle, setJobTitle] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [place, setPlace] = useState('');
  const [workMode, setWorkMode] = useState('');
  const [responsibilities, setResponsibilities] = useState([]);
  const [offers, setOffers] = useState([]);
  const [salaryRange, setSalaryRange] = useState([30000, 70000]);
  const [isSalarySpecified, setIsSalarySpecified] = useState(true);
  const [requirements, setRequirements] = useState([]);
  const [jobLink, setJobLink] = useState('');
  const [questions, setQuestions] = useState([]);
  const [jobType, setJobType] = useState('');
  const [isOpen, setIsOpen] = useState(true);
  const [jobOfferData, setJobOfferData] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedRequirement, setSelectedRequirement] = useState(null);
  const recruiterID = isAuthenticated ? sessionStorage.getItem('recruiterId') : null;
  const employer = isAuthenticated ? sessionStorage.getItem('company') : null;
  const stepDetails = getStepDetails(subscription);
  const [title, setTitle] = useState(stepDetails[0].title);
  const [paragraph, setParagraph] = useState(stepDetails[0].paragraph);

  const [createJobOffer, { data }] = useMutation(CREATE_JOB_OFFER, {
    onError: (error) => {
      console.error("Error occurred during mutation: ", error.message);
    },
    onCompleted: async (data) => {
      console.log("Mutation completed successfully: ", data);
      const jobId = data.createJobOffer.id;
      const link = `https://app.thesimplplatform.io/apply/${jobId}`;
      setJobLink(link);

      // Send notification email to Nico
      const recruiterEmail = user.email;
      const recruiterName = user.name;

      try {
        const response = await fetch('https://simpl-backend-f1e8862591da.herokuapp.com/notify-job-offer-created', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            recruiterEmail,
            recruiterName,
            jobOfferId: jobId,
          }),
        });

        const emailData = await response.json();
        if (emailData.success) {
          console.log('Notification email sent successfully!');
        } else {
          console.error('Failed to send notification email.');
        }
      } catch (error) {
        console.error('Error sending notification email:', error);
      }

      if (subscription === "Free") {
        nextStep();  // Move to the subscription step
      } else {
        nextStep();  // Move to the job offer created step
      }
    },
  });

  const onPlanUpgrade = async () => {
    try {
      if (!stripeCustomerId) {
        throw new Error('Invalid customerID');
      }

      const response = await fetch('https://simpl-backend-f1e8862591da.herokuapp.com/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ stripeCustomerId })
      });

      const data = await response.json();

      if (response.ok) {
        if (data.url) {
          try {
            new URL(data.url);
            window.location.href = data.url;
          } catch (e) {
            console.error('Invalid URL in response:', data.url);
            alert('Received invalid URL from server.');
          }
        }
      } else {
        console.error('Error creating checkout session:', data);
        alert(`Error: ${data.message || 'Failed to create checkout session'}`);
      }
    } catch (error) {
      console.error('Error in onPlanUpgrade:', error);
      alert(`Error: ${error.message || 'An unexpected error occurred'}`);
    }
  };

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const nextStep = () => {
    if (currentStep < stepDetails.length - 1) {
      setCurrentStep(currentStep + 1);
      setTitle(stepDetails[currentStep + 1].title);
      setParagraph(stepDetails[currentStep + 1].paragraph);
    }
  };

  const previousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      setTitle(stepDetails[currentStep - 1].title);
      setParagraph(stepDetails[currentStep - 1].paragraph);
    }
  };

  const renderRequirements = () => {
    return (
      <div>
        {requirements.map((requirement, index) => (
          <RequirementItem
            key={index}
            requirement={requirement}
            onDelete={() => handleDeleteRequirement(index)}
          />
        ))}
      </div>
    );
  };

  const handleSalarySpecifyChange = () => {
    setIsSalarySpecified(!isSalarySpecified);
    if (isSalarySpecified) {
      setSalaryRange(null);
    } else {
      setSalaryRange([30000, 70000]);
    }
  };

  const handleAddRequirement = (requirement) => {
    setRequirements(prevRequirements => [...prevRequirements, requirement]);
  };

  const handleDeleteRequirement = (index) => {
    setRequirements(prevRequirements => prevRequirements.filter((_, idx) => idx !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit triggered");

    if (isAuthenticated) {
      console.log("User is authenticated, submitting job offer");

      try {
        const response = await createJobOffer({
          variables: {
            userId: user.sub,
            recruiterId: recruiterID,
            title: jobTitle,
            description: jobDescription,
            place: place,
            offers: offers,
            workMode: workMode,
            responsibilities: responsibilities,
            salaryRange: isSalarySpecified ? salaryRange : null,
            requirements: requirements,
            employer: employer,
            type: jobType,
            questions: questions.map(q => ({
              id: q.id,
              text: q.question,
              questiontype: q.type,
              questionrequirement: q.requirementValue ? q.requirementValue : "",
              rejectIfNotMet: q.rejectIfNotMet,
            })),
          },
        });

        if (response.data && response.data.createJobOffer) {
          console.log("Job offer created successfully", response.data.createJobOffer);
          if (subscription === "Free") {
            nextStep();  // Move to the subscription step
          } else {
            nextStep();  // Move to the job offer created step
          }
        }

      } catch (error) {
        console.error(t('Error creating job offer'), error);
      }
    } else {
      console.log("User is not authenticated, job offer not submitted");
    }
  };

  const handlePromotionRequest = async () => {
    const recruiterEmail = user.email;
    const recruiterName = user.name;
    const jobOfferId = jobLink.split('/').pop();

    try {
      const response = await fetch('https://simpl-backend-f1e8862591da.herokuapp.com/request-promotion', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          recruiterEmail,
          recruiterName,
          jobOfferId,
        }),
      });

      const data = await response.json();
      if (data.success) {
        alert('Promotion request sent successfully!');
      } else {
        alert('Failed to send promotion request.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while sending the promotion request.');
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <TextField label={t("Job Title")} value={jobTitle} onChange={e => setJobTitle(e.target.value)} />;
      case 1:
        return <LongTextField label={t("Role Description")} value={jobDescription} onChange={e => setJobDescription(e.target.value)} />;
      case 2:
        return <AddressField label={t("Office Address")} value={place} onChange={value => setPlace(value.label)} />;
      case 3:
        return (
          <SelectField
            label='Work Mode'
            options={["In Office", "Hybrid", "Remote"]}
            value={workMode}
            onChange={(e) => setWorkMode(e.target.value)}
          />
        );
      case 4:
        return (
          <ItemList
            label={t("Responsibilities")}
            items={responsibilities}
            setItems={setResponsibilities}
          />
        );
      case 5:
        return (
          <ItemList
            label={t("What We Offer")}
            items={offers}
            setItems={setOffers}
          />
        );
      case 6:
        return (
          <div>
            {renderRequirements()}
            <DropdownButton onAdd={handleAddRequirement} />
          </div>
        );
      case 7:
        return <QuestionList questions={questions} setQuestions={setQuestions} />;
      case 8:
        return (
          <div>
            <RangeSlider salaryRange={salaryRange} setSalaryRange={setSalaryRange} disabled={!isSalarySpecified} />
          </div>
        );
      case 9:
        if (subscription === "Free") {
          return (
            <div>
              <p>Your job offer has been created but will be posted on job boards automatically after you subscribe to the platform.</p>
            </div>
          );
        } else {
          return (
            <div>
              <p>Your job offer has been created and will be posted immediately on as many job boards as possible. To promote the job offer and receive up to 5x more applications, click the button below to send a promotion request.</p>
            </div>
          );
        }
      default:
        return <div />;
    }
  };

  const canProceedToNext = () => {
    if (currentStep === 0 && !jobTitle) return false;
    if (currentStep === 1 && !jobDescription) return false;
    return true;
  };

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(onClose, 500);
  };

  return (
    <div className='create-job-offer-overlay'>
      <button className='close-button-create' onClick={handleClose}>Cancel</button>

      <div className='create-job-offer-content'>
        <div className='create-job-offer-leftright'>
          <div className='create-job-offer-left'>
            <h1 className='create-job-offer-title'>{title}</h1>
            <p>{paragraph}</p>
          </div>
          <Divider type="vertical" style={{ height: '100%', backgroundColor: '#E0E0E0' }} />
          <div className='create-job-offer-right'>
            <TransitionGroup component={null}>
              <CSSTransition key={currentStep} timeout={300} classNames="slide">
                <div>{renderStepContent(currentStep)}</div>
              </CSSTransition>
            </TransitionGroup>
            <div className='navigation-buttons'>
              {currentStep > 0 && <button className='back-button-employer' onClick={previousStep}>{t('Back')}</button>}
              {currentStep < stepDetails.length - 1 && currentStep !== 8 && <button className='next-button-employer' onClick={nextStep} disabled={!canProceedToNext()}>{t('Next')}</button>}
              {currentStep === 8 && <button className='next-button-employer' onClick={handleSubmit}>{t('Complete')}</button>}
              {currentStep === 9 && subscription === "Free" && <button className='next-button-employer' onClick={onPlanUpgrade}>Subscribe Now</button>}
              {currentStep === 9 && subscription !== "Free" && (
                <>
                  <button className='next-button-employer' onClick={handlePromotionRequest}>Request Promotion</button>
                  <button className='next-button-employer' onClick={() => { navigator.clipboard.writeText(jobLink); alert("Link copied to clipboard!"); }}>Copy Apply Link</button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateJobOfferPage;
