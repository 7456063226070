import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom'; // Commented out as it's not currently used
import { useAuth0 } from '@auth0/auth0-react';

const PreRegistration = () => {
  // const navigate = useNavigate(); // Commented out as it's not currently used
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    // Extract 'role' query parameter from the URL
    const params = new URLSearchParams(window.location.search);
    const role = params.get('role');
    //console.log (`Extracted role from URL: ${role}`); // Log the extracted role

    // Store the role in sessionStorage if it exists
    if (role) {
      sessionStorage.setItem('signupRole', role);
      //console.log (`Stored '${role}' in sessionStorage under 'signupRole'.`); // Confirm storage
    } else {
      //console.log ("No 'role' query parameter found in URL.");
    }

    // Temporarily commenting out the redirect to observe the session storage behavior
    loginWithRedirect();

    // Logging the stored role immediately after setting it (for debugging purposes)
    const storedRole = sessionStorage.getItem('signupRole');
    //console.log (`Immediately retrieved stored role from sessionStorage: ${storedRole}`);
    
  }, [loginWithRedirect]); // Removed navigate from the dependency array as it's not used

  return null; // This page doesn't need to render anything
};

export default PreRegistration;
