import React from 'react';
import './SaveChanges.css';
import { useTranslation } from 'react-i18next';


const SaveChanges = ({ onClick, isModified }) => {
  const { t } = useTranslation();
  const buttonClass = isModified ? "save-changes-button modified" : "save-changes-button";
  return (
    <button className={buttonClass} onClick={onClick} disabled={!isModified}>
        {t('Save Changes')}
    </button>
  );
}

export default SaveChanges;
