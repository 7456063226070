import React, { useState } from 'react';
import './ExperienceForm.css';
import { Form, InputNumber, Checkbox, Card, Select, Tag, Input, Button } from 'antd';
import { JobExperienceCategories } from '../../../../categoriestags/JobExperienceCategories';
import { useTranslation } from 'react-i18next';
const { Option } = Select;
const { CheckableTag } = Tag;

const ExperienceForm = ({ onAdd, setVisible }) => {
  const { t } = useTranslation();
  const [years, setYears] = useState('');
  const [noYearsRequired, setNoYearsRequired] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [mandatory, setMandatory] = useState(true);
  let counter = 0;

  const generateUUID = () => {
    const timePart = Date.now().toString(36);
    const randomPart = Math.random().toString(36).substring(2, 15);
    const countPart = (counter++).toString(36);
    return `${timePart}-${randomPart}-${countPart}`;
  };

  const handleSubmit = () => {
    const requirement = {
      id: generateUUID(),
      requirementtype: "Experience",
      category: selectedCategory,
      years: noYearsRequired ? 0 : years,
      tags: selectedTags,
      mandatory: mandatory,
    };
    onAdd(requirement);
    setYears('');
    setSelectedTags([]);
    setSelectedCategory('');
    setNoYearsRequired(false);
    setSearchValue('');
    setMandatory(true);
    setVisible(null); // Hide the form after submission by setting it to null
  };

  const handleDropdownVisibleChange = (open) => {
    document.body.style.overflow = open ? 'hidden' : 'visible';
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setSelectedTags([]);
    setSearchValue('');
  };

  const handleTagSelect = (tag, checked) => {
    let nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
    if (nextSelectedTags.length > 5) {
      nextSelectedTags = nextSelectedTags.slice(0, 5);
    }
    setSelectedTags(nextSelectedTags);
  };

  const categories = JobExperienceCategories.map(category => (
    <Option value={category.name} key={category.name}>{t(category.name)}</Option>
  ));

  const tags = selectedCategory
    ? JobExperienceCategories
        .find(category => category.name === selectedCategory)
        .tags.filter(tag => tag.toLowerCase().includes(searchValue.toLowerCase()))
        .map(tag => (
          <CheckableTag
            key={tag}
            checked={selectedTags.indexOf(tag) > -1}
            onChange={checked => handleTagSelect(tag, checked)}
          >
            {t(tag)}
          </CheckableTag>
        ))
    : null;

  return (
    <div className='language-form'>
      <Form layout="vertical" onFinish={handleSubmit}>
        <h2 className='yearsexperience-title'>{t('Years of Experience')}</h2>
        <Form.Item>
          <InputNumber min={0} value={years} onChange={setYears} disabled={noYearsRequired} required={!noYearsRequired} />
          <Checkbox className='requirement-checkbox' checked={noYearsRequired} onChange={e => setNoYearsRequired(e.target.checked)}>{t('No years required')}</Checkbox>
        </Form.Item>
        <h2 className='category-title'>{t('Category')}</h2>
        <Form.Item>
          <Select placeholder={t('Select a Category')} className='category-select' onSelect={handleCategorySelect} onDropdownVisibleChange={handleDropdownVisibleChange}>
            {categories}
          </Select>
        </Form.Item>
        {selectedCategory && (
          <Card title={t('Tags')}>
            <Input
              placeholder={t('Search tags')}
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              style={{ marginBottom: '10px' }}
            />
            {tags}
          </Card>
        )}
        <Form.Item>
          <Button className='add-button-requirement' type="primary" htmlType="submit">{t('Add')}</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ExperienceForm;
