import React, { useState } from 'react';
import './DropdownButton.css';
import RequirementCard from './RequirementCard';
import ExperienceForm from './forms/ExperienceForm';
import AcademicForm from './forms/AcademicForm';
import LanguageForm from './forms/LanguageForm';
import { useTranslation } from 'react-i18next';

const DropdownButton = ({ onAdd }) => {
  const { t } = useTranslation();
  const requirements = [
    { type: 'Experience', displayName: t('Experience'), description: t('Specify job title and minimum years of experience') },
    { type: 'Academic', displayName: t('Academic'), description: t('Specify required academic qualification') },
    { type: 'Language', displayName: t('Language'), description: t('Specify a language required for the job')}
  ];
  const [selectedRequirement, setSelectedRequirement] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectRequirement = (type) => {
    setIsOpen(false);
    setSelectedRequirement(type);
  };

  const handleBack = () => {
    setSelectedRequirement(null);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`dropdown-button ${isOpen ? 'open' : ''}`}>
      <button className='requirements-dropdown-button' onClick={toggleDropdown}>{t('Add Requirement')}</button>
      {
        selectedRequirement ? (
          <>
            <button className='back-button' onClick={handleBack}>{t('Back')}</button>
            {{
              'Experience': <ExperienceForm onAdd={onAdd} setVisible={setSelectedRequirement} />,
              'Academic': <AcademicForm onAdd={onAdd} setVisible={setSelectedRequirement} />,
              'Language': <LanguageForm onAdd={onAdd} setVisible={setSelectedRequirement} />,
            }[selectedRequirement]}
          </>
        ) : isOpen && (
          <div className='dropdown-content'>
            {requirements.map(req => (
              <RequirementCard 
                key={req.type}
                type={req.displayName}
                description={req.description}
                onClick={() => handleSelectRequirement(req.type)}
              />
            ))}
          </div>
        )
      }
    </div>
  );
};

export default DropdownButton;
