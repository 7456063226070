import React from 'react';
import './SelectionField.css';
import { useTranslation } from 'react-i18next';

const SelectionField = ({ options, value, onChange, alignRight = false }) => {
  const { t } = useTranslation();

  return (
    <div className={`selection-field ${alignRight ? 'align-right' : ''}`}>
      {options.map(option => (
        <button 
          key={option} 
          className={`selection-button ${value.includes(option) ? 'selected' : ''}`}
          onClick={() => onChange(option)}
        >
          {t(option)}
        </button>
      ))}
    </div>
  );
};


export default SelectionField;
