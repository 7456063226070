import React from 'react';
import './LinkedInButton.css';
import LinkedinLogo from '../../images/LinkedinLogo.svg';

function LinkedInButton({ linkedinUrl }) {
  const handleRedirect = () => {
    window.open(linkedinUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <button className="linkedin-button" onClick={handleRedirect}>
      <img src={LinkedinLogo} alt="LinkedIn Logo" className="linkedin-svg"/>
    </button>
  );
}

export default LinkedInButton;
