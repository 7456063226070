import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSubscription } from '../../contexts/SubscriptionContext';


const FreeOverlay = () => {
  const { t } = useTranslation();
  const { subscription, stripeCustomerId } = useSubscription();


  const onPlanUpgrade = async () => {
    try {
      if (!stripeCustomerId) {
        throw new Error('Invalid customerID');
      }
  
      const response = await fetch('https://simpl-backend-f1e8862591da.herokuapp.com/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ stripeCustomerId })
      });
  
      const data = await response.json();
  
      if (response.ok) {
        if (data.url) {
          try {
            new URL(data.url);
            window.location.href = data.url;
          } catch (e) {
            console.error('Invalid URL in response:', data.url);
            alert('Received invalid URL from server.');
          }
        }
      } else {
        console.error('Error creating checkout session:', data);
        alert(`Error: ${data.message || 'Failed to create checkout session'}`);
      }
    } catch (error) {
      console.error('Error in onPlanUpgrade:', error);
      alert(`Error: ${error.message || 'An unexpected error occurred'}`);
    }
  };

  return (
    <div className='free-version-section'>
      <h2 className='free-version-title'>{t('You are using the free version of SimpL')}</h2>
      <p>{t("SimpL only has one plan, and it is the most competitive price you will find out there, that is why we can give little value for free: \n The job offers, applicants and data in general you see is all mock data to show you how the platform works and looks!")}</p>
      <div className='free-version-cta'>
        <button className='start-recruiting-button' onClick={onPlanUpgrade}>
          {t('Start Recruiting')}
        </button>
      </div>
    </div>
  );
};

export default FreeOverlay;
