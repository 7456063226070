import React, { useState, useEffect, useRef, createContext, useContext } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Divider } from 'antd';
import Checkmark from '../../../images/checkmark.svg';
import { useQuery, gql, useMutation } from '@apollo/client';
import Button from 'react-bootstrap/Button';
import Tooltip from '../../../components/tooltip/Tooltip';
import TextField from '../../../components/textfields/TextField';
import LongTextField from "../../../components/textfields/LongTextField";
import { useNavigate, useParams } from 'react-router-dom';
import Tag from '../../../components/tag/Tag';
import ResumeArrow from '../../../images/resume_parse_arrow.svg';
import PencilIcon from '../../../images/pencil_vector.svg';
import CloseIcon from '../../../images/x_vector.svg';
import { useTranslation } from 'react-i18next';
import { useApplicationState } from '../../../contexts/ApplicationStateContext';
import { JobExperienceCategories } from '../../../categoriestags/JobExperienceCategories';
import { AcademicCategories } from '../../../categoriestags/AcademicCategories';
import CatTagsSelection from '../../../components/textfields/CatTagsSelection';
import confetti from 'canvas-confetti';
import Hotjar from '@hotjar/browser'; // Import Hotjar
import './ApplyJobPage.css'

const CREATE_APPLICANT_AND_APPLICATION = gql`
mutation Mutation($profile: ProfileInput!, $academics: [AcademicInput], $jobexperiences: [JobExperienceInput], $applications: [ApplicationInput]) {
  createApplicant(profile: $profile, academics: $academics, jobexperiences: $jobexperiences, applications: $applications) {
    id
    profile {
      fullname
      email
      birthdate
      gender
      linkedinUrl
      profileImageUrl
      resumeUrl
      phone
      address
      languages {
        name
        level
      }
    }
    academics {
      school
      title
      startDate
      endDate
      category
      tags
    }
    jobexperiences {
      employer
      role
      description
      startDate
      endDate
      experience
      category
      tags
    }
    applications {
      jobOfferID
      status
      isQualified
      requirementsMet
      applicationDate
      resumeScore
      coverLetterScore
      answersScore
      answers {
        questionId
        text
      }
      coverLetter
    }
  }
}
`;


const GET_JOB_OFFER = gql`
query GetJobOffer($jobOfferId: ID!) {
    getJobOffer(id: $jobOfferId) {
        id
        userID
        recruiterID
        title
        description
        place
        workMode
        responsibilities
        offers
        salaryRange
        requirements{
          id
          requirementtype
          category
          tags
          years
        }
        state
        createdAt
        workHours
        questions {
          text
          id
          questiontype
          questionrequirement
          rejectIfNotMet
        }
    }
}`;

const GET_RECRUITER = gql`
query GetRecruiter($recruiterId: ID!) {
    getRecruiter(id: $recruiterId) {
        id
        userID
        company
        description
        email
        logoUrl
    }
}`;

const Icon = ({ name }) => {
  if (name === 'pencil') {
    return <img src={PencilIcon} alt="Edit" />;
  }
  if (name === 'close') {
    return <img src={CloseIcon} alt="Close" />;
  }
  return null;
};

const ResponsibilitiesView = ({ responsibilities }) => {
  return (
    <ul>
      {responsibilities.map((resp, index) => (
        <li key={index}>{resp}</li>
      ))}
    </ul>
  );
};
const RequirementView = ({ requirements }) => {
  // First, let's separate the language requirements from the others
  const languageRequirements = requirements.filter(req => req.requirementtype === 'Language');
  const otherRequirements = requirements.filter(req => req.requirementtype !== 'Language');

  // Function to render non-language requirement based on its type
  const renderRequirementContent = (requirement) => {
    switch (requirement.requirementtype) {
      case 'Experience':
        return (
          <>
            <p className='apply-page-requirement-item-text'>
            {requirement.years === 0 ? 'Experience with' : `${requirement.years} years of experience in`} <strong>{requirement.category}</strong> with
            </p>
            <div>{requirement.tags.map((tag, index) => <Tag key={index}>{tag}</Tag>)}</div>
          </>
        );
      case 'Academic':
        return (
          <>
            <p className='apply-page-requirement-item-text'>
              A <strong>{requirement.category}</strong> with
            </p>
            <div>{requirement.tags.map((tag, index) => <Tag key={index}>{tag}</Tag>)}</div>
          </>
        );
      default:
        return <p>Unknown requirement type</p>;
    }
  };

  // Render a single card for all language requirements
  const renderLanguageRequirement = (languageReqs) => {
    return (
      <>
        <p className='apply-page-requirement-item-text'>Knowledge of the following <strong>languages</strong></p>
        <div>{languageReqs.map((req, index) => <Tag key={index}>{req.category} ({req.tags[0]})</Tag>)}</div>
      </>
    );
  };

  return (
    <div className='apply-page-requirement-items'>
      {/* Render non-language requirements */}
      {otherRequirements.map((requirement, index) => (
        <div key={requirement.id} className={`apply-page-requirement-item ${index % 2 === 0 ? 'even' : 'odd'}`}>
          {renderRequirementContent(requirement)}
        </div>
      ))}
      {/* Render language requirements if there are any */}
      {languageRequirements.length > 0 && (
        <div className={`apply-page-requirement-item ${otherRequirements.length % 2 === 0 ? 'even' : 'odd'}`}>
          {renderLanguageRequirement(languageRequirements)}
        </div>
      )}
    </div>
  );
};
const QuestionsAndAnswers = ({ questions, setAnswers, setCoverLetter, answers, coverLetter }) => {
  const { jobId } = useParams();
  const { updateAnswersAndCover } = useApplicationState();

  // Save answers to localStorage
  useEffect(() => {
    if (answers.length > 0) {
      console.log(`Saving answers to localStorage: ${JSON.stringify(answers)}`);
      localStorage.setItem(`answers-${jobId}`, JSON.stringify(answers));
    }
  }, [answers, jobId]);

  // Save cover letter to localStorage
  useEffect(() => {
    if (coverLetter !== '') {
      console.log(`Saving coverLetter to localStorage: ${coverLetter}`);
      localStorage.setItem(`coverLetter-${jobId}`, coverLetter);
    }
  }, [coverLetter, jobId]);

  // Initialize answers based on questions
  useEffect(() => {
    const savedAnswers = localStorage.getItem(`answers-${jobId}`);
    const savedCoverLetter = localStorage.getItem(`coverLetter-${jobId}`);

    console.log(`Retrieved savedAnswers from localStorage: ${savedAnswers}`);
    console.log(`Retrieved savedCoverLetter from localStorage: ${savedCoverLetter}`);

    if (savedAnswers && savedAnswers !== '[]') {
      setAnswers(JSON.parse(savedAnswers));
    } else if (questions && questions.length > 0) {
      const initialAnswers = questions.map(q => ({ questionId: q.id, text: '' }));
      console.log('Initializing answers:', initialAnswers);
      setAnswers(initialAnswers);
    }

    if (savedCoverLetter) {
      setCoverLetter(savedCoverLetter);
    }
  }, [setAnswers, setCoverLetter, jobId, questions]);

  const handleAnswerChange = (questionId, newText) => {
    const newAnswers = answers.map(a =>
      a.questionId === questionId ? { ...a, text: newText } : a
    );
    setAnswers(newAnswers);
  };

  const handleSaveAnswer = (questionId) => {
    const updatedAnswers = answers.map(a =>
      a.questionId === questionId ? { ...a, text: a.text.trim() } : a
    );
    setAnswers(updatedAnswers);
    updateAnswersAndCover(updatedAnswers, coverLetter, jobId);
  };

  const handleCoverLetterChange = (newText) => {
    setCoverLetter(newText);
  };

  const handleSaveCoverLetter = () => {
    updateAnswersAndCover(answers, coverLetter.trim(), jobId);
  };

  const renderInputField = (question) => {
  const answer = answers.find(a => a.questionId === question.id)?.text || '';

  if (question.questiontype === 'Numbers') {
    return (
      <input
        type="number"
        className="answer-input numbers-input"
        value={answer}
        onChange={(e) => handleAnswerChange(question.id, e.target.value)}
        onBlur={() => handleSaveAnswer(question.id)}
      />
    );
  } else if (question.questiontype === 'Yes/No') {
    return (
      <select
        className="answer-input yes-no-input"
        value={answer}
        onChange={(e) => handleAnswerChange(question.id, e.target.value)}
        onBlur={() => handleSaveAnswer(question.id)}
      >
        <option value="">Select</option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
    );
  } else {
    return (
      <textarea
        className="answer-input text-input"
        value={answer}
        onChange={(e) => handleAnswerChange(question.id, e.target.value)}
        onBlur={() => handleSaveAnswer(question.id)}
      />
    );
  }
};


  return (
    <div className='apply-page-questions'>
      {questions.map((question, index) => (
        <div key={question.id} className="question-and-answer">
          <h3 className="question-text">{question.text}</h3>
          <div className={`input-container ${index % 2 === 0 ? 'even' : 'odd'}`}>
            {renderInputField(question)}
          </div>
        </div>
      ))}
      <div className="question-and-answer">
        <h2 className="question-text">Cover Letter</h2>
        <textarea
          className="answer-input text-input"
          value={coverLetter}
          onChange={(e) => handleCoverLetterChange(e.target.value)}
          onBlur={handleSaveCoverLetter}
        />
      </div>
    </div>
  );
};

export async function generateStandardizedResume(applicantData) {
  const { profile, jobexperiences, academics } = applicantData;
  return `
Name: ${profile.fullname}
Email: ${profile.email}
Birthdate: ${profile.birthdate || ""}
Gender: ${profile.gender}
Phone: ${profile.phone}
Address: ${profile.address}
LinkedIn: ${profile.linkedinUrl}
Languages: ${profile.languages.map(lang => `${lang.name}: ${lang.level}`).join(', ')}

Job Experiences:
${jobexperiences.map(exp => `
Role: ${exp.role}
Employer: ${exp.employer}
Start Date: ${exp.startDate}
End Date: ${exp.endDate}
Description: ${exp.description}
Tags: ${exp.tags.join(', ')}
`).join('\n')}

Academics:
${academics.map(edu => `
Title: ${edu.title}
School: ${edu.school}
Start Date: ${edu.startDate}
End Date: ${edu.endDate}
Tags: ${edu.tags.join(', ')}
`).join('\n')}
`;
}

export async function generateAnswers(answers, questions) {
  return questions.map((question, index) => `
  Question: ${question.text}
  Answer: ${answers[index]?.text || ""}
  `).join('\n');
}

export async function generateJobDescription(jobOfferData) {
  const { title, description, requirements } = jobOfferData;
  return `
  Company and Job Offer Description and Requirements:
  Title: ${title}
  Description: ${description}
  Requirements: ${requirements.map(req => `${req.requirementtype}: ${req.category} (${req.tags.join(', ')})`).join('\n')}
  `;
}




const InfoCard = ({ userData }) => {
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState(userData);
  const { updateProfile } = useApplicationState(); // Use context to update the profile

  useEffect(() => {
    setData(userData); // Update local state when userData changes
  }, [userData]); // Depend on userData to trigger this effect

  const isEmpty = !data.email || !data.phone || !data.fullname; // Data validation check

  const handleEditMode = () => {
    setEditMode(!editMode);
  };


  const calculateAge = (birthdate) => {
    // Ensure the birthdate is in 'yyyy-mm-dd' format
    if (!birthdate) return 0; // Return 0 if birthdate is not provided or invalid
  
    // Split the date to get year, month, and day components
    const [year, month, day] = birthdate.split('-').map(Number); // Convert strings to numbers
    const birthDate = new Date(year, month - 1, day); // Create a date object; months are 0-indexed in JavaScript
    const today = new Date(); // Current date
  
    let age = today.getFullYear() - birthDate.getFullYear(); // Calculate preliminary age
    const m = today.getMonth() - birthDate.getMonth();
    
    // Adjust age if the current month and day have not yet reached the month and day of birth
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
  
    return age; // Return the calculated age
  };
  
  

  
  const handleChange = (key, value) => {
    setData({ ...data, [key]: value });
  };

  const handleSave = () => {
    if (!isEmpty) {
      updateProfile(data); // Update context with the edited profile
      setEditMode(false); // Exit edit mode
    } else {
      console.error('Validation failed, missing required information');
    }
  };

  


  return (
    <div className='info-card-whole'>
      <div className={`info-card ${editMode ? 'expanded' : 'collapsed'}  ${isEmpty ? 'input-error' : ''}`}>
        {editMode ? (
          <>
            <TextField
              label="Phone"
              type="text"
              placeholder="Your phone number"
              value={data.phone}
              onChange={(e) => handleChange('phone', e.target.value)}
            />
            <TextField
              label="Email"
              type="email"
              placeholder="Your email address"
              value={data.email}
              onChange={(e) => handleChange('email', e.target.value)}
            />
            <TextField
              label="Birth Date"
              type="date"
              value={data.birthdate || ''}
              onChange={(e) => handleChange('birthdate', e.target.value)}
            />
            <TextField
              label="Gender"
              type="text"
              placeholder="Your gender"
              value={data.gender}
              onChange={(e) => handleChange('gender', e.target.value)}
            />
            <button className='save-button' onClick={handleSave}>Apply Changes</button>
          </>
        ) : (
          <>
            <p>Phone: {data.phone || 'Not provided'}</p>
            <p>Email: {data.email || 'Not provided'}</p>
            <p>Age: {data.birthdate ? calculateAge(data.birthdate) + ' years' : 'No birthdate provided'}</p>
            <p>Gender: {data.gender || 'Not provided'}</p>
          </>
        )}

      </div>
      <button className='edit-button' onClick={handleEditMode}>
        {editMode ? <Icon name='close' /> : <Icon name='pencil' />}
      </button>
    </div>
  );
};

const CredentialEdit = ({ credential, onSave, isEditMode, credentialType, onDelete }) => {

  const { t } = useTranslation();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [data, setData] = useState(credential);
  
  const [editMode, setEditMode] = useState(isEditMode || !data.category); // Enter edit mode if category is absent
    const [selectedCategory, setSelectedCategory] = useState(data.category);
  const [selectedTags, setSelectedTags] = useState(data.tags || []);

  //console.log ('Initial data set from credential:', data);  // Log initial data setting
  //console.log ('Initial selected tags:', selectedTags);  // Log initial selected tags


  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setSelectedTags([]);
  };

  const handleTagChange = (tag, checked) => {
    setSelectedTags(prevTags => {
        const tagExists = prevTags.includes(tag);
        let newTags;
        if (checked && !tagExists) {
            newTags = [...prevTags, tag];
        } else if (!checked) {
            newTags = prevTags.filter(t => t !== tag);
        } else {
            newTags = prevTags;
        }
        return newTags;
    });
};

  // Ensuring that `data.tags` is updated before rendering
  useEffect(() => {
    setData(prevData => ({ ...prevData, tags: selectedTags }));
  }, [selectedTags]); // Depend on selectedTags to trigger update



const [isEndDatePresent, setIsEndDatePresent] = useState(data.endDate === '2100-01-01');

// Adjust handleChange to handle the present checkbox toggle
const handlePresentToggle = (checked) => {
  setIsEndDatePresent(checked);
  if (checked) {
    // If 'Present' is checked, set endDate to a default value to represent 'Present'
    setData({ ...data, endDate: '2100-01-01' });
  } else {
    // If unchecked, clear the endDate
    setData({ ...data, endDate: '' });
  }
};


const handleChange = (key, value) => {
  
    setData({ ...data, [key]: value });
  
};



const handleSave = () => {


  onSave({
    ...data,
    id: credential.id, // Make sure to include the original credential's ID
    category: selectedCategory,
    tags: selectedTags,
    startDate: data.startDate,
    endDate: isEndDatePresent ? '2100-01-01' : data.endDate,
    school: data.school,
    employer: data.employer,
    role: data.role,
    description: data.description,
  });
  setEditMode(false);
};


  const handleDeleteClick = () => {
    if (confirmDelete) {
      onDelete(credential.id); // Perform the deletion
      setConfirmDelete(false); // Reset the confirmation state
    } else {
      setConfirmDelete(true); // Set to request confirmation
      // Optional: Automatically reset confirmation after a delay
      setTimeout(() => setConfirmDelete(false), 5000);
    }
  };
  
  const deleteButtonClass = confirmDelete ? "confirm-delete-button" : "delete-button";

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('default', { month: 'short', year: 'numeric' });
  };
  
  
  return (
    <div className="credential-card-whole">
      <div className={`credential-card ${isEditMode ? 'expanded' : 'collapsed'}`}>
        {editMode ? (
          <>
            {credentialType === 'academics' && (
              <>
                <TextField 
                  label="School"
                  type="text"
                  placeholder="School name"
                  value={data.school}
                  onChange={(e) => handleChange('school', e.target.value)}
                />
                <TextField
                  label="Title"
                  type="text"
                  placeholder="Degree title"
                  value={data.title}
                  onChange={(e) => handleChange('title', e.target.value)}
                />
                {/* Add fields specific to academic credentials */}
              </>
            )}
            {credentialType === 'jobexperiences' && (
              <>
                <TextField
                  label="Employer"
                  type="text"
                  placeholder="Employer name"
                  value={data.employer}
                  onChange={(e) => handleChange('employer', e.target.value)}
                />
                <TextField
                  label="Role"
                  type="text"
                  placeholder="Your role"
                  value={data.role}
                  onChange={(e) => handleChange('role', e.target.value)}
                />
                <LongTextField
                  label="Description"
                  placeholder="Role description"
                  value={data.description}
                  onChange={(e) => handleChange('description', e.target.value)}
                />
                {/* Add fields specific to job experience credentials */}
              </>
            )}
            <TextField
              label="Start Date"
              type="date"
              placeholder="Start date"
              value={data.startDate || ''}
              onChange={(e) => handleChange('startDate', e.target.value)}
            />

<div className="credential-end-date">
            <TextField
              label="End Date"
              type="date"
              placeholder="End date"
              value={isEndDatePresent ? 'Present' : data.endDate || ''}
        onChange={(e) => handleChange('endDate', e.target.value)}
        disabled={isEndDatePresent} // Disable field if 'Present' is checked
        style={{ color: isEndDatePresent ? 'grey' : 'inherit' }} // Grey out the text if 'Present' is checked
            />
            <label>
              <input
                className='present-checkbox'
                type="checkbox"
                checked={isEndDatePresent}
                onChange={(e) => handlePresentToggle(e.target.checked)}
              />
              Present
            </label>
          </div>

            <CatTagsSelection
              categoriesAndTags={credentialType === 'jobexperiences' ? JobExperienceCategories : AcademicCategories}
              maxTags={5}
              onCategorySelect={handleCategoryChange}
              onTagsSelect={handleTagChange}
              selectedCategory={selectedCategory}
              selectedTags={selectedTags}
            />

            <div className="credential-card-buttons">
            <button className='save-button' onClick={() => handleSave()}>Apply Changes</button>
            <button
  className={confirmDelete ? "confirm-delete-button" : "delete-button"}
  onClick={handleDeleteClick}
>
  {confirmDelete ? 'Are you sure?' : 'Delete'}
</button>


            </div>
          </>
        ) : (
          <>
            <div className="credential-title">{data.title || data.role}</div>
            <div className="credential-school">{data.school || data.employer}</div>
<div className="credential-dates">
{formatDate(data.startDate)} to {data.endDate === '2100-01-01' ? 'Present' : formatDate(data.endDate)}
</div>

<div className="credential-tags">
  {data.tags && data.tags.length > 0 ? data.tags.map((tag, index) => {
      return <Tag key={tag}>{t(tag)}</Tag>;
  }) : console.log ('No tags to render or tags array is empty')}
</div>

          </>
        )}

      </div>
      <button className="edit-button" onClick={() => {
  setEditMode(!editMode);  // Toggle edit mode
}}>
  {editMode ? <Icon name='close' /> : <Icon name='pencil' />}
</button>

    </div>
  );
};



const CredentialSectionEdit = ({
  className,
  title,
  credentials,
  onClickAdd,
  type,
  handleSaveCredential,
  removeCredential,
}) => {

  const { t } = useTranslation();

  return (
    <div className={`section ${className}`}>
      <div className="section-header">
        <h2 className='title'>{title}</h2>
      </div>
      <Divider />
      {credentials.map(credential => (
        <CredentialEdit
          key={credential.id}
          credential={credential}
          onSave={handleSaveCredential}
          credentialType={type}
          onDelete={removeCredential}
        />
      ))}

      <button className='apply-page-add-credential' onClick={onClickAdd}>Add {type}</button>

    </div>
  );
};


const ThankYouOverlay = ({ setShowThankYouOverlay }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (canvasRef.current) {
      const myConfetti = confetti.create(canvasRef.current, { resize: true });
  
      const intervalId = setInterval(() => {
        myConfetti({
          angle: 90,
          spread: 100,
          startVelocity: 40,
          elementCount: 150,
          decay: 0.8,
          colors: ['#D2FF2F', '#C92FFF', '#2FE6FF'],
          origin: { y: 0.4 } // Makes sure the confetti comes from the top-middle of the canvas
        });
      }, 2000); // Adjust the timing for continuous effect
  
      return () => {
        clearInterval(intervalId);
        myConfetti.reset();
      };
    }
  }, []);
  

  return (
    <div className="thank-you-overlay">
      <div className="thank-you-message">
        <h3>Thank you for applying!</h3>
        <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, width: '100%', height: '100%' }}></canvas>
      </div>
    </div>
  );
};


const ApplyJobPage = () => {
  const navigate = useNavigate();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { jobId } = useParams();
  
  const [jobOfferData, setJobOfferData] = useState(null);
  const [recruiterData, setRecruiterData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState([]);
  const [existingApplications, setExistingApplications] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [coverLetter, setCoverLetter] = useState("");
  const [showThankYouOverlay, setShowThankYouOverlay] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('idle');
  const [resumeUrl, setResumeUrl] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [isParsing, setIsParsing] = useState(false);
  const [isResumeParsed, setIsResumeParsed] = useState(false);
  const [linkedinUrl, setLinkedinUrl] = useState(() => {
    const savedLinkedinUrl = localStorage.getItem('linkedinUrl');
    console.log(`Retrieved LinkedIn URL from localStorage: ${savedLinkedinUrl}`);
    return savedLinkedinUrl || "";
  });
    const [userData, setUserData] = useState({
    fullname: '',
    email: '',
    birthdate: '',
    gender: '',
    phone: '',
    address: '',
    linkedinUrl: '',
    resumeUrl: '',
    profileImageUrl: '',
  });

  const {
    applicantData,
    setApplicantData,
    clearApplicantData,
    updateProfile,
    addCredential,
    updateCredential,
    removeCredential,
    addOrUpdateApplication,
  } = useApplicationState();

  const siteId = 5005170;
  const hotjarVersion = 6;
  
  Hotjar.init(siteId, hotjarVersion);

  const [createApplicantAndApplication, { dataApplicant, loadingApplicant, errorApplicant }] = useMutation(CREATE_APPLICANT_AND_APPLICATION);

  const { loading: loadingJobOffer, error: jobOfferError, data: jobOfferRawData } = useQuery(GET_JOB_OFFER, {
    variables: { jobOfferId: jobId },
    skip: !jobId,
    onError: (error) => {
      console.error('Job Offer Error Details:', error);
      console.error('GraphQL Errors:', error.graphQLErrors);
      console.error('Network Error:', error.networkError);
    }
  });

  const { loading: loadingRecruiter, error: recruiterError, data: recruiterRawData } = useQuery(GET_RECRUITER, {
    variables: { recruiterId: jobOfferData?.recruiterID },
    skip: !jobOfferData,
    onError: (error) => {
      console.error('Recruiter Error Details:', error);
      console.error('GraphQL Errors:', error.graphQLErrors);
      console.error('Network Error:', error.networkError);
    }
  });

  const convertDateFormat = (dateString) => {
    if (!dateString || dateString === '') return null;
    
    // Remove any "undefined-" prefix
    dateString = dateString.replace('undefined-', '');
    
    // Check if the date is already in ISO format
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      return dateString;
    }
    
    const [month, year] = dateString.split('/');
    if (month && year) {
      return `${year}-${month.padStart(2, '0')}-01`;
    } else {
      console.error('Invalid date format:', dateString);
      return null;
    }
  };

  useEffect(() => {
    if (jobOfferRawData) {
      setJobOfferData(jobOfferRawData.getJobOffer);
      //console.log ('Job Offer Data:', jobOfferRawData.getJobOffer);
    }
  }, [jobOfferRawData]);

  useEffect(() => {
    if (recruiterRawData) {
      setRecruiterData(recruiterRawData.getRecruiter);
      //console.log ('Recruiter Data:', recruiterRawData.getRecruiter);
    }
  }, [recruiterRawData]);

  useEffect(() => {
    if (jobOfferData && jobOfferData.questions) {
      setAnswers(jobOfferData.questions.map(question => ({ questionId: question.id, text: '' })));
      //console.log ('Initialized answers from jobOfferData:', jobOfferData.questions.map(question => ({ questionId: question.id, text: '' })));
    }
  }, [jobOfferData]);

  useEffect(() => {
    if (linkedinUrl !== '') {
      console.log(`Saving LinkedIn URL to localStorage: ${linkedinUrl}`);
      localStorage.setItem('linkedinUrl', linkedinUrl);
    }
  }, [linkedinUrl]);

  const handleSaveUserData = (newData) => {
    setUserData(newData);
    //console.log ('User data updated:', newData);
  };

  

  const onAnswersUpdate = (newAnswers) => {
    setAnswers(newAnswers);
    //console.log ('onAnswersUpdate - Answers updated:', newAnswers);
  };

  const calculateYearsOfExperience = (startDate, endDate) => {
    try {
      const end = endDate === '2100-01-01' ? new Date().toISOString().slice(0, 10) : endDate;
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(end);
  
      if (isNaN(startDateObj.getTime()) || isNaN(endDateObj.getTime())) {
        console.error('Invalid date format:', { startDate, endDate });
        return 0;
      }
  
      const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365.25;
      const experience = (endDateObj - startDateObj) / millisecondsPerYear;
  
      return Math.ceil(Math.max(experience, 0));
    } catch (err) {
      console.error('Error calculating experience:', err);
      return 0;
    }
  };

  const addCredentialfunc = (type) => {
    const typeMapping = {
      'Experience': 'jobexperiences',
      'Education': 'academics'
    };
  
    const mappedType = typeMapping[type] || type;
  
    let newCredential;
  
    if (mappedType === 'jobexperiences') {
      newCredential = {
        role: '',
        employer: '',
        startDate: '',
        endDate: '',
        description: '',
        tags: [],
        id: `${mappedType}-${Date.now()}`
      };
    } else if (mappedType === 'academics') {
      newCredential = {
        title: '',
        school: '',
        startDate: '',
        endDate: '',
        tags: [],
        id: `${mappedType}-${Date.now()}`
      };
    } else {
      newCredential = {
        title: '',
        school: '',
        startDate: '',
        endDate: '',
        description: '',
        tags: [],
        id: `${mappedType}-${Date.now()}`
      };
    }
  
    addCredential(mappedType, newCredential);
  
    setCredentials(currentCredentials => [...currentCredentials, newCredential]);
    //console.log ('Added new credential:', newCredential);
  };
  
  const handleSaveCredential = (type, updatedCredential) => {
    const typeMapping = {
      'Experience': 'jobexperiences',
      'Education': 'academics'
    };
  
    const mappedType = typeMapping[type] || type;
    
    const convertedCredential = {
      ...updatedCredential,
      startDate: convertDateFormat(updatedCredential.startDate),
      endDate: updatedCredential.endDate === '2100-01-01' ? updatedCredential.endDate : convertDateFormat(updatedCredential.endDate)
    };
    
    updateCredential(mappedType, convertedCredential);
  };
  

  useEffect(() => {
    if (jobOfferData && jobOfferData.questions) {
      const initialAnswers = jobOfferData.questions.map(question => ({ questionId: question.id, text: '' }));
      setAnswers(initialAnswers);
      //console.log ('useEffect - Initialized answers from jobOfferData:', initialAnswers);
    }
  }, [jobOfferData]);

  useEffect(() => {
    const interval = setInterval(() => {
      //console.log ("ApplicantData:", applicantData);
    }, 30000);

    return () => clearInterval(interval);
  }, [applicantData]);

  const handleRemoveCredential = (type, credentialId) => {
    removeCredential(type, credentialId);
    //console.log ('Removed credential:', { type, credentialId });
  };

  const checkRequirements = (applicantData, jobRequirements) => {
    const requirementsMet = [];

    jobRequirements.forEach(requirement => {
        let isMet = false;

        switch (requirement.requirementtype) {
            case 'Experience':
                const totalExperience = applicantData.jobexperiences.reduce((total, je) => {
                    if (je.category === requirement.category &&
                        (requirement.tags.length === 0 || requirement.tags.every(tag => je.tags.includes(tag)))) {
                        return total + je.experience;
                    }
                    return total;
                }, 0);

                if (requirement.years === 0) {
                    // Check if there's at least one matching experience entry
                    isMet = applicantData.jobexperiences.some(je => 
                        je.category === requirement.category &&
                        (requirement.tags.length === 0 || requirement.tags.every(tag => je.tags.includes(tag)))
                    );
                } else {
                    isMet = totalExperience >= requirement.years;
                }
                break;

            case 'Academic':
                isMet = applicantData.academics.some(ac => {
                    const match = ac.category === requirement.category &&
                                  (requirement.tags.length === 0 || requirement.tags.every(tag => ac.tags.includes(tag)));
                    return match;
                });
                break;

            case 'Language':
                isMet = applicantData.profile.languages.some(lang => {
                    const desiredLevel = parseInt(requirement.tags[0], 10);  // Assuming the level is stored as a string in requirement.tags[0]
                    const match = lang.name === requirement.category && lang.level >= desiredLevel;
                    return match;
                });
                break;

            default:
                break;
        }

        if (isMet) {
            requirementsMet.push(requirement.id);
        }
    });

    return requirementsMet;
};


  
  useEffect(() => {
    //console.log ('Answers updated:', answers);
  }, [answers]);

  useEffect(() => {
    //console.log ('Cover Letter updated:', coverLetter);
  }, [coverLetter]);

  const parsingLockRef = useRef(false);
  const handleResumeSubmit = async (resumeUrl) => {
    if (parsingLockRef.current || isResumeParsed) return;
  
    parsingLockRef.current = true;
    setIsParsing(true);
  
    let credentialCounter = 0;
  
    try {
      const response = await fetch('https://simpl-backend-f1e8862591da.herokuapp.com/process-resume', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ resumeUrl })
      });
  
      if (!response.ok) throw new Error('Failed to parse resume');
      const { jobId } = await response.json();
      console.log('Job ID:', jobId); // Log the job ID
  
      // Polling function to check job status
      const checkJobStatus = async () => {
        const statusResponse = await fetch(`https://simpl-backend-f1e8862591da.herokuapp.com/job-status/${jobId}`);
        const statusData = await statusResponse.json();
  
        console.log('Job Status:', statusData); // Log job status
  
        if (statusData.state === 'completed') {
          const parsedData = statusData.result;
  
          // Ensure parsedData has the expected structure
          if (!parsedData || !parsedData.profile || !parsedData.profile.applicant) {
            throw new Error('Invalid response format');
          }
  
          const generateId = () => {
            const datePart = new Date().getTime();
            const counterPart = credentialCounter++;
            return `id-${datePart}-${counterPart}`;
          };
  
          const languages = (parsedData.profile.applicant.languages?.items || []).map(lang => ({
            name: lang.language.name,
            level: lang.language.level
          }));
  
          const updatedProfile = {
            ...parsedData.profile.applicant,
            languages: languages,
            linkedinUrl: linkedinUrl,  // Add the LinkedIn URL here
            resumeUrl: resumeUrl, // Add resumeUrl here
          };
  
          const updatedData = {
            profile: updatedProfile,
            academics: parsedData.profile.academics?.items.map(item => ({
              ...item.academic,
              tags: item.academic.tags || [],
              id: generateId(),
            })) || [],
            jobexperiences: parsedData.profile.jobexperiences?.items.map(item => ({
              ...item.jobexperience,
              tags: item.jobexperience.tags || [],
              id: generateId(),
            })) || [],
            applications: []
          };
  
          setApplicantData(updatedData);
  
          setIsResumeParsed(true);
          setUploadStatus('completed');
          setIsParsing(false);
          parsingLockRef.current = false;
        } else if (statusData.state === 'failed') {
          throw new Error('Resume processing failed');
        } else {
          setTimeout(checkJobStatus, 5000); // Poll every 5 seconds
        }
      };
  
      checkJobStatus();
    } catch (error) {
      console.error('Error submitting resume:', error);
      setUploadStatus('idle');
      setIsParsing(false);
      parsingLockRef.current = false;
    }
  };
  
  const checkQuestionRequirements = (answers, questions) => {
    let isRejected = false;
  
    questions.forEach(question => {
      const answer = answers.find(a => a.questionId === question.id)?.text;
      
      if (question.questionrequirement) {
        if (question.questiontype === 'Numbers') {
          if (answer && parseFloat(answer) < parseFloat(question.questionrequirement)) {
            isRejected = question.rejectIfNotMet;
          }
        } else if (question.questiontype === 'Yes/No') {
          if (answer && answer !== question.questionrequirement) {
            isRejected = question.rejectIfNotMet;
          }
        }
      }
    });
  
    return isRejected;
  };
  
  

  useEffect(() => {
    if (resumeUrl && !isResumeParsed) {
      handleResumeSubmit(resumeUrl);
    }
  }, [resumeUrl, isResumeParsed]);


  const handleApply = async () => {
    setLoading(true);
    try {
      console.log('handleApply - Starting application process.');
  
      const linkedInUrl = applicantData.profile.linkedinUrl;
      let profileImageUrl = applicantData.profile.profileImageUrl;
  
      if (linkedInUrl) {
        try {
          profileImageUrl = await fetchAndUploadProfileImage(linkedInUrl);
          setProfileImageUrl(profileImageUrl); // Update the state
        } catch (error) {
          console.warn('Failed to fetch or upload profile image:', error);
          // Continue without the profile image
        }
      }
  
      const updatedJobExperiences = applicantData.jobexperiences.map(jobExp => {
        const startDate = convertDateFormat(jobExp.startDate);
        const endDate = jobExp.endDate === '2100-01-01' ? jobExp.endDate : convertDateFormat(jobExp.endDate);
        const calculatedExperience = calculateYearsOfExperience(startDate, endDate);
        return {
          ...jobExp,
          experience: calculatedExperience,
          startDate,
          endDate,
        };
      });
  
      const updatedAcademics = applicantData.academics.map(academic => ({
        ...academic,
        startDate: convertDateFormat(academic.startDate),
        endDate: convertDateFormat(academic.endDate),
      }));
  
      // Update applicantData directly
      const updatedApplicantData = {
        ...applicantData,
        jobexperiences: updatedJobExperiences,
        academics: updatedAcademics,
        profile: {
          ...applicantData.profile,
          profileImageUrl: profileImageUrl || null,
          resumeUrl: resumeUrl,
          birthdate: convertDateFormat(applicantData.profile.birthdate),
        }
      };
  
      const requirementsMet = checkRequirements(updatedApplicantData, jobOfferData.requirements);
  
      const isRejected = checkQuestionRequirements(answers, jobOfferData.questions);
  
      const applicationData = {
        jobOfferID: jobId,
        answers: answers,
        coverLetter: coverLetter,
        requirementsMet: requirementsMet,
        status: isRejected ? 'Rejected' : 'Applied',
        resumeScore: 0,
        coverLetterScore: 0,
        answersScore: 0
      };
  
      const standardizedResume = await generateStandardizedResume(updatedApplicantData);
      const jobAnswers = await generateAnswers(answers, jobOfferData.questions);
      const jobDescription = await generateJobDescription(jobOfferData);
  
      const scoringData = {
        standardizedResume,
        coverLetter: coverLetter,
        answers: jobAnswers,
        jobDescription
      };
  
      const response = await fetch('https://simpl-backend-f1e8862591da.herokuapp.com/score-application', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(scoringData)
      });
  
      if (!response.ok) throw new Error('Failed to score application');
      const scores = await response.json();
  
      applicationData.resumeScore = scores.resumeScore;
      applicationData.coverLetterScore = scores.coverLetterScore;
      applicationData.answersScore = scores.answersScore;
  
      await createApplicantAndApplication({
        variables: {
          profile: updatedApplicantData.profile,
          academics: updatedApplicantData.academics,
          jobexperiences: updatedApplicantData.jobexperiences,
          applications: [applicationData],
        },
      });
  
      await sendConfirmationEmail(updatedApplicantData.profile.email, updatedApplicantData.profile.fullname, jobOfferData.title);
  
      setShowThankYouOverlay(true);
      clearApplicantData();
      setTimeout(() => {
        window.location.href = 'https://thesimplplatform.io';
      }, 5000); // Redirect after 5 seconds
    } catch (error) {
      console.error("Failed to apply:", error);
      // Optionally, show an error message to the user
      alert("There was an error submitting your application. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  



  
  const sendConfirmationEmail = async (applicantEmail, applicantName, jobTitle) => {
    try {
      const response = await fetch('https://simpl-backend-f1e8862591da.herokuapp.com/send-application-confirmation-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          applicantEmail,
          applicantName,
          jobTitle,
        }),
      });
  
      const data = await response.json();
      if (data.success) {
        console.log('Confirmation email sent successfully');
      } else {
        console.error('Error sending confirmation email:', data.error);
        alert('Error sending confirmation email');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file || file.size > 10485760) {
      alert('File size should not exceed 10MB');
      return;
    }
  
    try {
      setUploadStatus('uploading');
      const formData = new FormData();
      formData.append("file", file);
  
      const response = await fetch('https://simpl-backend-f1e8862591da.herokuapp.com/upload', {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) throw new Error('Failed to upload resume');
      const result = await response.json();
      const resumeUrl = result.imageUrl;
  
      // Store resumeUrl in a state variable that persists through parsing
      setResumeUrl(resumeUrl);
  
      handleResumeSubmit(resumeUrl);
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Failed to upload resume. Please try again.');
      setUploadStatus('idle');
    }
  };
  
  const fetchAndUploadProfileImage = async (linkedInUrl) => {
    try {
        const fetchImageResponse = await fetch('https://simpl-backend-f1e8862591da.herokuapp.com/fetch-linkedin-image', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ linkedinUrl: linkedInUrl })
        });

        if (fetchImageResponse.ok) {
            const imageBlob = await fetchImageResponse.blob();
            const imageFile = new File([imageBlob], 'profile-image.jpg', { type: imageBlob.type });

            const formData = new FormData();
            formData.append('file', imageFile);

            const uploadResponse = await fetch('https://simpl-backend-f1e8862591da.herokuapp.com/upload', {
                method: 'POST',
                body: formData
            });

            if (!uploadResponse.ok) throw new Error('Failed to upload profile image');
            const result = await uploadResponse.json();
            const profileImageUrl = result.imageUrl;
            console.log('Profile Image Url:', profileImageUrl);
            return profileImageUrl;
        } else {
            console.warn('Failed to fetch LinkedIn profile image');
            return null;
        }

    } catch (error) {
        console.error('Error handling LinkedIn profile image:', error);
        return null;
    }
};


  
  
  
  

  

  return (
    <div className='apply-page'>
      {showThankYouOverlay && <ThankYouOverlay />}
      <div className='apply-content'>
        <div className='apply-content-1'>
          <div className='apply-page-company'>
            <div className='apply-page-company-logo-name'>
              <img src={recruiterData?.logoUrl || 'https://via.placeholder.com/80'} alt='company-logo' className='company-logo'/>
              <h3 className='apply-page-company-name'>{recruiterData?.company || 'Company Name'}</h3>
            </div>
            <p>{recruiterData?.description}</p>
          </div>
          <div className='apply-page-job-info'>
            <div className='apply-page-job-info-title-description'>
              <h1>{jobOfferData?.title}</h1>
              <h3 className='apply-page-place'>{jobOfferData?.place || 'Location'}</h3>
              <p>{jobOfferData?.description}</p>
            </div>
            <div className='apply-page-salary-work-type'>
              <div>
                <h3>Salary Range</h3>
                <p className='apply-page-salary'>{jobOfferData?.salaryRange ? `€${jobOfferData.salaryRange[0]} - €${jobOfferData.salaryRange[1]}` : 'N/A'}</p>
              </div>
              <div>
                <h3>Work Type</h3>
                <p className='apply-page-salary'>{jobOfferData?.workMode || 'N/A'}</p>
              </div>
            </div>
            <div className='apply-page-respo-what'>
              <div className='apply-page-respo'>
                <h3>Responsibilities</h3>
                <ResponsibilitiesView responsibilities={jobOfferData?.responsibilities || []} />
              </div>
              <div className='apply-page-respo'>
                <h3>What We Offer</h3>
                <ResponsibilitiesView responsibilities={jobOfferData?.offers || []} />
              </div>
            </div>
            <div className='apply-page-requirements'>
              <h3>Requirements</h3>
              <RequirementView requirements={jobOfferData?.requirements || []} />
            </div>
          </div>
        </div>
        <Divider />
        <div className='apply-content-1'>
          <h1>Apply</h1>
          <QuestionsAndAnswers
            questions={jobOfferData?.questions || []}
            setAnswers={setAnswers}
            setCoverLetter={setCoverLetter}
            answers={answers}
            coverLetter={coverLetter}
          />

          <TextField
            label="LinkedIn Url"
            type="text"
            placeholder="Your LinkedIn profile url"
            value={linkedinUrl}
            onChange={(e) => setLinkedinUrl(e.target.value)}
          />   
          <div className='apply-page-resume-parser'>Resume Parser</div>
          <p><small>This process may take up to 2 minutes</small></p>
          <input
            type="file"
            id="resumeInput"
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept=".pdf,.doc,.docx"
          />
          <button className='upload-resume-button' onClick={() => document.getElementById('resumeInput').click()}>
            <div className={`upload-resume-button-div ${uploadStatus}`}>
              <p>
                {uploadStatus === 'idle' && 'Upload Resume'}
                {uploadStatus === 'uploading' && 'Loading...'}
                {uploadStatus === 'parsing' && 'Parsing Resume...'}
                {uploadStatus === 'completed' && 'Parsing Complete'}
              </p>
              {uploadStatus === 'completed' ? (
                <img src={Checkmark} alt='complete-icon' className='resume-complete-icon' />
              ) : uploadStatus === 'uploading' || uploadStatus === 'parsing' ? (
                <div className="spinner"></div>
              ) : (
                <img src={ResumeArrow} alt='upload-icon' className='resume-upload-arrow' />
              )}
            </div>
          </button>
          
          {isResumeParsed && (
            <div className='resume-parsed-element'>
              <p>Here is your parsed resume, fix any details we might have gotten wrong before submitting!</p>
              <h1>{applicantData.profile.fullname}</h1>
              <InfoCard
                userData={applicantData.profile}
                onSave={handleSaveUserData}
              />
              <CredentialSectionEdit
                className="jobexperience-section"
                title="Job Experience"
                credentials={applicantData.jobexperiences}
                handleSaveCredential={(credential) => handleSaveCredential('jobexperiences', credential)}
                onClickAdd={() => addCredentialfunc('jobexperiences')}
                type='jobexperiences'
                removeCredential={(credentialId) => handleRemoveCredential('jobexperiences', credentialId)}
              />
              <CredentialSectionEdit
                className="education-section"
                title="Education"
                credentials={applicantData.academics}
                handleSaveCredential={(credential) => handleSaveCredential('academics', credential)}
                onClickAdd={() => addCredentialfunc('academics')}
                type='academics'
                removeCredential={(credentialId) => handleRemoveCredential('academics', credentialId)}
              />
              <button className='apply-page-apply-button' onClick={handleApply} disabled={loading}>
                Apply
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplyJobPage;

