import React, { createContext, useContext, useState, useMemo } from 'react';


export const ApplicationStateContext = createContext();

export const useApplicationState = () => useContext(ApplicationStateContext);

export const ApplicationStateProvider = ({ children }) => {
  const initialApplicantData = {
    profile: {
      fullname: '',
      email: '',
      birthdate: '', // Represented as a string, e.g., '1990-01-01'
      gender: '',
      phone: '',
      address: '',
      languages: [{ name: '', level: 0 }],
    },
    academics: [{
      school: '',
      title: '',
      startDate: '', // Dates as strings
      endDate: '', // Dates as strings or 'Present'
      category: '',
      tags: [],
    }],
    jobexperiences: [{
      employer: '',
      role: '',
      description: '',
      startDate: '', // Dates as strings
      endDate: '', // Dates as strings or 'Present'
      experience: 0,
      category: '',
      tags: [],
    }],
    applications: [{
      jobOfferID: '', // Placeholder for ObjectId reference
      status: '',
      isQualified: false,
      requirementsMet: [],
      applicationDate: new Date().toISOString().split('T')[0], // Current date as YYYY-MM-DD
      answers: [{ questionId: '', answer: '' }],
      coverLetter: '',
    }]
  };

  const [applicantData, setApplicantData] = useState(initialApplicantData);

  // Enhanced function to include logging
  const updateProfile = (profileUpdates) => {
    setApplicantData(prevData => {
      const newData = { ...prevData, profile: { ...prevData.profile, ...profileUpdates } };
      //console.log ('Updated Profile Data:', newData.profile);
      //console.log ('Updated Context:', newData);
      return newData;
    });
  };

  const addOrUpdateApplication = (applicationData) => {
    setApplicantData(prevData => {
      const existingIndex = prevData.applications.findIndex(app => app.jobOfferID === applicationData.jobOfferID);
  
      let updatedApplications;
      if (existingIndex > -1) {
        // Update existing application
        updatedApplications = prevData.applications.map((app, index) => {
          if (index === existingIndex) {
            return { ...app, ...applicationData };
          }
          return app;
        });
      } else {
        // Add new application if not exists
        updatedApplications = [...prevData.applications, { ...applicationData, id: `application-${Date.now()}` }];
      }
  
      return { ...prevData, applications: updatedApplications };
    });
  };
  
  
  const updateAnswersAndCover = (newAnswers, newCoverLetter, jobId) => {
    setApplicantData(prevData => {
      if (!prevData.applications) {
        console.error("Applications array is undefined.");
        return prevData; // Exit if applications is undefined to avoid further errors
      }
  
      const updatedApplications = prevData.applications.map((app) => {
        if (app.jobOfferID === jobId) {
          return { ...app, answers: newAnswers, coverLetter: newCoverLetter };
        }
        return app;
      });
  
      //console.log ('Updated applications data:', updatedApplications); // Debug log
      return { ...prevData, applications: updatedApplications };
    });
  };
  
  const addCredential = (type, credential) => {
    //console.log ('Adding new credential of type:', type);
  
    setApplicantData(prevData => {
      if (!prevData[type] || !Array.isArray(prevData[type])) {
        console.error(`Type '${type}' is not valid or not an array.`);
        return prevData;
      }
  
      const newCredential = { ...credential, id: `${type}-${Date.now()}` };
      const updatedCredentials = [...prevData[type], newCredential];
  
      //console.log (`Added new ${type}:`, newCredential);
      return { ...prevData, [type]: updatedCredentials };
    });
  };
  
  const updateCredential = (type, credential) => {
    //console.log ('Updating credential of type:', type);
//console.log ('New credential data:', credential);

  
    setApplicantData(prevData => {
      if (!prevData[type] || !Array.isArray(prevData[type])) {
        console.error(`Type '${type}' is not valid or not an array.`);
        return prevData;
      }
  
      const existingIndex = prevData[type].findIndex(c => c.id === credential.id);
      if (existingIndex === -1) {
        console.error('Credential not found for update:', credential.id);
        return prevData;
      }
  
      //console.log ('Existing credential before update:', prevData[type][existingIndex]);

      const updatedCredentials = prevData[type].map((c, index) =>
  index === existingIndex ? { ...c, ...credential } : c
);

  
      //console.log (`Updated ${type}:`, updatedCredentials);
      return { ...prevData, [type]: updatedCredentials };
    });
  };
  
  
  const removeCredential = (type, credentialId) => {
    //console.log (`Attempting to remove credential with ID: ${credentialId} from type: ${type}`);
    setApplicantData(prevData => {
      if (!prevData[type]) {
        console.error(`Error: No such data type '${type}' in the state.`);
        return prevData; // Early return if the type is incorrect
      }
  
      const updatedCredentials = prevData[type].filter(c => c.id !== credentialId);
      if (prevData[type].length === updatedCredentials.length) {
        console.error('No credential found with ID:', credentialId);
      } else {
        //console.log (`Credential with ID ${credentialId} removed.`);
      }
      const updatedData = { ...prevData, [type]: updatedCredentials };
      //console.log (`Updated ${type} data after removal:`, updatedData);
      return updatedData;
    });
  };
  
  

  const clearApplicantData = () => {
    //console.log ('Clearing applicant data.');
    setApplicantData(initialApplicantData);
  };

  const value = useMemo(() => ({
    applicantData,
    setApplicantData,
    updateProfile,
    addCredential,
    updateCredential,
    removeCredential,
    clearApplicantData,
    updateAnswersAndCover, // Updated function name here
    addOrUpdateApplication,
  }), [applicantData]);
  
  

  return (
    <ApplicationStateContext.Provider value={value}>
      {children}
    </ApplicationStateContext.Provider>
  );
};