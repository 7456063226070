import React from 'react';
import './Credential.css';  // Import the CSS file
import Tag from '../tag/Tag';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Helper function to format dates
const formatDate = (timestamp) => {
  if (!timestamp || isNaN(timestamp)) return 'Not available';
  let date = new Date(Number(timestamp));
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  if (month < 10) month = '0' + month;
  return `${year}/${month}`;
};

// Function to calculate experience
const calculateExperience = (startDate, endDate) => {
  if (!startDate || isNaN(startDate)) return 'Not available';

  let start = new Date(Number(startDate));
  let end = endDate && !isNaN(endDate) ? new Date(Number(endDate)) : new Date();
  
  // Treat January 1, 2100, as the current date
  if (end.getFullYear() === 2100 && end.getMonth() === 0 && end.getDate() === 1) {
    end = new Date();
  }

  // If difference is less than a month, return in days
  if (end - start < 1000*60*60*24*30) {
    let daysDifference = Math.floor((end - start) / (1000*60*60*24));
    return `${daysDifference} day${daysDifference > 1 ? 's' : ''}`;
  }

  let yearsDifference = end.getFullYear() - start.getFullYear();
  let monthsDifference = end.getMonth() - start.getMonth();

  if (monthsDifference < 0) {
    yearsDifference--;
    monthsDifference += 12;
  }

  let experience = yearsDifference > 0 ? `${yearsDifference} year${yearsDifference > 1 ? 's' : ''}` : '';
  experience += monthsDifference > 0 ? ` ${monthsDifference} month${monthsDifference > 1 ? 's' : ''}` : '';

  return experience.trim();
};

const Credential = ({ credential, credentialType, clickable = true }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const formatEndDate = (endDate) => {
    const endDateObj = new Date(Number(endDate));
    if (endDateObj.getFullYear() === 2100 && endDateObj.getMonth() === 0 && endDateObj.getDate() === 1) {
      return 'Present';
    }
    return formatDate(endDate);
  };

  const handleOnClick = () => {
    return;
  };

  return (
    <div className="credential" onClick={handleOnClick}>
      <div className="credential-title">{credential.title || credential.role || credential.school}</div>
      <div className="credential-school">{credential.school || credential.employer || credential.provider}</div>
      <div className="credential-dates">
        {formatDate(credential.startDate)} - {formatEndDate(credential.endDate)} ({calculateExperience(credential.startDate, credential.endDate)})
      </div>
      <div className="credential-tags">
        {credential.tags.map((tag) => (
          <span className="tag" key={tag}>{t(tag)}</span>
        ))}
      </div>
    </div>
  );
};

export default Credential;
