import React from "react";
import './Jobofferstate.css';
import { useTranslation } from 'react-i18next';

const JobOfferState = ({state}) => {
    const { t } = useTranslation();
    switch(state) {
        case 'Active':
            return (
                <div className="state-active">
                    {t(state)}
                </div>
            );
        case 'Paused':
            return (
                <div className="state-paused">
                    {t(state)}
                </div>
            );
        case 'Closed':
            return (
                 <div className="state-closed">
                     {t(state)}
                </div>
            );
        default:
            return <div className="state">{state}</div>;
    }
};

export default JobOfferState;