import React from 'react';
import Lottie from 'lottie-react';
import './Loading.css';
import animationData from './loading.json';

const LoadingPage = () => {
  return (
    <div className='loading-page'>
      <Lottie animationData={animationData} loop={true} autoplay={true} style={{ width: 100, height: 100 }} />
    </div>
  );
};

export default LoadingPage;
