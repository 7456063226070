// index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import client from './apolloClient';
import ApolloClientProvider from './apolloClient';
import './i18n'; // Ensure this line is here to initialize i18n
import Hotjar from '@hotjar/browser'; // Import Hotjar

// Initialize Hotjar
const siteId = 5005170;
const hotjarVersion = 6;
Hotjar.init(siteId, hotjarVersion);

const root = createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={window.location.origin + "/authpage"}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
  >
    <ApolloClientProvider client={client}>
      <App />
    </ApolloClientProvider>
  </Auth0Provider>
);

reportWebVitals();
