import React from 'react';
import './Createjoboffer.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Create_button = ({ onClick }) => {  // Corrected from (handleClick) to ({ onClick })
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <button className="create-button" onClick={onClick}> 
      {t('Create Job Offer')}
    </button>
  );
}

export default Create_button;
