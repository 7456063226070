import React, { useState, useEffect } from 'react';
import RefreshIcon from '../../images/refresh_icon.svg';
import './RefreshButton.css';

const RefreshButton = ({ onClick }) => {
  const [canClick, setCanClick] = useState(true);
  const [countdown, setCountdown] = useState(0);

  const handleClick = () => {
    if (canClick) {
      setCanClick(false);
      setCountdown(10);
      onClick();
    }
  };

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setCanClick(true);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  return (
    <div className='refresh-component'>
      <button 
        className={`refresh-button ${canClick ? '' : 'disabled'}`} 
        onClick={handleClick}
      >
      <img src={RefreshIcon} className="refresh-icon" alt="refresh"/>
      </button>
      {countdown > 0 && <span className="countdown">{countdown}</span>}
    </div>
  );
};

export default RefreshButton;
