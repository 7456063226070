import React, { useState, useEffect } from 'react';
import { ApolloClient, InMemoryCache, createHttpLink, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useAuth0 } from "@auth0/auth0-react";

const ApolloClientProvider = ({ children }) => {
  const [client, setClient] = useState(null);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  useEffect(() => {

    //console.log ("Using GraphQL URI:", process.env.REACT_APP_SERVER_URI); // This should print the correct URI

    const httpLink = createHttpLink({
      uri: `${process.env.REACT_APP_SERVER_URI}`,
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        );
      if (networkError) console.error(`[Network error]: ${networkError}`);
    });

    const authLink = setContext(async (_, { headers }) => {
      try {
        const token = isAuthenticated ? await getAccessTokenSilently() : null;
        //console.log ('Access token:', token);
        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
          },
        };
      } catch (error) {
        console.error('Error fetching access token:', error);
        return { headers }; // Proceed without authentication token if error occurs
      }
    });

    const newClient = new ApolloClient({
      link: errorLink.concat(authLink.concat(httpLink)),
      cache: new InMemoryCache(),
    });

    setClient(newClient);
  }, [getAccessTokenSilently, isAuthenticated]);

  if (!client) {
    return <div>Loading...</div>; // or a loading spinner
  }

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
};

export default ApolloClientProvider;
