import React from 'react';
import Slider from '@mui/material/Slider';
import './RangeSlider.css';
import { useTranslation } from 'react-i18next';


const RangeSlider = ({ salaryRange, setSalaryRange, disabled }) => {
  const { t } = useTranslation();
  const handleSliderChange = (event, newValue) => {
    if (!disabled) {
      setSalaryRange(newValue);
    }
  };

  const sliderStyle = {
    color: '#D2FF2F',
    // Other styles...
    opacity: disabled ? 0.5 : 1,
  };

  const thumbStyle = {
    height: '24px',
    width: '24px',
    backgroundColor: '#202020',
    border: '2px solid #FFD700',
    // Other styles...
  };

  return (
    <div className='salary-slider'>
      <h3 className='salary-title'>{t('Salary Range')}</h3>
      <p className='salary-range'>
        {t('Current range')}: {salaryRange ? `${salaryRange[0]} - ${salaryRange[1]}` : t('Not Specified')}
      </p>
      <Slider
        style={sliderStyle}
        thumbStyle={thumbStyle}
        value={salaryRange || [10000, 200000]} // Default value if salaryRange is null
        onChange={handleSliderChange}
        valueLabelDisplay="off"
        step={1000}
        min={10000}
        max={200000}
        disabled={disabled} // Disabling the Slider
      />
    </div>
  );
};

export default RangeSlider;
