import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './ApplicantsScroll.css';
import NavbarRecruiting from '../../../../components/navbar_recruiting/NavbarRecruiting';
import ApplicantGeneral from '../../../../components/applicant/applicant-general/ApplicantGeneral';
import { useEffect } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import Slider from '@mui/material/Slider';
import SelectField from '../../../../components/textfields/SelectField';
import copy from '../../../../images/copy_clipboard.svg'
import TextField from '../../../../components/textfields/TextField';
import CollapsibleFilterSection from '../../../../components/filters/CollapsableFilter'
import ApplicantScrollView from '../../../../components/applicant-scroll/ApplicantScrollView';

const GET_APPLICATIONS_FOR_JOB_OFFER = gql`
query GetApplicationsForJobOffer($jobOfferId: ID!) {
  getApplicationsForJobOffer(jobOfferID: $jobOfferId) {
    fullname
    requirementsMet
    isQualified
    status
    applicationDate
    applicantID
    coverLetter
    answers {
      questionText
      answerText
    }
  }
}
`;

const UPDATE_JOB_OFFER = gql`
  mutation UpdateJobOffer($jobOfferId: ID!, $newState: String!) {
    updateJobOffer(id: $jobOfferId, state: $newState) {
      id
      state
    }
  }
`;

const GET_JOB_OFFER = gql`
  query GetJobOffer($JobOfferId: ID!) {
  getJobOffer(id: $JobOfferId) {
    id
    title
    place
    workMode
    state
    requirements
    questions {
      questionText
    }
  }
}
`;



const ApplicantsScroll = () => {
  const [linkCopied, setLinkCopied] = useState(false);
  const [jobOfferState, setJobOfferState] = useState(null);
  const [applicationStartDate, setApplicationStartDate] = useState('');
  const [applicationEndDate, setApplicationEndDate] = useState('');
    const [minRequirementsMet, setMinRequirementsMet] = useState(0);
    const [maxRequirementsMet, setMaxRequirementsMet] = useState(100);
    const states = ["Active", "Paused", "Closed"]; // You can update this list as needed
    const options = ["Yes", "No"]; // You can update this list as needed
    const [minQuestionsAnswered, setMinQuestionsAnswered] = useState(0);
    const [maxQuestionsAnswered, setMaxQuestionsAnswered] = useState(100);
    const [coverLetterProvided, setCoverLetterProvided] = useState(false);
    const [filteredApplicants, setFilteredApplicants] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
  const [filterSettings, setFilterSettings] = useState({
    searchTerm: '',
    filterStates: {
      Applied: true,
      Rejected: true,
      Screening: true,
      Interview: true,
      Offer: true,
    },
    minRequirementsMet: 0,
    maxRequirementsMet: 100,
    minQuestionsAnswered: 0,
    maxQuestionsAnswered: 100,
    coverLetterProvided: false,
  });
  // Pagination states


  const id = sessionStorage.getItem('currentJobOfferId');

  const [updateJobOffer, { loading: updatingJobOffer, error: updateJobOfferError }] = useMutation(UPDATE_JOB_OFFER, {
    onError: (error) => {
      console.error('Job Offer Update Error Details:', error);
      console.error('GraphQL Errors:', error.graphQLErrors);
      console.error('Network Error:', error.networkError);
    },
    onCompleted: (data) => {
      setJobOfferState(data.updateJobOffer.state);
    }
  });


  const { loading: loadingJobOffer, error: jobofferError, data: jobofferData } = useQuery(
    GET_JOB_OFFER,
    {
      variables: { JobOfferId: id },
      skip: !id,
      onError: (error) => {
        console.error('Job Offer Error Details:', error);
        console.error('GraphQL Errors:', error.graphQLErrors);
        console.error('Network Error:', error.networkError);
      }         
    }
  );

  const { loading: loadingApplicants, error: applicantsError, data: applicantsData } = useQuery(
    GET_APPLICATIONS_FOR_JOB_OFFER,
    {
      variables: { jobOfferId: id },
      skip: !id,
      onError: (error) => {
        console.error('Applicants Error Details:', error);
        console.error('GraphQL Errors:', error.graphQLErrors);
        console.error('Network Error:', error.networkError);
      }         
    }
);

  const jobOffer = jobofferData ? jobofferData.getJobOffer : null;
  const applicants = applicantsData ? applicantsData.getApplicationsForJobOffer : [];
  const jobApplicationLink = `https://app.thesimplplatform.io/apply/${id}`;
const applicantsPerPage = 10;

// Change page
const paginate = pageNumber => setCurrentPage(pageNumber);

  useEffect(() => {
    if (jobOffer) {
      setJobOfferState(jobOffer.state);
    }
  }, [jobOffer]);

  const handleStateChange = (state) => {
    setFilterSettings(prevSettings => ({
      ...prevSettings,
      filterStates: {
        ...prevSettings.filterStates,
        [state]: !prevSettings.filterStates[state],
      },
    }));
  };

  useEffect(() => {
    if (applicantsData) {
      setFilteredApplicants(applicantsData.getApplicationsForJobOffer);
    }
  }, [applicantsData]);

  const applyFilters = () => {
    const filtered = applicants.filter(applicant => {
      // Ensure there is an answers array, if not, treat it as an empty array
      const answers = applicant.answers || [];
    
      // Filter answers that match the job offer's questions and are not empty
      const relevantAnswersCount = answers.reduce((count, answer) => {
        if (jobOfferQuestions.includes(answer.questionText) && answer.answerText.trim().length > 0) {
          return count + 1;
        }
        return count;
      }, 0);
    
      // Check if the applicant meets the minimum requirements
      const meetsRequirements = applicant.requirementsMet.length >= filterSettings.minRequirementsMet;
      // Combine all filter conditions
      return applicant.fullname.toLowerCase().includes(filterSettings.searchTerm.toLowerCase()) &&
        filterSettings.filterStates[applicant.status] &&
        relevantAnswersCount >= filterSettings.minQuestionsAnswered &&
        (!filterSettings.coverLetterProvided || (applicant.coverLetter && applicant.coverLetter.length > 0)) &&
        (!applicationStartDate || new Date(applicant.applicationDate) >= new Date(applicationStartDate)) &&
        (!applicationEndDate || new Date(applicant.applicationDate) <= new Date(applicationEndDate)) &&
        meetsRequirements; // Use the variable for readability
    });
  
    setCurrentPage(1); // Reset to the first page after applying filters
    setFilteredApplicants(filtered); // Correct way to set state
  };
  
  

  const resetFilters = () => {
    setFilterSettings({
      searchTerm: '',
      filterStates: {
        Applied: true,
        Rejected: true,
        Screening: true,
        Interview: true,
        Offer: true,
      },
      minRequirementsMet: 0,
      maxRequirementsMet: 100,
      minQuestionsAnswered: 0,
      maxQuestionsAnswered: 100,
      coverLetterProvided: false,
    });
    setCurrentPage(1); // Reset to the first page after resetting filters
    setFilteredApplicants(applicants); // Reset to original list of applicants
  };
  


    useEffect(() => {
      if (jobOffer) {
        setJobOfferState(jobOffer.state);
      }
    }, [jobOffer]);

    


    const [minYearsExperience, setMinYearsExperience] = useState(0);
    const [maxDistance, setMaxDistance] = useState(1000);
    const [allRequirements, setAllRequirements] = useState(false);
    const [requirementsMet, setRequirementsMet] = useState(0);
    const [questionsAnswered, setQuestionsAnswered] = useState(0);


const jobOfferQuestions = jobOffer && jobOffer.questions ? jobOffer.questions.map(q => q.questionText) : [];

const indexOfLastApplicant = currentPage * applicantsPerPage;
const indexOfFirstApplicant = indexOfLastApplicant - applicantsPerPage;
const currentApplicants = filteredApplicants.slice(indexOfFirstApplicant, indexOfLastApplicant);


const appliedApplicants = applicants.filter(applicant => applicant.status === "Applied");


  const handleJobOfferStateChange = (event) => {
    const newState = event.target.value;
    updateJobOffer({
      variables: {
        jobOfferId: id,
        newState
      }
    });
  };
  
    

    const [selectedView, setSelectedView] = useState('general');

    const renderViewOption = (optionName, path) => {
    const isSelected = optionName === selectedView;

      return (
        <Link 
          to={path} 
          className={isSelected ? 'view-option-selected' : 'view-option'}
          onClick={() => setSelectedView(optionName)}
        >
          {optionName.charAt(0).toUpperCase() + optionName.slice(1)}
        </Link>
      );
    }

    //console.log (applicantsData?.getApplicationsForJobOffer.applicantID)

    return (
      <div className='applicants-general-page'>
          <NavbarRecruiting />
  
          <div className='applicants-general-content'>
              <h2 className='joboffers-title'>{jobOffer ? jobOffer.title : "Loading..."}</h2>
              <h2 className='page-name'>Applications</h2>
              <hr className='divider' />
              <ApplicantScrollView applicantId={applicantsData?.getApplicationsForJobOffer.applicantID} />              
        </div>
      </div>
    );
    }

export default ApplicantsScroll;
