import React, { useState } from "react";
import './ItemList.css'; // Renamed CSS file
import TextField from "./TextField";
import { useTranslation } from 'react-i18next';

const ItemList = ({ items, setItems, label }) => {
  const [newItem, setNewItem] = useState('');
  const { t } = useTranslation();

  const handleAdd = () => {
    if (newItem.trim()) {
      setItems([...items, newItem]);
      setNewItem('');
    }
  };

  const handleRemove = (index) => {
    setItems(items.filter((_, i) => i !== index));
  };

  return (
    <div className="item-list">
      <div className="add-item-textfield">
             <TextField 
  label= {t(label)}
  type="text" 
  placeholder={t(label)} // Use label for placeholder too
  value={newItem}
  onChange={(e) => setNewItem(e.target.value)} 
  />
        <button onClick={handleAdd} className="add-item-button">{t('Add')}</button>
      </div>
      <ul>
        {items.map((item, index) => (
          <li className="item-list-li" key={index}>
            {item}
            <button className="item-delete-button" onClick={() => handleRemove(index)}>{t('Delete')}</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ItemList;
