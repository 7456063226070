import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import { useQuery, useMutation, gql } from '@apollo/client';
import TextField from '../../../../components/textfields/TextField';
import NavbarRecruiting from '../../../../components/navbar_recruiting/NavbarRecruiting';
import Button from '@mui/material/Button';
import { Divider } from 'antd';
import { useLocation } from 'react-router-dom';
import LongTextField from '../../../../components/textfields/LongTextField';
import SelectionField from '../../../../components/textfields/SelectionField';
import AddressField from '../../../../components/textfields/AddressField';
import RangeSlider from '../../../../components/textfields/RangeSlider';
import ResponsibilityList from '../../../../components/textfields/ItemList';
import QuestionList from '../../../../components/textfields/QuestionList';
import './JobOfferDetails.css';

const GET_JOB_OFFER = gql`
    query GetJobOffer($JobOfferId: ID!) {
        getJobOffer(id: $JobOfferId) {
            id
            title
            description
            place
            workMode
            responsibilities
            salaryRange
            requirements
            state
            employer
            type
            questions {
                questionText
                priority
            }
        }
    }
`;

const UPDATE_JOB_OFFER = gql`
    mutation UpdateJobOffer(
        $jobOfferId: ID!,
        $title: String,
        $description: String,
        $place: String,
        $workMode: String,
        $responsibilities: [String],
        $salaryRange: [Float],
        $requirements: String,
        $state: String,
        $employer: String,
        $type: String,
        $questions: [QuestionInput]
    ) {
        updateJobOffer(
            id: $jobOfferId,
            input: {
                title: $title,
                description: $description,
                place: $place,
                workMode: $workMode,
                responsibilities: $responsibilities,
                salaryRange: $salaryRange,
                requirements: $requirements,
                state: $state,
                employer: $employer,
                type: $type,
                questions: $questions
            }
        ) {
            id
        }
    }
`;

const JobOfferDetails = () => {
    const { id } = useParams();
    const { t } = useTranslation(); // Make sure to use this hook
    const location = useLocation();


    // Assuming initial states for all your fields
    const [jobTitle, setJobTitle] = useState('');
    const [jobDescription, setJobDescription] = useState('');
    const [place, setPlace] = useState('');
    const [workMode, setWorkMode] = useState('');
    const [jobType, setJobType] = useState('');
    const [responsibilities, setResponsibilities] = useState([]);
    const [salaryRange, setSalaryRange] = useState([0, 0]);
    const [isSalarySpecified, setIsSalarySpecified] = useState(true);
    const [questions, setQuestions] = useState([]);

    const handleSalarySpecifyChange = (e) => setIsSalarySpecified(!e.target.checked);


    const [jobOfferDetails, setJobOfferDetails] = useState({
        title: '',
        description: '',
        place: '',
        workMode: '',
        responsibilities: [],
        salaryRange: [],
        requirements: '',
        state: '',
        employer: '',
        type: '',
        questions: []
    });

    const { loading: loadingJobOffer, error: jobOfferError, data: jobOfferData } = useQuery(GET_JOB_OFFER, {
        variables: { JobOfferId: id },
        skip: !id,
        onCompleted: data => {
            setJobOfferDetails(data.getJobOffer);
        }
    });

    const [updateJobOffer] = useMutation(UPDATE_JOB_OFFER, {
        onCompleted: () => {
            //console.log ('Job offer updated successfully');
        }
    });

    const jobOffer = jobOfferData ? jobOfferData.getJobOffer : null;

    const handleInputChange = (field, value) => {
        setJobOfferDetails(prev => ({ ...prev, [field]: value }));
    };

    const handleSaveChanges = () => {
        updateJobOffer({ variables: { jobOfferId: id, ...jobOfferDetails } });
    };

    useEffect(() => {
        if (jobOfferData && jobOfferData.getJobOffer) {
          const offerDetails = jobOfferData.getJobOffer;
      
          setJobOfferDetails({
            title: offerDetails.title || '',
            description: offerDetails.description || '',
            place: offerDetails.place || '',
            workMode: offerDetails.workMode || '',
            responsibilities: offerDetails.responsibilities || [],
            salaryRange: offerDetails.salaryRange || [0, 0],
            requirements: offerDetails.requirements || '',
            state: offerDetails.state || '',
            employer: offerDetails.employer || '',
            type: offerDetails.type || '',
            questions: offerDetails.questions || [],
          });
      
          // If you also want to set individual states, you can do so
          setJobTitle(offerDetails.title || '');
          setJobDescription(offerDetails.description || '');
          setPlace(offerDetails.place || '');
          setWorkMode(offerDetails.workMode || '');
          setJobType(offerDetails.type || '');
          setResponsibilities(offerDetails.responsibilities || []);
          setSalaryRange(offerDetails.salaryRange || [0, 0]);
          setQuestions(offerDetails.questions.map(q => ({ ...q })) || []); // Cloning objects if they exist
        }
      }, [jobOfferData]);
      

    if (loadingJobOffer) return <p>Loading...</p>;
    if (jobOfferError) return <p>Error, please reload the page</p>;

    return (
        <div className='applicants-general-page'>
            <NavbarRecruiting />

            <div className='applicants-general-content'>
                <h2 className='page-name'>
                    <Link to="/job_offers" style={{ textDecoration: 'none', color: '#B4B4B4' }}>Job Offers&nbsp;{' > '}&nbsp;</Link>
                    Applications
                </h2>
                <h2 className='joboffers-title'>{jobOffer ? jobOffer.title : "Loading..."}</h2>
                <div className='page-selection'>
                    <button className={`page-selection-button ${location.pathname.includes('/applicants/') ? 'active' : ''}`}>
                        Applicants
                    </button>
                    <button className={`page-selection-button ${location.pathname.includes('/details/') ? 'active' : ''}`}>
                        Details
                    </button>
                </div>
                <div className='joboffer-details-container'>
                    <div className='joboffer-details-textfields-container'>
                    <TextField 
            label="Job Title" 
            type="text" 
            placeholder="Job Title" 
            value={jobTitle} 
            onChange={(e) => setJobTitle(e.target.value)} 
          />
          <LongTextField 
            label="Job Description"
            value={jobDescription}
            onChange={(e) => setJobDescription(e.target.value)}
            placeholder="Job Description"
          />
          <Divider />
          <AddressField 
            label="Office Address" 
            placeholder="Office Address" 
            value={place} 
            onChange={(value) => setPlace(value.label)} 
        />
          <h2 className='type-label'>{t('Mode')}</h2>
          <SelectionField 
            label="Work Mode"
            options={["In Office","Hybrid", "Remote"]} 
            value={workMode} 
            onChange={setWorkMode}
          />

<Divider />

<h2 className='type-label'>{t('Type')}</h2>

<SelectionField 
        label="Job Type"
        options={["Full-time","Part-time", "Contract", "Internship"]} 
        value={jobType} 
        onChange={setJobType}
      />

        <ResponsibilityList
          responsibilities={responsibilities}
          setResponsibilities={setResponsibilities}
        />
        <div className="salary-range-box">
        <RangeSlider
  salaryRange={salaryRange}
  setSalaryRange={setSalaryRange}
  disabled={!isSalarySpecified}
/>
      <div className="salary-range-specify-checkbox">
        <input
          type="checkbox"
          checked={!isSalarySpecified}
          onChange={handleSalarySpecifyChange}
        />
        <label>{t('Do Not Specify Salary Range')}</label>
        </div>
        </div>
        
        <QuestionList
      questions={questions}
      setQuestions={setQuestions}
    />

                </div>
                </div>
            </div>
        </div>
    );
};

export default JobOfferDetails;
  