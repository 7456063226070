import React from 'react';
import Credential from '../credential/Credential';
import RefreshButton from '../refresh-button/RefreshButton';
import Add_Button from '../add-button/Add-button';
import './CredentialSection.css';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';


const CredentialSection = ({ 
    className, 
    title, 
    credentials, 
    onAddClick, 
    credentialType, 
    showAddButton = true, 
    onRefresh, 
    showRefreshButton = true,
    clickable = true
}) => {

  const { t } = useTranslation();

  return (
    <div className={`section ${className}`}>
      <div className="section-header">
        <h2 className='title'>{t(title)}</h2>
        {showRefreshButton && <RefreshButton onClick={onRefresh} />}
      </div>
      <Divider />
      {credentials.map(credential => (
  <Credential 
    key={credential.id} 
    credential={credential} 
    credentialType={credentialType} 
    clickable={clickable !== false}  // Assuming `clickable` is the new prop passed to CredentialSection. If not provided, defaults to true.
  />
))}
      {showAddButton && <Add_Button onClick={onAddClick} />}
    </div>
  );
};

export default CredentialSection;
