import React from 'react';
import './EmailConfirm.css';

const EmailConfirm = () => {
    return (
        <div className="email-confirm-container">
            <h1>Thanks for confirming your email!</h1>
            <p>Your email has been successfully confirmed.</p>
            <button onClick={() => window.location.href = 'https://thesimplplatform.io'}>Go to Website</button>
        </div>
    );
}

export default EmailConfirm;
