import React, { useState } from 'react';
import './LanguageForm.css';
import { useTranslation } from 'react-i18next';
import SelectField from '../../../textfields/SelectField';

const COMMON_LANGUAGES = [
  'English', 'Spanish', 'Mandarin', 'Hindi', 'Arabic', 'Portuguese', 'Bengali', 'Russian', 
  'Japanese', 'Punjabi', 'German', 'Javanese', 'Wu', 'Malay', 'Telugu', 'Vietnamese', 
  'Korean', 'French', 'Marathi', 'Tamil', 'Urdu', 'Turkish', 'Italian', 'Thai', 'Gujarati', 
  'Jin', 'Southern Min', 'Persian', 'Polish', 'Pashto', 'Kannada', 'Xiang', 'Malayalam', 
  'Sundanese', 'Hausa', 'Odia', 'Burmese', 'Hakka', 'Ukrainian', 'Bhojpuri', 'Tagalog', 
  'Yoruba', 'Maithili', 'Uzbek', 'Sindhi', 'Amharic', 'Fula', 'Romanian', 'Oromo', 'Igbo', 
  'Azerbaijani', 'Awadhi', 'Gan', 'Cebuano', 'Dutch', 'Kurdish', 'Serbo-Croatian', 'Malagasy', 
  'Saraiki', 'Nepali', 'Sinhalese', 'Chittagonian', 'Zhuang', 'Khmer', 'Turkmen', 'Somali', 
  'Marwari', 'Magahi', 'Haryanvi', 'Hungarian', 'Chhattisgarhi', 'Greek', 'Chewa', 'Deccan', 
  'Akan', 'Kazakh', 'Northern Min', 'Sylheti', 'Zulu', 'Czech', 'Kinyarwanda'
].sort(); ;

const PROFICIENCY_LEVELS = [
  { value: '1', label: 'Basic' },
  { value: '2', label: 'Conversational' },
  { value: '3', label: 'Proficient' },
  { value: '4', label: 'Fluent' }
];

const LanguageForm = ({ onAdd, setVisible }) => {
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedProficiency, setSelectedProficiency] = useState('');
  const { t } = useTranslation();
  let counter = 0;

  const generateUUID = () => {
    const timePart = Date.now().toString(36);
    const randomPart = Math.random().toString(36).substring(2, 15);
    const countPart = (counter++).toString(36);
    return `${timePart}-${randomPart}-${countPart}`;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedLanguage && selectedProficiency) {
      const proficiency = PROFICIENCY_LEVELS.find(level => level.label === selectedProficiency).value;
      const requirement = {
        id: generateUUID(),
        requirementtype: "Language",
        category: selectedLanguage,
        tags: [proficiency],
        mandatory: true,
        years: 0
      };
      onAdd(requirement);
      setSelectedLanguage('');
      setSelectedProficiency('');
      setVisible(null); // Hide the form after submission by setting it to null
    }
  };

  return (
    <div>
      <div className='language-form'>
        <SelectField
          label='Language'
          placeholder='Select Language'
          value={selectedLanguage}
          onChange={(e) => setSelectedLanguage(e.target.value)}
          options={COMMON_LANGUAGES.map(language => language)}
        />
        <SelectField
          label='Proficiency'
          placeholder='Select Proficiency'
          value={selectedProficiency}
          onChange={(e) => setSelectedProficiency(e.target.value)}
          options={PROFICIENCY_LEVELS.map(level => level.label)}
        />
      </div>
      <button className='add-button-requirement' onClick={handleSubmit}>{t('Add')}</button>
    </div>
  );
};

export default LanguageForm;
