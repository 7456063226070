export const AcademicCategories = [
    {
      name: "High School Diploma",
      tags: ["Algebra",
      "Geometry",
      "Calculus",
      "Trigonometry",
      "Statistics",
      "Physics",
      "Chemistry",
      "Biology",
      "English Literature",
      "Creative Writing",
      "History",
      "Geography",
      "Economics",
      "Computer Programming",
      "Web Development",
      "Graphic Design",
      "Music",
      "Visual Arts",
      "Theater"],
    },
    {
      name: "Bachelor's Degree",
      tags: [ "Accounting",
      "Anthropology",
      "Architecture",
      "Biomedical Engineering",
      "Chemical Engineering",
      "Civil Engineering",
      "Computer Science",
      "Criminology",
      "Economics",
      "Electrical Engineering",
      "Environmental Science",
      "Film Studies",
      "Finance",
      "Game Design",
      "Healthcare Administration",
      "Industrial Engineering",
      "Journalism",
      "Kinesiology",
      "Linguistics",
      "Marketing"],
    },
    {
      name: "Master's Degree",
      tags: [ "Aerospace Engineering",
      "Astronomy",
      "Bioinformatics",
      "Biostatistics",
      "Clinical Psychology",
      "Cognitive Science",
      "Data Science",
      "Educational Leadership",
      "Energy Engineering",
      "Entrepreneurship",
      "Food Science",
      "Genetics",
      "Geophysics",
      "Human Resources",
      "Information Security",
      "Materials Science",
      "Mathematical Modeling",
      "Molecular Biology",
      "Nanotechnology",
      "Neuroscience"],
    },
    {
        name: "Doctorate Degree",
        tags: [ "Artificial Intelligence",
        "Astrophysics",
        "Behavioral Economics",
        "Biochemistry",
        "Biophysics",
        "Cell Biology",
        "Climate Science",
        "Complex Systems",
        "Computational Biology",
        "Cryptography",
        "Demography",
        "Developmental Psychology",
        "Ecology",
        "Epidemiology",
        "Ethics",
        "Evolutionary Biology",
        "Fluid Mechanics",
        "Human-Computer Interaction",
        "Immunology",
        "Philosophy of Science"],
      },
      {
        name: "Post-Doctorate",
        tags: [ "Additive Manufacturing",
        "Affective Computing",
        "Applied Mathematics",
        "Biomaterials",
        "Biomedical Imaging",
        "Biophotonics",
        "Cancer Research",
        "Cardiovascular Research",
        "Cognitive Neuroscience",
        "Computational Chemistry",
        "Computational Fluid Dynamics",
        "Control Theory",
        "Drug Discovery",
        "Energy Storage",
        "Environmental Policy",
        "Graph Theory",
        "Machine Learning",
        "Microbiology",
        "Optics",
        "Robotics"],
      },
  ];