import React from 'react';
import './RequirementItem.css';
import Tag from '../../tag/Tag';
import { useTranslation } from 'react-i18next';

const PROFICIENCY_LEVELS = [
  { value: '1', label: 'Basic' },
  { value: '2', label: 'Conversational' },
  { value: '3', label: 'Proficient' },
  { value: '4', label: 'Fluent' }
];

const RequirementItem = ({ requirement, onDelete }) => {
  const { t } = useTranslation();

  let requirementDetails = '';
  if (requirement.requirementtype === 'Experience') {
    const years = requirement.years === 0 ? t('No years required') : `${requirement.years} ${t('years')}`;
    const tags = requirement.tags.map((tag, i) => <Tag key={i}>{t(tag)}</Tag>);
    requirementDetails = (
      <>
        <p>{t('Experience')}: {years}</p>
        <p>{t('Category')}: {t(requirement.category)}</p>
        <div className="credential-tags">{tags}</div>
      </>
    );
  } else if (requirement.requirementtype === 'Academic') {
    const tags = requirement.tags.map((tag, i) => <Tag key={i}>{t(tag)}</Tag>);
    requirementDetails = (
      <>
        <p>{t(requirement.category)}</p>
        <div className="credential-tags">{tags}</div>
      </>
    );
  } else if (requirement.requirementtype === 'Distance') {
    requirementDetails = `${t('Max Distance')}: ${requirement.category} km`;
  } else if (requirement.requirementtype === 'Language') {
    const proficiencyLevel = PROFICIENCY_LEVELS.find(level => level.value === requirement.tags[0].toString()).label;
    requirementDetails = (
      <>
        <p>{t('Language')}: {t(requirement.category)}</p>
        <p>{t('Proficiency')}: {t(proficiencyLevel)}</p>
      </>
    );
  }

  return (
    <div className='requirement-item-whole'>
      <div className={`requirement-item ${requirement.requirementtype ? requirement.requirementtype.toLowerCase() : ''}`}>
        <div className="requirement-content">
          <h4 className="requirement-type">{t(requirement.type)}</h4>
          {requirementDetails}
        </div>
      </div>
      <button className="requirement-delete" onClick={onDelete}>{t('X')}</button>
    </div>
  );
};

export default RequirementItem;
