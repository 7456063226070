import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import RefreshButton from '../../../components/refresh-button/RefreshButton';
import { useAuth0 } from '@auth0/auth0-react';
import './Pool.css';
import NavbarRecruiting from '../../../components/navbar_recruiting/NavbarRecruiting';
import ApplicantPool from '../../../components/applicant/applicant-pool/ApplicantPool';
import { useQuery, gql } from '@apollo/client';
import SelectField from '../../../components/textfields/SelectField';
import TextField from '../../../components/textfields/TextField';
import CollapsibleFilterSection from '../../../components/filters/CollapsableFilter';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';
import SelectionField from '../../../components/textfields/SelectionField';
import ApplicantView from '../../pages/job_offers/applicant_view/ApplicantView';
import { useSubscription } from '../../../contexts/SubscriptionContext';
import FreeOverlay from '../../../components/free-overlay/FreeOverlay';

const GET_RECRUITER_BY_USER = gql`
query GetRecruiterByUserId($userId: String!) {
    getRecruiterByUserId(userID: $userId) {
      id
      userID
      company
      description
    }
  }
`;

const GET_APPLICANTS_BY_RECRUITER = gql`
query GetApplicantsByRecruiter($userId: String!) {
  getApplicantsByRecruiter(userID: $userId) {
    id
    profile {
      fullname
      email
      birthdate
      gender
      linkedinUrl
      phone
      address
      languages {
        name
        level
      }
    }
    academics {
      school
      title
      startDate
      endDate
      category
      tags
    }
    jobexperiences {
      employer
      role
      description
      startDate
      endDate
      experience
      category
      tags
    }
    applications {
      jobOfferID
      status
      isQualified
      requirementsMet
      applicationDate
      answers {
        questionId
        text
      }
      coverLetter
    }
  }
}
`;

const Pool = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { user, isAuthenticated } = useAuth0();
  const userID = user ? user.sub : null;
  const [currentPage, setCurrentPage] = useState(1);
  const [applicantsPerPage, setApplicantsPerPage] = useState(10);
  const [currentApplicants, setCurrentApplicants] = useState([]);
  const [filteredApplicants, setFilteredApplicants] = useState([]);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const { subscription } = useSubscription();

  const handleOpenOverlay = (applicant) => {
    setSelectedApplicant(applicant);
    setIsOverlayOpen(true);
  };

  const handleCloseOverlay = () => {
    setIsOverlayOpen(false);
    setSelectedApplicant(null);
  };

  const { loading: loadingRecruiter, error: recruiterError, data: recruiterData } = useQuery(
    GET_RECRUITER_BY_USER,
    {
      variables: { userId: user?.sub },
      skip: !isAuthenticated,
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        if (data?.getRecruiterByUserId?.id) {
          sessionStorage.setItem('recruiterId', data.getRecruiterByUserId.id);
          sessionStorage.setItem('company', data.getRecruiterByUserId.company);
        }
      },
    }
  );

  const recruiterID = isAuthenticated ? sessionStorage.getItem('recruiterId') : null;

  const { loading: loadingApplicants, error: applicantsError, data: applicantsData } = useQuery(
    GET_APPLICANTS_BY_RECRUITER,
    {
      variables: { userId: userID },
      skip: !userID,
      onError: (error) => {
        console.error('Applicant Error Details:', error);
        console.error('GraphQL Errors:', error.graphQLErrors);
        console.error('Network Error:', error.networkError);
      },
      onCompleted: data => {
        //console.log ('Applicants data:', data);
      }
    }
  );

  const applicants = applicantsData ? applicantsData.getApplicantsByRecruiter : [];
  //console.log (applicants);

  useEffect(() => {
    setFilteredApplicants(applicants);
  }, [applicantsData]);

  useEffect(() => {
    const indexOfFirstApplicant = (currentPage - 1) * applicantsPerPage;
    const indexOfLastApplicant = indexOfFirstApplicant + applicantsPerPage;
    setCurrentApplicants(filteredApplicants.slice(indexOfFirstApplicant, indexOfLastApplicant));
  }, [currentPage, filteredApplicants, applicantsPerPage]);

  useEffect(() => {
    if (loadingApplicants) {
      //console.log ("Loading applicants...");
    } else if (applicantsError) {
      console.error("Error loading applicants:", applicantsError);
    }
  }, [loadingApplicants, applicantsError]);

  const paginate = pageNumber => {
    //console.log (`Changing to page ${pageNumber}`);
    setCurrentPage(pageNumber);
  };

  return (
    <div className='applicants-general-page'>
      <NavbarRecruiting />
      <div className='applicants-general-content'>
        {isOverlayOpen && selectedApplicant && (
          <ApplicantView
            applicant={selectedApplicant}
            onClose={handleCloseOverlay}
            viewType="type2"
          />
        )}
        <h2 className='joboffers-title'>{t('Applicant Pool')}</h2>

        <div className='job-offers-section'>
          {subscription === 'Free' && (
                     <FreeOverlay />

          )}
          <div className='job-offers-header'>
            <span className='job-offers-header-element'>{t('Applicant Info')}</span>
            <span className='job-offers-header-element'>{t('Contacts')}</span>
            <span className='job-offers-header-element'>{t('Address')}</span>
            <span className='job-offers-header-element'>{t('Notes')}</span>
          </div>
          {currentApplicants.map(applicant => (
            <ApplicantPool
              key={applicant.id}
              applicant={applicant}
              onOpenOverlay={() => handleOpenOverlay(applicant)}
            />
          ))}
          <div className='pagination'>
            {[...Array(Math.ceil(filteredApplicants.length / applicantsPerPage)).keys()].map(number => (
              <button className='pagination-button' key={number} onClick={() => paginate(number + 1)}>
                {number + 1}
              </button>
            ))}
            <p className='current-page'>{t('Page')} {currentPage}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pool;
