import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './JobOffersPage.css';
import RefreshButton from '../../../components/refresh-button/RefreshButton';
import NavbarRecruiting from '../../../components/navbar_recruiting/NavbarRecruiting';
import Create_button from '../../../components/create-job-offer/Createjoboffer';
import { jobOffers } from '../test/TestData';
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, useNavigate } from 'react-router-dom';
import SelectionField from '../../../components/textfields/SelectionField';
import TextField from '../../../components/textfields/TextField';
import { useMutation, useQuery, gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import CreateJobOfferPage from './create_job_offer/CreateJobOfferPage';
import { useSubscription } from '../../../contexts/SubscriptionContext';
import JobOffer from '../../../components/joboffer/JobOfferComponent';
import FreeOverlay from '../../../components/free-overlay/FreeOverlay';

const GET_RECRUITER_BY_USER = gql`
query GetRecruiterByUserId($userId: String!) {
    getRecruiterByUserId(userID: $userId) {
      id
      userID
      company
      description
      subscription
    }
  }
`;

const UPDATE_JOB_OFFER_LAST_OPENED_AT = gql`
  mutation UpdateJobOfferLastOpenedAt($id: ID!) {
    updateJobOfferLastOpenedAt(id: $id) {
      id
      lastOpenedAt
    }
  }
`;


const GET_JOB_OFFERS_BY_RECRUITER = gql`
query GetJobOffersByRecruiter($recruiterId: ID!) {
    getJobOffersByRecruiter(recruiterID: $recruiterId) {
      id
      userID
      recruiterID
      title
      description
      place
      workMode
      responsibilities
      offers
      salaryRange
      applicationsCount
      state
      createdAt
      workHours
      newApplicantsCount
    }
  }
`;

const JobOffers = () => {
  const { t } = useTranslation();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [searchTerm, setSearchTerm] = useState('');
  const { subscription, mockdata } = useSubscription();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [updateLastOpenedAt] = useMutation(UPDATE_JOB_OFFER_LAST_OPENED_AT);


  const toggleCreateModal = () => {
    setIsCreateModalOpen(!isCreateModalOpen);
  };

  const [filterSettings, setFilterSettings] = useState({
    searchTerm: '',
    locationTerm: '',
    selectedStates: ['Active'], // This will now be an array to hold multiple states
    dateRange: {
      startDate: null,
      endDate: null,
    },
    sortDirection: 'desc', // 'desc' for latest to oldest, 'asc' for oldest to latest
  });

  const dateOptions = {
    "": "Select Date",
    "last-7-days": "Last 7 Days",
    "last-30-days": "Last 30 Days",
    "last-6-months": "Last 6 Months",
    "last-year": "Last Year",
    // Add more options as needed
  };

  const handleDateOptionChange = (startOrEnd, value) => {
    const newDateRange = { ...filterSettings.dateRange };
    
    switch (value) {
      case "last-7-days":
        newDateRange[startOrEnd] = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
        break;
      case "last-30-days":
        newDateRange[startOrEnd] = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
        break;
      default:
        newDateRange[startOrEnd] = null;
    }
    
    setFilterSettings({ ...filterSettings, dateRange: newDateRange });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const jobOffersPerPage = 5;

  const { loading: loadingRecruiter, error: recruiterError, data: recruiterData } = useQuery(
    GET_RECRUITER_BY_USER,
    {
      variables: { userId: user?.sub },
      skip: !isAuthenticated,
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        if (data?.getRecruiterByUserId?.id) {
          sessionStorage.setItem('recruiterId', data.getRecruiterByUserId.id);
          sessionStorage.setItem('company', data.getRecruiterByUserId.company);
        }
      },
    }
  );

  const recruiterID = isAuthenticated ? sessionStorage.getItem('recruiterId') : null;
  const company = isAuthenticated ? sessionStorage.getItem('company') : null;

  const alternateRecruiterID = '664f375379f01cad2d52d895';

  const { loading: loadingJobOffers, error: joboffersError, data: joboffersData, refetch: refetchJobOffers } = useQuery(
    GET_JOB_OFFERS_BY_RECRUITER,
    {
      variables: { recruiterId: subscription === 'Free' ? alternateRecruiterID : recruiterID },
      skip: !recruiterID && subscription !== 'Free',
      pollInterval: 100000,  // Poll every 10000 ms (10 seconds)
      onError: (error) => {
        console.error('Job Offers Error Details:', error);
        console.error('GraphQL Errors:', error.graphQLErrors);
        console.error('Network Error:', error.networkError);
      }
    }
  );

  const removeFilter = (filterType) => {
    switch (filterType) {
      case 'searchTerm':
        setSearchTerm('');
        break;
      case 'locationTerm':
        setFilterSettings({ ...filterSettings, locationTerm: '' });
        break;
      case 'selectedStates':
        setFilterSettings({ ...filterSettings, selectedStates: [] });
        break;
      default:
        break;
    }
  };

  const handleJobOfferClick = async (joboffer) => {
    try {
      await updateLastOpenedAt({ variables: { id: joboffer.id } });
      sessionStorage.setItem('currentJobOfferId', joboffer.id);
    } catch (error) {
      console.error('Error updating last opened at:', error);
    }
  };
  
  
  const jobOffers = joboffersData?.getJobOffersByRecruiter ?? [];
  const sortedJobOffers = [...jobOffers].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  
  const filteredJobOffers = jobOffers.filter(offer => {
    const matchesSearchTerm = offer.title.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesLocation = offer.place.toLowerCase().includes(filterSettings.locationTerm.toLowerCase());
    const matchesSelectedStates = filterSettings.selectedStates.length === 0 || filterSettings.selectedStates.includes(offer.state);
    const isInDateRange = (!filterSettings.dateRange.startDate || new Date(offer.createdAt) >= new Date(filterSettings.dateRange.startDate)) &&
    (!filterSettings.dateRange.endDate || new Date(offer.createdAt) <= new Date(filterSettings.dateRange.endDate));
    return matchesSearchTerm && matchesLocation && matchesSelectedStates && isInDateRange;
  });

  const stateOptions = ['Active', 'Paused', 'Closed'];

  const handleStateChange = (state) => {
    setFilterSettings(prevSettings => {
      const isSelected = prevSettings.selectedStates.includes(state);
      return {
        ...prevSettings,
        selectedStates: isSelected
          ? prevSettings.selectedStates.filter(s => s !== state)
          : [...prevSettings.selectedStates, state],
      };
    });
  };

  const [executionCount, setExecutionCount] = useState(0);

  useEffect(() => {
    if (executionCount < 2) {
      if (recruiterData?.getRecruiterByUserId?.id && isAuthenticated) {
        sessionStorage.setItem('recruiterId', recruiterData.getRecruiterByUserId.id);
        sessionStorage.setItem('company', recruiterData.getRecruiterByUserId.company);
        refetchJobOffers();
      }
      setExecutionCount(count => count + 1);
    }
  }, [recruiterData, isAuthenticated, refetchJobOffers, executionCount]);

  const indexOfLastJobOffer = currentPage * jobOffersPerPage;
  const indexOfFirstJobOffer = indexOfLastJobOffer - jobOffersPerPage;
  const currentJobOffers = filteredJobOffers.slice(indexOfFirstJobOffer, indexOfLastJobOffer);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div className='job-offers-page'>
      {isCreateModalOpen && <CreateJobOfferPage onClose={toggleCreateModal} />}
      <NavbarRecruiting />
      <div className='joboffers-content'>
        {subscription === 'Free' && (
          <FreeOverlay />
        )}
        <div className='active-filters-section'>
          <h2 className='active-filters-title'>{t('Active Filters')}</h2>
          <div className='active-filters'>
            {searchTerm && (
              <div className="active-filter-tag">
                {searchTerm}
                <button className='remove-filter-btn' onClick={() => removeFilter('searchTerm')}>×</button>
              </div>
            )}
            {filterSettings.locationTerm && (
              <div className="active-filter-tag">
                {filterSettings.locationTerm}
                <button className='remove-filter-btn' onClick={() => removeFilter('locationTerm')}>×</button>
              </div>
            )}
            {filterSettings.selectedStates.length > 0 && filterSettings.selectedStates.map((state, index) => (
              <div className="active-filter-tag" key={index}>
                {state}
                <button className='remove-filter-btn' onClick={() => handleStateChange(state)}>×</button>
              </div>
            ))}
          </div>
        </div>
        <div className='job-offers-section'>
          <div className='job-offers-header'>
            <span className='job-offers-header-element'>{t('Job Title')}</span>
            <span className='job-offers-header-element'>{t('Active For')}</span>
            <span className='job-offers-header-element'>{t('Status')}</span>
            <span className='job-offers-header-element'>{t('Applications')}</span>
          </div>
          {currentJobOffers.map(joboffer => (
  <Link to={`/applicants/${joboffer.id}`} key={joboffer.id} onClick={() => handleJobOfferClick(joboffer)}>
    <JobOffer joboffer={joboffer} />
  </Link>
))}

          <div className='pagination'>
            {[...Array(Math.ceil(filteredJobOffers.length / jobOffersPerPage)).keys()].map(number => (
              <button className='pagination-button' key={number} onClick={() => paginate(number + 1)}>
                {number + 1}
              </button>
            ))}
            <p className='current-page'>{t('Page')} {currentPage}</p>
          </div>
        </div>
      </div>
      <div className='filter-content'>
        <h2 className='filter-title'>{t('Filters')}</h2>
        <TextField
          label={t('Search')}
          type="text"
          placeholder={t('Search Offers')}
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
        <div className='text-field-label'>{t('Status')}</div>
        <SelectionField
          options={stateOptions}
          value={filterSettings.selectedStates}
          onChange={(selectedOption) => handleStateChange(selectedOption)}
        />
        <TextField    
          label={t('Location')}
          type="text"
          placeholder={t('Filter Location')}
          value={filterSettings.locationTerm}
          onChange={e => setFilterSettings({ ...filterSettings, locationTerm: e.target.value })}
        />
        <Create_button onClick={toggleCreateModal} />
      </div>
    </div>
  );
};

export default JobOffers;
