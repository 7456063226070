    import React from 'react';
    import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
    import './AddressField.css';
    import { useTranslation } from 'react-i18next';


    const AddressField = ({ label, placeholder, value, onChange }) => {
        const { t } = useTranslation();

        return (
            <div className="addressfield">
                <label className="address-field-label">{t(label)}</label>
                <GooglePlacesAutocomplete
  apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
  initialValue={value}
                    selectProps={{
                        onChange: onChange,
                        placeholder: t(placeholder),
                    }}
                />   
            </div>
        );
    };

    export default AddressField;
