import React from 'react';
import './RequirementCard.css';

const RequirementCard = ({ type, description, onClick }) => (
  <div className='requirement-card' onClick={onClick}>
    <h2 className='requirement-card-type'>{type}</h2>
    <p className='requirement-card-description'>{description}</p>
  </div>
);

export default RequirementCard;
