import React, { useEffect, useState, useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext.js';
import { userCanAccess } from './RoleControl';
import axios from 'axios';
import LoadingPage from './Loading';
import Login from './PreRegistration.js';

const GET_RECRUITER = gql`
query GetRecruiterByUserId($userId: String!) {
    getRecruiterByUserId(userID: $userId) {
        id
        userID
        company
        description
        email
    }
}`;


const Auth = () => {
  const [userRole, setUserRole] = useState(null);
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, appState } = useAuth0();
  const [needsPostRegistration, setNeedsPostRegistration] = useState(false);
  const navigate = useNavigate();
  const defaultUrl = '/job_offers';



  const { loading: loadingRecruiter, error: recruiterError, data: recruiterData } = useQuery(
    GET_RECRUITER,
    {
      variables: { userId: user?.sub},
      skip: !isAuthenticated,
    }
  );

  const roleMappings = {
    'JobSeeker': 'rol_R0Q7Q18kTdk8svnd',
    'Employer': 'rol_Q4N7WvG43Yp2vOBI',
  };

  const recruiterExists = Boolean(recruiterData?.getRecruiterByUserId);
  useEffect(() => {
    //console.log ('Effect: Auth status and loading states:', { isLoading, isAuthenticated, loadingRecruiter });
  
    if (isLoading || loadingRecruiter) {
      //console.log ('Still loading...');
      return; // Early return if still loading anything
    }
  
    if (!isAuthenticated) {
      //console.log ('Not authenticated, should show login.');
      return; // Handle not authenticated state
    }
  
    if (recruiterError) {
      console.error('Error loading recruiter data:', recruiterError);
      return; // Handle error in loading data
    }
  
    if (!recruiterExists) {
      //console.log ('Recruiter does not exist, navigating to post_employer.');
      navigate('/post_employer');
    } else {
      //console.log (`Navigating to ${defaultUrl}`);
      navigate(defaultUrl);
    }
  }, [isLoading, loadingRecruiter, isAuthenticated, recruiterExists, recruiterError]); // Reduced dependencies
  
  if (isLoading || loadingRecruiter) {
    //console.log ('Showing LoadingPage.');
    return <LoadingPage />;
  }

  if (!isAuthenticated) {
    //console.log ('User not authenticated, showing Login.');
    return <Login />;
  }

  if (recruiterError) {
    console.error('Error loading applicant or recruiter data:', recruiterError);
    return <div>Error loading data, please try again later.</div>;
  }

};

export default Auth;
