import React, { useState } from 'react';
import './FeedbackComponent.css';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useAuth0 } from "@auth0/auth0-react";
import i18n from 'i18next'; // Ensure this import is correct
import { useTranslation } from 'react-i18next';

const CREATE_FEEDBACK = gql`
  mutation CreateFeedback($message: String!, $user: String!, $path: String) {
    createFeedback(message: $message, user: $user, path: $path) {
      id
    }
  }
`;

const FeedbackComponent = ({t}) => {
    const [showModal, setShowModal] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const [createFeedback] = useMutation(CREATE_FEEDBACK);
    const { user, isAuthenticated, isLoading } = useAuth0();


    const handleToggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const handleSubmit = async () => {
        try {
            await createFeedback({
                variables: {
                    message: feedback,
                    user: user?.email,
                    path: window.location.pathname
                }
            });
            alert(t('Thank you for your feedback!'));
        } catch (error) {
            alert(t('There was an error submitting your feedback. Please try again.'));
            console.error('Error submitting feedback:', error);
        }

        setFeedback('');
        setShowModal(false);
    }
    // Correct approach to switch language
   const changeLanguage = async (lng) => {
  await i18n.changeLanguage(lng);
  //console.log (`Language changed to ${lng}`);
};


    return (
        <div className="feedback-container">
            <div className="feedback-toggle" onClick={handleToggleExpand}>
                <div className={`arrow-icon ${isExpanded ? 'down' : ''}`}></div>
            </div>
            {isExpanded && (
                <>
                    <button className="feedback-button" onClick={() => setShowModal(true)}>Feedback</button>
                    <div className="language-switcher">
                        <button onClick={() => changeLanguage('en')}>EN</button>
                        <button onClick={() => changeLanguage('it')}>IT</button>

                    </div>
                </>
            )}

            {showModal && (
                <div className="feedback-overlay">
                    <div className="feedback-modal">
                        <button className="close-button" onClick={() => setShowModal(false)}>X</button>
                        <h2 className='feedback-modal-title'>{t('We Value Your Feedback!')}</h2>
                        <p className='feedback-modal-text'>{t('Your feedback is essential to us. Please let us know about any issues, confusing interfaces, or potential new features you would like to see. We aim to make this platform as user-friendly as possible')}!</p>
                        <textarea 
                            value={feedback}
                            onChange={e => setFeedback(e.target.value)}
                            placeholder={t("Provide your feedback...")}
                        ></textarea>
                        <button className="submit-button" onClick={handleSubmit}>{t('Submit')}</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default FeedbackComponent;
