import React from "react";
import './SelectField.css';
import { useTranslation } from 'react-i18next';


const SelectField = ({ label, placeholder, value, onChange, options }) => {
    const { t } = useTranslation();
    
    return (
        <div className="selectfield">
            <label className="select-field-label">{t(label)}</label>
            <select className="select-field-input"
                value={value}
                onChange={onChange}
            >
              <option value="">{t(placeholder)}</option>
              {options.map(option => (
                <option key={option} value={option}>{t(option)}</option>
              ))}
            </select>
        </div>
    );
};

export default SelectField;
