// Tag.js
import React from 'react';
import './Tag.css';

const Tag = ({ children }) => {
  return (
    <div className="tag">
      <p>{children}</p>
    </div>
  );
};


export default Tag;
