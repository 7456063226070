import React, { useState } from 'react';
import './NavbarRecruiting.css';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../../images/logo-notext-white.svg';
import LogoutButton from '../logout-button/LogoutButton';
import JobOffersLogo from '../../images/Frame 5010.svg';
import PoolLogo from '../../images/Frame 5011.svg';
import SettingsLogo from '../../images/Frame 5009.svg';
import { useTranslation } from 'react-i18next';

const NavbarButton = ({ icon, labelKey, navigateTo, subRoutes = [] }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = location.pathname.startsWith(navigateTo) || subRoutes.some(route => location.pathname.startsWith(route));

  return (
    <div className={`navbar-button ${isActive ? 'active' : ''}`} onClick={() => navigate(navigateTo)}>
      <img src={icon} alt={t(labelKey)} className='navbar-logo' />
      <span>{t(labelKey)}</span>
    </div>
  );
}

const NavbarRecruiting = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className={`navbar ${isExpanded ? 'expanded' : ''}`}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <div className="navbar-content">
        <div>
          <img src={logo} alt="SimpL" className="logo" />
          <div className="navbar-buttons">
            <NavbarButton icon={JobOffersLogo} labelKey="Job Offers" navigateTo="/job_offers" subRoutes={["/applicants/"]} />
            <NavbarButton icon={SettingsLogo} labelKey="Settings" navigateTo="/recruiter_settings" />
          </div>
        </div>
        <LogoutButton isExpanded={isExpanded} />
      </div>
    </div>
  );
}

export default NavbarRecruiting;