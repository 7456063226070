import React, { useState, useEffect } from 'react';
import './ApplicantGeneral.css';
import { Link } from "react-router-dom";
import { useQuery, useMutation, gql } from '@apollo/client';
import State from './Application_State.svg';
import checkmark from '../../../images/checkmark.svg';
import LongTextField from '../../textfields/LongTextField';
import SaveChanges from '../../save-changes/SaveChanges';
import { useTranslation } from 'react-i18next';
import ProfilePicture from '../../profile-picture/ProfilePicture';

// Updated GraphQL Queries and Mutations
const GET_APPLICANT_NOTES = gql`
  query GetApplicantNotes($applicantID: ID!) {
    getApplicantNotes(applicantID: $applicantID) {
      id
      content
      createdAt
      recruiterID
    }
  }
`;

const ADD_NOTE_TO_APPLICANT = gql`
  mutation AddNoteToApplicant($applicantID: ID!, $note: NoteInput!) {
    addNoteToApplicant(applicantID: $applicantID, note: $note) {
      id
      notes {
        id
        content
        createdAt
        recruiterID
      }
    }
  }
`;



const ApplicantGeneral = ({ applicant, application, totalRequirements, totalQuestions, onOpenOverlay, overallScore }) => {
  const [newNote, setNewNote] = useState('');
  const [noteId, setNoteId] = useState(null);
  const [isModified, setIsModified] = useState(false);
  const [toCreate, setToCreate] = useState(false);
  const { t } = useTranslation();

  const recruiterId = sessionStorage.getItem('recruiterId');

  const { loading, error, data, refetch } = useQuery(GET_APPLICANT_NOTES, {
    variables: { applicantID: applicant.id },
    skip: !applicant.id,
  });


  const [addNoteToApplicant] = useMutation(ADD_NOTE_TO_APPLICANT);


  const handleTextChange = (e) => {
    setNewNote(e.target.value);
    setIsModified(true);
  };

  const renderCircles = (filledCount, totalCount) => {
    const circles = [];
    for (let i = 0; i < totalCount; i++) {
      circles.push(
        <span key={i} className={`circle-top ${i < filledCount ? 'filled' : ''}`}></span>
      );
    }
    return circles;
  };

  const handleSaveClick = async () => {
    if (newNote.trim() !== '') {
      await addNoteToApplicant({
        variables: {
          applicantID: applicant.id,
          note: {
            content: newNote,
            recruiterID: recruiterId,
          },
        },
      });
      setNewNote('');
      setIsModified(false);
      refetch();
    }
  };

  function truncateText(text, maxLength) {
    // Check if the text length exceeds the maximum length
    if (text.length > maxLength) {
      // If so, truncate the text and append '...'
      return text.substring(0, maxLength) + '...';
    }
    // If not, return the text as is
    return text;
  }

  // Filter out empty answers
  const nonEmptyAnswers = application.answers.filter(answer => answer.text.trim() !== '');

  const renderLatestNote = () => {
    if (loading) return <p>Loading notes...</p>;
    if (error) return <p>Error loading notes</p>;
    
    const notes = data?.getApplicantNotes || [];
    if (notes.length === 0) {
      return <p>No notes added for the applicant yet.</p>;
    }
    
    const latestNote = notes.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];
    return (
      <div className="latest-note">
        <p><strong>Latest Note:</strong></p>
        <p>{latestNote.content}</p>
      </div>
    );
  };

  return (
    <div className="application-general">
      <div onClick={() => {
        //console.log ("Application clicked, opening overlay for applicant ID:", applicant.id);
        onOpenOverlay(applicant.id);
      }} className="application-left">


        <div className='pic-info-general'>
          <div className='profile-picture'>
          <ProfilePicture name={applicant.profile.fullname} imageUrl={applicant.profile.profileImageUrl} />
          </div>
          <div className='application-info-general'>
            <div className="application-name">
              {truncateText(applicant.profile.fullname, 15)}
            </div>
            <div className="application-date">
              {t('Applied ')} {new Date(parseInt(application.applicationDate)).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
            </div>
            <div className="application-role">{t(application.role)}</div>
            <div className='application-state'>
              <div className="application-status">
                {t(application.status)}
              </div>
            </div>
          </div>
        </div>
        <div className='application-req-answ-cover'>
          {totalRequirements > 0 &&
            <div className={`application-requirements ${application.requirementsMet.length === totalRequirements ? 'full-opacity' : ''}`}>
              {t("Requirements")}
              <br />
              <div className="circle-top-container">
                {renderCircles(application.requirementsMet.length, totalRequirements)}
              </div>
          </div>
          }
          {totalQuestions > 0 &&
            <div className={`application-answers ${nonEmptyAnswers.length === totalQuestions ? 'full-opacity' : ''}`}>
              {t("Answers")}
              <br />
              <div className="circle-top-container">
                {renderCircles(nonEmptyAnswers.length, totalQuestions)}
              </div> 
                         </div>
          }
          <div className={`application-answers`}>
              {t("Overall Score")}
              <br />
              <div>{Math.round(overallScore)}</div>
                         </div>
        </div>

      </div>
      <div className="application-right">
        {renderLatestNote()}
        
        <LongTextField
          label="Add New Note"
          placeholder="Type your note here..."
          value={newNote}
          onChange={handleTextChange}
          mode="dark" // or "light"
        />

        <SaveChanges onClick={handleSaveClick} isModified={isModified} />
      </div>
    </div>
  );
}

export default ApplicantGeneral;
