import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for type checking
import './ProfilePicture.css';

const ProfilePicture = ({ name, imageUrl, highlight = false }) => {
  // Function to extract initials from the name
  const getInitials = (fullName) => {
    const namesArray = fullName.trim().split(' ');
    const initials = namesArray.length > 1
      ? namesArray[0][0] + namesArray[1][0]
      : namesArray[0][0];
    return initials.toUpperCase();
  };

  return (
    <div className={`profile-picture-container ${highlight ? 'highlight-border' : ''}`}>
      {imageUrl ? (
        <img src={imageUrl} alt="Profile" className="profile-image" />
      ) : (
        getInitials(name)
      )}
    </div>
  );
};

ProfilePicture.propTypes = {
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  highlight: PropTypes.bool, // Define the new prop
};

ProfilePicture.defaultProps = {
  highlight: false, // Set default value to false
};

export default ProfilePicture;
