// src/context/mockData.js
export const mockJobOffers = [
    {
      id: "1",
      title: "Junior Software Developer",
      description: "Develop cutting-edge applications using the latest technology.",
      place: "Milan",
      workMode: "Remote",
      responsibilities: ["Develop applications", "Maintain code", "Review code"],
      offers: ["Flexible hours", "Health insurance"],
      salaryRange: [30000, 40000],
      requirements: [
        {
          id: "1",
          requirementtype: "Technical",
          category: "Software",
          tags: ["JavaScript", "React"],
          years: 2,
          mandatory: true
        }
      ],
      applicationsCount: 10,
      state: "Active",
      createdAt: "1711065600000",
      workHours: "40 hours per week"
    },
    {
      title: "Marketing Specialist",
      description: "Lead our digital marketing efforts to increase brand awareness.",
      place: "Rome",
      workMode: "Hybrid",
      responsibilities: ["Plan campaigns", "Analyze metrics", "Manage social media"],
      offers: ["Travel opportunities", "Performance bonuses"],
      salaryRange: [35000, 45000],
      requirements: [
        {
          id: "2",
          requirementtype: "Professional",
          category: "Marketing",
          tags: ["SEO", "PPC"],
          years: 3,
          mandatory: false
        }
      ],
      applicationsCount: 5,
      state: "Paused",
      createdAt: "1710028800000",
      workHours: "35 hours per week"
    },
    {
      title: "Human Resources Manager",
      description: "Oversee recruitment and employee satisfaction initiatives.",
      place: "Venice",
      workMode: "In-office",
      responsibilities: ["Recruit talent", "Organize training", "Manage employee records"],
      offers: ["Team building activities", "Wellness programs"],
      salaryRange: [50000, 60000],
      requirements: [
        {
          id: "3",
          requirementtype: "Human Resources",
          category: "Management",
          tags: ["Communication", "Leadership"],
          years: 5,
          mandatory: true
        }
      ],
      applicationsCount: 8,
      state: "Active",
      createdAt: "1709078400000",
      workHours: "Full-time"
    }
  ];

  export const mockApplicants = [
    {
      profile: {
        fullname: "Alex Smith",
        email: "alexsmith@example.com",
        birthdate: "1709078400000",
        gender: "Male",
        phone: "+390234567890",
        address: "123 Via Roma, Milan",
        linkedinUrl: "https://linkedin.com/in/alexsmith",
        languages: [
          { name: "English", level: "3" },
          { name: "Italian", level: "2" }
        ]
      },
      academics: [
        {
          id: "1",
          school: "University of Milan",
          title: "Bachelor of Computer Science",
          startDate: "1709078400000",
          endDate: "1809078400000",
          category: "Engineering",
          tags: ["Computer Science", "Software Development"]
        }
      ],
      jobexperiences: [
        {
          id: "1",
          employer: "Tech Innovations Inc.",
          role: "Software Developer",
          description: "Developed multiple applications using JavaScript frameworks",
          startDate: "1709078400000",
          endDate: "1809078400000",
          experience: 4,
          category: "Software Development",
          tags: ["JavaScript", "React"]
        }
      ],
      applications: [
        {
          jobOfferID: "TheIDofJobOffer", // Assume you replace this with the actual ID from your system
          status: "Applied",
          isQualified: true,
          requirementsMet: ["JavaScript", "React"],
          applicationDate: new Date(),
          answers: [
            { questionId: "1", text: "I have extensive experience in JavaScript.", filled: true }
          ],
          coverLetter: "I am very excited about the opportunity to work with your team..."
        }
      ]
    },
    {
      profile: {
        fullname: "Maria Rossi",
        email: "maria.rossi@example.com",
        birthdate: new Date("1985-08-23"),
        gender: "Female",
        phone: "+390234567891",
        address: "456 Via Napoli, Rome",
        linkedinUrl: "https://linkedin.com/in/mariarossi",
        languages: [
          { name: "Italian", level: "Native" },
          { name: "English", level: "Professional" }
        ]
      },
      academics: [
        {
          id: "2",
          school: "Bocconi University",
          title: "Master in Marketing",
          startDate: new Date("2003-09-01"),
          endDate: new Date("2007-07-01"),
          category: "Marketing",
          tags: ["Marketing", "Business Administration"]
        }
      ],
      jobexperiences: [
        {
          id: "2",
          employer: "Global Marketing Solutions",
          role: "Marketing Manager",
          description: "Led a team in developing comprehensive digital marketing strategies",
          startDate: new Date("2008-05-01"),
          endDate: new Date("2020-04-30"),
          experience: 12,
          category: "Marketing",
          tags: ["SEO", "PPC"]
        }
      ],
      applications: [
        {
          jobOfferID: "1", // Replace with the actual job offer ID
          status: "Screening",
          isQualified: false,
          requirementsMet: ["SEO", "PPC"],
          applicationDate: new Date(),
          answers: [
            { questionId: "2", text: "I have led successful PPC campaigns increasing ROI significantly.", filled: true }
          ],
          coverLetter: "I believe my extensive marketing background aligns perfectly with the job requirements..."
        }
      ]
    }
  ];
  