import React, { useState } from 'react';
import TextField from '../textfields/TextField';
import './QuestionList.css';
import SelectField from './SelectField';
import { useTranslation } from 'react-i18next';

const QUESTION_TYPES = ['Text', 'Numbers', 'Yes/No'];

const QuestionList = ({ questions, setQuestions }) => {
  const { t } = useTranslation();
  const [question, setQuestion] = useState('');
  const [type, setType] = useState('Text');
  const [requirementValue, setRequirementValue] = useState('');
  const [choices, setChoices] = useState([]);
  const [currentChoice, setCurrentChoice] = useState('');
  const [idCounter, setIdCounter] = useState(1);
  const [rejectIfNotMet, setRejectIfNotMet] = useState(false); // New state for rejection condition

  const addQuestion = () => {
    if (question) {
      const newQuestion = { id: idCounter.toString(), question, type, requirementValue, choices, rejectIfNotMet };
      setQuestions([...questions, newQuestion]);
      setQuestion('');
      setType('Text');
      setRequirementValue('');
      setChoices([]);
      setRejectIfNotMet(false);
      setIdCounter(idCounter + 1);
    }
  };

  const addChoice = () => {
    if (currentChoice.trim() !== '') {
      const newChoice = { text: currentChoice.trim(), required: false };
      setChoices([...choices, newChoice]);
      setCurrentChoice('');
    }
  };

  const toggleRequiredChoice = (index) => {
    const updatedChoices = choices.map((choice, i) => {
      if (type === 'Choice') {
        return { ...choice, required: i === index };
      }
      if (type === 'Multiple Choice' && i === index) {
        return { ...choice, required: !choice.required };
      }
      return choice;
    });
    setChoices(updatedChoices);
  };

  const deleteChoice = (choiceIndex) => {
    setChoices(choices.filter((_, index) => index !== choiceIndex));
  };

  const deleteQuestion = (index) => {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  };

  return (
    <div className='questionList'>
      <div className="question-content">
        <div className="question-and-type-container">
          <TextField
            instruction='Specify the type and, if applicable, set a requirement.'
            label='Question'
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder='Enter question'
          />
          <SelectField
            label='Type'
            options={QUESTION_TYPES}
            value={type}
            onChange={(e) => setType(e.target.value)}
          />
        </div>
        {type === 'Numbers' && (
          <div>
            <TextField
              label='Requirement'
              instruction={'For "Numbers" type, enter a numeric requirement, e.g., "5".'}
              value={requirementValue}
              onChange={(e) => setRequirementValue(e.target.value)}
              placeholder={t('Enter numeric requirement')}
              type="number"
            />
            <div className="reject-condition">
              <label>
                <input
                  type="checkbox"
                  checked={rejectIfNotMet}
                  onChange={(e) => setRejectIfNotMet(e.target.checked)}
                />
                {t('Reject if requirement not met')}
              </label>
            </div>
          </div>
        )}
        {type === 'Yes/No' && (
          <div>
            <div className="reject-condition">
              <label>{t('Requirement')}</label>
              <div>
                <input
                  type="radio"
                  id="requireYes"
                  name="requirementValue"
                  value="Yes"
                  checked={requirementValue === 'Yes'}
                  onChange={(e) => setRequirementValue(e.target.value)}
                />
                <label htmlFor="requireYes">{t('Yes')}</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="requireNo"
                  name="requirementValue"
                  value="No"
                  checked={requirementValue === 'No'}
                  onChange={(e) => setRequirementValue(e.target.value)}
                />
                <label htmlFor="requireNo">{t('No')}</label>
              </div>
            </div>
            <div className="reject-condition">
              <label>
                <input
                  type="checkbox"
                  checked={rejectIfNotMet}
                  onChange={(e) => setRejectIfNotMet(e.target.checked)}
                />
                {t('Reject if requirement not met')}
              </label>
            </div>
          </div>
        )}
        {(type === 'Choice' || type === 'Multiple Choice') && (
          <div className="choices-adding">
            <TextField
              label='Choice'
              instruction={"Add choices for the question. Mark choices as 'Required' as needed."}
              value={currentChoice}
              onChange={(e) => setCurrentChoice(e.target.value)}
              placeholder='Add a choice'
            />
            <button className="question-add-button" onClick={addChoice}>{t('Add Choice')}</button>
          </div>
        )}
      </div>
      {(choices.length > 0 && (type === 'Choice' || type === 'Multiple Choice')) && (
        <div className="choices-grid">
          {choices.map((choice, index) => (
            <div className="choice-item" key={index}>
              {choice.text}
              <input
                type="checkbox"
                checked={choice.required}
                onChange={() => toggleRequiredChoice(index)}
              />
            </div>
          ))}
        </div>
      )}
      <button className="question-add-button" onClick={addQuestion}>{t('Add Question')}</button>
      <div className='questionArea'>
        {questions.map((item, index) => (
          <div className='questionItem' key={index}>
            <h4>{t('Question')}: {item.question}</h4>
            <p>{t('Type')}: {t(item.type)}</p>
            {item.type === 'Numbers' && <p>{t('Requirement')}: {item.requirementValue}</p>}
            {(item.type === 'Choice' || item.type === 'Multiple Choice') && (
              <>
                <p>{t('Choices')}: {item.choices.map(choice => `${choice.text}${choice.required ? ` (${t('Required')})` : ''}`).join(', ')}</p>
              </>
            )}
            {item.type === 'Yes/No' && (
              <>
                <p>{t('Requirement')}: {t(item.requirementValue)}</p>
              </>
            )}
            {(item.type === 'Numbers' || item.type === 'Yes/No') && (
              <p>{t('Reject if requirement not met')}: {item.rejectIfNotMet ? t('Yes') : t('No')}</p>
            )}
            <button className="question-delete-button" onClick={() => deleteQuestion(index)}>{t('Delete')}</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuestionList;
