import React from 'react';
import './LongTextField.css';
import { useTranslation } from 'react-i18next';

const LongTextField = ({ label, value, onChange, mode, placeholder}) => {
  const { t } = useTranslation();
  const modeClass = mode === 'dark' ? 'dark-mode' : '';

  return (
    <div className={`long-text-field ${modeClass}`}>
      <label className={`long-text-field-label ${modeClass}`}>{t(label)}</label>
      <textarea
        className={`long-text-field-input ${modeClass}`}
        value={value}
        onChange={onChange}
        placeholder={placeholder ? t(placeholder) : ''}
      />
    </div>
  );
};

export default LongTextField;
