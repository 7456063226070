import React, { useState, useEffect } from 'react';
import './ApplicantView.css';
import NavbarRecruiting from '../../../../components/navbar_recruiting/NavbarRecruiting';
import PersonalInfo from '../../../../components/PersonalInfo/PersonalInfo';
import CredentialSection from '../../../../components/credentialsection/CredentialSection';
import RequirementsView from '../../../../components/textfields/requirements/RequirementsView';
import SelectionField from '../../../../components/textfields/SelectionField';
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from 'react-router-dom';
import { useQuery, gql, useMutation } from '@apollo/client';
import LoadingPage from '../../../../Loading';
import BookInterview from '../../../../components/book-interview/BookInterview';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import Tag from '../../../../components/tag/Tag';

const GET_JOB_OFFER = gql`
  query GetJobOffer($JobOfferId: ID!) {
    getJobOffer(id: $JobOfferId) {
      id
      title
      place
      workMode
      recruiterID
      requirements {
        id
        requirementtype
        category
        tags
        years
        mandatory
      }
      questions {
        id
        text
        questiontype
        questionrequirement
      }
    }
  }
`;

const UPDATE_APPLICATION_STATUS = gql`
  mutation UpdateApplicationStatus($applicantId: ID!, $applicationId: ID!, $newStatus: String!) {
    updateApplicationStatus(applicantId: $applicantId, applicationId: $applicationId, newStatus: $newStatus) {
      id
      status
    }
  }
`;


const GET_RECRUITER = gql`
  query GetRecruiter($recruiterId: ID!) {
    getRecruiter(id: $recruiterId) {
      id
      userID
      company
      email
      logoUrl
      bookingUrl
    }
  }
`;

const QAView = ({ answers, jobOffer }) => {
  const { t } = useTranslation();

  if (answers.length === 0) {
    return <p className='no-questions-display'>{t('There are either no questions or they have not been answered by this applicant')}</p>;
  }

  const answeredQuestions = answers.map(answer => {
    const question = jobOffer?.questions.find(q => q.id === answer.questionId);
    return {
      questionText: question ? question.text : t("Question not found"),
      answerText: answer.text,
    };
  });

  return (
    <div>
      <h2 className='applicant-info-title'>{t('Questions & Answers')}</h2>
      {answeredQuestions.map((item, index) => (
        <div key={index} className='applicant-qa-item'>
          <p className='qa-question'>{item.questionText}</p>
          <p className='qa-answer'>{item.answerText || t("No answer")}</p>
        </div>
      ))}
    </div>
  );
};

const RequirementView = ({ requirements, requirementsMet }) => {
  // First, let's separate the language requirements from the others
  const languageRequirements = requirements.filter(req => req.requirementtype === 'Language');
  const otherRequirements = requirements.filter(req => req.requirementtype !== 'Language');

  // Function to render non-language requirement based on its type
  const renderRequirementContent = (requirement) => {
    switch (requirement.requirementtype) {
      case 'Experience':
        return (
          <>
            <p className='apply-page-requirement-item-text'>
              {requirement.years} years of experience in <strong>{requirement.category}</strong> with
            </p>
            <div>{requirement.tags.map((tag, index) => <Tag key={index}>{tag}</Tag>)}</div>
          </>
        );
      case 'Academic':
        return (
          <>
            <p className='apply-page-requirement-item-text'>
              A <strong>{requirement.category}</strong> with
            </p>
            <div>{requirement.tags.map((tag, index) => <Tag key={index}>{tag}</Tag>)}</div>
          </>
        );
      default:
        return <p>Unknown requirement type</p>;
    }
  };

  // Render a single card for all language requirements
  const renderLanguageRequirement = (languageReqs) => {
    return (
      <>
        <p className='apply-page-requirement-item-text'>Knowledge of the following <strong>languages</strong></p>
        <div>{languageReqs.map((req, index) => <Tag key={index}>{req.category} ({req.tags[0]})</Tag>)}</div>
      </>
    );
  };

  // Determine if a requirement is met
  const isRequirementMet = (requirementId) => {
    return requirementsMet.includes(requirementId);
  };

  return (
    <div className='apply-page-requirement-items'>
      {/* Render non-language requirements */}
      {otherRequirements.map((requirement, index) => (
        <div
          key={requirement.id}
          className={`apply-page-requirement-item ${index % 2 === 0 ? 'even' : 'odd'} ${isRequirementMet(requirement.id) ? 'met-requirement' : 'unmet-requirement'}`}
        >
          {renderRequirementContent(requirement)}
        </div>
      ))}
      {/* Render language requirements if there are any */}
      {languageRequirements.length > 0 && (
        <div className={`apply-page-requirement-item ${otherRequirements.length % 2 === 0 ? 'even' : 'odd'} ${isRequirementMet(languageRequirements.map(req => req.id)) ? 'met-requirement' : 'unmet-requirement'}`}>
          {renderLanguageRequirement(languageRequirements)}
        </div>
      )}
    </div>
  );
};



const ApplicantView = ({ applicant, onClose, viewType }) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth0();
  const [status, setStatus] = useState('Applied');
  const [updateApplicationStatus] = useMutation(UPDATE_APPLICATION_STATUS);
  const id = sessionStorage.getItem('currentJobOfferId');
  const [isCoverLetterExpanded, setIsCoverLetterExpanded] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const toggleCoverLetterView = () => {
    setIsCoverLetterExpanded(!isCoverLetterExpanded);
  };

  const { loading: loadingJobOffer, error: jobOfferError, data: jobOfferData } = useQuery(
    GET_JOB_OFFER,
    {
      variables: { JobOfferId: id },
      skip: !id,
      onError: (error) => {
        console.error('Job Offer Error Details:', error);
      },
    }
  );

  const jobOffer = jobOfferData ? jobOfferData.getJobOffer : null;
  const relevantApplication = applicant.applications.find(application => application.jobOfferID === id);
  const applicationDate = new Date(Number(relevantApplication?.applicationDate)).toLocaleDateString('en-GB');
  const coverLetter = relevantApplication?.coverLetter || "";
  const displayCoverLetter = isCoverLetterExpanded ? coverLetter : `${coverLetter.substring(0, 200)}...`;

  const { loading: loadingRecruiter, error: recruiterError, data: recruiterData } = useQuery(GET_RECRUITER, {
    variables: { recruiterId: jobOffer?.recruiterID },
    skip: !jobOffer,
    onError: (error) => {
      console.error('Recruiter Error Details:', error);
    },
  });

  useEffect(() => {
    if (relevantApplication?.status) {
      setStatus(relevantApplication.status);
    }
  }, [relevantApplication]);

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    updateApplicationStatus({
      variables: {
        applicantId: applicant.id,
        applicationId: relevantApplication.id,
        newStatus: newStatus,
      },
    });
  };


  const handleClose = () => {
    setIsOpen(false);
    setTimeout(onClose, 500);
  };

  if (loadingJobOffer || loadingRecruiter) {
    return <LoadingPage />;
  }

  if (jobOfferError || recruiterError) {
    return <div>Error loading data</div>;
  }

  return (
    isAuthenticated && (
      <div className={`applicant-overlay ${isOpen ? 'slide-in' : 'slide-out'}`}>
        <div className='applicant-view-content'>
        <div className='credential-sections-recruiter'>
            <CredentialSection
              title="Job Experiences"
              credentials={applicant?.jobexperiences || []}
              showAddButton={false}
              credentialType='job'
              showRefreshButton={false}
            />
            <CredentialSection
              title="Education"
              credentials={applicant?.academics || []}
              showAddButton={false}
              credentialType='academic'
              showRefreshButton={false}
            />
          </div>
          <div className='applicant-info-close'>
            <button className='overlay-close-button' onClick={handleClose}>{t('Close')}</button>

            <div className='applicant-info'>
              {viewType === 'type1' ? (
                <>
                  <div className='applicant-info-section'>
                    <PersonalInfo applicant={applicant.profile} />
                  </div>
                  <Divider />
                  <div className='applicant-info-section'>
                    <h1 className='applicant-info-title'>{t('Applicant Info')}</h1>
                    <h3 className='applicant-info-application-date'>{t('Applied on')} {applicationDate}</h3>
                    <SelectionField
                      label="Status"
                      options={["Rejected", "Applied", "Screening", "Interview", "Offer"]}
                      value={status}
                      alignRight={false}
                      onChange={handleStatusChange}
                    />

                    {recruiterData && (
                      <BookInterview
                        recruiter={recruiterData.getRecruiter}
                        applicant={applicant}
                        jobTitle={jobOffer?.title}
                      />
                    )}
                  </div>
                  <Divider />
                  <div className='applicant-info-section'>
                    <h2 className='applicant-info-title'>{t('Requirements')}</h2>
                    <RequirementView
                      requirements={jobOffer?.requirements}
                      requirementsMet={relevantApplication?.requirementsMet}
                    />
                  </div>
                  <Divider />
                  <div className='applicant-info-section'>
                    <h2 className='applicant-info-title'>{t('Cover Letter')}</h2>
                    {coverLetter && coverLetter.length > 200 ? (
                      <>
                        <p className='applicant-cover-letter'>{displayCoverLetter}</p>
                        <button onClick={toggleCoverLetterView} className='requirements-dropdown-button'>
                          {isCoverLetterExpanded ? t('Show Less') : t('Show More')}
                        </button>
                      </>
                    ) : (
                      <p className='applicant-cover-letter'>{coverLetter}</p>
                    )}
                  </div>
                  <Divider />
                  <div className='applicant-info-section'>
                    <QAView answers={relevantApplication?.answers || []} jobOffer={jobOffer} />
                  </div>
                </>
              ) : (
                <>
                  <div className='applicant-info-section'>
                    <PersonalInfo applicant={applicant?.profile} />
                  </div>
                  <Divider />
                </>
              )}
            </div>
          </div>
          <div className='credential-sections-recruiter'>
            <CredentialSection
              title="Job Experiences"
              credentials={applicant?.jobexperiences || []}
              showAddButton={false}
              credentialType='job'
              showRefreshButton={false}
            />
            <CredentialSection
              title="Education"
              credentials={applicant?.academics || []}
              showAddButton={false}
              credentialType='academic'
              showRefreshButton={false}
            />
          </div>
          
        </div>
      </div>
    )
  );
};

export default ApplicantView;
