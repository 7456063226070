import React, { useState } from 'react';
import TextField from '../../../components/textfields/TextField';
import Applicant from '../../../components/applicant/applicant-general/ApplicantGeneral';
import ApplicantGeneral from '../../../components/applicant/applicant-general/ApplicantGeneral';


const Test = () => {
    const [value, setValue] = useState(''); // initial state is an empty string
  
    const handleChange = (event) => {
      setValue(event.target.value); // update the state when the text field changes
    };
  
    const jobOffer = {
      role: 'Software Developer',
      requirements: ['Javascript', 'React', 'Node.js', 'CSS', 'HTML', 'Communication', 'Teamwork'],
    };
  
    const application = {
      id: 1,
      name: 'John Doe',
      date: '2023-07-01',
      role: 'Software Developer',
      requirements: jobOffer.requirements,
      requirementsMet: ['Javascript', 'React', 'Node.js', 'CSS', 'HTML', 'Communication'],
    };
  
    return (
      <div>
        <TextField 
          label="Test Field" 
          type="text" 
          placeholder="Test Placeholder" 
          value={value} 
          onChange={handleChange} 
        />
        <ApplicantGeneral application={application} />
      </div>
    );
  }

export default Test;
