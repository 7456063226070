import React, { useState, useEffect } from 'react';
import './CatTagsSelection.css';
import { Form, Select } from 'antd';
import TagsComponent from './TagsComponent';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const CatTagsSelection = ({
  categoriesAndTags,
  maxTags,
  onCategorySelect,
  onTagsSelect,
  selectedCategory,
  selectedTags
}) => {
  const { t } = useTranslation();
  const [internalSelectedCategory, setInternalSelectedCategory] = useState(selectedCategory);
  const [internalSelectedTags, setInternalSelectedTags] = useState(selectedTags);

  useEffect(() => {
    setInternalSelectedCategory(selectedCategory);
  }, [selectedCategory]);
  
  useEffect(() => {
    setInternalSelectedTags(selectedTags);
  }, [selectedTags]);
  

  const handleCategoryChange = (value) => {
    setInternalSelectedCategory(value);
    onCategorySelect(value);
    setInternalSelectedTags([]);
    onTagsSelect([]);
  };

  const handleTagsChange = (tag, checked) => {
    setInternalSelectedTags(prevTags => {
      const updatedTags = checked
        ? [...new Set([...prevTags, tag])] // Ensure no duplicates
        : prevTags.filter(t => t !== tag);
  
      // Move the onTagsSelect call inside the updater function to ensure it has the updated state
      onTagsSelect(tag, checked); // This should now correctly indicate which tag and whether it was added or removed
      
      return updatedTags;
    });
  };
  


  return (
    <Form layout="vertical">
      <h2 className='category-title'>{t('Category')}</h2>
      <Form.Item>
        <Select
          placeholder={t("Select a Category")}
          value={internalSelectedCategory}
          onChange={handleCategoryChange}
          style={{ width: '100%' }}
        >
          {categoriesAndTags.map((category) => (
            <Option key={category.name} value={category.name}>{category.name}</Option>
          ))}
        </Select>
      </Form.Item>
      {internalSelectedCategory && (
        <TagsComponent
          category={internalSelectedCategory}
          tagsData={categoriesAndTags.find(cat => cat.name === internalSelectedCategory)?.tags || []}
          selectedTags={selectedTags}
          handleTagSelect={handleTagsChange}
          maxTags={maxTags}
        />
      )}
    </Form>
  );
};

export default CatTagsSelection;
