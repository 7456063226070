import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery, gql } from '@apollo/client';
import { mockJobOffers } from './../mockData'; // Ensure this import is correct based on where you store mock data

const GET_RECRUITER_BY_USER = gql`
query GetRecruiterByUserId($userId: String!) {
    getRecruiterByUserId(userID: $userId) {
      id
      userID
      company
      description
      subscription
      stripeCustomerId
    }
  }
`;

const SubscriptionContext = createContext();

export const useSubscription = () => useContext(SubscriptionContext);

const SubscriptionProvider = ({ children }) => {
  const { user, isAuthenticated } = useAuth0();
  const [subscription, setSubscription] = useState('Free'); // Default to 'Free' for simplicity
  const [stripeCustomerId, setStripeCustomerId] = useState(''); // State for storing stripe customer ID
  const [mockdata, setMockData] = useState(mockJobOffers); // Set default data

  const { loading: loadingRecruiter, error: recruiterError, data: recruiterData } = useQuery(
    GET_RECRUITER_BY_USER,
    {
      variables: { userId: user?.sub },
      skip: !isAuthenticated,
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        if (data?.getRecruiterByUserId?.id) {
          sessionStorage.setItem('recruiterId', data.getRecruiterByUserId.id);
          sessionStorage.setItem('company', data.getRecruiterByUserId.company);
          setStripeCustomerId(data.getRecruiterByUserId.stripeCustomerId);
        }
      },
    }
  );
  

  useEffect(() => {
    if (recruiterData?.getRecruiterByUserId?.subscription) {
      setSubscription(recruiterData.getRecruiterByUserId.subscription);
      //console.log ('Subscription:', recruiterData.getRecruiterByUserId.stripeCustomerId);
    }
  }, [loadingRecruiter, recruiterError, recruiterData]);

  
  const value = {
    subscription,
    mockdata,
    stripeCustomerId // Add stripeCustomerId to the context value
  };

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionProvider;
