export const JobExperienceCategories = [
   {
  section: "Software & Web Development",
  name: "Full-Stack Development",
  tags: [
    "JavaScript",
    "Node.js",
    "Express.js",
    "React.js",
    "Vue.js",
    "Ruby on Rails",
    "Python",
    "Django",
    "Java",
    "Spring Boot",
    "SQL", 
    "NoSQL",
    "MongoDB",
    "PostgreSQL",
    "Git",
    "API Development",
    "Heroku",
    "Docker",
    "AWS",
    "CI/CD",
    ".NET",
    "NextJS"
  ],
},
{
  section: "Software & Web Development",
  name: "Front-end Development",
  tags: [
    "HTML5",
    "CSS",
    "SASS",
    "LESS",
    "JavaScript",
    "ES6",
    "TypeScript",
    "React.js",
    "Vue.js",
    "Angular.js",
    "Redux",
    "Vuex",
    "Bootstrap",
    "Material-UI",
    "JQuery",
    "Bulma",
    "Webpack",
    "Babel",
    "NPM",
    "Yarn",
    "GraphQL",
    "Ajax",
    "Web Accessibility",
    "UI/UX Design",
    "Performance Optimization",
    "Responsive Web Design",
    "Cross-Browser Compatibility",
    "NextJS",
    ".NET"
  ],
},
{
  section: "Software & Web Development",
  name: "Back-end Development",
  tags: [
    "Node.js",
    "Ruby on Rails",
    "Python",
    "Flask",
    "Django",
    "Java",
    "Spring Boot",
    "PHP",
    "Laravel",
    "API Development",
    "REST",
    "GraphQL",
    "SQL",
    "NoSQL",
    "MongoDB",
    "PostgreSQL",
    "MySQL",
    "Docker",
    "Microservices",
    "AWS Lambda",
    "Serverless Architecture",
    "Apache Kafka",
    "Git",
    ".NET"
  ],
},
{
  section: "Software & Web Development",
  name: "Mobile App Development",
  tags: [
    "Swift",
    "Objective-C",
    "iOS",
    "Java",
    "Kotlin",
    "Android",
    "Flutter",
    "Dart",
    "React Native",
    "JavaScript",
    "TypeScript",
    "Mobile UI/UX Design",
    "Firebase",
    "Cross-Platform Development",
    "App Store Deployment",
    "Performance Tuning",
    "SQLite",
    "Push Notifications",
    "Mobile Hardware Integration"
  ],
},
{
  section: "Software & Web Development",
  name: "Embedded Systems Development",
  tags: [
    "C",
    "C++",
    "Assembly",
    "Microcontroller Programming",
    "RTOS",
    "Arduino",
    "Raspberry Pi",
    "Firmware Development",
    "IoT",
    "UART",
    "SPI",
    "I2C",
    "CAN",
    "Ethernet",
    "Wireless Communication",
    "Device Drivers",
    "RTOS",
    "Embedded Linux",
    "Debugging",
    "Hardware-Software Integration"
  ],
},
{
  section: "Software & Web Development",
  name: "Game Development",
  tags: [
    "Unity",
    "Unreal Engine",
    "C#",
    "C++",
    "DirectX",
    "OpenGL",
    "Physics Simulation",
    "2D/3D Animation",
    "AI Programming",
    "VR/AR Integration",
    "Game Testing",
    "Scripting",
    "Game Design",
    "Multiplayer Networking",
    "Monetization",
    "Mobile Game Development",
    "Console Game Development",
    "Cross-Platform Game Development"
  ],
},
{
  section: "Software & Web Development",
  name: "Desktop Application Development",
  tags: [
    "Java",
    "C++",
    "Python",
    "Electron",
    "C#",
    ".NET",
    "WPF",
    "Qt",
    "GTK",
    "Windows",
    "macOS",
    "Linux",
    "User Interface Design",
    "Multi-Threading",
    "Database Integration",
    "API Integration",
    "Performance Optimization"
  ],
},
{
  section: "Software & Web Development",
  name: "DevOps & Infrastructure",
  tags: [
    "Docker",
    "Kubernetes",
    "Jenkins",
    "GitLab CI/CD",
    "Travis CI",
    "AWS",
    "Azure",
    "Google Cloud",
    "Terraform",
    "Ansible",
    "Monitoring",
    "Logging",
    "Site Reliability",
    "Network Management",
    "Server Configuration",
    "Automation",
    "Release Management",
    "Version Control",
    "Infrastructure as Code",
    "Continuous Integration",
    "Continuous Deployment"
  ],
},
{
  section: "Software & Web Development",
  name: "Database Administration & Development",
  tags: [
    "SQL",
    "NoSQL",
    "MongoDB",
    "PostgreSQL",
    "MySQL",
    "Oracle DB",
    "Microsoft SQL Server",
    "SQLite",
    "Database Design",
    "Normalization",
    "Performance Tuning",
    "Backup and Recovery",
    "Replication",
    "Clustering",
    "Indexing",
    "Stored Procedures",
    "ETL",
    "Big Data Solutions",
    "Data Warehousing"
  ],
},
{
  section: "Software & Web Development",
  name: "Systems Architecture & Design",
  tags: [
    "System Design",
    "Microservices",
    "Monolithic Architecture",
    "Service-Oriented Architecture (SOA)",
    "API Design",
    "Database Design",
    "Distributed Systems",
    "Scalability",
    "Performance Optimization",
    "System Integration",
    "Data Flow Diagrams",
    "Network Design",
    "Security Architecture",
    "High Availability",
    "Disaster Recovery"
  ],
},
{
  section: "Software & Web Development",
  name: "Quality Assurance & Testing",
  tags: [
    "Manual Testing",
    "Automated Testing",
    "JUnit",
    "TestNG",
    "Selenium",
    "Appium",
    "Load Testing",
    "Stress Testing",
    "Performance Testing",
    "Integration Testing",
    "Regression Testing",
    "Black Box Testing",
    "White Box Testing",
    "UAT (User Acceptance Testing)",
    "Bug Tracking",
    "Test Case Development",
    "Agile Testing",
    "TDD (Test Driven Development)",
    "BDD (Behavior Driven Development)",
    "Continuous Testing"
  ],
},
{
  section: "Software & Web Development",
  name: "Cybersecurity & Ethical Hacking",
  tags: [
    "Penetration Testing",
    "Network Security",
    "Application Security",
    "Endpoint Security",
    "Cryptography",
    "Firewall Management",
    "IDS/IPS",
    "Vulnerability Assessment",
    "Malware Analysis",
    "Risk Assessment",
    "Incident Response",
    "Security Compliance",
    "Ethical Hacking",
    "Forensic Analysis",
    "OWASP",
    "Social Engineering",
    "Phishing"
  ],
},
{
  section: "Software & Web Development",
  name: "Data Science & Machine Learning Development",
  tags: [
    "Python",
    "R",
    "TensorFlow",
    "Keras",
    "PyTorch",
    "Scikit-learn",
    "Data Cleaning",
    "Data Visualization",
    "Statistical Analysis",
    "Regression",
    "Classification",
    "Deep Learning",
    "Neural Networks",
    "Natural Language Processing",
    "Computer Vision",
    "Big Data",
    "Spark",
    "Hadoop",
    "Time Series Analysis"
  ],
},
{
  section: "Software & Web Development",
  name: "Cloud Computing & Development",
  tags: [
    "AWS",
    "Azure",
    "Google Cloud Platform",
    "Serverless Computing",
    "Lambda",
    "CloudFormation",
    "Docker",
    "Kubernetes",
    "Elastic Beanstalk",
    "S3",
    "EC2",
    "CloudFront",
    "Cloud Networking",
    "IAM",
    "Database Services",
    "Storage Services",
    "Cloud Security",
    "Hybrid Cloud",
    "Multi-cloud Management"
  ],
},
{
  section: "Software & Web Development",
  name: "Blockchain & Distributed Systems Development",
  tags: [
    "Ethereum",
    "Smart Contracts",
    "Solidity",
    "Hyperledger",
    "DApp Development",
    "Cryptography",
    "Blockchain Architecture",
    "Distributed Ledger Technology",
    "Token Development",
    "ICO",
    "Consensus Algorithms",
    "Peer-to-Peer Networks",
    "Blockchain Security",
    "Decentralized Systems",
    "Public and Private Blockchains"
  ],
},
{
  section: "Software & Web Development",
  name: "AR/VR Development",
  tags: [
    "Unity",
    "Unreal Engine",
    "C#",
    "C++",
    "ARKit",
    "ARCore",
    "Vuforia",
    "Oculus SDK",
    "SteamVR",
    "Virtual Reality",
    "Augmented Reality",
    "Mixed Reality",
    "3D Modeling",
    "Spatial Audio",
    "Gesture Recognition",
    "VR Game Development",
    "VR Interface Design",
    "Mobile AR/VR",
    "Performance Optimization"
  ],
},
{
  section: "Software & Web Development",
  name: "Algorithm Design & Optimization",
  tags: [
    "Big O Notation",
    "Search Algorithms",
    "Sort Algorithms",
    "Graph Algorithms",
    "Dynamic Programming",
    "Greedy Algorithms",
    "Recursion",
    "Tree Structures",
    "Hashing",
    "Cryptography Algorithms",
    "Machine Learning Algorithms",
    "Parallel Processing",
    "Distributed Algorithms",
    "Optimization Techniques"
  ],
},
{
  section: "Software & Web Development",
  name: "API Development & Integration",
  tags: [
    "REST",
    "SOAP",
    "GraphQL",
    "JSON",
    "XML",
    "OpenAPI",
    "Postman",
    "Swagger",
    "OAuth",
    "JWT",
    "API Security",
    "Rate Limiting",
    "API Gateway",
    "Webhooks",
    "API Documentation",
    "Third-party API Integration"
  ],
},
{
  section: "Software & Web Development",
  name: "E-commerce Development",
  tags: [
    "WooCommerce",
    "Magento",
    "Shopify",
    "BigCommerce",
    "Drupal Commerce",
    "PrestaShop",
    "OsCommerce",
    "SSL Integration",
    "Payment Gateway Integration",
    "Shopping Cart",
    "Product Management",
    "Checkout Process",
    "E-commerce SEO",
    "Mobile E-commerce",
    "Inventory Management"
  ],
},
{
  section: "Software & Web Development",
  name: "Content Management Systems (CMS) Development",
  tags: [
    "WordPress",
    "Joomla",
    "Drupal",
    "Typo3",
    "Squarespace",
    "Wix",
    "Blogger",
    "Custom CMS Development",
    "Themes",
    "Plugins",
    "Database Management",
    "Content Organization",
    "SEO Integration",
    "Multilingual Support"
  ],
},
{
  section: "Software & Web Development",
  name: "Firmware Development",
  tags: [
    "C",
    "C++",
    "Assembly",
    "RTOS",
    "Embedded C",
    "Microcontrollers",
    "Device Drivers",
    "Hardware Abstraction Layer",
    "I2C",
    "SPI",
    "UART",
    "Memory Management",
    "Power Management",
    "Bootloaders",
    "Debugging Tools",
    "Real-time Systems"
  ],
},

{
  section: "Design & Multimedia",
  name: "Graphic Design",
  tags: [
    "Adobe Creative Suite",
    "Photoshop",
    "Illustrator",
    "InDesign",
    "CorelDRAW",
    "Vector Illustration",
    "Photo Editing",
    "Typography",
    "Branding",
    "Logo Design",
    "Print Design",
    "Layout Design",
    "Color Theory",
    "Infographics",
    "Digital Illustration",
    "Raster Graphics"
  ],
},
{
  section: "Design & Multimedia",
  name: "UI/UX Design",
  tags: [
    "Sketch",
    "Figma",
    "Adobe XD",
    "Wireframing",
    "Prototyping",
    "User Testing",
    "Interaction Design",
    "User Persona Creation",
    "User Journey Mapping",
    "Usability Testing",
    "Information Architecture",
    "Responsive Design"
  ],
},
{
  section: "Design & Multimedia",
  name: "Animation & Video Production",
  tags: [
    "After Effects",
    "Premiere Pro",
    "Cinema 4D",
    "Maya",
    "3D Animation",
    "2D Animation",
    "Storyboarding",
    "Video Editing",
    "Motion Graphics",
    "Character Animation",
    "Special Effects",
    "Sound Editing",
    "Color Correction"
  ],
},
{
  section: "Design & Multimedia",
  name: "Web Design",
  tags: [
    "HTML5",
    "CSS3",
    "Responsive Design",
    "Bootstrap",
    "WordPress",
    "Joomla",
    "Drupal",
    "Theme Customization",
    "Landing Page Design",
    "Parallax Design",
    "Mobile-first Design",
    "Web Accessibility"
  ],
},
{
  section: "Design & Multimedia",
  name: "Illustration",
  tags: [
    "Digital Art",
    "Character Design",
    "Concept Art",
    "Technical Illustration",
    "Children's Book Illustration",
    "Comic Art",
    "Storyboarding",
    "Pen & Ink Drawing",
    "Watercolor",
    "Digital Painting",
    "Vector Art"
  ],
},
{
  section: "Design & Multimedia",
  name: "Product Design",
  tags: [
    "Sketching",
    "Prototyping",
    "CAD Design",
    "Material Selection",
    "Ergonomics",
    "3D Modeling",
    "User-centered Design",
    "Functional Prototypes",
    "Product Lifecycle Management",
    "Industrial Design",
    "Design Thinking"
  ],
},
{
  section: "Design & Multimedia",
  name: "Print Design & Layout",
  tags: [
    "Brochure Design",
    "Flyer Design",
    "Poster Design",
    "Business Cards",
    "Magazine Layout",
    "Catalog Design",
    "Typography",
    "Page Layout",
    "Pre-press",
    "Packaging Design",
    "Print Production"
  ],
},
{
  section: "Design & Multimedia",
  name: "3D Modeling & CAD",
  tags: [
    "Autodesk AutoCAD",
    "Blender",
    "ZBrush",
    "SolidWorks",
    "3ds Max",
    "Rhinoceros",
    "Revit",
    "Architecture Visualization",
    "3D Printing",
    "Sculpting",
    "Surface Modeling",
    "Technical Drawing"
  ],
},
{
  section: "Design & Multimedia",
  name: "Game Design & Development",
  tags: [
    "Unity",
    "Unreal Engine",
    "Game Mechanics",
    "Level Design",
    "Character Design",
    "Scripting",
    "Mobile Game Design",
    "VR & AR Design",
    "Game Testing",
    "Sound Design",
    "Texture and Lighting",
    "Player Experience (PX)"
  ],
},
{
  section: "Design & Multimedia",
  name: "Audio Production & Editing",
  tags: [
    "Pro Tools",
    "Ableton Live",
    "FL Studio",
    "Sound Mixing",
    "Mastering",
    "Sound Effects",
    "Audio Restoration",
    "MIDI Production",
    "Voice Over",
    "Field Recording",
    "Sound Design",
    "Music Composition"
  ],
},
{
  section: "Design & Multimedia",
  name: "Photography",
  tags: [
    "Digital Photography",
    "Photo Editing",
    "Lightroom",
    "Portrait Photography",
    "Landscape Photography",
    "Product Photography",
    "Event Photography",
    "Studio Lighting",
    "Photojournalism",
    "Macro Photography",
    "Retouching",
    "Color Grading"
  ],
},
{
  section: "Design & Multimedia",
  name: "Multimedia Artistry",
  tags: [
    "Interactive Design",
    "Virtual Reality",
    "Augmented Reality",
    "MixedReality",
    "Video Production",
    "Sound Editing",
    "Multimedia Presentation",
    "Interactive PDFs",
    "Digital Installations",
    "eLearning Modules"
  ],
},
{
  section: "Design & Multimedia",
  name: "Motion Graphics",
  tags: [
    "After Effects",
    "Cinema 4D",
    "Storyboarding",
    "Character Animation",
    "Typography Animation",
    "Visual Effects",
    "Particle Effects",
    "2D & 3D Motion",
    "Logo Animation",
    "Infographics Animation"
  ],
},
{
  section: "Design & Multimedia",
  name: "Brand & Identity Design",
  tags: [
    "Logo Design",
    "Brand Guidelines",
    "Corporate Identity",
    "Brand Strategy",
    "Visual Identity Systems",
    "Business Cards",
    "Letterhead Design",
    "Brand Voice",
    "Merchandise Design",
    "Brand Audit"
  ],
},
{
  section: "Design & Multimedia",
  name: "Digital Art & Drawing",
  tags: [
    "Digital Painting",
    "Character Art",
    "Concept Art",
    "Manga & Comics",
    "Digital Sketching",
    "Fantasy Art",
    "Sci-fi Art",
    "Storyboarding",
    "Tablet & Stylus Techniques",
    "Digital Sculpting"
  ],
},
{
  section: "Design & Multimedia",
  name: "Typography & Lettering",
  tags: [
    "Font Design",
    "Calligraphy",
    "Hand Lettering",
    "Typeface Selection",
    "Print Typography",
    "Digital Typography",
    "Font Pairing",
    "Custom Lettering",
    "Typography Layout",
    "Type Anatomy",
    "Kerning",
    "Leading"
  ],
},
{
  section: "Design & Multimedia",
  name: "Environmental & Spatial Design",
  tags: [
    "Interior Design",
    "Architectural Design",
    "Landscape Design",
    "Retail Space Design",
    "Exhibition Design",
    "Wayfinding",
    "Signage",
    "Spatial Branding",
    "Public Space Planning",
    "Furniture Design"
  ],
},
{
  section: "Design & Multimedia",
  name: "Fashion & Apparel Design",
  tags: [
    "Garment Design",
    "Fashion Illustration",
    "Pattern Making",
    "Textile Design",
    "Fashion Branding",
    "Accessory Design",
    "Footwear Design",
    "Trend Forecasting",
    "Fashion Styling",
    "Fashion Photography"
  ],
},
{
  section: "Design & Multimedia",
  name: "Package Design",
  tags: [
    "Product Packaging",
    "Label Design",
    "Bottle & Container Design",
    "Retail Packaging",
    "Eco-Friendly Packaging",
    "Unboxing Experience",
    "Branding",
    "Typography",
    "Barcodes & Labeling",
    "Prototype Development"
  ],
},
{
  section: "Sales & Marketing",
  name: "Digital & Online Marketing",
  tags: [
    "SEO",
    "SEM",
    "PPC",
    "Google Ads",
    "Facebook Ads",
    "Google Analytics",
    "Affiliate Marketing",
    "Email Campaigns",
    "Landing Page Optimization",
    "Conversion Rate Optimization"
  ]
},
{
  section: "Sales & Marketing",
  name: "Public Relations & Branding",
  tags: [
    "Media Relations",
    "Crisis Management",
    "Event Coordination",
    "Brand Strategy",
    "Corporate Communication",
    "Stakeholder Engagement",
    "Content Creation"
  ]
},
{
  section: "Sales & Marketing",
  name: "Sales",
  tags: [
    "Lead Generation",
    "Cold Calling",
    "Sales Presentations",
    "Account Management",
    "Negotiation",
    "CRM Tools",
    "Sales Strategy",
    "Cross-selling",
    "Up-selling"
  ]
},
{
  section: "Sales & Marketing",
  name: "Content & Social Media Marketing",
  tags: [
    "Content Strategy",
    "Blogging",
    "Video Creation",
    "Instagram Marketing",
    "Twitter Marketing",
    "LinkedIn Marketing",
    "Influencer Collaboration",
    "Content Calendar",
    "Engagement Analysis"
  ]
},
{
  section: "Sales & Marketing",
  name: "Market Research & Strategy",
  tags: [
    "Consumer Surveys",
    "Focus Groups",
    "Market Segmentation",
    "Competitive Analysis",
    "SWOT Analysis",
    "Trend Forecasting",
    "Data Analytics",
    "Product Positioning"
  ]
},
{
  section: "Sales & Marketing",
  name: "Product & Event Marketing",
  tags: [
    "Product Launches",
    "Promotional Campaigns",
    "Event Planning",
    "Sponsorship Management",
    "Trade Shows",
    "Product Demos",
    "Cross-Promotions",
    "Venue Selection",
    "Event Logistics"
  ]
},
{
  section: "Sales & Marketing",
  name: "Channel & Partnership Management",
  tags: [
    "Channel Strategy",
    "Partner Recruitment",
    "Co-Marketing Initiatives",
    "Channel Training",
    "Partnership Agreements",
    "Revenue Sharing",
    "Affiliate Programs",
    "Reseller Programs"
  ]
},
{
  section: "Administration & Support",
  name: "Office & Executive Administration",
  tags: [
    "Scheduling",
    "Meeting Coordination",
    "Office Management",
    "Stakeholder Communication",
    "Calendar Management",
    "Reporting",
    "Office Software Proficiency",
    "Travel Coordination",
    "Budgeting",
    "Event Organization",
    "Minute Taking",
    "Task Prioritization"
  ],
},
{
  section: "Administration & Support",
  name: "Customer & Technical Support",
  tags: [
    "Helpdesk Operation",
    "Customer Service",
    "Technical Troubleshooting",
    "CRM Tools",
    "Communication Skills",
    "Problem-Solving",
    "SLA Compliance",
    "Customer Feedback Analysis",
    "Product Knowledge",
    "Remote Support Tools",
    "Service Ticket Management",
    "Conflict Resolution"
  ],
},
{
  section: "Administration & Support",
  name: "Human Resources & Recruitment",
  tags: [
    "Talent Acquisition",
    "Onboarding",
    "Employee Relations",
    "Benefits Administration",
    "HRIS Systems",
    "Training & Development",
    "Performance Management",
    "Compensation Analysis",
    "Legal Compliance",
    "Employee Engagement",
    "Exit Interviews",
    "Payroll Management"
  ],
},
{
  section: "Administration & Support",
  name: "Facilities & Event Management",
  tags: [
    "Venue Sourcing",
    "Vendor Management",
    "Event Planning",
    "Budgeting & Costing",
    "Logistics",
    "Facility Maintenance",
    "Space Planning",
    "Security Coordination",
    "Health & Safety",
    "Inventory Control",
    "Resource Allocation",
    "Event Promotion"
  ],
},
{
  section: "Administration & Support",
  name: "Data & Document Management",
  tags: [
    "Document Archiving",
    "Data Entry",
    "Database Management",
    "Confidentiality",
    "Version Control",
    "Data Analysis",
    "Record Keeping",
    "Filing Systems",
    "Electronic Storage Solutions",
    "Document Review & Editing",
    "Collaborative Tools",
    "Metadata Management"
  ],
},
{
  section: "Finance & Management",
  name: "Financial Analysis & Corporate Finance",
  tags: [
    "Financial Modeling",
    "Forecasting",
    "Budgeting",
    "Variance Analysis",
    "Financial Reporting",
    "Strategic Planning",
    "Capital Budgeting",
    "DCF",
    "NPV Analysis",
    "IRR",
    "Cost Analysis",
    "Profitability Analysis",
    "Financial Ratios",
    "Balance Sheet Analysis",
    "Income Statement Analysis",
    "Cash Flow Analysis",
    "Scenario Analysis",
    "Sensitivity Analysis",
    "Financial Statements Consolidation",
    "Merger Modeling",
  ],
},
{
  section: "Finance & Management",
  name: "Project Management",
  tags: [
    "Scrum",
    "Agile",
    "Waterfall",
    "Kanban",
    "Lean",
    "MS Project",
    "PMP",
    "Prince2",
    "Risk Assessment",
    "Stakeholder Management",
    "Gantt Chart",
    "Project Scheduling",
    "Budget Management",
    "Resource Allocation",
    "Scope Management",
    "Quality Management",
    "Documentation",
    "Reporting",
  ],
},
{
  section: "Finance & Management",
  name: "Consulting",
  tags: [
    "Business Strategy",
    "Management Consulting",
    "IT Consulting",
    "Market Analysis",
    "SWOT Analysis",
    "Competitive Analysis",
    "Change Management",
    "Operational Efficiency",
    "Stakeholder Interviews",
    "Client Relations",
    "Business Process Reengineering",
    "Benchmarking",
  ],
},
{
  section: "Finance & Management",
  name: "Accounting & Bookkeeping",
  tags: [
    "General Ledger",
    "Accounts Payable",
    "Accounts Receivable",
    "Payroll Processing",
    "Journal Entries",
    "Bank Reconciliation",
    "Financial Statement Preparation",
    "GAAP",
    "Tax Preparation",
    "Invoicing",
    "Expense Tracking",
    "QuickBooks",
    "Xero",
    "Financial Record Keeping",
    "Monthly Closings",
  ],
},
{
  section: "Finance & Management",
  name: "Investment & Portfolio Management",
  tags: [
    "Asset Allocation",
    "Risk Management",
    "Bonds",
    "Equities",
    "Hedge Funds",
    "Mutual Funds",
    "Alternative Investments",
    "Technical Analysis",
    "Fundamental Analysis",
    "Return on Investment (ROI)",
    "CAPM",
    "Derivatives",
    "Securities Analysis",
    "Market Research",
    "Wealth Management",
  ],
},
{
  section: "Finance & Management",
  name: "Auditing & Compliance & Regulatory Affairs",
  tags: [
    "Internal Audit",
    "External Audit",
    "SOX Compliance",
    "Risk Assessment",
    "Audit Reports",
    "Financial Review",
    "Operational Audit",
    "Compliance Testing",
    "Regulatory Reporting",
    "Quality Assurance",
    "Control Framework",
    "Financial Regulations",
    "Anti-Money Laundering",
    "Know Your Customer (KYC)",
  ],
},
{
  section: "Finance & Management",
  name: "Taxation & Tax Consulting",
  tags: [
    "Income Tax",
    "VAT",
    "Sales Tax",
    "Corporate Tax",
    "International Tax",
    "Tax Planning",
    "Tax Compliance",
    "Tax Deductions",
    "Tax Advisory",
    "Tax Law",
    "Tax Audit",
    "Tax Accounting",
    "Transfer Pricing",
    "Tax Software",
    "Excise Tax",
  ],
},
{
  section: "Finance & Management",
  name: "Risk Management & Treasury Management",
  tags: [
    "Liquidity Management",
    "Financial Risk",
    "Operational Risk",
    "Credit Risk",
    "Market Risk",
    "Enterprise Risk Management (ERM)",
    "Risk Modelling",
    "Derivatives Hedging",
    "Currency Hedging",
    "Interest Rate Forecasting",
    "Cash Management",
    "Investment Management",
    "Debt Management",
    "Capital Structure",
  ],
},
{
  section: "Finance & Management",
  name: "Mergers & Acquisitions",
  tags: [
    "Due Diligence",
    "Valuation",
    "Synergy Analysis",
    "Deal Structuring",
    "Negotiation",
    "Integration Planning",
    "Post-merger Integration",
    "Divestitures",
    "Spin-offs",
    "LBO Modelling",
    "Acquisition Financing",
  ],
},
{
  section: "Finance & Management",
  name: "Banking & Lending",
  tags: [
    "Retail Banking",
    "Commercial Banking",
    "Investment Banking",
    "Credit Analysis",
    "Loan Underwriting",
    "Portfolio Management",
    "Mortgages",
    "Credit Cards",
    "Personal Loans",
    "Asset Management",
    "Relationship Management",
  ],
},
{
  section: "Finance & Management",
  name: "Financial Planning & Advising",
  tags: [
    "Wealth Management",
    "Retirement Planning",
    "Estate Planning",
    "Tax Planning",
    "Risk Assessment",
    "Asset Allocation",
    "Life Insurance",
    "Trusts",
    "Investment Strategies",
    "Client Relations",
    "Financial Goals Setting",
  ],
},
{
  section: "Finance & Management",
  name: "Insurance",
  tags: [
    "Underwriting",
    "Claims Management",
    "Policy Administration",
    "Risk Analysis",
    "Product Development",
    "Insurance Sales",
    "Reinsurance",
    "Actuarial Analysis",
    "Insurance Regulations",
    "Property and Casualty",
    "Life Insurance",
  ],
},
{
  section: "Finance & Management",
  name: "Venture Capital & Private Equity",
  tags: [
    "Deal Sourcing",
    "Portfolio Management",
    "Equity Research",
    "Valuation",
    "Due Diligence",
    "Term Sheet Negotiation",
    "Capital Raising",
    "Exit Strategies",
    "Investment Strategies",
    "Fund Management",
  ],
},
{
  section: "Finance & Management",
  name: "Business Strategy & Planning & Operations Management",
  tags: [
    "Strategic Planning",
    "Operational Efficiency",
    "Process Improvement",
    "Business Modelling",
    "Market Analysis",
    "Product Lifecycle Management",
    "Operational Strategy",
    "Lean Management",
    "Six Sigma",
    "Supply Chain Strategy",
    "Performance Metrics",
  ],
},
{
  section: "Finance & Management",
  name: "Supply Chain & Logistics Management",
  tags: [
    "Inventory Management",
    "Procurement",
    "Demand Forecasting",
    "Logistics Planning",
    "Warehousing",
    "Distribution",
    "Supplier Relations",
    "Supply Chain Optimization",
    "Transportation Management",
    "Order Fulfillment",
    "Just-In-Time (JIT)",
    "Vendor Management",
  ],
},
{
  section: "Engineering & Manufacturing",
  name: "Mechanical Engineering",
  tags: [
    "CAD",
    "SolidWorks",
    "AutoCAD",
    "Finite Element Analysis",
    "Dynamics",
    "Thermodynamics",
    "Fluid Mechanics",
    "Kinematics",
    "Machine Design",
    "MATLAB",
    "ANSYS",
    "Pro/ENGINEER",
    "HVAC",
    "Mechanical Testing",
    "Prototyping"
  ],
},

{
  section: "Engineering & Manufacturing",
  name: "Electrical Engineering",
  tags: [
    "Circuit Design",
    "PCB Design",
    "Power Systems",
    "Embedded Systems",
    "Signal Processing",
    "Control Systems",
    "VHDL",
    "Verilog",
    "Microcontrollers",
    "PLC",
    "Electromagnetic Theory",
    "MATLAB",
    "LabVIEW",
    "Oscilloscope",
    "RF Engineering"
  ],
},

{
  section: "Engineering & Manufacturing",
  name: "Civil Engineering",
  tags: [
    "Structural Analysis",
    "Hydrology",
    "Geotechnical",
    "AutoCAD Civil 3D",
    "Surveying",
    "Transportation Engineering",
    "Sustainability",
    "Infrastructure Design",
    "Construction Management",
    "Land Development",
    "Water Resources",
    "Environmental Impact",
    "Reinforced Concrete",
    "Steel Design"
  ],
},

{
  section: "Engineering & Manufacturing",
  name: "Chemical Engineering",
  tags: [
    "Process Engineering",
    "Material Balance",
    "Kinetics",
    "ChemCAD",
    "Distillation",
    "Heat Transfer",
    "Reactor Design",
    "Separation Processes",
    "Fluid Dynamics",
    "Petrochemicals",
    "Pharmaceuticals",
    "Safety & Hazard Analysis",
    "Polymers",
    "Biochemical"
  ],
},

{
  section: "Engineering & Manufacturing",
  name: "Biomedical Engineering",
  tags: [
    "Medical Devices",
    "Bioinformatics",
    "Biomechanics",
    "Biomedical Imaging",
    "Tissue Engineering",
    "Biomedical Signal Processing",
    "Biomaterials",
    "Rehabilitation Engineering",
    "Genomics",
    "Prosthetics",
    "Orthopedic Design",
    "Biomedical Optics",
    "Medical Robotics",
    "Clinical Engineering"
  ],
},
{
  section: "Engineering & Manufacturing",
  name: "Aerospace Engineering",
  tags: [
    "Flight Mechanics",
    "Aerodynamics",
    "Propulsion",
    "Aircraft Structures",
    "Avionics",
    "Navigation Systems",
    "Control Systems",
    "Spacecraft Design",
    "Wind Tunnel Testing",
    "Aircraft Maintenance",
    "Composite Materials",
    "Orbital Mechanics",
    "CFD",
    "Rocket Science",
    "Flight Simulation"
  ],
},

{
  section: "Engineering & Manufacturing",
  name: "Industrial Engineering",
  tags: [
    "Lean Manufacturing",
    "Six Sigma",
    "Operations Research",
    "Ergonomics",
    "Supply Chain Management",
    "Logistics",
    "Work Study",
    "Quality Control",
    "Systems Optimization",
    "Project Management",
    "Facility Layout",
    "Material Handling",
    "Statistical Analysis",
    "Simulation Modeling",
    "Production Planning"
  ],
},

{
  section: "Engineering & Manufacturing",
  name: "Manufacturing Engineering",
  tags: [
    "CNC Machining",
    "Injection Molding",
    "Quality Assurance",
    "Process Improvement",
    "Materials Selection",
    "Robotics",
    "Welding",
    "Assembly Line Design",
    "Product Lifecycle Management",
    "Tool Design",
    "Lean Principles",
    "Additive Manufacturing",
    "Rapid Prototyping",
    "ISO Standards",
    "SPC (Statistical Process Control)"
  ],
},

{
  section: "Engineering & Manufacturing",
  name: "Environmental Engineering",
  tags: [
    "Water Treatment",
    "Air Quality Management",
    "Waste Management",
    "Sustainability",
    "Environmental Impact Assessment",
    "Hazardous Waste Management",
    "Site Remediation",
    "Stormwater Management",
    "Pollution Control",
    "Green Building Design",
    "Ecology",
    "Hydrogeology",
    "Carbon Footprint Analysis",
    "Recycling Systems",
    "Environmental Regulations"
  ],
},

{
  section: "Engineering & Manufacturing",
  name: "Structural Engineering",
  tags: [
    "Building Design",
    "Foundation Design",
    "Seismic Design",
    "Load Analysis",
    "Finite Element Analysis",
    "Bridge Design",
    "Retaining Walls",
    "Structural Inspections",
    "Reinforced Concrete",
    "Steel Structures",
    "Timber Structures",
    "Masonry Design",
    "Earthquake Engineering",
    "Wind Analysis",
    "Composite Structures"
  ],
},


  ];

  