import React from 'react';
import './PrivacyAndTermsContent.css';

const PrivacyAndTermsContent = () => {
    return (
        <div className="privacy-terms-container">
            <section className="privacy-policy">
                <h2>Privacy Policy for Recruiters on SimpL</h2>
                <p>Last updated: 23/04/2024</p>
                <p>Introduction:</p>
                <p>Welcome to SimpL. As recruiters, your privacy and the responsible handling of the data you access are paramount. This policy details our commitments and your obligations regarding data protection.</p>

                <h3>1. Information Collection:</h3>
                <p>We collect information necessary to facilitate recruitment processes:</p>
                <ul>
                    <li>Contact details and professional information necessary to establish your recruiter profile.</li>
                    <li>Interaction data related to your activities on the platform, such as job postings, candidate evaluations, and communications.</li>
                    <li>Technical data including login information, cookies, and usage statistics.</li>
                </ul>

                <h3>2. Use of Data:</h3>
                <p>Your data is used for the following purposes:</p>
                <ul>
                    <li>To manage your recruiter profile and facilitate recruitment operations.</li>
                    <li>To communicate with you about platform updates or candidate notifications.</li>
                    <li>To ensure compliance with legal requirements and to enhance platform security.</li>
                </ul>

                <h3>3. Data Sharing and Disclosure:</h3>
                <p>Your data may be shared with:</p>
                <ul>
                    <li>Third-party service providers who assist with our operations (subject to strict data protection agreements).</li>
                    <li>Authorities or legal bodies when required by law or to protect rights and safety.</li>
                </ul>

                <h3>4. Data Rights:</h3>
                <p>As per GDPR, you have rights to access, correct, or delete your personal data. Contact our Data Protection Officer to exercise these rights.</p>

                <h3>5. Data Security:</h3>
                <p>We employ robust security measures to protect your data, though no system is infallible. Immediate action will be taken to address any potential data breaches.</p>

                <h3>6. Changes to This Policy:</h3>
                <p>Updates to this policy will be communicated through the platform. Continued use after such updates constitutes agreement to the new terms.</p>
            </section>

            <section className="terms-of-service">
                <h2>Terms of Service for Recruiters on SimpL</h2>
                <p>Last updated: 15/02/2024</p>

                <h3>1. Account Responsibilities:</h3>
                <p>As a recruiter, you agree to:</p>
                <ul>
                    <li>Maintain the security of your account and confidentiality of passwords.</li>
                    <li>Ensure accuracy of the information in your postings and communications.</li>
                    <li>Use the platform only for lawful recruitment purposes.</li>
                </ul>

                <h3>2. Prohibited Activities:</h3>
                <p>You are strictly prohibited from:</p>
                <ul>
                    <li>Engaging in any form of discrimination, harassment, or unethical conduct.</li>
                    <li>Violating privacy rights or misusing candidates' data.</li>
                    <li>Attempting to disrupt or harm the platform’s operational integrity.</li>
                </ul>

                <h3>3. License and Permissions:</h3>
                <p>By using SimpL, you grant us a license to use your company’s branding and job listings to operate the platform effectively.</p>

                <h3>4. Termination and Suspension:</h3>
                <p>We reserve the right to suspend or terminate your access for breaches of these terms.</p>

                <h3>5. Limitation of Liability:</h3>
                <p>While we strive for the highest operational standards, SimpL cannot be held liable for any direct or consequential losses stemming from platform use or data breaches beyond our reasonable control.</p>

            </section>
        </div>
    );
};

export default PrivacyAndTermsContent;
