import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, Route, Routes } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Test from './simpl_recruiting/pages/test/Test';
import ApplicantsGeneral from './simpl_recruiting/pages/job_offers/applicants_general/ApplicantsGeneral';
import CreateJobOfferPage from './simpl_recruiting/pages/job_offers/create_job_offer/CreateJobOfferPage';
import ApplicantView from "./simpl_recruiting/pages/job_offers/applicant_view/ApplicantView";
import RecruiterSettings from "./simpl_recruiting/pages/settings/RecruiterSettings";
import FeedbackComponent from "./components/feedback/FeedbackComponent";
import PrivacyAndTermsContent from "./PrivacyAndTermsContent";
import ApplyJobPage from "./simpl_profile/pages/application/ApplyJobPage.js";
import ApplicantsScroll from "./simpl_recruiting/pages/job_offers/applicants_scroll/ApplicantsScroll";
import Pool from './simpl_recruiting/pages/pool/Pool';
import LoadingPage from "./Loading";
import JobOfferDetails from "./simpl_recruiting/pages/job_offers/applicants_general/JobOfferDetails.js"
import { useEffect, useState } from "react";
import PreRegistration from './PreRegistration';
import JobBoard from './simpl_recruiting/pages/jobboard/JobBoard';
import Auth from "./Auth";
import i18n from './i18n'; // Ensure this import is correct
import PostEmployerPage from './simpl_recruiting/pages/post-employer/PostEmployer';
import { ApplicationStateProvider} from './contexts/ApplicationStateContext';
import SubscriptionProvider from './contexts/SubscriptionContext';
import JobOffers from './simpl_recruiting/pages/job_offers/JobOffersPage';
import EmailConfirm from './EmailConfirm';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const ProtectedRoute = ({ allowedRoles, children }) => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();


  if (isLoading) {
    return <LoadingPage/>;
  }

  if (!isAuthenticated) {
  
    const currentPath = window.location.pathname;
  
    // Save it in sessionStorage only if it's from the 'apply' page
    if (currentPath.startsWith('/apply')) {
        sessionStorage.setItem('targetUrl', currentPath);
        loginWithRedirect({
          appState: { targetUrl: currentPath }
        });

    } else {
        loginWithRedirect();
    }

    return null;
}

 


  const userRole = user['https://thesimplplatform.io/roles'];

  return children;
};


const App = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    const handleLanguageChange = (lng) => {
      // This will set the state causing a re-render
      setLanguage(lng);
    };

    // Listen for language changes
    i18n.on('languageChanged', handleLanguageChange);

    // Cleanup the listener on component unmount
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);


  return (
    <Router>
    <SubscriptionProvider>
    <ApplicationStateProvider>
      <Routes>
        <Route path="/authpage" element={<Auth />} />
        <Route path="/job_offers" element={<ProtectedRoute allowedRoles={['Employer']}><JobOffers /></ProtectedRoute>} />
        <Route path="/pool" element={<ProtectedRoute allowedRoles={['Employer']}><Pool /></ProtectedRoute>} />
        <Route path="/test" element={<Test />} />
        <Route path="/applicants/:jobId" element={<ProtectedRoute allowedRoles={['Employer']}><ApplicantsGeneral /></ProtectedRoute>} />
        <Route path="/details" element={<ProtectedRoute allowedRoles={['Employer']}><JobOfferDetails /></ProtectedRoute>} />
        <Route path="/applicants/scroll/:jobId" element={<ApplicantsScroll/>} />
        <Route path="/applicant/:applicantId" element={<ProtectedRoute allowedRoles={['Employer']}><ApplicantView /></ProtectedRoute>} />
        <Route path="/create_job_offer" element={<ProtectedRoute allowedRoles={['Employer']}><CreateJobOfferPage /></ProtectedRoute>} />
        <Route path="/recruiter_settings" element={<ProtectedRoute allowedRoles={['Employer']}><RecruiterSettings /></ProtectedRoute>} />
        <Route path="/post_employer" element={<ProtectedRoute allowedRoles={['Employer']}><PostEmployerPage /></ProtectedRoute>} />
        <Route path="/pre-registration" element={<PreRegistration />} />
        <Route path="/loading" element={<LoadingPage />} />
        <Route path="/apply/:jobId" element={<ApplyJobPage />} />
        <Route path="/privacy-and-terms" element={<PrivacyAndTermsContent />} /> {/* Added the new route here */}
        <Route path="*" element={<Navigate replace to="/job_offers" />} />
        <Route path="/jobboard/:companyName" element={<JobBoard />} />
        <Route path="/email-confirm" element={<EmailConfirm />} />
      </Routes>
      <FeedbackComponent/>
      <ToastContainer position="top-right" autoClose={3000} />

      </ApplicationStateProvider>
    </SubscriptionProvider>
    </Router>
  );
}


export default App;
