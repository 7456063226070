import React from 'react';
import './JobOfferComponent.css';
import JobOfferState from '../job-offer-state/Jobofferstate'
import { useTranslation } from 'react-i18next';


const JobOffer = ({ joboffer }) => {
  const { t } = useTranslation();

  const dateCreated = new Date(parseInt(joboffer.createdAt));
  const currentDate = new Date();
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const daysActive = Math.round(Math.abs((dateCreated - currentDate) / oneDay));

  const placeParts = joboffer.place.split(',');
  const cityName = placeParts.length >= 3 ? placeParts[2].trim() : joboffer.place;

  console.log(joboffer);

  return (
    <div className="joboffer">
      <div className='joboffer-title-locality'>
        <div className="joboffer-title">{joboffer.title}</div>
        <div className="joboffer-locality-type">{cityName} ({t(joboffer.workMode)})</div>
      </div>
      <div className="joboffer-activity">
        {daysActive} {t('Days')}
      </div>
      <JobOfferState state={joboffer.state} />
      <div className="joboffer-applications-number">
        {joboffer.applicationsCount}
        {joboffer.newApplicantsCount > 0 && (
          <div className="joboffer-new-applications">
            +{joboffer.newApplicantsCount}
          </div>
        )}
      </div>
    </div>
  );
};

export default JobOffer;